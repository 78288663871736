import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { stylesheet } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"
import { ViewComponent } from "../../../../base/ViewComponent"

interface Props extends ViewComponentProps {
  active?: boolean
  disabled?: boolean
  onClick?: (event: any) => void
}

const inactiveColor = "#787880"

export default class ReportSelectorButton extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSelectorButton"]
  }

  constructor(props) {
    super(props)

    setupStyles()
  }

  render() {
    super.render()

    const { children, active, onClick } = this.props

    return (
      <div
        className={classNames(styles.button, active ? styles.buttonActive : styles.buttonInactive)}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }
}

let styles
const setupStyles = () => {
  styles = stylesheet({
    button: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.25rem 1rem",
      borderRadius: "0.5rem",
      cursor: "pointer",
      transition: "color 0.3s ease, background-color 0.3s ease"
    },
    buttonActive: {
      color: ReportSelectorButton.theme.colors.fontColor.toString(),
      backgroundColor: ReportSelectorButton.theme.colors.inputBackgroundColor.toString()
    },
    buttonInactive: {
      color: ReportSelectorButton.theme.colors.fontLightColor.toString(),
      $nest: {
        "&:hover": {
          color: ReportSelectorButton.theme.colors.baseGray.lighten(0.3).toString()
        } as NestedCSSProperties
      }
    }
  })
}
