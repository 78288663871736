import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Grid from "lib/ui/components/layout/grid/Grid"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import {
  ReportVariant,
  blackColor,
  reportLargeBodyClass,
  reportMediumHeadingClass,
  reportSmallBodyClass,
  reportSmallHeadingClass
} from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
}

export default class ReportSectionMyPlan extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionMyPlan"]
  }

  constructor(props) {
    super(props)

    setupStyles(this.appConfig, props.reportVariant)
  }

  render() {
    const { reportVariant } = this.props

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid spacer spacerSize={reportVariant === "pdf" ? -12 : 8} />
        {this.renderMyPlanBackground()}
        <Grid container className="same-page">
          <Grid spacer spacerSize={16} />
          <Grid xs={12} lg={6} className={styles.titleContainer}>
            <Typography useContrastColor component="span" className={reportMediumHeadingClass}>
              {this.txt("start_story_title")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={6}></Grid>
          <Grid xs={12} lg={6}>
            <Typography useContrastColor className={reportLargeBodyClass}>
              {this.txt("start_story_description")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={6}></Grid>
          <Grid spacer spacerSize={reportVariant === "mobile" ? 30 : 12} />
          <Grid xs={reportVariant === "mobile" ? 12 : 10} className={styles.titleContainer}>
            <div className={styles.compass} />
            <Typography
              useContrastColor
              component="span"
              className={classNames(styles.myPlan, reportMediumHeadingClass)}
            >
              {this.txt("my_plan")}
            </Typography>
          </Grid>
          {reportVariant !== "mobile" && <Grid xs={2}></Grid>}
          <Grid spacer spacerSize={reportVariant === "mobile" ? 4 : 12} />
        </Grid>
        <Grid container className="same-page">
          <Grid sm={12}>
            <div className={styles.hrGradient}></div>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("higher_level_goal_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass)}>
              {this.txt("higher_level_goal_description")}
            </Typography>
            <div className={styles.textContainer}>
              {reportVariant !== "pdf" && (
                <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.placeholderText)}>
                  {this.txt("higher_level_goal_placeholder")}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="same-page">
          <Grid spacer spacerSize={3} />
          <Grid sm={12}>
            <div className={styles.hrGradient}></div>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("my_target_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass)}>
              {this.txt("my_target_description")}
            </Typography>
            <div className={styles.textContainer}>
              {reportVariant !== "pdf" && (
                <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.placeholderText)}>
                  {this.txt("my_target_placeholder")}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid spacer spacerSize={3} />
          <Grid xs={12}>
            <div className={styles.hrGradient}></div>
          </Grid>
        </Grid>
        <Grid container justify="space-between" className="same-page">
          <Grid xs={12}>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("my_actions_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass)}>
              {this.txt("my_actions_description")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={4} className={styles.myActionsTopMargin}>
            <div className={styles.textContainer}>
              {reportVariant !== "pdf" && (
                <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.placeholderText)}>
                  {this.txt("my_actions_placeholder_1")}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid xs={12} lg={4} className={styles.myActionsTopMargin}>
            <div className={styles.textContainer}>
              {reportVariant !== "pdf" && (
                <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.placeholderText)}>
                  {this.txt("my_actions_placeholder_2")}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid xs={12} lg={4} className={styles.myActionsTopMargin}>
            <div className={styles.textContainer}>
              {reportVariant !== "pdf" && (
                <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.placeholderText)}>
                  {this.txt("my_actions_placeholder_3")}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid container className="same-page">
          <Grid spacer spacerSize={3} />

          <Grid sm={12}>
            <div className={styles.hrGradient}></div>
            <Typography useContrastColor className={classNames(reportSmallHeadingClass, styles.textfieldTitle)}>
              {this.txt("obstacles_title")}
            </Typography>
            <Typography useContrastColor className={classNames(reportSmallBodyClass)}>
              {this.txt("obstacles_description")}
            </Typography>
            <div className={styles.textContainer}>
              {reportVariant !== "pdf" && (
                <Typography useContrastColor className={classNames(reportSmallBodyClass, styles.placeholderText)}>
                  {this.txt("obstacles_placeholder")}
                </Typography>
              )}
            </div>
          </Grid>
          <Grid spacer spacerSize={8} />
        </Grid>
      </>
    )
  }

  private renderMyPlanBackground() {
    return (
      <>
        <div className={styles.backgroundContainer}>
          <div className={styles.backgroundImage} />
        </div>
      </>
    )
  }
}

let styles

const setupStyles = (appConfig, reportVariant) => {
  styles = stylesheet({
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: reportVariant === "mobile" ? "center" : undefined
    },
    compass: {
      backgroundImage: `url(${appConfig.imagesRoot}/wellbeing_report/compass.svg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: reportVariant === "mobile" ? "-52vw" : undefined,
      backgroundSize: reportVariant === "mobile" ? "450vw" : "cover",
      width: reportVariant === "mobile" ? "calc(100% + 3rem)" : "88rem",
      height: "80rem",
      position: "absolute",
      marginLeft: reportVariant === "mobile" ? "-2rem" : "-8rem",
      marginTop: reportVariant === "mobile" ? "4rem" : "-3rem",
      zIndex: -3
    },
    myPlan: {
      marginLeft: reportVariant === "mobile" ? undefined : "22rem"
    },
    backgroundContainer: {
      position: "relative",
      height: 0,
      marginLeft: reportVariant === "mobile" ? "-10rem" : undefined
    },
    myActionsTopMargin: {
      marginTop: reportVariant === "mobile" ? csx.important("1rem") : undefined
    },
    backgroundImage: {
      position: "relative",
      margin: "inherit",
      height: "80rem",
      opacity: 0.35,
      backgroundImage: `url("${appConfig.imagesRoot}/wellbeing_report/my_plan.jpg")`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      boxShadow: `0 0 10rem 10rem rgb(${ReportSectionMyPlan.theme.colors.hintsa.charcoal.red()},${ReportSectionMyPlan.theme.colors.hintsa.charcoal.green()},${ReportSectionMyPlan.theme.colors.hintsa.charcoal.blue()}) inset`,
      zIndex: -5
    },
    hrGradient: {
      display: "flex",
      width: reportVariant === "mobile" ? "100%" : "calc(100% - 2rem);",
      height: "1px",
      alignItems: "center",
      justifyContent: "center",
      opacity: 0.8,
      background:
        "linear-gradient(to right, #EA9F88 0%, #FFE0D4 20%, #F8F6E3 40%, #B8CBC6 60%, #7996A1 80%, #537D81 100%)",
      zIndex: 100
    },
    textfieldTitle: {
      marginTop: "1rem",
      marginBottom: "0rem"
    },
    textContainer: {
      backgroundColor:
        reportVariant === "pdf" ? ReportSectionMyPlan.theme.colors.backgroundColor.toString() : blackColor.toString(),
      opacity: reportVariant === "pdf" ? 1 : 0.8,
      height: "7rem",
      padding: "0.75rem",
      width: reportVariant === "mobile" ? "100%" : "calc(100% - 2rem);"
    },
    placeholderText: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    }
  })
}
