import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Typography from "lib/ui/components/typography/Typography"

import { CategoryPrefix } from "lib/utils/report/WellbeingCommonReportUtils"
import {
  TypeOfReport,
  answersByCategory,
  getAnswerPoints,
  getAnswersAveragePoints
} from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"
import EnergyScoreView from "../../../ui/report/EnergyScoreView"
import ScoreCardView from "../../../ui/report/ScoreCardView"
import ScoreRatingView from "../../../ui/report/ScoreRatingView"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
  reportType: TypeOfReport
}

class SectionLifePerformanceView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["report", "pages", "report", "SectionLifePerformanceView"]
  }

  get energyScore() {
    const prefix = `${CategoryPrefix.WELLBEING_AT_WORK}_burnout_`
    const answers = this.props.answers?.filter(a => a.question_key?.startsWith(prefix)) ?? []

    return answers.reduce((total, answer) => total + getAnswerPoints(answer), 0)
  }

  get previousEnergyScore() {
    if (this.props.reportType !== TypeOfReport.FOLLOW_UP) {
      return null
    }

    const prefix = `${CategoryPrefix.WELLBEING_AT_WORK}_burnout_`
    const answers = this.props.previousAnswers?.filter(a => a.question_key?.startsWith(prefix)) ?? []

    return answers.reduce((total, answer) => total + getAnswerPoints(answer), 0)
  }

  get energyText() {
    if (this.energyScore > 12) {
      return this.txt("energy_12_5")
    } else if (this.energyScore > 8) {
      return this.txt("energy_9_11")
    } else {
      return this.txt("energy_3_8")
    }
  }

  getAnswers = (categoryPrefix: string) => answersByCategory(this.props.answers, categoryPrefix)
  getPreviousAnswers = (categoryPrefix: string) => {
    if (this.props.reportType !== TypeOfReport.FOLLOW_UP || !this.props.previousAnswers) {
      return []
    }
    return answersByCategory(this.props.previousAnswers, categoryPrefix)
  }

  getScore = (answer?: Answer) => answer?.options[0]?.points ?? 0

  render() {
    super.render()

    const { classes } = this.props

    const coreAverage = getAnswersAveragePoints(this.getAnswers(CategoryPrefix.CORE))
    const previousCoreAverage = getAnswersAveragePoints(this.getPreviousAnswers(CategoryPrefix.CORE))

    const workAverage = getAnswersAveragePoints(this.getAnswers(CategoryPrefix.WELLBEING_AT_WORK))
    const previousWorkAverage = getAnswersAveragePoints(this.getPreviousAnswers(CategoryPrefix.WELLBEING_AT_WORK))
    const life = this.getAnswers(CategoryPrefix.LIFE_SATISFACTION)[0]
    const previousLife = this.getPreviousAnswers(CategoryPrefix.LIFE_SATISFACTION)[0]
    const work = this.getAnswers(CategoryPrefix.WORK_PERFORMANCE)[0]
    const previousWork = this.getPreviousAnswers(CategoryPrefix.WORK_PERFORMANCE)[0]

    return (
      <li>
        <Typography className={classes.header} variant="smallHeading">
          {this.txt("title")}
        </Typography>

        <Grid className={classes.spacing} container spacing={4}>
          <Grid item xs={12}>
            {this.renderSubtitle(this.props.reportType)}
          </Grid>
        </Grid>

        <div className="same-page">
          <Grid className={classes.spacing} container spacing={4}>
            {life ? (
              <Grid item xs>
                <ScoreCardView
                  max={10}
                  previousScore={this.getScore(previousLife)}
                  score={this.getScore(life)}
                  title={this.txt(life.title)}
                />
              </Grid>
            ) : undefined}

            {coreAverage ? (
              <Grid item xs>
                <ScoreRatingView
                  previousScore={previousCoreAverage}
                  score={coreAverage}
                  title={this.txt("core_total")}
                />
              </Grid>
            ) : (
              <Grid item xs />
            )}

            <Grid item xs={12}>
              {this.renderDescription(this.props.reportType)}
              {(work || workAverage) && <Divider className={classes.spacing} />}
            </Grid>

            {work && (
              <Grid item xs>
                <ScoreCardView
                  max={10}
                  previousScore={this.getScore(previousWork)}
                  score={this.getScore(work)}
                  title={this.txt(work.title)}
                />
              </Grid>
            )}

            {workAverage ? (
              <Grid item xs>
                <Divider className={classes.spacing} />
                <ScoreRatingView
                  previousScore={previousWorkAverage}
                  score={workAverage}
                  title={this.txt("work_total")}
                />
              </Grid>
            ) : (
              <Grid item xs />
            )}

            <Grid item xs={12}>
              <Typography>{this.renderWorkPerformance(this.props.reportType)}</Typography>
            </Grid>
          </Grid>
        </div>

        <Divider className={classes.spacing} />

        {this.energyScore ? (
          <Grid className="same-page" container spacing={4}>
            <Grid item xs>
              <Typography paragraph variant="largeBold">
                {this.txt("energy_score")}
              </Typography>
              <Typography>{this.energyText}</Typography>
            </Grid>

            <Grid item xs>
              <EnergyScoreView previousScore={this.previousEnergyScore} score={this.energyScore} />
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.caption}>{this.txt("energy_score_base")}</Typography>
            </Grid>
          </Grid>
        ) : undefined}
      </li>
    )
  }

  private renderSubtitle(type: TypeOfReport) {
    return (
      <Typography variant="largeBody">
        {type === TypeOfReport.INITIAL ? this.txt("ingress") : this.txt("follow_up_ingress")}
      </Typography>
    )
  }

  private renderDescription(type: TypeOfReport) {
    return (
      <Typography>
        {type === TypeOfReport.INITIAL ? this.txt("description") : this.txt("follow_up_description")}
      </Typography>
    )
  }

  private renderWorkPerformance(type: TypeOfReport) {
    return (
      <Typography>
        {type === TypeOfReport.INITIAL ? this.txt("work_performance") : this.txt("follow_up_work_performance")}
      </Typography>
    )
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    header: { display: "inline" },
    caption: { color: ViewComponent.theme.colors.deEmphasizedColor.toString() },
    spacing: { marginBottom: spacing(2) }
  })

export default withStyles<any, any, Props>(styles)(SectionLifePerformanceView)
