import { environment } from "core/modules/config/appConfig"

export interface AppConfig {
  automaticLogoutMinutes: number
  googleAnalyticsToken: string
  imagesRoot: string
  spinnerDelay: number
}

export const appConfig: AppConfig = {
  automaticLogoutMinutes: 30,
  googleAnalyticsToken: { production: "G-2EXPSEF52Y", staging: "G-PPRSWPL57R" }[environment]!,
  imagesRoot: "/webapp/surveys_ui",
  spinnerDelay: 2000
}
