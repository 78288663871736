import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import AnswerView from "lib/ui/components/reports/AnswerView"
import Typography from "lib/ui/components/typography/Typography"

import { CategoryPrefix } from "lib/utils/report/WellbeingCommonReportUtils"
import {
  ReportType,
  answersByReportType,
  answersWithPoints,
  getMaxScoreForAnswer,
  pointsSorter
} from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
}

class SectionGoalsToActionsView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["report", "pages", "report", "SectionGoalsToActionsView"]
  }

  get actionAnswers() {
    const actionAnswers = answersByReportType(this.props.answers, ReportType.ACTION)
    const previousActionAnswers = answersByReportType(this.props.previousAnswers, ReportType.ACTION)
    const answers = answersWithPoints(actionAnswers).sort(pointsSorter)
    const answerIds = answers.map(answer => answer.question_id)
    const previousAnswers = answersWithPoints(previousActionAnswers)
      .filter(answer => answerIds.includes(answer.question_id))
      .sort((a, b) => {
        return answerIds.indexOf(a.question_id) - answerIds.indexOf(b.question_id)
      })
    return {
      answers,
      previousAnswers
    }
  }

  render() {
    const { classes } = this.props

    const weaknesses = {
      current: this.actionAnswers.answers.slice(0, 2),
      previous: this.actionAnswers.previousAnswers.slice(0, 2)
    }
    const strengths = {
      current: this.actionAnswers.answers
        .filter(a => {
          return (
            !a.question_key?.startsWith(CategoryPrefix.GENERAL_HEALTH) &&
            !a.question_key?.startsWith(CategoryPrefix.LIFE_SATISFACTION) &&
            !a.question_key?.startsWith(CategoryPrefix.WORK_PERFORMANCE)
          )
        })
        .slice(-2)
        .reverse(),
      previous: this.actionAnswers.previousAnswers
        .filter(a => {
          return (
            !a.question_key?.startsWith(CategoryPrefix.GENERAL_HEALTH) &&
            !a.question_key?.startsWith(CategoryPrefix.LIFE_SATISFACTION) &&
            !a.question_key?.startsWith(CategoryPrefix.WORK_PERFORMANCE)
          )
        })
        .slice(-2)
        .reverse()
    }

    return (
      <li>
        <Typography className={classes.header} variant="smallHeading">
          {this.txt("title")}
        </Typography>
        <Typography variant="largeBody">{this.txt("ingress")}</Typography>

        <section className="same-page">
          <Typography component="h3">{this.txt("weaknesses_title")}</Typography>
          <Typography paragraph>{this.txt("weaknesses_description")}</Typography>

          {this.renderAnswer(weaknesses)}
        </section>

        <section className="same-page">
          <Typography component="h3">{this.txt("strengths_title")}</Typography>
          <Typography paragraph>{this.txt("strengths_description")}</Typography>

          {this.renderAnswer(strengths)}
        </section>
      </li>
    )
  }

  renderAnswer = ({ current, previous }) => {
    const elements = []
    for (let index = 0; index < current.length; index++) {
      elements.push(
        <Card key={current.question_id} className={this.props.classes.answer} variant="outlined">
          <CardContent>
            <AnswerView
              answer={current[index]}
              maxScore={getMaxScoreForAnswer(current[index])}
              previousAnswer={previous[index]}
              showCategory
            />
          </CardContent>
        </Card>
      )
    }
    return elements
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    answer: { marginTop: spacing(2) },
    header: { display: "inline" }
  })

export default withStyles<any, any, Props>(styles)(SectionGoalsToActionsView)
