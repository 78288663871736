import { style } from "typestyle"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import Typography from "lib/ui/components/typography/Typography"

import { ViewComponent } from "../../base/ViewComponent"
import DeltaScoreView from "./DeltaScoreView"

interface Props extends ViewComponentProps {
  max?: number
  previousScore?: number
  previousScorePrecision?: number
  score?: number | string
}

export default class ScoreView extends ViewComponent<Props> {
  get componentName() {
    return ["ui", "report", "ScoreView"]
  }

  render() {
    super.render()

    const { max, previousScore, previousScorePrecision, score } = this.props
    const deltaScore = previousScore && previousScore > 0 ? Number(score) - Number(previousScore) : null

    return (
      <div>
        <div className={this.titleContainer}>
          <Typography noWrap noMargin variant="heading">
            {score} {max !== undefined && <small className={this.smallClass}>/ {max}</small>}
          </Typography>
        </div>
        {deltaScore !== null && !isNaN(deltaScore) && (
          <div className={this.deltaContainer}>
            <small className={this.deltaTitle}>{this.txt("title")}</small>
            <DeltaScoreView deltaScore={deltaScore} scorePrecision={previousScorePrecision} />
          </div>
        )}
      </div>
    )
  }

  private titleContainer = style({
    display: "flex",
    justifyContent: "end"
  })

  private deltaTitle = style({
    marginRight: "0.5rem"
  })

  private deltaContainer = style({
    display: "flex"
  })

  private smallClass = style({
    color: this.theme.colors.deEmphasizedColor.toString(),
    fontSize: "50%"
  })
}
