import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"

import { CategoryPrefix } from "lib/utils/report/WellbeingCommonReportUtils"
import { answersByCategory } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"
import CategoryAnswersView from "../../../ui/report/CategoryAnswersView"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
}

export default class extends ViewComponent<Props> {
  get answers() {
    return answersByCategory(this.props.answers, CategoryPrefix.WELLBEING_AT_WORK)
  }

  get previousAnswers() {
    return answersByCategory(this.props.previousAnswers, CategoryPrefix.WELLBEING_AT_WORK)
  }

  render() {
    return (
      <CategoryAnswersView
        answers={this.answers}
        component="li"
        headerComponent="h2"
        previousAnswers={this.previousAnswers}
      />
    )
  }
}
