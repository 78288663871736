import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as csx from "csx"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import Typography from "lib/ui/components/typography/Typography"
import { ViewComponent } from "../../base/ViewComponent"
import DeltaScoreView from "./DeltaScoreView"

interface Props extends ViewComponentProps {
  score?: number
  previousScore?: number
}

class EnergyScoreView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["ui", "report", "EnergyScoreView"]
  }

  render() {
    const { classes, previousScore, score } = this.props

    const deltaScore = previousScore ? Number(score) - Number(previousScore) : null
    super.render()

    const bars = []
    for (let i = 3; i <= 15; i++) {
      const className = i < 9 ? classes.low : i < 13 ? classes.medium : classes.high

      bars.push(
        <div key={i} className={classNames(classes.bar, className)}>
          {i === score ? (
            <Typography variant="heading" className={classes.indicator}>
              {i}
              <i />
            </Typography>
          ) : null}
          <div />
          <small>{i}</small>
        </div>
      )
    }

    return (
      <div>
        <div className={classes.root}>{bars}</div>
        {deltaScore !== null && !isNaN(deltaScore) && (
          <div className={classes.deltaContainer}>
            <small className={classes.deltaTitle}>{this.txt("delta_title")}</small>
            <DeltaScoreView deltaScore={deltaScore} />
          </div>
        )}
      </div>
    )
  }
}

const styles = () =>
  createStyles({
    deltaContainer: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "end"
    },
    deltaTitle: {
      marginRight: "0.5rem"
    },
    root: {
      alignItems: "flex-end",
      display: "flex",
      flexDirection: "row",
      height: "5rem",

      "@media print": { minWidth: "8cm" }
    },
    indicator: {
      marginBottom: csx.important(0)
    },
    bar: {
      flex: 1,
      textAlign: "center",

      "& i": {
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderBottom: "10px solid transparent",
        display: "block",
        height: 0,
        margin: "0 auto",
        width: 0
      },

      "& small": {
        color: ViewComponent.theme.colors.deEmphasizedColor.toString()
      },

      "& div": {
        borderBottom: "6px solid transparent",
        height: 0,
        overflow: "hidden"
      },
      "& big + div": { borderBottomWidth: "10px" },
      "&:first-child div": { borderRadius: "6px 0 0 6px" },
      "&:last-child div": { borderRadius: "0 6px 6px 0" },
      "&:not(:last-child) div": { marginRight: "1px" }
    },

    low: { "& div, & i": { borderBottomColor: ViewComponent.theme.colors.report.negative.toString() } },
    medium: { "& div, & i": { borderBottomColor: ViewComponent.theme.colors.report.neutral.toString() } },
    high: { "& div, & i": { borderBottomColor: ViewComponent.theme.colors.report.positive.toString() } }
  })

export default withStyles<any, any, Props>(styles)(EnergyScoreView)
