import Box from "@material-ui/core/Box"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as React from "react"

import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import AnswerView from "lib/ui/components/reports/AnswerView"
import Typography from "lib/ui/components/typography/Typography"

import { getAnswersAveragePoints, getMaxScoreForAnswer } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../base/ViewComponent"
import ScoreRatingView from "./ScoreRatingView"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  categoryHeader?: string
  categoryIcon?: string
  component?: React.ElementType
  headerComponent?: React.ElementType
  previousAnswers?: Answer[]
}

const XS_ONLY = { xs: "inline-block", sm: "none" }

// dom fragments to insert during rendering to force page breaks
// to appear at specific points in the document
const forcePageBreak = <div className="hard-page-break"></div>
const emptyDiv = <div></div>

class CategoryAnswersView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["ui", "report", "CategoryAnswersView"]
  }

  render() {
    const { answers, categoryHeader, categoryIcon, classes, component, previousAnswers } = this.props

    if (!answers?.length) return null

    const average = getAnswersAveragePoints(answers)
    const previousAverage = previousAnswers ? getAnswersAveragePoints(previousAnswers) : undefined
    const icon = categoryIcon && (
      <Box component="span" display={XS_ONLY} displayPrint="inline-block">
        <img alt="" className={classes.categoryIcon} src={categoryIcon} />
      </Box>
    )

    const answerViews = []
    const elements = []
    for (let index = 0; index < answers.length; index++) {
      const answer = answers[index]
      const previousAnswer = previousAnswers[index]
      answerViews.push(
        <li key={answer.question_id}>
          <AnswerView answer={answer} maxScore={getMaxScoreForAnswer(answer)} previousAnswer={previousAnswer} />
        </li>
      )
    }

    return (
      <Box component={component}>
        <Typography className={classNames(classes.headerImage, classes.title)} variant="smallHeading">
          {categoryHeader && <img alt="" className={classes.categoryHeader} src={categoryHeader} />}
          {icon}
          {this.txt(answers[0].category)}
        </Typography>
        <Typography paragraph variant="largeBody">
          {this.txt(answers[0].category_description)}
        </Typography>

        <ol className={classes.answers}>
          {answerViews}
          <li className={classes.elementScore}>
            <ScoreRatingView previousScore={previousAverage} score={average} title={this.txt("element_score")} />
          </li>
        </ol>
      </Box>
    )
  }

  checkForLineBreak = (questionID: number) => {
    // after inspecting the report output, these are the specific questions
    // at which a forced page break are placed, to create a visually appealing
    // print out

    if (questionID == 55 || questionID == 8 || questionID == 45) {
      return [forcePageBreak, emptyDiv]
    } else {
      return null
    }
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    answers: {
      listStylePosition: "initial",

      "& li": {
        borderTop: `1px solid ${ViewComponent.theme.colors.disabled.toString()}`,
        padding: "1rem"
      },
      "& li::marker": { color: ViewComponent.theme.colors.secondary.toString() }
    },

    categoryHeader: {
      marginBottom: spacing(2),
      width: "100%"
    },

    categoryIcon: {
      height: "30px",
      marginBottom: "6px",
      marginRight: "6px"
    },

    elementScore: { listStyleType: "none" },

    header: { display: "inline-block" },
    headerImage: { display: "block" },
    title: {
      display: "inline"
    }
  })

export default withStyles<any, any, Props>(styles)(CategoryAnswersView)
