import { style } from "typestyle"

import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import { TranslatedString } from "core/modules/state/model/Model"
import Button from "lib/ui/components/form/Button"
import CheckBox from "lib/ui/components/form/Checkbox"
import CardLayout from "lib/ui/components/layout/CardLayout"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import MarkdownConverter from "lib/ui/components/markdown/MarkdownConverter"
import LanguageSelectorDropdown from "lib/ui/components/settings/LanguageSelectorDropdown"
import Typography from "lib/ui/components/typography/Typography"

import { Glyph } from "lib/ui/components/symbols"
import { ViewComponent } from "../../../base/ViewComponent"

interface StartPageViewProps extends ViewComponentProps {
  title: string
  description: string
  language: string
  logo: string
  customLogo: string
  customWelcomeMessage: TranslatedString
  showLanguageSelection?: boolean
  showConsent: boolean
  error?: "notFound" | "error" | "alreadyCompleted" | "surveyClosed"
  estimatedTime: number
  numberOfQuestions: number
  includesReport: boolean
  surveySubheading?: string
  onSetLanguage: (language: string) => void
  onStart: () => void
}

interface StartPageViewState extends ViewComponentState {
  consentGiven: boolean
}

export default class StartPageView extends ViewComponent<StartPageViewProps, StartPageViewState> {
  get componentName() {
    return ["survey", "pages", "start", "StartPageView"]
  }

  constructor(props) {
    super(props)

    this.state = { consentGiven: false }
  }

  private get containerClass() {
    return style({
      display: "flex",
      flexDirection: "column",
      height: "100%",
      minWidth: this.isMobile ? "18rem" : undefined,
      margin: this.isMobile ? "1.5rem" : "2.5rem"
    })
  }

  get logoClass() {
    const { logo } = this.props

    return style({
      backgroundImage: `url(${logo})`,
      backgroundSize: "cover",
      width: "12.75rem",
      height: "2.25rem",
      marginBottom: "2rem"
    })
  }

  get organisationLogoClass() {
    const { customLogo } = this.props

    return style({
      backgroundImage: `url(${customLogo})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "right",
      height: "3rem"
    })
  }

  get buttonContainerClass() {
    return style({
      marginTop: "2rem",
      flexGrow: this.isMobile ? 1 : undefined,
      display: "flex",
      alignItems: this.isMobile ? "flex-end" : undefined
    })
  }

  get introductionContainerClass() {
    return this.isMobile
      ? style({
          fontSize: "14px"
        })
      : style({
          margin: "auto"
        })
  }

  get labelClass() {
    return style({
      display: "inline-block",
      fontWeight: "inherit",
      $nest: {
        "p:last-child": { margin: 0 }
      }
    })
  }

  get consentContainerClass() {
    return style({
      display: "flex",
      alignItems: "center"
    })
  }

  get mobileLayoutClass() {
    return style({
      height: "100%",
      display: "flex",
      flexDirection: "column"
    })
  }

  get infoGlyphClass() {
    return style({
      marginRight: "0.25rem"
    })
  }

  get titleClass() {
    return this.isMobile
      ? style({
          fontSize: "28px",
          fontWeight: "bold"
        })
      : style({
          fontSize: "3rem",
          fontWeight: "bold",
          marginTop: "0.25rem"
        })
  }

  get infoContainerClass() {
    return style({
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
      marginTop: "-1.5rem"
    })
  }

  get infoParentContainerClass() {
    return style({
      marginTop: "1.5rem",
      marginBottom: "-0.5rem"
    })
  }

  render() {
    super.render()

    return this.isMobile ? this.renderMobile() : this.renderDesktop()
  }

  private renderMobile() {
    return <div className={this.mobileLayoutClass}>{this.renderMain()}</div>
  }

  private renderDesktop() {
    return (
      <CardLayout width="50rem" background={this.theme.background} headerBackgroundColor={this.theme.colors.primary}>
        {this.renderMain()}
      </CardLayout>
    )
  }

  private renderMain() {
    super.render()

    const { surveySubheading } = this.props

    return (
      <div className={this.containerClass}>
        {this.renderLogos()}
        <div>
          <div>
            <div className={this.introductionContainerClass}>
              <Typography variant="heading" className={this.titleClass}>
                {this.props.title}
              </Typography>
              {surveySubheading && <Typography variant="smallHeading">{surveySubheading}</Typography>}
              {this.renderLanguageSelection()}
              {this.renderSurveyInfo()}
              <MarkdownConverter linksInNewWindow={true}>
                <Markdown
                  content={
                    this.props.customWelcomeMessage?.[this.props.language] ??
                    this.props.customWelcomeMessage?.["en"] ??
                    this.props.description
                  }
                />
              </MarkdownConverter>
            </div>
          </div>
        </div>
        {this.renderConsent()}
        {this.renderStartButton()}
      </div>
    )
  }

  private renderSurveyInfo() {
    const { numberOfQuestions, estimatedTime, includesReport } = this.props

    return (
      <div className={this.infoParentContainerClass}>
        {estimatedTime && (
          <div className={this.infoContainerClass}>
            <Glyph glyphClass={this.infoGlyphClass} glyphType="materialize" glyph="timer" />
            <Typography component="span">
              <Markdown content={this.txt("estimatedTime", { estimatedTime })} />
            </Typography>
          </div>
        )}
        <div className={this.infoContainerClass}>
          <Glyph glyphClass={this.infoGlyphClass} glyphType="materialize" glyph="live_help" />
          <Typography component="span">
            <Markdown content={this.txt("numberOfQuestions", { numberOfQuestions })} />
          </Typography>
        </div>
        {includesReport && (
          <div className={this.infoContainerClass}>
            <Glyph glyphClass={this.infoGlyphClass} glyphType="materialize" glyph="assessment" />
            <Typography component="span">
              <Markdown content={this.txt("reportCustomTextWellbeing")} />
            </Typography>
          </div>
        )}
      </div>
    )
  }

  private renderLogos() {
    if (this.props.customLogo) {
      return this.renderLogoWithCustom()
    }

    return this.renderLogo()
  }

  private renderLogo() {
    return (
      <Grid container>
        <Grid item sm={12}>
          <div className={this.logoClass} />
        </Grid>
      </Grid>
    )
  }

  private renderLogoWithCustom() {
    return (
      <Grid container>
        <Grid item sm={6}>
          <div className={this.logoClass} />
        </Grid>
        <Grid item sm={6}>
          <div className={this.organisationLogoClass} />
        </Grid>
      </Grid>
    )
  }

  private renderStartButton() {
    const { error, showConsent, onStart } = this.props
    const { consentGiven } = this.state

    if (error) {
      return undefined
    }

    return (
      <div className={this.buttonContainerClass}>
        <Button fullWidth={this.isMobile} disabled={showConsent && !consentGiven} onClick={onStart}>
          {this.txt("start")}
        </Button>
      </div>
    )
  }

  private renderConsent() {
    const { consentGiven } = this.state
    const { showConsent } = this.props

    if (!showConsent) return false

    return (
      <div className={this.consentContainerClass}>
        <CheckBox
          id="consent_given"
          value={consentGiven}
          onChange={() => this.setState({ consentGiven: !consentGiven })}
        />
        <label htmlFor="consent_given" className={this.labelClass}>
          <MarkdownConverter linksInNewWindow>
            <Markdown content={this.txt("tos")} />
          </MarkdownConverter>
        </label>
      </div>
    )
  }

  private renderLanguageSelection() {
    const { language, showLanguageSelection, onSetLanguage } = this.props

    if (!showLanguageSelection) return null

    return <LanguageSelectorDropdown language={language} setLanguage={onSetLanguage} />
  }
}
