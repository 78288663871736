import Box from "@material-ui/core/Box"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import Button from "lib/ui/components/form/Button"
import Typography from "lib/ui/components/typography/Typography"

import { COBL_CATEGORIES } from "lib/utils/report/WellbeingCommonReportUtils"
import { answersByCategory } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"
import CategoryAnswersView from "../../../ui/report/CategoryAnswersView"

interface SectionProps extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
}

interface SectionState extends ViewComponentState {
  tab: string
}

class SectionView extends ViewComponent<SectionProps & WithStyles<typeof styles>, SectionState> {
  constructor(props) {
    super(props)

    this.state = { tab: COBL_CATEGORIES[0] }
  }

  get componentName() {
    return ["report", "pages", "report", "SectionCoBLView"]
  }

  buildTabs(allAnswers: Answer[], previousAnswers: Answer[]) {
    return COBL_CATEGORIES.map(category => {
      const answers = answersByCategory(allAnswers, category)
      const previousAnswersByCategory = answersByCategory(previousAnswers, category)
      const headerUrl = `${this.appConfig.imagesRoot}/header-${category}.jpg`
      const iconUrl = `${this.appConfig.imagesRoot}/icon-${category}.png`
      const title = this.txt(answers[0]?.category)

      if (answers.length === 0) return undefined

      return { answers, category, headerUrl, iconUrl, previousAnswersByCategory, title }
    }).filter(Boolean)
  }

  onChangeTab = tab => this.setState({ tab })

  render() {
    const { answers, classes, previousAnswers } = this.props
    const tabs = this.buildTabs(answers, previousAnswers)

    return (
      <li>
        <Typography className={classes.header} variant="smallHeading">
          {this.txt("title")}
        </Typography>

        <Box display={{ xs: "none", sm: "block" }} displayPrint="none">
          {this.renderTabs(tabs)}
        </Box>

        {this.renderTabPanels(tabs)}
      </li>
    )
  }

  renderTabPanels(tabs) {
    const { classes } = this.props

    return tabs.map(tab => (
      <Box
        key={tab.category}
        aria-labelledby={`tab-${tab.category}`}
        className={classes.tabPanel}
        displayPrint="block"
        hidden={!(this.isMobile || tab.category === this.state.tab)}
        role="tabpanel"
      >
        <CategoryAnswersView
          answers={tab.answers}
          categoryHeader={tab.headerUrl}
          categoryIcon={tab.iconUrl}
          previousAnswers={tab.previousAnswersByCategory}
        />
      </Box>
    ))
  }

  renderTabs(tabs) {
    const { classes } = this.props

    return (
      <nav className={classes.tabs} role="tablist">
        {tabs.map(tab => (
          <Button
            key={tab.category}
            ariaControls={`content-${tab.category}`}
            ariaSelected={this.state.tab === tab.category}
            className={classes.tab}
            color="primary"
            context={tab.category}
            id={`tab-${tab.category}`}
            noIcon
            onClick={this.onChangeTab}
            role="tab"
            variant="action"
          >
            <img alt="" src={tab.iconUrl} />
            <span className="title">{tab.title}</span>
          </Button>
        ))}
      </nav>
    )
  }
}

const styles = () =>
  createStyles({
    tab: {
      border: `1px solid ${ViewComponent.theme.colors.faintColor.toString()}`,
      borderBottomColor: "transparent",
      borderRadius: "6px 6px 0 0",
      marginBottom: "-1px",
      marginRight: "0.4rem",
      padding: "0.25rem 1rem",

      "&[aria-selected=false]": {
        opacity: 0.75,

        "& .title": { display: "none" }
      },
      "&[aria-selected=true]": {
        borderBottomColor: ViewComponent.theme.colors.windowColor.toString(),
        justifyContent: "flex-start",
        minWidth: "12rem"
      },
      "& img": { height: "30px", filter: "saturate(0)" }
    },

    tabPanel: { breakAfter: "page" },
    header: { display: "inline" },
    tabs: {
      marginTop: "1rem",
      borderBottom: `1px solid ${ViewComponent.theme.colors.faintColor.toString()}`,
      paddingLeft: "0.75rem"
    }
  })

export default withStyles<any, any, SectionProps>(styles)(SectionView)
