import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import AnswerView from "lib/ui/components/reports/AnswerView"
import GASScaleView from "lib/ui/components/reports/GASScaleView"
import Typography from "lib/ui/components/typography/Typography"

import { CategoryPrefix } from "lib/utils/report/WellbeingCommonReportUtils"
import { answersWithPoints, getAnswerPoints, getMaxScoreForAnswer } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
}

// Set a lower limit for what's considered excellence when
// showing continued strengths.
const continuedExcellenceThreshold = 4

class SectionWellbeingGoalAndChangesView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["report", "pages", "report", "SectionWellbeingGoalAndChangesView"]
  }

  get goal() {
    return this.props.previousAnswers?.find(a => a.question_key === "gm_goal")?.options[0]?.note
  }

  get how_well_reached_goal_score() {
    return this.props.answers?.find(a => a.question_key === "wc_goal")?.options[0]?.points
  }

  get how_well_reached_goal_answer() {
    return this.props.answers?.find(a => a.question_key === "wc_goal_exceeded")?.options[0]?.note
  }

  get positive_lifestyle_changes_gas() {
    return this.props.answers?.find(a => a.question_key === "wc_lifestyle_changes")?.options[0]?.points
  }

  get positive_life_style_answer() {
    return this.props.answers?.find(a => a.question_key === "wc_goal_exceed")?.options[0]?.note
  }

  get changed_or_achieved_in_this_programme_answer() {
    return this.props.answers?.find(a => a.question_key === "wc_changes")?.options[0]?.note
  }

  render() {
    const { classes, answers, previousAnswers } = this.props
    const currentAnswersWithPoints = answersWithPoints(answers)
    const previousAnswersWithPoints = previousAnswers ? answersWithPoints(previousAnswers) : undefined

    const continuedStrengthsQuestions = previousAnswersWithPoints
      ? this.continued_strengths_questions(currentAnswersWithPoints, previousAnswersWithPoints)
      : null
    const biggestIndividualChangesQuestions = previousAnswersWithPoints
      ? this.biggest_individual_changes_questions(currentAnswersWithPoints, previousAnswersWithPoints)
      : null
    const lowerScoresQuestions = this.lower_scores_questions(currentAnswersWithPoints)

    return (
      <li>
        <Typography className={classes.header} variant="smallHeading">
          {this.txt("title")}
        </Typography>
        {this.goal && (
          <>
            <Typography variant="largeBody">{this.txt("reached_goal_title")}</Typography>
            <Typography className={classes.goal}>&ldquo;{this.goal}&rdquo;</Typography>
          </>
        )}
        <Typography variant="largeBody">{this.txt("how_well_reached_goal_title")}</Typography>
        <GASScaleView score={this.how_well_reached_goal_score} />
        {this.how_well_reached_goal_answer && (
          <Typography className={classes.goal}>&ldquo;{this.how_well_reached_goal_answer}&rdquo;</Typography>
        )}
        <Typography variant="largeBody">{this.txt("positive_life_style_title")}</Typography>
        <GASScaleView score={this.positive_lifestyle_changes_gas} />
        {this.positive_life_style_answer && (
          <Typography className={classes.goal}>&ldquo;{this.positive_life_style_answer}&rdquo;</Typography>
        )}
        <Typography variant="largeBody">{this.txt("changed_or_achieved_in_programme_title")}</Typography>
        {this.changed_or_achieved_in_this_programme_answer && (
          <Typography className={classes.goal}>
            &ldquo;{this.changed_or_achieved_in_this_programme_answer}&rdquo;
          </Typography>
        )}
        <Typography>{this.txt("goal_footer_text")}</Typography>
        <Typography variant="largeBody">{this.txt("reflect_changes_youve_made_title")}</Typography>
        <div className="same-page">
          <ol className={classes.obstacles}>
            <li key="reflect1">{this.txt("reflect_changes_1")}</li>
            <li key="reflect2">{this.txt("reflect_changes_2")}</li>
          </ol>
        </div>
        {previousAnswersWithPoints && (
          <>
            {biggestIndividualChangesQuestions?.current?.length > 0 && (
              <section className="same-page">
                <Typography component="h3">{this.txt("biggest_individual_changes_title")}</Typography>
                {this.renderAnswer(biggestIndividualChangesQuestions)}
              </section>
            )}
            {continuedStrengthsQuestions?.current?.length > 0 && (
              <section className="same-page">
                <Typography component="h3">{this.txt("continued_strengths_title")}</Typography>
                {this.renderAnswer(continuedStrengthsQuestions)}
              </section>
            )}
          </>
        )}
        {lowerScoresQuestions?.current?.length > 0 && (
          <section className="same-page">
            <Typography component="h3">{this.txt("focus_on_title")}</Typography>
            {this.renderAnswer(lowerScoresQuestions)}
          </section>
        )}
      </li>
    )
  }

  biggest_individual_changes_questions(answers: Answer[], previous_answers: Answer[]) {
    // filter out those follow up, Work Performance and Life Satisfaction questions
    answers = answers.filter(a => {
      return a.question_key?.match(/gm_|fc_|wc_|fe_|gh_lifestyle|wp|ls/gm) === null
    })

    const sorted_answers = answers.slice().sort((a, b) => {
      const previous_a = previous_answers.find(previousAnswer => previousAnswer.question_id === a.question_id)
      const previous_b = previous_answers.find(previousAnswer => previousAnswer.question_id === b.question_id)

      if ([a, b, previous_a, previous_b].includes(undefined)) return 0

      const delta_a = getAnswerPoints(a) - getAnswerPoints(previous_a)
      const delta_b = getAnswerPoints(b) - getAnswerPoints(previous_b)
      return delta_b - delta_a
    })
    const current = sorted_answers.slice(0, 2)
    const currentAnswerIds = current.map(answer => answer.question_id)
    const previous = previous_answers
      .filter(previousAnswer => currentAnswerIds.includes(previousAnswer.question_id))
      .sort((a, b) => {
        return currentAnswerIds.indexOf(a.question_id) - currentAnswerIds.indexOf(b.question_id)
      })
    return { current: current, previous: previous }
  }

  continued_strengths_questions(answers: Answer[], previous_answers: Answer[]) {
    let excellent_answers = answers
      .filter(answer => {
        return getAnswerPoints(answer) >= continuedExcellenceThreshold
      })
      .filter(answer => {
        return (
          !answer.question_key?.startsWith(CategoryPrefix.GENERAL_HEALTH) &&
          !answer.question_key?.startsWith(CategoryPrefix.LIFE_SATISFACTION) &&
          !answer.question_key?.startsWith(CategoryPrefix.WORK_PERFORMANCE) &&
          answer.question_key // Filter out custom questions by lack of question_key
        )
      })
    let previous_excellent_answers = previous_answers.filter(answer => {
      return getAnswerPoints(answer) >= continuedExcellenceThreshold
    })
    // Filter out answers which are not excellent anymore
    previous_excellent_answers = previous_excellent_answers.filter(answer => {
      return excellent_answers.find(a => a.question_id === answer.question_id) !== undefined
    })
    const previous_answer_ids = previous_excellent_answers.map(answer => answer.question_id)
    excellent_answers = excellent_answers
      .filter(answer => {
        return previous_excellent_answers.find(a => a.question_id === answer.question_id) !== undefined
      })
      .sort((a, b) => {
        return previous_answer_ids.indexOf(a.question_id) - previous_answer_ids.indexOf(b.question_id)
      })
    const current = excellent_answers.splice(0, 2)
    const previous = previous_excellent_answers.splice(0, 2)
    return {
      current: current,
      previous: previous
    }
  }

  lower_scores_questions(answers: Answer[]) {
    const filtered_answers = answers.filter(a => {
      return (
        !a.question_key?.startsWith(CategoryPrefix.GENERAL_HEALTH) &&
        !a.question_key?.startsWith(CategoryPrefix.CORE) &&
        !a.question_key?.startsWith(CategoryPrefix.WELLBEING_AT_WORK) &&
        !a.question_key?.startsWith(CategoryPrefix.WELLBEING_GOAL_AND_CHANGE) &&
        !a.question_key?.startsWith(CategoryPrefix.GOAL_MOTIVATION) &&
        !a.question_key?.startsWith("sr_practices") &&
        a.question_key && // Filter out custom questions by lack of question_key
        getAnswerPoints(a) <= 3
      )
    })

    const sorted_answers = filtered_answers.slice().sort((a, b) => {
      return getAnswerPoints(a) - getAnswerPoints(b)
    })

    return { current: sorted_answers.slice(0, 2) }
  }

  renderAnswer = params => {
    const elements = []
    for (let index = 0; index < params.current.length; index++) {
      const previousAnswer = params.previous ? params.previous[index] : null
      elements.push(
        <Card key={params.current[index].question_id} className={this.props.classes.answer} variant="outlined">
          <CardContent>
            <AnswerView
              answer={params.current[index]}
              maxScore={getMaxScoreForAnswer(params.current[index])}
              previousAnswer={previousAnswer}
              showCategory
            />
          </CardContent>
        </Card>
      )
    }
    return elements
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    answer: { marginTop: spacing(2) },
    header: { display: "inline" },
    goal: {
      color: ViewComponent.theme.colors.secondary.toString(),
      fontStyle: "italic",
      display: "inline",
      alignItems: "center"
    },
    spacing: {
      marginBottom: spacing(2),
      "@media print": { minWidth: "8cm" }
    },
    obstacles: {
      marginTop: spacing(2),
      listStylePosition: "outside",
      listStyleType: "disc",
      "& li": { marginBottom: "10px" },
      "& li::marker": { color: ViewComponent.theme.colors.secondary.toString() }
    }
  })

export default withStyles<any, any, Props>(styles)(SectionWellbeingGoalAndChangesView)
