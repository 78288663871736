import { appName } from "../../AppName"

export const surveysAppTranslations = {
  [appName]: {
    report: {
      common: {
        category_descriptions: {
          bm: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          co: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          fe: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          gh: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          gm: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          ls: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          me: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          nu: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          pa: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          sr: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          ww: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          wp: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          },
          wc: {
            en: "Your overall state of health: curing and preventing illness, and promoting an all- round healthy lifestyle."
          }
        },
        results: {
          excellent: {
            en: "Excellent",
            fi: "Erinomainen",
            de: "Ausgezeichnet",
            es: "Excelente",
            fr: "Excellent",
            sv: "Utmärkt",
            da: "Fremragende",
            et: "Suurepärane",
            no: "Utmerket",
            pl: "Doskonały",
            zh: "优秀",
            pt: "Excelente",
            ru: "Отлично"
          },
          good: {
            en: "Good",
            fi: "Hyvä",
            de: "Gut",
            es: "Bueno",
            fr: "Bon",
            sv: "Bra",
            da: "Godt",
            et: "Hea",
            no: "Bra",
            pl: "Dobry",
            zh: "好",
            pt: "Bom",
            ru: "Хорошо"
          },
          fair: {
            da: "Neutral",
            de: "Neutral",
            en: "Neutral",
            et: "Neutraalne",
            fi: "Neutraali",
            no: "Nøytral",
            sv: "Neutralt",
            pl: "Obojętnie",
            ru: "Нормально",
            es: "Neutro",
            fr: "Neutre",
            pt: "Neutro",
            zh: "中等"
          },
          satisfactory: {
            en: "Monitor",
            fi: "Seuraa",
            de: "Überwachen",
            es: "Monitorear",
            fr: "Surveiller",
            sv: "Övervaka",
            da: "Overvåge",
            et: "Jälgima",
            no: "Overvåke",
            pl: "Monitorować",
            zh: "监控",
            pt: "Monitorar",
            ru: "Сконцентрироваться"
          },
          poor: {
            da: "Forbedring",
            de: "Verbessern",
            en: "Improve",
            et: "Parandamine",
            fi: "Paranna",
            no: "Forbedring",
            sv: "Förbättra",
            pl: "Poprawa",
            ru: "Улучшить",
            es: "Mejorar",
            fr: "Améliorer",
            pt: "Melhorar",
            zh: "改善"
          }
        }
      }
    },
    components: {
      report: {
        pages: {
          report: {
            ui: {
              ScoreMeter: {
                yourScore: {
                  en: "Your score*"
                }
              }
            },
            SurvivingPerformingThrivingContainer: {
              surviving_13_15: {
                da: "Du har få eller ingen tegn på udmattelse og er generelt energisk. Godt klaret!",
                de: "Sie zeigen wenig bis gar keine Anzeichen von Erschöpfung und sind generell voller Energie. Gut gemacht!",
                en: "You have little to no sign of exhaustion and are generally energized. Well done!",
                et: "Sul on vähe või üldse mitte kurnatuse märke ja oled üldiselt energiline. Hästi tehtud!",
                fi: "Et juurikaan kärsi väsymyksestä ja olet yleisesti energinen. Hyvä!",
                no: "Du har lite eller ingen tegn til utmattelse og er generelt energisk. Bra gjort!",
                sv: "Du har inga eller endast svaga tecken på utmattning och verkar ha bra energi just nu. Fantastiskt!",
                pl: "Masz niewiele oznak wyczerpania lub nie masz ich wcale i ogólnie jesteś naładowany energią. Bardzo dobrze!",
                ru: "У тебя практически нет признаков истощения и, как правило, ты полон энергии. Отличная работа!",
                es: "Tienes pocos o ningún signo de agotamiento y, en general, estás lleno/a de energía. ¡Bien hecho!",
                fr: "Vous ne présentez que peu ou pas de signes d’épuisement et vous êtes généralement énergique. Bravo !",
                pt: "Tens poucos ou nenhuns sinais de exaustão e, de um modo geral, estás cheio(a) de energia. Muito bem!",
                zh: "你没有或几乎没有疲惫的迹象，总体上精力充沛。完成得很出色！"
              },
              surviving_9_12: {
                da: "Du har nogle lette tegn på fysisk, kognitiv eller følelsesmæssig udmattelse. Vær mere opmærksom på dit energiniveau og husk at restituere.",
                de: "Sie haben einige leichte Anzeichen von körperlicher, kognitiver oder emotionaler Erschöpfung. Achten Sie mehr auf Ihr Energieniveau und denken Sie daran, sich zu erholen.",
                en: "You have some slight signs of physical, cognitive, or emotional exhaustion. Pay more attention to your energy levels and remember to recover.",
                et: "Sul on kergeid füüsilise, kognitiivse või emotsionaalse kurnatuse tunnuseid. Pööra rohkem tähelepanu oma energiatasemele ja ära unusta taastumist.",
                fi: "Sinulla on lieviä fyysisen, kognitiivisen tai emotionaalisen väsymyksen oireita. Kiinnitä enemmän huomiota energiatasoihisi ja muista palautuminen.",
                no: "Du har noen små tegn på fysisk, kognitiv eller følelsesmessig utmattelse. Vær mer oppmerksom på energinivåene dine og husk å restituere.",
                sv: "Det uppvisar vissa tecken på fysisk, kognitiv eller emotionell trötthet. Var uppmärksam på din energinivå och prioritera återhämtning och välmående.",
                pl: "Masz niewielkie oznaki wyczerpania fizycznego, poznawczego lub emocjonalnego. Zwracaj większą uwagę na poziom energii i pamiętaj o regeneracji.",
                ru: "У тебя есть легкие признаки физического, когнитивного или эмоционального истощения. Уделяй больше внимания своему уровню энергии и не забывай восстанавливаться.",
                es: "Tienes ligeros signos de agotamiento físico, cognitivo o emocional. Presta más atención a tus niveles de energía y acuérdate de recuperarte.",
                fr: "Vous présentez de légers signes d’épuisement physique, cognitif ou émotionnel. Faites plus attention à votre niveau d’énergie et n’oubliez pas de vous reposer.",
                pt: "Tens alguns sinais ligeiros de cansaço físico, cognitivo ou emocional. Presta mais atenção aos teus níveis de energia e não te esqueças de reservar tempo para recuperar.",
                zh: "你在身体、认知或情感方面表现出轻微的疲惫迹象。多关注自己的精力充沛程度，记得恢复精力。"
              },
              surviving_3_8: {
                da: "Du har nogle tegn på fysisk, kognitiv eller følelsesmæssig udmattelse. Sørg for at restituere ordentligt, pas på dig selv og sørg for at række ud efter støtte.",
                de: "Sie haben Anzeichen von körperlicher, kognitiver oder emotionaler Erschöpfung. Sorgen Sie für eine gute Erholung, achten Sie auf sich selbst und holen Sie sich Unterstützung.",
                en: "You have some signs of physical, cognitive, or emotional exhaustion. Make sure to recover well, take care of yourself, and reach out for support.",
                et: "Sul on füüsilise, kognitiivse või emotsionaalse kurnatuse tunnuseid. Hoolitse kvaliteetse taastumise eest, hoolitse enda eest ja otsi kindlasti abi.",
                fi: "Sinulla on joitain fyysisen, kognitiivisen tai emotionaalisen väsymyksen oireita. Pidä huolta itsestäsi ja palautumisestasi. Hae tukea, jos tarvitset.",
                no: "Du har noen tegn på fysisk, kognitiv eller følelsesmessig utmattelse. Sørg for god restitusjon, ta vare på deg selv og søk støtte om du trenger det.",
                sv: "Du uppvisar tydliga tecken på fysisk, mental eller känslomässig trötthet. Se till att få tillräcklig återhämtning varje dag och tveka inte att söka stöd.",
                pl: "Masz oznaki fizycznego, poznawczego lub emocjonalnego wyczerpania. Zapewnij sobie dobrą regenerację, dbaj o siebie i sięgnij po wsparcie, jeśli potrzebujesz.",
                ru: "У тебя есть некоторые признаки физического, когнитивного или эмоционального истощения. Обеспечь себе качественное восстановление, позаботься о себе и обязательно обратись за поддержкой.",
                es: "Tienes algunos signos de agotamiento físico, cognitivo o emocional. Asegúrate de una buena recuperación, cuida de ti y busca apoyo si lo necesitas.",
                fr: "Vous présentez des signes d’épuisement physique, cognitif ou émotionnel. Assurez-vous de bien récupérer, prenez soin de vous et demandez de l’aide si vous en avez besoin.",
                pt: "Tens alguns sinais de cansaço físico, cognitivo ou emocional. Não te esqueças de reservar tempo para recuperar, cuidar de ti e procurar ajuda, se precisares.",
                zh: "你在身体、认知或情感方面表现出一些疲惫迹象。确保高质量的精力恢复，照顾好自己，并确保寻求支持。"
              },
              performing_17_20: {
                en: "You seem to be mastering this mode. Can you go from ‘great’ to ‘excellent’?",
                fi: "Näytät hallitsevan tämän olotilan. Voitko mennä 'mahtavasta' 'erinomaiseen'?",
                de: "Sie scheinen diesen Modus zu beherrschen. Können Sie von ‚großartig‘ zu ‚ausgezeichnet‘ wechseln?",
                es: "Parece que dominas este modo. ¿Puedes pasar de 'genial' a 'excelente'?",
                fr: "Vous semblez maîtriser ce mode. Pouvez-vous passer de 'super' à 'excellent'?",
                sv: "Du verkar ha bra koll på detta läge. Utmärkt!",
                da: "Du ser ud til at mestre denne tilstand. Kan du gå fra 'fantastisk' til 'fremragende'?",
                et: "Tundub, et sa valdad seda režiimi. Kas saad minna „suurepäraselt“ „suurepäraseks“?",
                no: "Du ser ut til å mestre denne modusen. Kan du gå fra ‘flott’ til ‘utmerket’?",
                pl: "Wydaje się, że opanowujesz ten tryb. Czy możesz przejść od „świetnego” do „doskonałego”?",
                zh: "你似乎已经掌握了这种模式。你能从‘优秀’变成‘卓越’吗？",
                pt: "Parece que estás a dominar este modo. Podes passar de 'ótimo' para 'excelente'?"
              },
              performing_13_16: {
                en: "You’re doing okay in this mode, but there’s room for improvement.",
                fi: "Sinulla menee hyvin tässä olotilassa, mutta parantamisen varaa on.",
                es: "Lo estás haciendo bien en este modo, pero hay margen de mejora.",
                de: "Sie machen es in diesem Modus gut, aber es gibt Raum für Verbesserungen.",
                fr: "Vous vous en sortez bien dans ce mode, mais il y a place à l'amélioration.",
                sv: "Du hanterar detta läge relativt ok, men det finns plats för förbättringar.",
                da: "Du klarer dig okay i denne tilstand, men der er plads til forbedring.",
                et: "Sa teed selles režiimis hästi, kuid seal on arenguruumi.",
                no: "Du gjør det bra i denne modusen, men det er rom for forbedringer.",
                pl: "Radzisz sobie dobrze w tym trybie, ale jest miejsce na poprawę.",
                zh: "你在这种模式下表现不错，但还有提升空间。",
                pt: "Estás a fazer bem neste modo, mas há espaço para melhorias."
              },
              performing_4_12: {
                en: "You show signs of struggle in this mode. Make it a priority to find balance.",
                fi: "Osoitat merkkejä kamppailusta tässä olotilassa. Priorisoi tasapainon löytäminen.",
                es: "Muestras signos de lucha en este modo. Prioriza encontrar el equilibrio.",
                de: "Sie zeigen Anzeichen von Schwierigkeiten in diesem Modus. Machen Sie es zu Ihrer Priorität, ein Gleichgewicht zu finden.",
                fr: "Vous montrez des signes de difficulté dans ce mode. Faites-en une priorité de trouver un équilibre.",
                sv: "Det ser ut som att du har det kämpigt i det här läget. Fokusera på att återfinna balansen.",
                da: "Du viser tegn på kamp i denne tilstand. Prioriter at finde balance.",
                et: "Näitad selles režiimis võitluse märke. Tee oma prioriteediks tasakaalu leidmine.",
                no: "Du viser tegn til kamp i denne modusen. Gjør det til en prioritet å finne balanse.",
                pl: "Wykazujesz oznaki walki w tym trybie. Uczyń znalezienie równowagi priorytetem.",
                zh: "你在这种模式下表现出挣扎的迹象。优先找到平衡。",
                pt: "Mostras sinais de luta neste modo. Prioriza encontrar equilíbrio."
              },
              thriving_3_8: {
                en: "You seem to be struggling in this mode. Work on your wellbeing first to get your foundation right.",
                fi: "Näytät kamppailevan tässä tilassa. Työskentele ensin hyvinvointisi eteen saadaksesi perustasi kuntoon.",
                es: "Parece que estás luchando en este modo. Trabaja primero en tu bienestar para establecer una base sólida.",
                de: "Sie scheinen in diesem Modus zu kämpfen. Arbeiten Sie zuerst an Ihrem Wohlbefinden, um eine solide Basis zu schaffen.",
                fr: "Vous semblez lutter dans ce mode. Travaillez d'abord sur votre bien-être pour établir une base solide.",
                sv: "Du verkar ha det kämpigt just nu. Arbeta med ditt välmående först för att stärka grunden.",
                da: "Du ser ud til at kæmpe i denne tilstand. Arbejd først på dit velbefindende for at få din fundament på plads.",
                et: "Tundub, et sa võitled selles režiimis. Tööta kõigepealt oma heaolu nimel, et oma vundament korda saada.",
                no: "Du ser ut til å slite i denne modusen. Jobb med ditt velvære først for å få fundamentet riktig.",
                pl: "Wydaje się, że zmagasz się w tym trybie. Najpierw popracuj nad swoim dobrostanem, aby uzyskać solidną podstawę.",
                zh: "你似乎在这种模式下挣扎。首先关注你的健康，以打好基础。",
                pt: "Parece que estás a lutar neste modo. Trabalha primeiro no teu bem-estar para ter uma base sólida."
              },
              thriving_9_12: {
                en: "You have a good base for thriving, but are not quite there yet.",
                fi: "Sinulla on hyvä perusta kukoistukselle, mutta et ole vielä aivan siellä.",
                es: "Tienes una buena base para prosperar, pero aún no has llegado.",
                de: "Sie haben eine gute Basis zum Gedeihen, aber es fehlt noch etwas.",
                fr: "Vous avez une bonne base pour prospérer, mais vous n'y êtes pas encore tout à fait.",
                sv: "Du har en stark grund att bygga på, men du har inte nått hela vägen än.",
                da: "Du har et godt fundament for at trives, men du er der ikke helt endnu.",
                et: "Sul on kindel alus õitsenguks, kuid sa pole veel päris kohal.",
                no: "Du har et godt grunnlag for å blomstre, men er ikke helt der ennå.",
                pl: "Masz dobrą podstawę do rozwoju, ale jeszcze do tego nie doszedłeś.",
                zh: "你有坚实的基础可以茁壮成长，但还没有完全达到。",
                pt: "Tens uma boa base para prosperar, mas ainda não chegaste lá."
              },
              thriving_13_15: {
                en: "You have an excellent base for thriving. Are you experiencing it in daily work? If not, focus on your workday habits.",
                fi: "Sinulla on erinomainen perusta kukoistukselle. Koetko sitä päivittäisessä työssä? Jos et, keskity päivittäisiin tapoihisi työpäivän aikana.",
                es: "Tienes una excelente base para prosperar. ¿Lo estás experimentando en el trabajo diario? Si no, concéntrate en tus hábitos laborales.",
                de: "Sie haben eine ausgezeichnete Basis zum Gedeihen. Erleben Sie es täglich bei der Arbeit? Wenn nicht, konzentrieren Sie sich auf Ihre Arbeitsgewohnheiten.",
                fr: "Vous avez une excellente base pour prospérer. L'expérimentez-vous dans votre travail quotidien ? Sinon, concentrez-vous sur vos habitudes de travail.",
                sv: "Du har en fantastisk grund för att nå din fulla potential. Märker du det i ditt dagliga arbete? Om inte, sätt fokus på att justera dina arbetsvanor.",
                da: "Du har et fremragende fundament for at trives. Oplever du det i dit daglige arbejde? Hvis ikke, fokuser på dine arbejdsdagsvaner.",
                et: "Sul on suurepärane alus õitsenguks. Kas koged seda igapäevases töös? Kui ei, keskendu oma tööpäeva harjumustele.",
                no: "Du har et utmerket grunnlag for å blomstre. Opplever du det i ditt daglige arbeid? Hvis ikke, fokuser på arbeidsdagens vaner.",
                pl: "Masz doskonałą podstawę do rozwoju. Czy doświadczasz tego w codziennej pracy? Jeśli nie, skoncentruj się na swoich nawykach w pracy.",
                zh: "你有卓越的基础可以茁壮成长。你在日常工作中经历过吗？如果没有，请专注于你的工作习惯。",
                pt: "Tens uma excelente base para prosperar. Estás a experienciá-lo no trabalho diário? Se não, foca-te nos teus hábitos de trabalho."
              },
              surviving_title: {
                en: "Surviving",
                fi: "Selviytyminen",
                de: "Überleben",
                es: "Sobrevivir",
                fr: "Survivre",
                sv: "Överleva",
                da: "Overleve",
                et: "Ellujäämine",
                no: "Overleve",
                pl: "Przetrwać",
                zh: "生存",
                pt: "Sobreviver"
              },
              performing_title: {
                en: "Performing",
                fi: "Suoriutuminen",
                de: "Leisten",
                es: "Rendimiento",
                fr: "Performer",
                sv: "Prestera",
                da: "Præstere",
                et: "Tegutsemine",
                no: "Prestere",
                pl: "Działać",
                zh: "表演",
                pt: "Desempenhar"
              },
              thriving_title: {
                en: "Thriving",
                fi: "Kukoistaminen",
                de: "Gedeihen",
                es: "Prosperar",
                fr: "Prospérer",
                sv: "Blomstra",
                da: "Blomstre",
                et: "Õitsema",
                no: "Blomstre",
                pl: "Rozkwitać",
                zh: "茁壮成长",
                pt: "Prosperar"
              },
              surviving_description: {
                en: "Surviving is those tough times in life when we struggle to keep up and cope.",
                fi: "Selviytyminen on niitä raskaita aikoja elämässä, jolloin kamppailemme jaksamisen ja pärjäämisen kanssa.",
                de: "Überleben sind die harten Zeiten im Leben, in denen wir uns bemühen, Schritt zu halten und zurechtzukommen.",
                es: "Sobrevivir son esos momentos difíciles de la vida cuando luchamos por mantenernos y sobrellevar la situación.",
                fr: "Survivre, ce sont ces moments difficiles de la vie où nous avons du mal à suivre et à faire face.",
                sv: "Överlevnad är de tuffa perioderna i livet när vi kämpar för att klara av och orka med dagen.",
                da: "At overleve er de svære perioder i livet, hvor vi kæmper for at følge med og klare os.",
                et: "Ellujäämine on need rasked ajad elus, kui püüame toime tulla ja hakkama saada.",
                no: "Å overleve er de tøffe periodene i livet når vi sliter med å holde tritt og takle ting.",
                pl: "Przetrwanie to te trudne chwile w życiu, kiedy zmagamy się z nadążaniem i radzeniem sobie.",
                zh: "生存是生活中那些我们努力跟上和应对的艰难时刻。",
                pt: "Sobreviver são aqueles tempos difíceis na vida em que lutamos para acompanhar e lidar com as situações."
              },
              performing_description: {
                en: "Performing is our ‘default’ mode: when we meet expectations and balance work and home.",
                fi: "Suoriutuminen on oletustilamme: kun täytämme odotukset ja pidämme työn ja kodin tasapainossa.",
                de: "Leisten ist unser 'Standardmodus': wenn wir den Erwartungen entsprechen und Arbeit und Zuhause in Einklang bringen.",
                es: "Rendimiento es nuestro modo ‘predeterminado’: cuando cumplimos con las expectativas y equilibramos trabajo y hogar.",
                fr: "Performer est notre mode « par défaut » : lorsque nous répondons aux attentes et équilibrons travail et maison.",
                sv: "Att prestera är vårt normalläge: när vi möter förväntningar och jobbar med att balansera arbete och privatliv.",
                da: "Præstere er vores 'standard' tilstand: når vi lever op til forventningerne og balancerer arbejde og hjem.",
                et: "Tegutsemine on meie 'vaikimisi' režiim: kui me täidame ootusi ja tasakaalustame tööd ja kodu.",
                no: "Prestere er vår ‘standardmodus’: når vi møter forventningene og balanserer arbeid og hjem.",
                pl: "Działać to nasz 'domyślny' tryb: kiedy spełniamy oczekiwania i równoważymy pracę i dom.",
                zh: "表演是我们的“默认”模式：当我们按预期表现，平衡工作和家庭。",
                pt: "Desempenhar é o nosso modo 'padrão': quando atendemos às expectativas e equilibramos trabalho e casa."
              },
              thriving_description: {
                en: "Thriving happens in short, intense moments of learning, energy, and creativity.",
                fi: "Kukoistaminen tapahtuu lyhyissä, intensiivisissä oppimisen, energian ja luovuuden hetkissä.",
                de: "Gedeihen geschieht in den kurzen, intensiven Momenten des Lernens, der Energie und der Kreativität.",
                es: "Prosperar ocurre en esos breves e intensos momentos de aprendizaje, energía y creatividad.",
                fr: "Prospérer se produit dans ces moments courts et intenses d'apprentissage, d'énergie et de créativité.",
                sv: "Blomstring sker i korta, intensiva ögonblick av energi, kreativitet och personlig utveckling.",
                da: "Trives sker i de korte, intense øjeblikke af læring, energi og kreativitet.",
                et: "Õitsema toimub nendes lühikestes, intensiivsetes õppimise, energia ja loovuse hetkedes.",
                no: "Å blomstre skjer i de korte, intense øyeblikkene av læring, energi og kreativitet.",
                pl: "Rozkwitać następuje w tych krótkich, intensywnych momentach nauki, energii i kreatywności.",
                zh: "茁壮成长发生在那些短暂而集中的学习、能量和创造力时刻。",
                pt: "Prosperar acontece naqueles momentos curtos e intensos de aprendizagem, energia e criatividade."
              },
              surviving_footer: {
                en: "This score is based on the Shirom-Melamed Burnout Index, which measures physical, cognitive, and emotional exhaustion. This is just an indicator and shouldn't be used as a diagnostic tool. If you're concerned, reach out to a health professional for a validated test.",
                fi: "Tämä tulos perustuu Shirom-Melamedin loppuunpalamisindeksiin, joka mittaa fyysistä, kognitiivista ja emotionaalista uupumusta. Tämä on vain viitteellinen tulos, eikä sitä tule käyttää diagnoosivälineenä. Jos olet huolissasi, ota yhteyttä terveydenhuollon ammattilaiseen saadaksesi vahvistetun testin.",
                de: "Dieser Wert basiert auf dem Shirom-Melamed Burnout Index, der körperliche, kognitive und emotionale Erschöpfung misst. Dies ist nur ein Indikator und sollte nicht als Diagnosewerkzeug verwendet werden. Wenn du besorgt bist, wende dich an einen Gesundheitsfachmann für einen validierten Test.",
                es: "Esta puntuación se basa en el Índice de Burnout de Shirom-Melamed, que mide el agotamiento físico, cognitivo y emocional. Esta medida es solo un indicativo y no debe usarse como una herramienta de diagnóstico. Si te preocupa, consulta a un profesional de la salud para obtener una prueba validada.",
                fr: "Ce score est basé sur l'indice de burnout de Shirom-Melamed, qui mesure l'épuisement physique, cognitif et émotionnel. Ceci est juste un indicateur et ne doit pas être utilisé comme outil de diagnostic. Si tu es inquiet, consulte un professionnel de la santé pour un test validé.",
                sv: "Dessa frågor kommer från Shirom-Melamed Burnout Index, som bedömer fysisk, kognitiv och emotionell utmattning. Det är endast en indikation och bör inte användas för diagnos. Om du är orolig, tala med en vårdpersonal för ett mer omfattande test.",
                da: "Denne score er baseret på Shirom-Melamed Burnout Index, som måler fysisk, kognitiv og følelsesmæssig udmattelse. Dette er kun en indikator og bør ikke bruges som et diagnostisk værktøj. Hvis du er bekymret, kontakt en sundhedsprofessionel for en valideret test.",
                et: "See tulemus põhineb Shirom-Melamedi läbipõlemise indeksil, mis mõõdab füüsilist, kognitiivset ja emotsionaalset kurnatust. See on ainult näitaja ja seda ei tohiks kasutada diagnostikavahendina. Kui oled mures, pöördu kinnitatud testi saamiseks tervishoiutöötaja poole.",
                no: "Denne poengsummen er basert på Shirom-Melamed Burnout Index, som måler fysisk, kognitiv og emosjonell utmattelse. Dette er bare en indikator og bør ikke brukes som et diagnostisk verktøy. Hvis du er bekymret, kontakt en helsepersonell for en validert test.",
                pl: "Ten wynik opiera się na wskaźniku wypalenia Shirom-Melamed, który mierzy fizyczne, poznawcze i emocjonalne wyczerpanie. To jest tylko wskaźnik i nie powinien być używany jako narzędzie diagnostyczne. Jeśli masz obawy, skontaktuj się z lekarzem w celu uzyskania zweryfikowanego testu.",
                zh: "此分数基于Shirom-Melamed倦怠指数，该指数衡量身体、认知和情绪疲劳。这只是一个指标，不应作为诊断工具。如果您感到担忧，请咨询健康专业人员以进行验证测试。",
                pt: "Esta pontuação baseia-se no Índice de Burnout de Shirom-Melamed, que mede a exaustão física, cognitiva e emocional. Esta é apenas uma indicação e não deve ser usada como ferramenta de diagnóstico. Se estiver preocupado, consulte um profissional de saúde para um teste validado."
              },
              performing_footer: {
                en: "This mode captures the basic building blocks of good work. The questions are based on the resources side of the classic Job Demands & Resources model: meaning, control, support, and personal energy.",
                fi: "Tämä olotila kattaa hyvän työn perustavanlaatuiset rakennuspalikat. Kysymykset perustuvat klassisen Työvaatimukset ja -resurssit -mallin resurssipuoleen: merkitys, hallinta, tuki ja henkilökohtainen energia.",
                de: "Dieser Modus erfasst die grundlegenden Bausteine guter Arbeit. Die Fragen basieren auf der Ressourcenseite des klassischen Job Demands & Resources Modells: Bedeutung, Kontrolle, Unterstützung und persönliche Energie.",
                es: "Este modo captura los bloques de construcción fundamentales del buen trabajo. Las preguntas se basan en el lado de los recursos del modelo clásico de Demandas y Recursos Laborales: significado, control, apoyo y energía personal.",
                fr: "Ce mode capture les éléments fondamentaux du bon travail. Les questions sont basées sur le côté ressources du modèle classique Demandes et Ressources du travail: sens, contrôle, soutien et énergie personnelle.",
                sv: "I detta läge betonas de grundläggande faktorerna för bra arbete. Frågorna är baserade på resurssidan av den klassiska modellen Job Demands & Resources: mening, kontroll, stöd och personlig energi.",
                da: "Denne tilstand fanger de grundlæggende byggesten af godt arbejde. Spørgsmålene er baseret på ressurssiden af den klassiske Job Demands & Resources model: mening, kontrol, støtte og personlig energi.",
                et: "See režiim hõlmab hea töö põhielemente. Küsimused põhinevad klassikalise Töö Nõudmised ja Ressursid mudeli ressursipoolt: tähendus, kontroll, tugi ja isiklik energia.",
                no: "Denne modusen fanger de grunnleggende byggesteinene for godt arbeid. Spørsmålene er basert på ressurssiden av den klassiske Job Demands & Resources-modellen: mening, kontroll, støtte og personlig energi.",
                pl: "Ten tryb obejmuje podstawowe elementy dobrej pracy. Pytania opierają się na zasobach klasycznego modelu Wymagań i Zasobów Pracy: sens, kontrola, wsparcie i energia osobista.",
                zh: "此模式捕捉了良好工作的基础构建模块。问题基于经典工作需求与资源模型的资源方面：意义、控制、支持和个人能量。",
                pt: "Este modo capta os blocos de construção fundamentais de um bom trabalho. As perguntas são baseadas no lado dos recursos do modelo clássico de Demandas e Recursos de Trabalho: significado, controle, apoio e energia pessoal."
              },
              thriving_footer: {
                en: "The questions in this mode are adapted from Aalto University’s Future of Work research group on what helps us flourish at work: engagement, competence, and learning.",
                fi: "Tämän olotilan kysymykset pohjautuvat Aalto-yliopiston Työn Tulevaisuus -tutkimusryhmän työssä kukoistamiseen liittyviin mittareihin: sitoutuminen, pätevyys ja oppiminen.",
                de: "Die Fragen in diesem Modus wurden von der Aalto University’s Future of Work Forschungsgruppe zu Maßnahmen zur Förderung des Wohlbefindens am Arbeitsplatz adaptiert: Engagement, Kompetenz und Lernen.",
                es: "Las preguntas en este modo están adaptadas del grupo de investigación sobre el Futuro del Trabajo de la Universidad de Aalto sobre lo que nos ayuda a prosperar en el trabajo: compromiso, competencia y aprendizaje.",
                fr: "Les questions de ce mode sont adaptées du groupe de recherche sur l'avenir du travail de l'Université Aalto sur les mesures contribuant à l'épanouissement au travail: engagement, compétence et apprentissage.",
                sv: "Frågorna här är utformade utifrån insikter från Aalto University’s Future of Work forskargrupp med fokus på vad som hjälper oss att blomstra på jobbet, bl.a. engagemang, kompetens och lärande.",
                da: "Spørgsmålene i denne tilstand er tilpasset fra Aalto University's Future of Work forskningsgruppe om hvad der hjælper os med at trives på arbejdet: engagement, kompetence og læring.",
                et: "Selle režiimi küsimused on kohandatud Aalto Ülikooli Tuleviku Töö uurimisrühma tööalase õitsengu edendamiseks: kaasatus, pädevus ja õppimine.",
                no: "Spørsmålene i denne modusen er tilpasset fra Aalto University's Future of Work forskningsgruppe om hva som hjelper oss å blomstre på jobben: engasjement, kompetanse og læring.",
                pl: "Pytania w tym trybie są dostosowane z grupy badawczej Aalto University's Future of Work dotyczącej działań przyczyniających się do rozwoju w pracy: zaangażowanie, kompetencje i nauka.",
                zh: "此模式中的问题改编自阿尔托大学未来工作研究小组关于什么有助于我们在工作中茁壮成长的研究：参与感、能力和学习。",
                pt: "As perguntas neste modo são adaptadas do grupo de pesquisa Future of Work da Universidade Aalto sobre o que nos ajuda a prosperar no trabalho: engajamento, competência e aprendizado."
              }
            },
            ElementDetailsContainer: {
              element_specific_results: {
                en: "Results by element",
                fi: "Elementtikohtaiset tulokset",
                de: "Element-spezifische Ergebnisse",
                es: "Resultados específicos del elemento",
                fr: "Résultats spécifiques de l'élément",
                sv: "Resultat per element",
                da: "Elementspecifikke resultater",
                et: "Elementide spetsiifilised tulemused",
                no: "Elementspesifikke resultater",
                pl: "Wyniki specyficzne dla elementów",
                zh: "元素特定结果",
                pt: "Resultados específicos do elemento"
              },
              category_description_co: {
                en: "The Core is your identity, purpose and control in life. It’s about finding meaning, realising your potential and building a life that looks like you.",
                fi: "Ydin on identiteettisi, tarkoituksesi ja elämänhallintasi. Siinä on kyse merkityksen löytämisestä, potentiaalin hyödyntämisestä ja itsesi näköisen elämän rakentamisesta.",
                de: "Der Kern ist Ihre Identität, Ihr Zweck und Ihre Kontrolle im Leben. Es geht darum, Sinn zu finden, Ihr Potenzial zu verwirklichen und ein Leben zu gestalten, das Ihnen ähnelt.",
                es: "El núcleo es tu identidad, propósito y control en la vida. Se trata de encontrar significado, realizar tu potencial y construir una vida que se parezca a ti.",
                fr: "Le noyau est votre identité, votre but et votre contrôle dans la vie. Il s'agit de trouver un sens, de réaliser votre potentiel et de construire une vie qui vous ressemble.",
                sv: "Kärnan består av din identitet, mening och kontroll i livet. Det handlar om att hitta rätt riktning, uppnå din fulla potential och skapa ett liv som speglar ditt sanna jag.",
                da: "Kernen er din identitet, formål og kontrol i livet. Det handler om at finde mening, realisere dit potentiale og bygge et liv, der ligner dig.",
                et: "Tuum on sinu identiteet, eesmärk ja kontroll elus. See on tähenduse leidmine, oma potentsiaali realiseerimine ja oma näo järgi elu loomine.",
                no: "Kjernen er din identitet, formål og kontroll i livet. Det handler om å finne mening, realisere potensialet ditt og bygge et liv som ser ut som deg.",
                pl: "Rdzeń to twoja tożsamość, cel i kontrola w życiu. Chodzi o znalezienie sensu, zrealizowanie swojego potencjału i zbudowanie życia, które wygląda jak ty.",
                zh: "核心是你的身份、目的和生活控制。它关乎找到意义，实现你的潜力并构建一个像你的生活。",
                pt: "O núcleo é a sua identidade, propósito e controle na vida. É sobre encontrar significado, realizar seu potencial e construir uma vida que se pareça com você."
              },
              category_description_pa: {
                en: "Physical activity is the level of endurance, strength, and mobility that's right for you personally.",
                fi: "Fyysinen aktiivisuus on sinulle henkilökohtaisesti sopiva kestävyyden, voiman ja liikkuvuuden taso.",
                de: "Körperliche Aktivität ist das Maß an Ausdauer, Kraft und Beweglichkeit, das für Sie persönlich geeignet ist.",
                es: "La actividad física es el nivel de resistencia, fuerza y movilidad que es adecuado para ti personalmente.",
                fr: "L'activité physique est le niveau d'endurance, de force et de mobilité qui vous convient personnellement.",
                sv: "Fysisk aktivitet är den nivå av uthållighet, styrka och rörlighet som passar just dig och din hälsa.",
                da: "Fysisk aktivitet er det niveau af udholdenhed, styrke og mobilitet, der er rigtigt for dig personligt.",
                et: "Füüsiline aktiivsus on sinu jaoks sobiv vastupidavuse, jõu ja liikuvuse tase.",
                no: "Fysisk aktivitet er nivået av utholdenhet, styrke og mobilitet som passer for deg personlig.",
                pl: "Aktywność fizyczna to poziom wytrzymałości, siły i mobilności odpowiedni dla ciebie osobiście.",
                zh: "体力活动是对你个人而言适合的耐力、力量和灵活性水平。",
                pt: "A atividade física é o nível de resistência, força e mobilidade que é adequado para você pessoalmente."
              },
              category_description_gh: {
                en: "General Health is your overall state of health: helping to cure or prevent illness, and promoting an all-round healthy lifestyle.",
                fi: "Yleinen terveys on kokonaisvaltainen terveydentilasi, joka auttaa parantamaan tai ehkäisemään sairauksia ja edistää kokonaisvaltaista terveellistä elämäntapaa.",
                de: "Allgemeine Gesundheit ist Ihr allgemeiner Gesundheitszustand: hilft bei der Heilung oder Vorbeugung von Krankheiten und fördert einen rundum gesunden Lebensstil.",
                es: "La salud general es tu estado de salud general: ayuda a curar o prevenir enfermedades y promueve un estilo de vida saludable en general.",
                fr: "La santé générale est votre état de santé général : aider à guérir ou à prévenir les maladies et promouvoir un mode de vie sain.",
                sv: "Allmän hälsa är ditt totala hälsotillstånd: den bidrar till att förebygga och behandla sjukdomar samt främjar en hälsosam och balanserad livsstil.",
                da: "Generel sundhed er din overordnede sundhedstilstand: hjælper med at kurere eller forebygge sygdom og fremmer en alsidig sund livsstil.",
                et: "Üldine tervis on teie üldine tervislik seisund: aitab haigusi ravida või ennetada ning edendab terviklikku tervislikku eluviisi.",
                no: "Generell helse er din generelle helsetilstand: bidrar til å kurere eller forebygge sykdom, og fremmer en allsidig sunn livsstil.",
                pl: "Zdrowie ogólne to twój ogólny stan zdrowia: pomaganie w leczeniu lub zapobieganiu chorobom i promowanie wszechstronnego zdrowego stylu życia.",
                zh: "总体健康是你的整体健康状态：有助于治愈或预防疾病，促进全方位的健康生活方式。",
                pt: "A saúde geral é o seu estado geral de saúde: ajuda a curar ou prevenir doenças e promove um estilo de vida saudável completo."
              },
              category_description_nu: {
                en: "Nutrition is the healthy diet that enables you to function optimally: prevent diseases, boost immunity, and have physical and cognitive daily energy.",
                fi: "Ravitsemus on terveellinen ruokavalio, joka mahdollistaa optimaalisen toimintakyvyn: ehkäisee sairauksia, vahvistaa vastustuskykyä ja antaa fyysistä ja kognitiivista energiaa päivittäin.",
                de: "Ernährung ist die gesunde Ernährung, die Ihnen ein optimales Funktionieren ermöglicht: Krankheiten vorzubeugen, die Immunität zu stärken und körperliche sowie kognitive tägliche Energie zu haben.",
                es: "La nutrición es la dieta saludable que te permite funcionar de manera óptima: prevenir enfermedades, aumentar la inmunidad y tener energía física y cognitiva diaria.",
                fr: "La nutrition est le régime alimentaire sain qui vous permet de fonctionner de manière optimale : prévenir les maladies, renforcer l'immunité et avoir une énergie physique et cognitive quotidienne.",
                sv: "Kost är den näring som hjälper dig fungera optimalt: förebygga sjukdomar, stärka immunförsvaret och uppehålla din dagliga energi.",
                da: "Ernæring er den sunde kost, der gør det muligt for dig at fungere optimalt: forebygge sygdomme, styrke immunforsvaret og have fysisk og kognitiv daglig energi.",
                et: "Toitumine on tervislik toitumine, mis võimaldab teil optimaalselt toimida: ennetada haigusi, tugevdada immuunsust ja omada füüsilist ja kognitiivset igapäevast energiat.",
                no: "Ernæring er det sunne kostholdet som gjør at du kan fungere optimalt: forebygge sykdommer, styrke immunforsvaret og ha fysisk og kognitiv daglig energi.",
                pl: "Odżywianie to zdrowa dieta, która umożliwia optymalne funkcjonowanie: zapobieganie chorobom, wzmacnianie odporności i dostarczanie codziennej energii fizycznej i poznawczej.",
                zh: "营养是使你能够最佳运作的健康饮食：预防疾病，提高免疫力，拥有身体和认知的日常能量。",
                pt: "A nutrição é a dieta saudável que permite que você funcione de forma otimizada: prevenir doenças, aumentar a imunidade e ter energia física e cognitiva diária."
              },
              category_description_me: {
                en: "Mental energy are your thoughts, emotions, and mindset. It’s your ability to manage daily routines, work, pressure, relationships, and priorities.",
                fi: "Henkinen energia on ajatuksesi, tunteesi ja ajattelutapasi. Se on kykysi hallita päivittäisiä rutiineja, työtä, painetta, ihmissuhteita ja prioriteetteja.",
                de: "Mentale Energie umfasst Ihre Gedanken, Emotionen und Ihre Einstellung. Es ist Ihre Fähigkeit, tägliche Routinen, Arbeit, Druck, Beziehungen und Prioritäten zu managen.",
                es: "La energía mental son tus pensamientos, emociones y mentalidad. Es tu capacidad para gestionar las rutinas diarias, el trabajo, la presión, las relaciones y las prioridades.",
                fr: "L'énergie mentale, ce sont vos pensées, vos émotions et votre état d'esprit. C'est votre capacité à gérer les routines quotidiennes, le travail, la pression, les relations et les priorités.",
                sv: "Din mentala energi omfattar dina tankar, känslor och din inställning. Det är din förmåga att hantera vardagliga rutiner, arbete, stress, utmaningar, relationer och prioriteter.",
                da: "Mental energi er dine tanker, følelser og tankesæt. Det er din evne til at håndtere daglige rutiner, arbejde, pres, relationer og prioriteter.",
                et: "Vaimne energia on teie mõtted, emotsioonid ja mõtteviis. See on teie võime hallata igapäevaseid rutiine, tööd, survet, suhteid ja prioriteete.",
                no: "Mental energi er dine tanker, følelser og tankesett. Det er din evne til å håndtere daglige rutiner, arbeid, press, relasjoner og prioriteringer.",
                pl: "Energia psychiczna to twoje myśli, emocje i nastawienie. To twoja zdolność do zarządzania codziennymi rutynami, pracą, stresem, relacjami i priorytetami.",
                zh: "心理能量是你的思想、情感和心态。它是你管理日常生活、工作、压力、关系和优先事项的能力。",
                pt: "A energia mental são os seus pensamentos, emoções e mentalidade. É a sua capacidade de gerir rotinas diárias, trabalho, pressão, relacionamentos e prioridades."
              },
              category_description_bm: {
                en: "Biomechanics is your body’s ability to move easily, with control and without pain. Good biomechanics reduces injuries and enables lifelong physical activity.",
                fi: "Biomekaniikka on kehosi kyky liikkua helposti, hallitusti ja ilman kipua. Hyvä biomekaniikka vähentää vammoja ja mahdollistaa elinikäisen fyysisen aktiivisuuden.",
                de: "Biomechanik ist die Fähigkeit Ihres Körpers, sich leicht, kontrolliert und schmerzfrei zu bewegen. Gute Biomechanik reduziert Verletzungen und ermöglicht lebenslange körperliche Aktivität.",
                es: "La biomecánica es la capacidad de tu cuerpo para moverse con facilidad, con control y sin dolor. Una buena biomecánica reduce las lesiones y permite una actividad física de por vida.",
                fr: "La biomécanique est la capacité de votre corps à bouger facilement, avec contrôle et sans douleur. Une bonne biomécanique réduit les blessures et permet une activité physique à vie.",
                sv: "Biomekanik är din kropps förmåga att röra sig kontrollerat och smärtfritt. Bra biomekanik förebygger skador och hjälper dig vara fysiskt aktiv livet ut.",
                da: "Biomekanik er din krops evne til at bevæge sig let, med kontrol og uden smerter. God biomekanik reducerer skader og muliggør livslang fysisk aktivitet.",
                et: "Biomehaanika on teie keha võime kergesti, kontrolli all ja valutult liikuda. Hea biomehaanika vähendab vigastusi ja võimaldab eluaegset füüsilist aktiivsust.",
                no: "Biomekanikk er kroppens evne til å bevege seg lett, med kontroll og uten smerte. God biomekanikk reduserer skader og muliggjør livslang fysisk aktivitet.",
                pl: "Biomechanika to zdolność twojego ciała do łatwego poruszania się, z kontrolą i bez bólu. Dobra biomechanika zmniejsza ryzyko urazów i umożliwia aktywność fizyczną przez całe życie.",
                zh: "生物力学是你身体轻松、控制和无痛移动的能力。良好的生物力学减少受伤并使终生保持身体活动。",
                pt: "A biomecânica é a capacidade do seu corpo de se mover facilmente, com controle e sem dor. Uma boa biomecânica reduz lesões e permite a atividade física ao longo da vida."
              },
              category_description_sr: {
                en: "Sleep & Recovery is your ability to recuperate, regenerate, and perform again tomorrow. It includes night-time recovery, rest, detachment, and periods of renewal.",
                fi: "Uni ja palautuminen on kykysi toipua, uudistua ja suoriutua jälleen huomenna. Se sisältää yönaikaisen palautumisen, levon, irtautumisen sekä uudistumisen jaksot.",
                de: "Schlaf und Erholung sind Ihre Fähigkeit, sich zu erholen, zu regenerieren und am nächsten Tag wieder leistungsfähig zu sein. Dazu gehören nächtliche Erholung, Ruhe, Loslösung und Erneuerungsphasen.",
                es: "El sueño y la recuperación son tu capacidad para recuperarte, regenerarte y volver a rendir mañana. Incluye la recuperación nocturna, el descanso, el desapego y los períodos de renovación.",
                fr: "Le sommeil et la récupération sont votre capacité à récupérer, à vous régénérer et à performer à nouveau demain. Cela inclut la récupération nocturne, le repos, le détachement et les périodes de renouvellement.",
                sv: "Sömn och återhämtning handlar om din förmåga att återuppbygga dina resurser och vara redo igen nästa dag. Det innefattar nattlig återhämtning, vila, avkoppling och perioder av förnyelse.",
                da: "Søvn og restitution er din evne til at komme dig, regenerere og præstere igen i morgen. Det inkluderer nattetid restitution, hvile, frakobling og fornyelsesperioder.",
                et: "Uni ja taastumine on teie võime taastuda, taastuda ja homme uuesti töötada. See hõlmab öist taastumist, puhkamist, eemaldumist ja uuendamisperioode.",
                no: "Søvn og restitusjon er din evne til å komme deg, regenerere og prestere igjen i morgen. Det inkluderer nattlig restitusjon, hvile, frakobling og perioder med fornyelse.",
                pl: "Sen i regeneracja to twoja zdolność do odpoczynku, regeneracji i ponownego działania jutro. Obejmuje nocną regenerację, odpoczynek, oderwanie się i okresy odnowy.",
                zh: "睡眠和恢复是你恢复、再生和明天再次表现的能力。包括夜间恢复、休息、脱离和更新期。",
                pt: "Sono e recuperação é a sua capacidade de recuperar, regenerar e desempenhar novamente amanhã. Inclui recuperação noturna, descanso, desconexão e períodos de renovação."
              },
              category_description_co_2: {
                en: `
### The Core is about three simple questions:

* **Identity**: Do you know who you are?
* **Purpose**: Do you know what you want?
* **Control**: Are you in control of your life?`,
                fi: `
### Ydin käsittelee kolmea yksinkertaista kysymystä:

* **Identiteetti**: Tiedätkö kuka olet?
* **Tarkoitus**: Tiedätkö mitä haluat?
* **Hallinta**: Hallitsetko elämääsi?`,
                de: `
### Der Kern dreht sich um drei einfache Fragen:

* **Identität**: Weißt du, wer du bist?
* **Zweck**: Weißt du, was du willst?
* **Kontrolle**: Hast du dein Leben unter Kontrolle?`,
                es: `
### El núcleo trata sobre tres preguntas simples:

* **Identidad**: ¿Sabes quién eres?
* **Propósito**: ¿Sabes lo que quieres?
* **Control**: ¿Tienes el control de tu vida?`,
                fr: `
### Le noyau tourne autour de trois questions simples :

* **Identité** : Savez-vous qui vous êtes ?
* **But** : Savez-vous ce que vous voulez ?
* **Contrôle** : Avez-vous le contrôle de votre vie ?`,
                sv: `
### Kärnan kan beskrivas med tre enkla frågor:

* **Identitet**: Vet du vem du är?
* **Mening**: Vet du vad du vill?
* **Kontroll**: Har du kontroll över ditt liv?`,
                da: `
### Kernen handler om tre tilsyneladende enkle spørgsmål:

* **Identitet**: Ved du, hvem du er?
* **Formål**: Ved du, hvad du vil?
* **Kontrol**: Har du kontrol over dit liv?`,
                et: `
### Tuum hõlmab kolme petlikult lihtsat küsimust:

* **Identiteet**: Kas sa tead, kes sa oled?
* **Eesmärk**: Kas sa tead, mida sa tahad?
* **Kontroll**: Kas sa kontrollid oma elu?`,
                no: `
### Kjernen handler om tre tilsynelatende enkle spørsmål:

* **Identitet**: Vet du hvem du er?
* **Formål**: Vet du hva du vil?
* **Kontroll**: Har du kontroll over livet ditt?`,
                pl: `
### Rdzeń dotyczy trzech pozornie prostych pytań:

* **Tożsamość**: Czy wiesz, kim jesteś?
* **Cel**: Czy wiesz, czego chcesz?
* **Kontrola**: Czy masz kontrolę nad swoim życiem?`,
                zh: `
### 核心涉及三个看似简单的问题：

* **身份**：你知道你是谁吗？
* **目标**：你知道你想要什么吗？
* **控制**：你掌控你的生活吗？`,
                pt: `
### O núcleo é sobre três perguntas simples:

* **Identidade**: Você sabe quem você é?
* **Propósito**: Você sabe o que você quer?
* **Controle**: Você está no controle da sua vida?`
              },
              category_description_pa_2: {
                en: `
* **Endurance**: ability to sustain physical activity over time
* **Strength**: ability to lift, carry, and resist weights or forces against gravity
* **Mobility**: ability to maintain a full range of motion in your joints and muscles`,
                fi: `
* **Kestävyys**: kyky ylläpitää fyysistä aktiivisuutta pidemmän aikaa
* **Voima**: kyky nostaa, kantaa ja vastustaa painoja tai voimia painovoimaa vastaan
* **Liikkuvuus**: kyky ylläpitää täysi liikerata nivelissäsi ja lihaksissasi`,
                de: `
* **Ausdauer**: Fähigkeit, körperliche Aktivität über einen längeren Zeitraum aufrechtzuerhalten
* **Kraft**: Fähigkeit, Gewichte zu heben, zu tragen und Kräften gegen die Schwerkraft zu widerstehen
* **Beweglichkeit**: Fähigkeit, einen vollen Bewegungsumfang in Ihren Gelenken und Muskeln aufrechtzuerhalten`,
                es: `
* **Resistencia**: capacidad de mantener la actividad física a lo largo del tiempo
* **Fuerza**: capacidad de levantar, llevar y resistir pesos o fuerzas contra la gravedad
* **Movilidad**: capacidad de mantener un rango completo de movimiento en tus articulaciones y músculos`,
                fr: `
* **Endurance**: capacité à maintenir une activité physique dans le temps
* **Force**: capacité à soulever, porter et résister à des poids ou à des forces contre la gravité
* **Mobilité**: capacité à maintenir une amplitude de mouvement complète dans vos articulations et vos muscles`,
                sv: `
* **Uthållighet**: din förmåga att bibehålla fysisk aktivitet
* **Styrka**: din förmåga att lyfta, bära och motstå vikter
* **Rörlighet**: din förmåga till ett fullt rörelseutslag i dina leder och muskler`,
                da: `
* **Udholdenhed**: evnen til at opretholde fysisk aktivitet over tid
* **Styrke**: evnen til at løfte, bære og modstå vægte eller kræfter mod tyngdekraften
* **Mobilitet**: evnen til at opretholde et fuldt bevægelsesområde i dine led og muskler`,
                et: `
* **Vastupidavus**: võime säilitada füüsilist aktiivsust aja jooksul
* **Jõud**: võime tõsta, kanda ja vastupanu osutada raskustele või jõududele gravitatsiooni vastu
* **Liikuvus**: võime säilitada liigestes ja lihastes täielik liikumisulatus`,
                no: `
* **Utholdenhet**: evne til å opprettholde fysisk aktivitet over tid
* **Styrke**: evne til å løfte, bære og motstå vekter eller krefter mot tyngdekraften
* **Mobilitet**: evne til å opprettholde et fullt bevegelsesutslag i leddene og musklene dine`,
                pl: `
* **Wytrzymałość**: zdolność do utrzymywania aktywności fizycznej przez długi czas
* **Siła**: zdolność do podnoszenia, przenoszenia i opierania się ciężarom lub siłom przeciwdziałającym grawitacji
* **Mobilność**: zdolność do utrzymania pełnego zakresu ruchu w stawach i mięśniach`,
                zh: `
* **耐力**：持续进行体力活动的能力
* **力量**：提升、搬运和抵抗重量或重力的能力
* **灵活性**：保持关节和肌肉全方位运动的能力`,
                pt: `
* **Resistência**: capacidade de sustentar a atividade física ao longo do tempo
* **Força**: capacidade de levantar, carregar e resistir a pesos ou forças contra a gravidade
* **Mobilidade**: capacidade de manter toda a amplitude de movimento nas suas articulações e músculos`
              },
              category_description_gh_2: {
                en: "Many of the illnesses we suffer from today are linked to lifestyle factors. For improved lifespan and healthspan, we need to focus on medical prevention, i.e. the actions we do for our wellbeing.",
                fi: "Monet tämän päivän sairauksista liittyvät elämäntapatekijöihin. Pidemmän ja terveemmän eliniän saavuttamiseksi meidän on keskityttävä ennaltaehkäisyyn eli toimiin, joita teemme hyvinvointimme hyväksi.",
                de: "Viele der Krankheiten, an denen wir heute leiden, sind mit Lebensstilfaktoren verbunden. Für eine verbesserte Lebensdauer und Gesundheitsspanne müssen wir uns auf medizinische Prävention konzentrieren, d.h. die Maßnahmen, die wir für unser Wohlbefinden ergreifen.",
                es: "Muchas de las enfermedades que sufrimos hoy en día están relacionadas con factores de estilo de vida. Para mejorar la esperanza de vida y la salud, debemos centrarnos en la prevención médica, es decir, las acciones que realizamos para nuestro bienestar.",
                fr: "De nombreuses maladies dont nous souffrons aujourd'hui sont liées à des facteurs de mode de vie. Pour améliorer la longévité et la durée de vie en bonne santé, nous devons nous concentrer sur la prévention médicale, c'est-à-dire les actions que nous entreprenons pour notre bien-être.",
                sv: "Många av dagens sjukdomar är starkt kopplade till livsvanor. För att öka både livslängd och friska år bör vi fokusera på medicinskt förebyggande åtgärder, dvs de handlingar vi gör för vårt välbefinnande.",
                da: "Mange af de sygdomme, vi lider af i dag, er forbundet med livsstilsfaktorer. For at forbedre levetiden og sundhedsforløbet skal vi fokusere på medicinsk forebyggelse, dvs. de handlinger, vi gør for vores velbefindende.",
                et: "Paljud tänapäeval esinevad haigused on seotud elustiilifaktoritega. Eluea ja tervisliku eluea parandamiseks peame keskenduma meditsiinilisele ennetamisele, st tegevustele, mida teeme oma heaolu heaks.",
                no: "Mange av sykdommene vi lider av i dag er knyttet til livsstilsfaktorer. For å forbedre levetiden og helsetiden, må vi fokusere på medisinsk forebygging, dvs. de handlingene vi gjør for vårt velvære.",
                pl: "Wiele chorób, na które cierpimy dzisiaj, jest związanych z czynnikami stylu życia. Aby poprawić długość życia i zdrowia, musimy skupić się na profilaktyce medycznej, czyli działaniach, które podejmujemy dla naszego dobrostanu.",
                zh: "我们今天遭受的许多疾病都与生活方式因素有关。为了延长寿命和健康寿命，我们需要关注医疗预防，即我们为健康所做的行动。",
                pt: "Muitas das doenças que sofremos hoje estão ligadas a fatores de estilo de vida. Para melhorar a longevidade e a saúde, precisamos focar na prevenção médica, ou seja, nas ações que fazemos para o nosso bem-estar."
              },
              category_description_nu_2: {
                en: `
Nutrition is a combination of:

* **What** you eat – getting the right nutrients
* **How much** you eat – not over-eating or under-eating
* **How** you eat – daily rhythms for your lifestyle`,
                fi: `
Ravitsemus on yhdistelmä seuraavia tekijöitä:

* **Mitä syöt** – oikeiden ravintoaineiden saaminen
* **Kuinka paljon syöt**  – ylensyömisen ja alisyömisen välttäminen
* **Kuinka syöt** – elämäntyyliisi sopiva päivittäinen rytmi`,
                de: `
Ernährung ist eine Kombination aus:

* **Was** Sie essen – die richtigen Nährstoffe bekommen
* **Wie viel** Sie essen – nicht zu viel oder zu wenig essen
* **Wie** Sie essen – tägliche Rhythmen für Ihren Lebensstil`,
                es: `
La nutrición es una combinación de:

* **Qué** comes – obtener los nutrientes adecuados
* **Cuánto** comes – no comer en exceso ni en defecto
* **Cómo** comes – ritmos diarios para tu estilo de vida`,
                fr: `
La nutrition est une combinaison de:

* **Ce que** vous mangez – obtenir les bons nutriments
* **Combien** vous mangez – ne pas trop manger ni trop peu
* **Comment** vous mangez – des rythmes quotidiens adaptés à votre style de vie`,
                sv: `
Kost är en kombination av:

* **Vad** du äter – få i dig rätt näringsämnen
* **Hur mycket** du äter – varken för mycket eller för lite
* **Hur** du äter – dina dagliga rytmer och matvanor`,
                da: `
Ernæring er en kombination af:

* **Hvad** du spiser – få de rigtige næringsstoffer
* **Hvor meget** du spiser – ikke overspise eller underspise
* **Hvordan** du spiser – daglige rytmer for din livsstil`,
                et: `
Toitumine on kombinatsioon järgmistest:

* **Mida** sa sööd – saades õiged toitained
* **Kui palju** sa sööd – ei tohi üle süüa ega alatoituda
* **Kuidas** sa sööd – igapäevased rütmid sinu elustiilile`,
                no: `
Ernæring er en kombinasjon av:

* **Hva** du spiser – få i deg de riktige næringsstoffene
* **Hvor mye** du spiser – ikke spise for mye eller for lite
* **Hvordan** du spiser – daglige rytmer for din livsstil`,
                pl: `
Odżywianie to połączenie:

* **Co** jesz – dostarczanie odpowiednich składników odżywczych
* **Ile** jesz – unikanie przejadania się lub niedojadania
* **Jak** jesz – codzienne rytmy dostosowane do twojego stylu życia`,
                zh: `
营养是以下方面的组合：

* **你吃什么** – 获取正确的营养
* **你吃多少** – 不过量或不过少
* **你怎么吃** – 适应你的生活方式的日常节奏`,
                pt: `
A nutrição é uma combinação de:

* **O que** você come – obter os nutrientes certos
* **Quanto** você come – não comer demais ou de menos
* **Como** você come – ritmos diários para o seu estilo de vida`
              },
              category_description_co_3: {
                en: `
### Why the Core is important

* **Resilience:** A strong sense of self separates who you are from what you do. If your identity relies heavily on work, setbacks can become overwhelming

* **Direction:** A clear purpose helps you identify what matters and what to prioritise

* **Sustainable change:** Have you ever struggled to maintain new habits? Sustainable change starts from your Core, a key focus of Hintsa coaching`,
                fi: `
### Miksi Ydin on tärkeä

* **Resilienssi:** Vahva käsitys itsestä erottaa sen, kuka olet, siitä, mitä teet. Jos identiteettisi perustuu vahvasti työhön, takaiskut voivat olla ylivoimaisia

* **Suunta:** Selkeä tarkoitus auttaa sinua tunnistamaan, mikä on tärkeää ja mitä priorisoida

* **Kestävä muutos:** Oletko koskaan kamppaillut uusien tapojen ylläpitämisen kanssa? Kestävä muutos alkaa Ytimestäsi, mikä on Hintsa-valmennuksen keskeinen painopiste`,
                de: `
### Warum der Kern wichtig ist

* **Resilienz:** Ein starkes Selbstbewusstsein trennt, wer Sie sind, von dem, was Sie tun. Wenn Ihre Identität stark von der Arbeit abhängt, können Rückschläge überwältigend sein

* **Richtung:** Ein klares Ziel hilft Ihnen, zu erkennen, was wichtig ist und was Priorität hat

* **Nachhaltige Veränderung:** Haben Sie jemals Schwierigkeiten gehabt, neue Gewohnheiten aufrechtzuerhalten? Nachhaltige Veränderung beginnt bei Ihrem Kern, einem zentralen Fokus des Hintsa-Coachings`,
                es: `
### Por qué el núcleo es importante

* **Resiliencia:** Un fuerte sentido de sí mismo separa quién eres de lo que haces. Si tu identidad depende en gran medida del trabajo, los contratiempos pueden volverse abrumadores

* **Dirección:** Un propósito claro te ayuda a identificar qué es importante y qué priorizar

* **Cambio sostenible:** ¿Alguna vez has tenido dificultades para mantener nuevos hábitos? El cambio sostenible comienza desde tu núcleo, un enfoque clave del coaching de Hintsa`,
                fr: `
### Pourquoi le noyau est important

* **Résilience:** Un fort sens de soi sépare qui vous êtes de ce que vous faites. Si votre identité repose fortement sur le travail, les revers peuvent devenir accablants

* **Direction:** Un but clair vous aide à identifier ce qui compte et ce qu'il faut prioriser

* **Changement durable:** Avez-vous déjà eu du mal à maintenir de nouvelles habitudes ? Le changement durable commence par votre noyau, un point central du coaching Hintsa`,
                sv: `
### Varför kärnan är viktig

* **Resiliens:** När din självkänsla är stark, skiljer du bättre din identitet från dina prestationer. Om du bygger din identitet starkt på ditt arbete kan motgångar kännas överväldigande

* **Riktning:** Ett klart livsmål hjälper dig urskilja vad som är betydelsefullt och bör prioriteras

* **Hållbar förändring:** Har du kämpat med att hålla fast vid nya vanor? Hållbar förändring börjar från din Kärna, en hörnsten i Hintsa-coaching`,
                da: `
### Hvorfor kernen er vigtig

* **Modstandsdygtighed:** En stærk følelse af selv adskiller, hvem du er, fra hvad du gør. Hvis din identitet er stærkt afhængig af arbejde, kan tilbageslag blive overvældende

* **Retning:** Et klart formål hjælper dig med at identificere, hvad der betyder noget, og hvad du skal prioritere

* **Bæredygtig forandring:** Har du nogensinde haft problemer med at opretholde nye vaner? Bæredygtig forandring starter fra din kerne, et centralt fokus for Hintsa-coaching`,
                et: `
### Miks tuum on oluline

* **Paindlikkus:** Tugev enesetunne eraldab selle, kes sa oled, sellest, mida sa teed. Kui teie identiteet tugineb suuresti tööle, võivad tagasilöögid muutuda ülekaalukaks

* **Suund:** Selge eesmärk aitab teil tuvastada, mis on oluline ja mida prioriseerida

* **Jätkusuutlik muutus:** Kas olete kunagi võidelnud uute harjumuste säilitamise nimel? Jätkusuutlik muutus algab teie tuumast, Hintsa coaching keskne fookus`,
                no: `
### Hvorfor kjernen er viktig

* **Motstandsdyktighet:** En sterk følelse av selvet skiller hvem du er fra hva du gjør. Hvis identiteten din er sterkt avhengig av arbeid, kan tilbakeslag bli overveldende

* **Retning:** Et klart formål hjelper deg med å identifisere hva som betyr noe og hva du skal prioritere

* **Bærekraftig endring:** Har du noen gang slitt med å opprettholde nye vaner? Bærekraftig endring starter fra kjernen din, et sentralt fokus i Hintsa-coaching`,
                pl: `
### Dlaczego rdzeń jest ważny

* **Odporność:** Silne poczucie siebie oddziela to, kim jesteś, od tego, co robisz. Jeśli twoja tożsamość mocno opiera się na pracy, niepowodzenia mogą stać się przytłaczające

* **Kierunek:** Jasny cel pomaga określić, co jest ważne i co priorytetowo traktować

* **Zrównoważona zmiana:** Czy kiedykolwiek miałeś trudności z utrzymaniem nowych nawyków? Zrównoważona zmiana zaczyna się od twojego rdzenia, kluczowego elementu coachingu Hintsa`,
                zh: `
### 为什么核心很重要

* **韧性:** 强烈的自我感将你是谁与你做什么区分开来。如果你的身份严重依赖于工作，挫折可能会变得难以承受

* **方向:** 明确的目标帮助你确定重要的事情和优先事项

* **可持续变化:** 你是否曾经难以保持新习惯？可持续的变化始于你的核心，这是Hintsa教练的重点`,
                pt: `
### Por que o Núcleo é importante

* **Resiliência:** Um forte senso de identidade separa quem você é do que você faz. Se sua identidade depende muito do trabalho, os contratempos podem se tornar avassaladores

* **Direção:** Um propósito claro ajuda você a identificar o que é importante e o que priorizar

* **Mudança sustentável:** Você já teve dificuldade em manter novos hábitos? A mudança sustentável começa pelo seu Núcleo, um foco fundamental do coaching Hintsa`
              },
              category_description_co_4: {
                en: `
### Start exploring your Core

* **Signature strengths**: Start by verbalising your strengths. What three things are you good at? Ask a friend for their perspective.

* **Values:** What do you value in life or in other people? Write down a few key values or principles. 

* **Fast forward:** If you continue on your current path, where will you be in ten years? Is that where you want to go?`,
                fi: `
### Aloita Ytimesi tutkiminen

* **Tunnusomaiset vahvuutesi**: Aloita sanoittamalla vahvuutesi. Missä kolmessa asiassa olet hyvä? Kysy ystävältä hänen näkökulmaansa.

* **Arvot:** Mitä arvostat elämässä tai muissa ihmisissä? Kirjoita ylös muutama keskeinen arvo tai periaate.

* **Pikakelaus:** Jos jatkat nykyisellä polullasi, missä olet kymmenen vuoden kuluttua? Onko se suunta, minne haluat mennä?`,
                de: `
### Beginnen Sie, Ihren Kern zu erkunden

* **Signaturstärken**: Beginnen Sie damit, Ihre Stärken zu verbalisieren. In welchen drei Dingen sind Sie gut? Fragen Sie einen Freund nach seiner Perspektive.

* **Werte:** Was schätzen Sie im Leben oder an anderen Menschen? Schreiben Sie ein paar Schlüsselwerte oder Prinzipien auf.

* **Vorspulen:** Wenn Sie auf Ihrem aktuellen Weg weitermachen, wo werden Sie in zehn Jahren sein? Ist das, wo Sie hinwollen?`,
                es: `
### Comienza a explorar tu Núcleo

* **Fortalezas distintivas**: Comienza verbalizando tus fortalezas. ¿En qué tres cosas eres bueno? Pregunta a un amigo por su perspectiva.

* **Valores:** ¿Qué valoras en la vida o en otras personas? Escribe algunos valores o principios clave.

* **Adelantar:** Si continúas en tu camino actual, ¿dónde estarás en diez años? ¿Es allí donde quieres ir?`,
                fr: `
### Commencez à explorer votre noyau

* **Forces distinctives**: Commencez par verbaliser vos forces. Quelles sont les trois choses dans lesquelles vous êtes bon ? Demandez à un ami son point de vue.

* **Valeurs:** Qu'est-ce que vous appréciez dans la vie ou chez les autres ? Écrivez quelques valeurs ou principes clés.

* **Avance rapide:** Si vous continuez sur votre chemin actuel, où serez-vous dans dix ans ? Est-ce là où vous voulez aller ?`,
                sv: `
### Börja utforska din Kärna

* **Signaturstyrkor:** Börja med att notera dina styrkor. Vilka tre saker är du särskiljt bra på? Fråga en vän om deras syn.

* **Värderingar:** Vad värderar du högst i livet eller hos andra människor? Notera några av dina viktigaste värden eller principer.

* **Blicka framåt:** Om du håller fast vid din nuvarande riktning, var kommer du att vara om tio år? Är det den framtid du önskar?`,
                da: `
### Begynd at udforske din kerne

* **Signaturstyrker:** Start med at verbalisere dine styrker. Hvilke tre ting er du god til? Spørg en ven om deres perspektiv.

* **Værdier:** Hvad værdsætter du i livet eller hos andre mennesker? Skriv et par nøgleværdier eller principper ned.

* **Spol frem:** Hvis du fortsætter på din nuværende vej, hvor vil du være om ti år? Er det der, du vil hen?`,
                et: `
### Alustage oma tuuma uurimist

* **Allkirjastatud tugevused:** Alustage oma tugevuste verbaliseerimisest. Milles kolmes asjas olete hea? Küsige sõbralt nende vaatenurka.

* **Väärtused:** Mida hindate elus või teistes inimestes? Kirjutage üles mõned põhiväärtused või -põhimõtted.

* **Kiirkerimine:** Kui jätkate oma praegusel teel, kus te olete kümne aasta pärast? Kas see on koht, kuhu soovite minna?`,
                no: `
### Begynn å utforske kjernen din

* **Signaturstyrker:** Begynn med å verbalisere styrkene dine. Hva er tre ting du er god på? Spør en venn om deres perspektiv.

* **Verdier:** Hva verdsetter du i livet eller hos andre mennesker? Skriv ned noen nøkkelverdier eller prinsipper.

* **Spol frem:** Hvis du fortsetter på din nåværende vei, hvor vil du være om ti år? Er det dit du vil gå?`,
                pl: `
### Zacznij odkrywać swój Rdzeń

* **Podstawowe mocne strony:** Zacznij od werbalizacji swoich mocnych stron. W czym jesteś dobry w trzech rzeczach? Zapytaj znajomego o jego perspektywę.

* **Wartości:** Co cenisz w życiu lub w innych ludziach? Zapisz kilka kluczowych wartości lub zasad.

* **Przewijanie do przodu:** Jeśli będziesz kontynuować swoją obecną ścieżkę, gdzie będziesz za dziesięć lat? Czy to tam chcesz iść?`,
                zh: `
### 开始探索你的核心

* **标志性优势:** 先口头表达你的优势。你擅长哪三件事？问问朋友他们的看法。

* **价值观:** 你在生活中或其他人身上重视什么？写下几个关键价值观或原则。

* **快进:** 如果你继续现在的道路，十年后你会在哪里？那是你想去的地方吗？`,
                pt: `
### Comece a explorar o seu Núcleo

* **Pontos fortes de assinatura:** Comece verbalizando seus pontos fortes. Em que três coisas você é bom? Pergunte a um amigo sobre sua perspectiva.

* **Valores:** O que você valoriza na vida ou nas outras pessoas? Anote alguns valores ou princípios chave.

* **Avanço rápido:** Se você continuar no seu caminho atual, onde estará em dez anos? É para lá que você quer ir?`
              },
              category_description_pa_3: {
                en: `
### Why physical activity is important

Physical activity (alongside sleep) is the best medical prevention known to science. It…

* …boosts longevity and healthspan

* …builds your ‘energy engine’: heart, lungs, and circulation

* …decreases cognitive decline and boosts brain health

* …improves emotional stability and confidence

* …decreases anxiety and depression

* …improves focus and cognitive flexibility

* …is often a keystone habit that unlocks other good habits`,
                fi: `
### Miksi fyysinen aktiivisuus on tärkeää

Fyysinen aktiivisuus (yhdessä unen kanssa) on paras tieteen tuntema lääketieteellinen ennaltaehkäisy. Se…

* …lisää pitkäikäisyyttä ja terveyttä

* …rakentaa 'energiamoottoriasi': sydäntä, keuhkoja ja verenkiertoa

* …hidastaa kognitiivista heikentymistä ja parantaa aivojen terveyttä

* …parantaa emotionaalista vakautta ja itseluottamusta

* …vähentää ahdistusta ja masennusta

* …parantaa keskittymistä ja kognitiivista joustavuutta

* …on usein keskeinen tapa, joka avaa oven muille hyville tavoille`,
                de: `
### Warum körperliche Aktivität wichtig ist

Körperliche Aktivität (neben Schlaf) ist die beste medizinische Prävention, die die Wissenschaft kennt. Es…

* …steigert die Langlebigkeit und die Gesundheitsspanne

* …baut Ihr 'Energie-Motor': Herz, Lunge und Kreislauf auf

* …verringert den kognitiven Abbau und verbessert die Gehirngesundheit

* …verbessert die emotionale Stabilität und das Selbstbewusstsein

* …verringert Angst und Depression

* …verbessert die Konzentration und kognitive Flexibilität

* …ist oft eine Schlüsselgewohnheit, die andere gute Gewohnheiten freisetzt`,
                es: `
### Por qué la actividad física es importante

La actividad física (junto con el sueño) es la mejor prevención médica conocida por la ciencia. Esto…

* …aumenta la longevidad y la salud

* …construye tu ‘motor de energía’: corazón, pulmones y circulación

* …disminuye el deterioro cognitivo y mejora la salud cerebral

* …mejora la estabilidad emocional y la confianza

* …disminuye la ansiedad y la depresión

* …mejora el enfoque y la flexibilidad cognitiva

* …a menudo es un hábito clave que desbloquea otros buenos hábitos`,
                fr: `
### Pourquoi l'activité physique est importante

L'activité physique (avec le sommeil) est la meilleure prévention médicale connue de la science. Cela…

* …augmente la longévité et la durée de vie en bonne santé

* …construit votre 'moteur énergétique' : cœur, poumons et circulation

* …diminue le déclin cognitif et améliore la santé cérébrale

* …améliore la stabilité émotionnelle et la confiance en soi

* …diminue l'anxiété et la dépression

* …améliore la concentration et la flexibilité cognitive

* …est souvent une habitude clé qui en débloque d'autres bonnes`,
                sv: `
### Varför fysisk aktivitet är viktigt

Fysisk aktivitet (tillsammans med sömn) är den bästa förebyggande åtgärden som vetenskapen känner till. Fysisk aktivitet…

* …ökar livslängd och friska år

* …utvecklar din 'energimotor': hjärta, lungor och cirkulation

* …motverkar kognitiv nedgång och förbättrar hjärnans hälsa

* …bygger emotionell hälsa och självförtroende

* …lindrar ångest och depression

* …förbättrar fokus

* …är ofta en "nyckelvana" som leder till andra goda vanor`,
                da: `
### Hvorfor fysisk aktivitet er vigtigt

Fysisk aktivitet (sammen med søvn) er den bedste medicinske forebyggelse, videnskaben kender. Det…

* …øger levetiden og sundhedsspændet

* …bygger din 'energi motor': hjerte, lunger og kredsløb

* …mindsker kognitiv tilbagegang og forbedrer hjernens sundhed

* …forbedrer følelsesmæssig stabilitet og selvtillid

* …mindsker angst og depression

* …forbedrer fokus og kognitiv fleksibilitet

* …er ofte en nøglevane, der låser op for andre gode vaner`,
                et: `
### Miks füüsiline aktiivsus on oluline

Füüsiline aktiivsus (koos unega) on parim teadusele teadaolev meditsiiniline ennetus. See…

* …suurendab pikaealisust ja tervislikku eluiga

* …ehitab teie 'energiamootori': süda, kopsud ja vereringe

* …vähendab kognitiivset langust ja parandab aju tervist

* …parandab emotsionaalset stabiilsust ja enesekindlust

* …vähendab ärevust ja depressiooni

* …parandab keskendumisvõimet ja kognitiivset paindlikkust

* …on sageli võtmeharjumus, mis avab muid häid harjumusi`,
                no: `
### Hvorfor fysisk aktivitet er viktig

Fysisk aktivitet (sammen med søvn) er den beste medisinske forebyggingen kjent av vitenskapen. Det…

* …øker levetid og helsetid

* …bygger din 'energimotor': hjerte, lunger og sirkulasjon

* …reduserer kognitiv nedgang og forbedrer hjernehelsen

* …forbedrer emosjonell stabilitet og selvtillit

* …reduserer angst og depresjon

* …forbedrer fokus og kognitiv fleksibilitet

* …er ofte en nøkkelvane som låser opp andre gode vaner`,
                pl: `
### Dlaczego aktywność fizyczna jest ważna

Aktywność fizyczna (wraz ze snem) jest najlepszą profilaktyką medyczną znaną nauce. To…

* …zwiększa długowieczność i zdrowie

* …buduje twój 'silnik energetyczny': serce, płuca i krążenie

* …zmniejsza spadek funkcji poznawczych i poprawia zdrowie mózgu

* …poprawia stabilność emocjonalną i pewność siebie

* …zmniejsza lęk i depresję

* …poprawia koncentrację i elastyczność poznawczą

* …często jest to kluczowy nawyk, który odblokowuje inne dobre nawyki`,
                zh: `
### 为什么体育锻炼很重要

体育活动（与睡眠一起）是科学界公认的最佳医学预防措施。它...

* …延长寿命和健康寿命

* …构建你的“能量引擎”：心脏、肺和循环系统

* …减少认知衰退并促进大脑健康

* …改善情绪稳定性和自信心

* …减轻焦虑和抑郁

* …提高专注力和认知灵活性

* …往往是解锁其他良好习惯的基石习惯`,
                pt: `
### Por que a atividade física é importante

A atividade física (junto com o sono) é a melhor prevenção médica conhecida pela ciência. Isso…

* …aumenta a longevidade e a saúde

* …constrói seu 'motor de energia': coração, pulmões e circulação

* …diminui o declínio cognitivo e melhora a saúde do cérebro

* …melhora a estabilidade emocional e a confiança

* …diminui a ansiedade e a depressão

* …melhora o foco e a flexibilidade cognitiva

* …é frequentemente um hábito fundamental que desbloqueia outros bons hábitos`
              },
              category_description_pa_4: {
                en: `
### Start physical activity with 'why'

Discovering your “why” is the key to start. Ask yourself:

* What do you seek from physical activity?

* What exercise forms do you enjoy?

* Why are you physically active or inactive?

* What obstacles do you face?

* What’s one thing you can commit to today?`,
                fi: `
### Aloita fyysinen aktiivisuus määrittelemällä 'miksi'

Oman "miksi" löytäminen on avain aloittamiseen. Kysy itseltäsi:

* Mitä etsit fyysisestä aktiivisuudesta?

* Mistä liikuntamuodoista pidät?

* Miksi olet fyysisesti aktiivinen tai passiivinen?

* Millaisia esteitä kohtaat?

* Mihin yhteen asiaan voit sitoutua tänään?`,
                de: `
### Beginnen Sie mit dem 'Warum' der körperlichen Aktivität

Das Entdecken Ihres „Warum“ ist der Schlüssel zum Start. Fragen Sie sich:

* Was suchen Sie bei körperlicher Aktivität?

* Welche Übungsformen genießen Sie?

* Warum sind Sie körperlich aktiv oder inaktiv?

* Welche Hindernisse stehen Ihnen im Weg?

* Wozu können Sie sich heute verpflichten?`,
                es: `
### Comienza la actividad física con 'por qué'

Descubrir tu “por qué” es la clave para empezar. Pregúntate:

* ¿Qué buscas en la actividad física?

* ¿Qué formas de ejercicio disfrutas?

* ¿Por qué eres físicamente activo o inactivo?

* ¿Qué obstáculos enfrentas?

* ¿A qué puedes comprometerte hoy?`,
                fr: `
### Commencez l'activité physique par « pourquoi »

Découvrir votre « pourquoi » est la clé pour commencer. Demandez-vous :

* Que recherchez-vous dans l'activité physique ?

* Quelles formes d'exercice appréciez-vous ?

* Pourquoi êtes-vous physiquement actif ou inactif ?

* Quels obstacles rencontrez-vous ?

* À quoi pouvez-vous vous engager aujourd'hui ?`,
                sv: `
### Börja med 'varför'

Att upptäcka ditt “varför” är viktigt. Fråga dig själv:

* Vad är ditt mål med fysisk aktivitet?

* Vilka träningsformer gillar du?

* Vad gör dig fysiskt aktiv eller inaktiv?

* Vilka hinder upplever du?

* Vad är en konkret handling du kan göra idag?`,
                da: `
### Start med 'hvorfor'

At opdage dit “hvorfor” er nøglen. Spørg dig selv:

* Hvad søger du fra fysisk aktivitet?

* Hvilke former for motion nyder du?

* Hvorfor er du fysisk aktiv eller inaktiv?

* Hvilke forhindringer står du over for?

* Hvad er én ting, du kan forpligte dig til i dag?`,
                et: `
### Alustage füüsilise tegevusega 'miks'

Oma “miksi” avastamine on alustamise võti. Küsige endalt:

* Mida sa füüsilisest aktiivsusest otsid?

* Milliseid treeningvorme sa naudid?

* Miks sa oled füüsiliselt aktiivne või passiivne?

* Milliste takistustega sa silmitsi seisad?

* Millele saad täna pühenduda?`,
                no: `
### Start med 'hvorfor'

Å oppdage ditt “hvorfor” er nøkkelen. Spør deg selv:

* Hva søker du fra fysisk aktivitet?

* Hvilke treningsformer liker du?

* Hvorfor er du fysisk aktiv eller inaktiv?

* Hvilke hindringer står du overfor?

* Hva er en ting du kan forplikte deg til i dag?`,
                pl: `
### Zacznij aktywność fizyczną od 'dlaczego'

Odkrycie swojego „dlaczego” jest kluczem do rozpoczęcia. Zadaj sobie pytanie:

* Czego szukasz w aktywności fizycznej?

* Jakie formy ćwiczeń lubisz?

* Dlaczego jesteś aktywny fizycznie lub nieaktywny?

* Jakie przeszkody napotykasz?

* Co możesz zobowiązać się do zrobienia dzisiaj?`,
                zh: `
### 以“为什么”开始体育活动

发现你的“为什么”是开始的关键。问问你自己：

* 你从体育活动中寻求什么？

* 你喜欢什么形式的锻炼？

* 你为什么身体活跃或不活跃？

* 你面临哪些障碍？

* 今天你可以承诺做的一件事是什么？`,
                pt: `
### Comece a atividade física com 'por quê'

Descobrir seu “porquê” é a chave para começar. Pergunte a si mesmo:

* O que você busca na atividade física?

* Quais formas de exercício você gosta?

* Por que você é fisicamente ativo ou inativo?

* Quais obstáculos você enfrenta?

* Qual é uma coisa à qual você pode se comprometer hoje?`
              },
              category_description_nu_3: {
                en: `
### Why nutrition is important

* Nutrition impacts gut and brain health, both crucial for healthspan

* An optimal diet can increase life expectancy by >10 years

* 70-80% of people do not get enough phytonutrients, i.e. diverse plant-based foods

* Stable blood sugar levels contribute to energy, mood, and clear thinking`,
                fi: `
### Miksi ravitsemus on tärkeää

* Ravitsemus vaikuttaa suoliston ja aivojen terveyteen, jotka molemmat ovat ratkaisevan tärkeitä terveydelle

* Optimaalinen ruokavalio voi pidentää elinajanodotetta yli 10 vuodella

* 70-80 % ihmisistä ei saa tarpeeksi fytoravinteita esimerkiksi monipuolisia kasviperäisiä ruokia

* Vakaa verensokeritaso edistää energiaa, mielialaa ja selkeää ajattelua`,
                de: `
### Warum Ernährung wichtig ist

* Ernährung beeinflusst die Gesundheit von Darm und Gehirn, beide sind entscheidend für die Gesundheitsspanne

* Eine optimale Ernährung kann die Lebenserwartung um >10 Jahre erhöhen

* 70-80% der Menschen bekommen nicht genug Phytonährstoffe, d.h. verschiedene pflanzliche Lebensmittel

* Stabile Blutzuckerspiegel tragen zu Energie, Stimmung und klarem Denken bei`,
                es: `
### Por qué la nutrición es importante

* La nutrición impacta la salud intestinal y cerebral, ambas cruciales para la salud

* Una dieta óptima puede aumentar la esperanza de vida en más de 10 años

* El 70-80% de las personas no obtienen suficientes fitonutrientes, es decir, alimentos vegetales diversos

* Los niveles estables de azúcar en la sangre contribuyen a la energía, el estado de ánimo y la claridad mental`,
                fr: `
### Pourquoi la nutrition est importante

* La nutrition impacte la santé intestinale et cérébrale, toutes deux cruciales pour la durée de vie en bonne santé

* Une alimentation optimale peut augmenter l'espérance de vie de plus de 10 ans

* 70 à 80 % des personnes ne consomment pas suffisamment de phytonutriments, c'est-à-dire d'aliments végétaux diversifiés

* Des niveaux de sucre sanguin stables contribuent à l'énergie, à l'humeur et à la clarté mentale`,
                sv: `
### Varför kost är viktigt

* Näring påverkar tarm- och hjärnhälsa - båda avgörande för friska år

* En optimal kost kan öka livslängden med 10+ år

* 70-80 % av oss får inte tillräckligt med "fytonäringsämnen", dvs varierande växtbaserad kost

* En stabil blodsockernivå bidrar till energi, humör och klart tänkande`,
                da: `
### Hvorfor ernæring er vigtig

* Ernæring påvirker tarm- og hjernehelbred, begge afgørende for sundhed

* En optimal kost kan øge forventet levealder med >10 år

* 70-80 % af mennesker får ikke nok phytonutrienter, dvs. forskellige plantebaserede fødevarer

* Stabile blodsukkerniveauer bidrager til energi, humør og klar tænkning`,
                et: `
### Miks toitumine on oluline

* Toitumine mõjutab soolte ja aju tervist, mõlemad on olulised tervise jaoks

* Optimaalne toitumine võib pikendada eluiga üle 10 aasta

* 70-80% inimestest ei saa piisavalt fütonutriente, s.t. erinevaid taimseid toite

* Stabiilne veresuhkru tase aitab kaasa energiale, meeleolule ja selgele mõtlemisele`,
                no: `
### Hvorfor ernæring er viktig

* Ernæring påvirker tarm- og hjernehelse, begge avgjørende for helse

* Et optimalt kosthold kan øke forventet levealder med >10 år

* 70-80 % av folk får ikke nok fytonæringsstoffer, dvs. ulike plantebaserte matvarer

* Stabile blodsukkernivåer bidrar til energi, humør og klar tenkning`,
                pl: `
### Dlaczego odżywianie jest ważne

* Odżywianie wpływa na zdrowie jelit i mózgu, oba są kluczowe dla zdrowia

* Optymalna dieta może zwiększyć oczekiwaną długość życia o >10 lat

* 70-80% ludzi nie otrzymuje wystarczającej ilości fitoskładników, czyli różnorodnych pokarmów roślinnych

* Stabilne poziomy cukru we krwi przyczyniają się do energii, nastroju i jasnego myślenia`,
                zh: `
### 为什么营养很重要

* 营养影响肠道和大脑健康，这两者对健康至关重要

* 最佳饮食可以将预期寿命延长超过10年

* 70-80% 的人没有摄入足够的植物营养素，即多样的植物性食物

* 稳定的血糖水平有助于能量、情绪和清晰思维`,
                pt: `
### Por que a nutrição é importante

* A nutrição afeta a saúde intestinal e cerebral, ambas cruciais para a saúde

* Uma dieta ideal pode aumentar a expectativa de vida em mais de 10 anos

* 70-80% das pessoas não ingerem fitonutrientes suficientes, ou seja, alimentos vegetais diversos

* Níveis estáveis de açúcar no sangue contribuem para energia, humor e clareza mental`
              },
              category_description_nu_4: {
                en: `
### Nutrition is highly personal, but these three tips fit everyone:

1. **Love water**: Hydration is critical for your focus, energy, health, and skin. Drink up!

1. **Eat real food**: Real food grows, swims, or grazes, is minimally processed, and might have been served by your great grandmother.

1. **Get colourful**: Varied phytonutrients (plants) are critical for your health, and colour is a good proxy. Try the ‘phytonutrient challenge’: can you eat red, blue, green, orange, and white plant foods in one day?

1. **80/20 rule**: Perfection is not the goal. Aim to get it right to 80%.`,
                fi: `
### Ravitsemus on hyvin henkilökohtaista, mutta nämä kolme vinkkiä sopivat kaikille:

1. **Rakasta vettä**: Nesteytys on kriittistä keskittymiskyvyn, energian, terveyden ja ihon kannalta. Juo vettä!

1. **Syö oikeaa ruokaa**: Oikea ruoka kasvaa, ui tai laiduntaa, on minimaalisesti prosessoitua ja sitä olisi voinut tarjoilla isoisoäitisi.

1. **Ole värikäs**: Vaihtelevat fytoravinteet (kasvit) ovat tärkeitä terveydellesi, ja väri on hyvä mittari. Kokeile 'fytoravinnehaastetta': voitko syödä punaisia, sinisiä, vihreitä, oransseja ja valkoisia kasviperäisiä ruokia yhdessä päivässä?

1. **80/20-sääntö**: Täydellisyys ei ole tavoite. Pyri onnistumaan 80-prosenttisesti.`,
                de: `
### Ernährung ist sehr persönlich, aber diese drei Tipps passen für jeden:

1. **Liebe Wasser**: Hydration ist entscheidend für Ihre Konzentration, Energie, Gesundheit und Haut. Trinken Sie ausreichend!

1. **Essen Sie echte Nahrung**: Echte Nahrung wächst, schwimmt oder grast, ist minimal verarbeitet und könnte von Ihrer Urgroßmutter serviert worden sein.

1. **Werden Sie bunt**: Vielfältige Phytonährstoffe (Pflanzen) sind entscheidend für Ihre Gesundheit, und Farbe ist ein guter Indikator. Versuchen Sie die ‘Phytonährstoff-Herausforderung’: Können Sie an einem Tag rote, blaue, grüne, orange und weiße pflanzliche Lebensmittel essen?

1. **80/20 Regel**: Perfektion ist nicht das Ziel. Streben Sie an, es zu 80 % richtig zu machen.`,
                es: `
### La nutrición es muy personal, pero estos tres consejos son válidos para todos:

1. **Ama el agua**: La hidratación es crítica para tu enfoque, energía, salud y piel. ¡Bebe agua!

1. **Come alimentos reales**: Los alimentos reales crecen, nadan o pastan, son mínimamente procesados y podrían haber sido servidos por tu bisabuela.

1. **Sé colorido**: Los fitonutrientes variados (plantas) son cruciales para tu salud, y el color es un buen indicador. Prueba el 'desafío de los fitonutrientes': ¿puedes comer alimentos vegetales rojos, azules, verdes, naranjas y blancos en un día?

1. **Regla 80/20**: La perfección no es el objetivo. Apunta a hacerlo bien en un 80%.`,
                fr: `
### La nutrition est très personnelle, mais ces trois conseils conviennent à tout le monde :

1. **Aimez l'eau** : L'hydratation est essentielle pour votre concentration, votre énergie, votre santé et votre peau. Buvez!

1. **Mangez de vrais aliments** : Les vrais aliments poussent, nagent ou paissent, sont peu transformés et pourraient avoir été servis par votre arrière-grand-mère.

1. **Soyez coloré** : Les phytonutriments variés (plantes) sont essentiels pour votre santé, et la couleur est un bon indicateur. Essayez le 'défi des phytonutriments' : pouvez-vous manger des aliments végétaux rouges, bleus, verts, oranges et blancs en une journée ?

1. **Règle 80/20** : La perfection n'est pas le but. Visez à bien faire à 80 %.`,
                sv: `
### Kost är otroligt personligt, men dessa tre tips passar alla:

1. **Älska vatten**: Hydrering är avgörande för fokus, energi, hälsa och hud. Skål!

1. **Ät riktig mat**: Riktig mat växer, simmar eller betar, är minimalt processerad, och serverades redan av din farmorsmor.

1. **Få färg**: För din hälsa är en varierad växtbaserad kost viktig, och färg är en bra indikator. Prova 'färgutmaningen': kan du inkludera röda, blå, gröna, orange och vita växtbaserade livsmedel i din dagliga kost?

1. **80/20-regeln**: Perfektion är inte målet. Sikta på att göra rätt 80% av tiden.`,
                da: `
### Ernæring er meget personlig, men disse tre tips passer til alle:

1. **Elsk vand**: Hydrering er afgørende for din fokus, energi, sundhed og hud. Drik op!

1. **Spis rigtig mad**: Rigtig mad vokser, svømmer eller græsser, er minimalt forarbejdet og kunne være blevet serveret af din oldemor.

1. **Bliv farverig**: Forskellige phytonutrients (planter) er afgørende for dit helbred, og farve er en god proxy. Prøv 'phytonutrient-udfordringen': kan du spise røde, blå, grønne, orange og hvide plantebaserede fødevarer på én dag?

1. **80/20-regel**: Perfektion er ikke målet. Stræb efter at få det rigtigt til 80%.`,
                et: `
### Toitumine on väga isiklik, kuid need kolm nõuannet sobivad kõigile:

1. **Armasta vett**: Hüdratsioon on teie keskendumise, energia, tervise ja naha jaoks kriitiline. Joo piisavalt vett!

1. **Söö päris toitu**: Päris toit kasvab, ujub või karjatab, on minimaalselt töödeldud ja seda võis serveerida teie vana-vanaema.

1. **Ole värvikas**: Mitmekesised fütonutrientid (taimed) on teie tervisele olulised ja värv on hea näitaja. Proovige 'fütonutrientide väljakutset': kas suudate süüa ühe päeva jooksul punaseid, siniseid, rohelisi, oranže ja valgeid taimseid toite?

1. **80/20 reegel**: Täiuslikkus ei ole eesmärk. Püüdke teha õigesti 80% ulatuses.`,
                no: `
### Ernæring er svært personlig, men disse tre tipsene passer for alle:

1. **Elsk vann**: Hydrering er avgjørende for fokus, energi, helse og hud. Drikk opp!

1. **Spis ekte mat**: Ekte mat vokser, svømmer eller beiter, er minimalt bearbeidet og kunne ha blitt servert av oldemoren din.

1. **Bli fargerik**: Varierte fytonæringsstoffer (planter) er avgjørende for helsen din, og farger er en god proxy. Prøv 'fytonæringsutfordringen': kan du spise røde, blå, grønne, oransje og hvite plantematvarer på en dag?

1. **80/20-regel**: Perfeksjon er ikke målet. Sikt på å få det riktig til 80%.`,
                pl: `
### Odżywianie jest bardzo osobiste, ale te trzy wskazówki pasują do wszystkich:

1. **Kochaj wodę**: Nawodnienie jest kluczowe dla twojej koncentracji, energii, zdrowia i skóry. Pij dużo!

1. **Jedz prawdziwe jedzenie**: Prawdziwe jedzenie rośnie, pływa lub pasie się, jest minimalnie przetworzone i mogło być serwowane przez twoją prababcię.

1. **Bądź kolorowy**: Różnorodne fitoskładniki (rośliny) są kluczowe dla twojego zdrowia, a kolor jest dobrym wskaźnikiem. Spróbuj 'wyzwania fitoskładników': czy możesz zjeść czerwone, niebieskie, zielone, pomarańczowe i białe rośliny w ciągu jednego dnia?

1. **Zasada 80/20**: Perfekcja nie jest celem. Staraj się osiągnąć 80% poprawności.`,
                zh: `
### 营养是高度个性化的，但这三个建议适合所有人：

1. **爱上水**：水分摄取对你的专注力、能量、健康和皮肤至关重要。多喝水！

1. **吃真正的食物**：真正的食物是生长、游泳或放牧的，经过最少的加工，可能由你的曾祖母提供。

1. **色彩缤纷**：多样化的植物营养素（植物）对你的健康至关重要，颜色是一个很好的指标。试试“植物营养素挑战”：你能在一天内吃到红色、蓝色、绿色、橙色和白色的植物性食物吗？

1. **80/20 法则**：完美不是目标。目标是达到80%的正确率。`,
                pt: `
### A nutrição é altamente pessoal, mas estas três dicas servem para todos:

1. **Ame a água**: A hidratação é fundamental para o seu foco, energia, saúde e pele. Beba bastante!

1. **Coma comida de verdade**: Comida de verdade cresce, nada ou pastoreia, é minimamente processada e poderia ter sido servida pela sua bisavó.

1. **Seja colorido**: Fito nutrientes variados (plantas) são fundamentais para a sua saúde, e a cor é um bom indicador. Experimente o 'desafio dos fito nutrientes': você pode comer alimentos vegetais vermelhos, azuis, verdes, laranja e brancos em um dia?

1. **Regra 80/20**: A perfeição não é o objetivo. O objetivo é acertar em 80% das vezes.`
              },
              category_description_me_3: {
                en: `
### Why mental energy is important

Perhaps the broadest element, mental energy includes:

1. **Thoughts**: what you think and how you speak

1. **Emotions**: how you feel and react

1. **Time & tasks**: what you prioritise and how well you focus

1. **Relationships**: who you engage with and how you interact`,
                fi: `
### Miksi henkinen energia on tärkeää

Kenties laajin osa-alue, henkinen energia sisältää:

1. **Ajatukset**: mitä ajattelet ja miten puhut

1. **Tunteet**: miltä sinusta tuntuu ja miten reagoit

1. **Aika ja tehtävät**: mitä priorisoit ja kuinka hyvin keskityt

1. **Ihmissuhteet**: kenen kanssa olet tekemisissä ja miten olet vuorovaikutuksessa`,
                sv: `
### Varför mental energi är viktigt

Detta är kanske det mest omfattande elementet, och det påverkar en stor del av livet:

1. **Ditt inre liv**: vad du tänker, känner, och hur du uppfattar världen

1. **Dina relationer**: vem du tillbringar tid med och hur

1. **Ditt jobb**: vad du gör, dina styrkor, och hur du lär dig

1. **Dina handlingar**: dina vanor, reaktioner, och prioriteter`,
                no: `
### Hvorfor mental energi er viktig

Kanskje det bredeste elementet, mental energi inkluderer:

1. **Tanker**: hva du tenker og hvordan du snakker

1. **Følelser**: hvordan du føler og reagerer

1. **Tid og oppgaver**: hva du prioriterer og hvor godt du fokuserer

1. **Forhold**: hvem du engasjerer deg med og hvordan du samhandler`,
                da: `
### Hvorfor mental energi er vigtigt

Måske det bredeste element, mental energi omfatter:

1. **Tanker**: hvad du tænker og hvordan du taler

1. **Følelser**: hvordan du føler og reagerer

1. **Tid og opgaver**: hvad du prioriterer og hvor godt du fokuserer

1. **Relationer**: hvem du engagerer dig med og hvordan du interagerer`,
                et: `
### Miks vaimne energia on oluline

Võib-olla kõige laiem element, vaimne energia hõlmab:

1. **Mõtted**: mida sa mõtled ja kuidas sa räägid

1. **Emotsioonid**: kuidas sa tunned ja reageerid

1. **Aeg ja ülesanded**: mida sa prioritiseerid ja kui hästi sa keskendud

1. **Suhted**: kellega sa suhtled ja kuidas sa suhtled`,
                de: `
### Warum mentale Energie wichtig ist

Vielleicht das breiteste Element, mentale Energie umfasst:

1. **Gedanken**: was Sie denken und wie Sie sprechen

1. **Emotionen**: wie Sie fühlen und reagieren

1. **Zeit und Aufgaben**: was Sie priorisieren und wie gut Sie sich konzentrieren

1. **Beziehungen**: mit wem Sie sich engagieren und wie Sie interagieren`,
                pl: `
### Dlaczego energia psychiczna jest ważna

Być może najszerszy element, energia psychiczna obejmuje:

1. **Myśli**: co myślisz i jak mówisz

1. **Emocje**: jak się czujesz i reagujesz

1. **Czas i zadania**: co priorytetyzujesz i jak dobrze się koncentrujesz

1. **Relacje**: z kim się angażujesz i jak interaktujesz`,
                zh: `
### 为什么心理能量很重要

也许是最广泛的元素，心理能量包括：

1. **思维**：你怎么想和怎么说

1. **情感**：你的感觉和反应

1. **时间和任务**：你的优先事项和专注程度

1. **关系**：你与谁互动以及如何互动`,
                pt: `
### Por que a energia mental é importante

Talvez o elemento mais amplo, a energia mental inclui:

1. **Pensamentos**: o que você pensa e como você fala

1. **Emoções**: como você se sente e reage

1. **Tempo e tarefas**: o que você prioriza e quão bem você se concentra

1. **Relacionamentos**: com quem você se envolve e como você interage`,
                es: `
### Por qué es importante la energía mental

Quizás el elemento más amplio, la energía mental incluye:

1. **Pensamientos**: lo que piensas y cómo hablas

1. **Emociones**: cómo te sientes y reaccionas

1. **Tiempo y tareas**: lo que priorizas y cuán bien te concentras

1. **Relaciones**: con quién te involucras y cómo interactúas`,
                fr: `
### Pourquoi l'énergie mentale est importante

Peut-être l'élément le plus large, l'énergie mentale inclut :

1. **Pensées** : ce que vous pensez et comment vous parlez

1. **Émotions** : comment vous vous sentez et réagissez

1. **Temps et tâches** : ce que vous priorisez et à quel point vous vous concentrez

1. **Relations** : avec qui vous vous engagez et comment vous interagissez`
              },
              category_description_me_4: {
                en: `
### A simple framework to start from: Energy Drains & Gains

1. **Energy drains:** Think about your past week or month. What situations, tasks, places, people, time of day, weather, circumstances, or comments drained your energy?

1. **Energy gains:** Same question - what gave you energy? 

What’s one thing you can do to reduce an energy drain or boost an energy gain?`,
                fi: `
### Yksinkertainen ajattelumalli, josta aloittaa: Energiavuodot ja -voitot

1. **Energiavuodot:** Mieti viime viikkoasi tai kuukauttasi. Mitkä tilanteet, tehtävät, paikat, ihmiset, vuorokaudenajat, sää, olosuhteet tai kommentit veivät energiaasi?

1. **Energiavoitot:** Sama kysymys - mikä antoi sinulle energiaa? 

Mikä on yksi asia, jonka voit tehdä vähentääksesi energiavuotoa tai lisätäksesi energiaa?`,
                sv: `
### En enkel regel att börja med: Energiförluster och -vinster

1. **Energiförluster:** Se tillbaka på den senaste veckan eller månaden. Vilka situationer, uppgifter, platser, personer, tider på dagen, väder, omständigheter eller kommentarer har sugit musten ur dig?

1. **Energivinster:** Samma fråga - vad har gett dig energi? 

Vad är en sak du kan göra för att minska en energidränering eller öka en energivinst?`,
                no: `
### En enkel ram å starte fra: Energityver og -gevinster

1. **Energityver:** Tenk på uken eller måneden som har gått. Hvilke situasjoner, oppgaver, steder, mennesker, tidspunkt på dagen, vær, omstendigheter eller kommentarer tappet energien din?

1. **Energigevinster:** Samme spørsmål - hva ga deg energi? 

Hva er én ting du kan gjøre for å redusere en energityv eller øke en energigevinst?`,
                da: `
### En enkel ramme at starte fra: Energidræn og -gevinster

1. **Energidræn:** Tænk på din sidste uge eller måned. Hvilke situationer, opgaver, steder, mennesker, tidspunkter på dagen, vejr, omstændigheder eller kommentarer drænede din energi?

1. **Energigevinster:** Samme spørgsmål - hvad gav dig energi? 

Hvad er én ting, du kan gøre for at reducere et energidræn eller øge en energigevinst?`,
                et: `
### Lihtne raamistik: Energia kadu ja kasu

1. **Energiakaod:** Mõtle oma möödunud nädalale või kuule. Millised olukorrad, ülesanded, kohad, inimesed, kellaajad, ilm, asjaolud või kommentaarid võtsid sinult energiat?

1. **Energiatõusud:** Sama küsimus - mis andis sulle energiat? 

Mis on üks asi, mida sa saad teha, et vähendada energiakadu või suurendada energiatõusu?`,
                de: `
### Ein einfaches Framework zum Start: Energieverlust und -gewinn

1. **Energieverlust:** Denken Sie an Ihre letzte Woche oder Ihren letzten Monat. Welche Situationen, Aufgaben, Orte, Menschen, Tageszeiten, Wetter, Umstände oder Kommentare haben Ihre Energie erschöpft?

1. **Energiegewinn:** Gleiche Frage - was hat Ihnen Energie gegeben? 

Was ist eine Sache, die Sie tun können, um einen Energieverlust zu reduzieren oder einen Energiegewinn zu steigern?`,
                pl: `
### Prosty schemat do rozpoczęcia: Straty i zyski energetyczne

1. **Straty energii:** Pomyśl o swoim ostatnim tygodniu lub miesiącu. Jakie sytuacje, zadania, miejsca, ludzie, pory dnia, pogoda, okoliczności lub komentarze odbierały ci energię?

1. **Zyski energetyczne:** To samo pytanie - co cię napełniało energią? 

Co możesz zrobić, aby zmniejszyć straty energii lub zwiększyć jej zyski?`,
                zh: `
### 开始的简单框架：能量消耗与获取

1. **能量消耗:** 想想你过去的一周或一个月。哪些情况、任务、地点、人物、时间、天气、环境或评论消耗了你的能量？

1. **能量获取:** 同样的问题 - 什么给你带来了能量？

你可以做些什么来减少能量消耗或增加能量获取？`,
                pt: `
### Uma estrutura simples para começar: Drenos e Ganhos de Energia

1. **Drenos de energia:** Pense na sua semana ou mês passado. Quais situações, tarefas, lugares, pessoas, hora do dia, clima, circunstâncias ou comentários drenaram sua energia?

1. **Ganhos de energia:** Mesma pergunta - o que te deu energia? 

Qual é uma coisa que você pode fazer para reduzir um dreno de energia ou aumentar um ganho de energia?`,
                es: `
### Un marco simple para empezar: Pérdidas y ganancias de energía

1. **Pérdidas de energía:** Piensa en tu última semana o mes. ¿Qué situaciones, tareas, lugares, personas, momento del día, clima, circunstancias o comentarios drenaron tu energía?

1. **Ganancias de energía:** La misma pregunta - ¿qué te dio energía? 

¿Qué es una cosa que puedes hacer para reducir una pérdida de energía o aumentar una ganancia de energía?`,
                fr: `
### Un cadre simple pour commencer : Pertes et gains d'énergie

1. **Pertes d'énergie :** Pensez à votre dernière semaine ou mois. Quelles situations, tâches, lieux, personnes, moments de la journée, météo, circonstances ou commentaires ont drainé votre énergie ?

1. **Gains d'énergie :** Même question - qu'est-ce qui vous a donné de l'énergie ? 

Quelle est une chose que vous pouvez faire pour réduire une perte d'énergie ou augmenter un gain d'énergie ?`
              },
              category_description_bm_3: {
                en: `
### Why biomechanics is important

* **Stay pain-free**: e.g. lower back pain is a leading cause of absenteeism, with 80% suffering from it at some point

* **Lifespan**: A sedentary lifestyle is a significant risk factor for all-cause mortality and health issues* **Healthspan**: Good biomechanics enables life-long activity and independent living`,
                fi: `
### Miksi biomekaniikka on tärkeää

* **Pysy kivuttomana**: esimerkiksi alaselän kipu on merkittävä poissaolojen syy, 80 % ihmisistä kärsii siitä jossain vaiheessa

* **Elinikä**: Istumatyö on merkittävä riskitekijä kaikista syistä johtuvalle kuolleisuudelle ja terveysongelmille

* **Terveysikä**: Hyvä biomekaniikka mahdollistaa elinikäisen aktiivisuuden ja itsenäisen elämän`,
                sv: `
### Varför biomekanik är viktigt

* **Håll dig smärtfri**: Smärta i nedre ryggen en av de främsta orsakerna till sjukfrånvaro och det drabbar upp till 80% av befolkningen någon gång i livet

* **Livslängd**: En inaktiv livsstil utgör en stor risk för både dödlighet och hälsoproblem

* **Friska år**: Bra biomekanik gör det möjligt att vara aktiv livet ut och att fortsätta bo självständigt`,
                no: `
### Hvorfor biomekanikk er viktig

* **Hold deg smertefri**: f.eks. er korsryggsmerter en ledende årsak til fravær, og 80 % lider av det på et tidspunkt

* **Levetid**: En stillesittende livsstil er en betydelig risikofaktor for dødelighet og helseproblemer

* **Helsealder**: God biomekanikk muliggjør livslang aktivitet og selvstendig liv`,
                da: `
### Hvorfor biomekanik er vigtig

* **Forbliv smertefri**: f.eks. er lændesmerter en førende årsag til fravær, med 80 % der lider af det på et tidspunkt

* **Levetid**: En stillesiddende livsstil er en væsentlig risikofaktor for dødelighed og sundhedsproblemer

* **Sundhedslevetid**: God biomekanik muliggør livslang aktivitet og selvstændig liv`,
                et: `
### Miks on biomehaanika oluline

* **Püsige valuvaba**: nt alaseljavalu on töölt puudumise peamine põhjus, 80 % kogeb seda mingil hetkel

* **Eluiga**: Istuv eluviis on oluline riskitegur kõikide põhjuste suremuses ja terviseprobleemides

* **Terviseiga**: Hea biomehaanika võimaldab elukestvat aktiivsust ja iseseisvat elu`,
                de: `
### Warum Biomechanik wichtig ist

* **Schmerzfrei bleiben**: z.B. Rückenschmerzen sind eine der häufigsten Ursachen für Fehlzeiten, 80 % leiden irgendwann darunter

* **Lebensdauer**: Ein sitzender Lebensstil ist ein bedeutender Risikofaktor für die Gesamtmortalität und Gesundheitsprobleme

* **Gesundheitsdauer**: Gute Biomechanik ermöglicht lebenslange Aktivität und unabhängiges Leben`,
                pl: `
### Dlaczego biomechanika jest ważna

* **Pozostań bez bólu**: np. ból w dolnej części pleców jest główną przyczyną nieobecności, 80 % cierpi na niego w pewnym momencie

* **Długość życia**: Siedzący tryb życia jest istotnym czynnikiem ryzyka dla ogólnej śmiertelności i problemów zdrowotnych

* **Zdrowie**: Dobra biomechanika umożliwia aktywność przez całe życie i niezależne życie`,
                zh: `
### 为什么生物力学很重要

* **保持无痛**：例如，下背痛是缺勤的主要原因，80%的人在某个时刻会遭受这种痛苦

* **寿命**：久坐的生活方式是所有原因导致的死亡率和健康问题的重要风险因素

* **健康寿命**：良好的生物力学能够实现终身活动和独立生活`,
                pt: `
### Por que a biomecânica é importante

* **Fique livre de dor**: por exemplo, a dor lombar é uma das principais causas de absenteísmo, com 80% das pessoas sofrendo disso em algum momento

* **Longevidade**: Um estilo de vida sedentário é um fator de risco significativo para mortalidade por todas as causas e problemas de saúde

* **Longevidade saudável**: Uma boa biomecânica permite atividade ao longo da vida e vida independente`,
                es: `
### Por qué es importante la biomecánica

* **Mantente libre de dolor**: por ejemplo, el dolor lumbar es una causa principal de ausentismo, y el 80 % lo sufre en algún momento

* **Esperanza de vida**: Un estilo de vida sedentario es un factor de riesgo significativo para la mortalidad por todas las causas y problemas de salud

* **Duración de la salud**: Una buena biomecánica permite la actividad de por vida y la vida independiente`,
                fr: `
### Pourquoi la biomécanique est importante

* **Restez sans douleur**: par exemple, les douleurs lombaires sont une cause majeure d'absentéisme, 80 % des gens en souffrent à un moment donné

* **Durée de vie**: Un mode de vie sédentaire est un facteur de risque significatif de mortalité toutes causes confondues et de problèmes de santé

* **Durée de santé**: Une bonne biomécanique permet une activité tout au long de la vie et une vie indépendante`
              },
              category_description_bm_4: {
                en: `
### Biomechanics in practice:

1. **Active breaks**: Break up your sitting every hour – a walk for a glass of water can be enough

1. **Straighten your tech-neck**: Bending to look at screens is a strain on your neck, up to 5x the head’s weight. Raise the screen to eye level when you can.

1. **Walk & Talk**: Aim for one daily walking meeting. If that’s tricky, take a meeting off-camera and stretch while you listen.`,
                fi: `
### Biomekaniikka käytännössä:

1. **Aktiiviset tauot**: Tauota istumistasi joka tunti – kävely hakemaan lasillinen vettä voi riittää

1. **Oikaise ruutuniskasi**: Näytön ääreen kumartuminen rasittaa niskaa, jopa viisinkertaisesti pääsi painon verran. Nosta näyttö silmien tasolle aina kun mahdollista.

1. **Kävele ja puhu**: Tavoittele yhtä päivittäistä kävelypalaveria. Jos se on hankalaa, pidä kokous ilman kameraa ja venyttele kuunnellessasi.`,
                sv: `
### Biomekanik i praktiken:

1. **Aktiva pauser**: Bryt upp sittandet varje timme – en kort promenad efter ett glas vatten kan räcka

1. **Räta ut din "tekniknacke"**: Nacken belastas upp till fem gånger huvudets vikt när du böjer dig för att titta på skärmar. Höj skärmen till ögonhöjd för att minska påfrestningen.

1. **Gå & prata**: Försök få in ett dagligt promenadmöte. Om det inte går, ta ett möte utan kamera och stretcha medan du lyssnar.`,
                no: `
### Biomekanikk i praksis:

1. **Aktive pauser**: Ta en pause fra sittingen hver time – en tur for et glass vann kan være nok

1. **Rett ut teknologinakken**: Å bøye seg for å se på skjermer er en belastning for nakken, opptil 5 ganger hodets vekt. Hev skjermen til øyehøyde når du kan.

1. **Gå og snakk**: Sikt på ett daglig gående møte. Hvis det er vanskelig, ta et møte uten kamera og strekk deg mens du lytter.`,
                da: `
### Biomekanik i praksis:

1. **Aktive pauser**: Bryd op med at sidde hver time – en gåtur for at få et glas vand kan være nok

1. **Ret din tekniknakke ud**: At bøje sig for at kigge på skærme er en belastning for nakken, op til 5 gange hovedets vægt. Hæv skærmen til øjenhøjde, når du kan.

1. **Gå og snak**: Sigt efter et dagligt gående møde. Hvis det er svært, så hold et møde uden kamera og stræk ud, mens du lytter.`,
                et: `
### Biomehaanika praktikas:

1. **Aktiivsed pausid**: Katkesta istumist iga tund – jalutuskäik klaasi vee järele võib olla piisav

1. **Sirgeks oma tehnoloogia-kael**: Kummardumine ekraanidele vaatamiseks on kaelale koormav, kuni 5 korda pea kaalust. Tõsta ekraan silmade kõrgusele, kui võimalik.

1. **Kõnni ja räägi**: Püüa teha üks igapäevane kõndimiskoosolek. Kui see on keeruline, siis võta koosolek kaameravabalt ja venita ennast kuulamise ajal.`,
                de: `
### Biomechanik in der Praxis:

1. **Aktive Pausen**: Unterbrechen Sie Ihr Sitzen jede Stunde – ein Spaziergang für ein Glas Wasser kann ausreichen

1. **Richten Sie Ihren Tech-Nacken auf**: Das Bücken, um auf Bildschirme zu schauen, belastet Ihren Nacken, bis zu 5-mal das Gewicht des Kopfes. Heben Sie den Bildschirm auf Augenhöhe, wenn Sie können.

1. **Gehen und reden**: Streben Sie ein tägliches Spaziergespräch an. Wenn das schwierig ist, führen Sie ein Meeting ohne Kamera durch und dehnen Sie sich, während Sie zuhören.`,
                pl: `
### Biomechanika w praktyce:

1. **Aktywne przerwy**: Przerywaj siedzenie co godzinę – spacer po szklankę wody może wystarczyć

1. **Wyprostuj tech-neck**: Pochylanie się, aby patrzeć na ekrany, obciąża szyję, do 5 razy ciężar głowy. Podnieś ekran na poziom oczu, kiedy możesz.

1. **Chodź i rozmawiaj**: Dąż do jednego codziennego spotkania na spacerze. Jeśli to trudne, zrób spotkanie bez kamery i rozciągnij się podczas słuchania.`,
                zh: `
### 生物力学在实践中：

1. **主动休息**：每小时打断一次坐姿——走路喝一杯水就足够了

1. **矫正你的技术颈**：低头看屏幕会给脖子带来压力，是头部重量的5倍。尽量将屏幕抬高到眼睛水平。

1. **走路和交谈**：每天争取一次步行会议。如果这很棘手，可以在不开摄像头的情况下开会，并在听的时候伸展身体。`,
                pt: `
### Biomecânica na prática:

1. **Pausas ativas**: Interrompa o tempo sentado a cada hora – uma caminhada para buscar um copo d'água pode ser suficiente

1. **Endireite seu pescoço técnico**: Inclinar-se para olhar para as telas é uma tensão no pescoço, até 5 vezes o peso da cabeça. Levante a tela ao nível dos olhos quando puder.

1. **Caminhe e fale**: Tente uma reunião caminhando diariamente. Se isso for complicado, faça uma reunião fora da câmera e alongue-se enquanto ouve.`,
                es: `
### Biomecánica en práctica:

1. **Pausas activas**: Interrumpe tu tiempo sentado cada hora – una caminata para buscar un vaso de agua puede ser suficiente

1. **Endereza tu cuello tecnológico**: Inclinarse para mirar pantallas es una tensión para tu cuello, hasta 5 veces el peso de la cabeza. Levanta la pantalla al nivel de los ojos cuando puedas.

1. **Camina y habla**: Apunta a una reunión caminando diaria. Si eso es complicado, haz una reunión sin cámara y estírate mientras escuchas.`,
                fr: `
### Biomécanique en pratique :

1. **Pauses actives** : Interrompez votre position assise toutes les heures – une marche pour prendre un verre d'eau peut suffire

1. **Redressez votre cou technologique** : Se pencher pour regarder des écrans est une tension pour votre cou, jusqu'à 5 fois le poids de la tête. Élevez l'écran au niveau des yeux quand vous le pouvez.

1. **Marchez et parlez** : Visez une réunion de marche quotidienne. Si c'est difficile, prenez une réunion hors caméra et étirez-vous pendant que vous écoutez.`
              },
              category_description_sr_3: {
                en: `
### Why sleep & recovery are important

* **Health**: Insufficient sleep increases your risk for obesity, diabetes, cardiovascular disease, poor immune function, and cancer

* **Cognitive performance**: Sleep and recovery are linked to memory, learning, cognitive capacity, emotional intelligence, and lower risk of mental ill-health

* **Learning**: Just as your muscles grow stronger during rest, your brain needs downtime to learn and develop`,
                fi: `
### Miksi uni ja palautuminen ovat tärkeitä

* **Terveys**: Riittämätön uni lisää riskiäsi liikalihavuuteen, diabetekseen, sydän- ja verisuonitauteihin, heikentyneeseen vastustuskykyyn ja syöpään

* **Kognitiivinen suorituskyky**: Uni ja palautuminen liittyvät muistiin, oppimiseen, kognitiiviseen kapasiteettiin ja tunneälyyn sekä sekä vähentävät mielenterveysongelmien riskiä

* **Oppiminen**: Aivan kuten lihaksesi vahvistuvat levon aikana, aivosi tarvitsevat lepoa oppiakseen ja kehittyäkseen`,
                sv: `
### Varför sömn och återhämtning är viktiga

* **Hälsa**: Otillräcklig sömn ökar din risk för bl.a. övervikt, diabetes, hjärt- och kärlsjukdomar, nedsatt immunförsvar och cancer

* **Kognitiv prestation**: God sömn och återhämtning stöder minnet, inlärning, kognitiv kapacitet, emotionell intelligens och mental hälsa

* **Inlärning**: Liksom dina muskler stärks under vila, behöver din hjärna viloperioder för att lära sig och utvecklas.`,
                no: `
### Hvorfor søvn og restitusjon er viktig

* **Helse**: Utilstrekkelig søvn øker risikoen for fedme, diabetes, hjerte- og karsykdommer, dårlig immunfunksjon og kreft

* **Kognitiv ytelse**: Søvn og restitusjon er knyttet til hukommelse, læring, kognitiv kapasitet, emosjonell intelligens og lavere risiko for psykiske lidelser

* **Læring**: Akkurat som musklene dine blir sterkere under hvile, trenger hjernen din hviletid for å lære og utvikle seg`,
                da: `
### Hvorfor søvn og restitution er vigtige

* **Sundhed**: Utilstrækkelig søvn øger din risiko for fedme, diabetes, hjerte-kar-sygdomme, dårlig immunfunktion og kræft

* **Kognitiv ydeevne**: Søvn og restitution er forbundet med hukommelse, læring, kognitiv kapacitet, følelsesmæssig intelligens og lavere risiko for psykisk sygdom

* **Læring**: Ligesom dine muskler bliver stærkere under hvile, har din hjerne brug for hvileperioder for at lære og udvikle sig`,
                et: `
### Miks uni ja taastumine on olulised

* **Tervis**: Ebapiisav uni suurendab teie riski haigestuda rasvumisse, diabeeti, südame-veresoonkonnahaigustesse, halvenenud immuunfunktsiooni ja vähki

* **Kognitiivne jõudlus**: Uni ja taastumine on seotud mälu, õppimise, kognitiivse võimekuse, emotsionaalse intelligentsuse ja vaimse tervisehäirete madalama riskiga

* **Õppimine**: Nii nagu teie lihased kasvavad puhkeajal tugevamaks, vajab teie aju õppimiseks ja arenemiseks puhkust`,
                de: `
### Warum Schlaf und Erholung wichtig sind

* **Gesundheit**: Unzureichender Schlaf erhöht das Risiko für Fettleibigkeit, Diabetes, Herz-Kreislauf-Erkrankungen, schlechte Immunfunktion und Krebs

* **Kognitive Leistung**: Schlaf und Erholung sind mit Gedächtnis, Lernen, kognitiver Kapazität, emotionaler Intelligenz und einem geringeren Risiko für psychische Erkrankungen verbunden

* **Lernen**: Genau wie Ihre Muskeln während der Ruhe stärker werden, benötigt Ihr Gehirn Ruhephasen, um zu lernen und sich zu entwickeln`,
                pl: `
### Dlaczego sen i regeneracja są ważne

* **Zdrowie**: Niewystarczająca ilość snu zwiększa ryzyko otyłości, cukrzycy, chorób sercowo-naczyniowych, słabej funkcji odpornościowej i raka

* **Wydajność poznawcza**: Sen i regeneracja są związane z pamięcią, uczeniem się, zdolnościami poznawczymi, inteligencją emocjonalną i niższym ryzykiem zaburzeń psychicznych

* **Uczenie się**: Podobnie jak mięśnie stają się silniejsze podczas odpoczynku, mózg potrzebuje czasu na odpoczynek, aby się uczyć i rozwijać`,
                zh: `
### 为什么睡眠和恢复很重要

* **健康**：睡眠不足会增加肥胖、糖尿病、心血管疾病、免疫功能不良和癌症的风险

* **认知表现**：睡眠和恢复与记忆、学习、认知能力、情商和较低的心理健康风险有关

* **学习**：就像你的肌肉在休息时变得更强壮一样，你的大脑需要休息时间来学习和发展`,
                pt: `
### Por que o sono e a recuperação são importantes

* **Saúde**: O sono insuficiente aumenta o risco de obesidade, diabetes, doenças cardiovasculares, função imunológica deficiente e câncer

* **Desempenho cognitivo**: O sono e a recuperação estão ligados à memória, aprendizado, capacidade cognitiva, inteligência emocional e menor risco de problemas de saúde mental

* **Aprendizagem**: Assim como seus músculos ficam mais fortes durante o descanso, seu cérebro precisa de tempo de inatividade para aprender e se desenvolver`,
                es: `
### Por qué son importantes el sueño y la recuperación

* **Salud**: La falta de sueño aumenta el riesgo de obesidad, diabetes, enfermedades cardiovasculares, mala función inmunológica y cáncer

* **Rendimiento cognitivo**: El sueño y la recuperación están relacionados con la memoria, el aprendizaje, la capacidad cognitiva, la inteligencia emocional y un menor riesgo de problemas de salud mental

* **Aprendizaje**: Al igual que tus músculos se fortalecen durante el descanso, tu cerebro necesita tiempo de inactividad para aprender y desarrollarse`,
                fr: `
### Pourquoi le sommeil et la récupération sont importants

* **Santé**: Un sommeil insuffisant augmente le risque d'obésité, de diabète, de maladies cardiovasculaires, de mauvaise fonction immunitaire et de cancer

* **Performance cognitive**: Le sommeil et la récupération sont liés à la mémoire, à l'apprentissage, à la capacité cognitive, à l'intelligence émotionnelle et à un risque réduit de troubles mentaux

* **Apprentissage**: Tout comme vos muscles se renforcent pendant le repos, votre cerveau a besoin de temps de repos pour apprendre et se développer`
              },
              category_description_sr_4: {
                en: `
### Quick recovery tips:

1. Go-to-sleep alarm: Many struggle to get 7-9 hours of sleep – try setting an alarm for bedtime, not just wake-up time

1. No-phone-zone: The smartphone keeps your brain activated. Challenge yourself to take a phone-free hour every day.

1. 5-minute focus: Struggle to form a meditation habit? Ease into it with a daily 5 minute break, where your only task is enjoying the view from the window.`,
                fi: `
### Nopeat palautumisvinkit:

1. Nukkumaanmenohälytys: Monilla on vaikeuksia saada 7-9 tuntia unta – kokeile asettaa hälytys nukkumaanmenoa varten, ei vain heräämisaikaa varten

1. Ei-puhelin-aluetta: Älypuhelin pitää aivosi aktivoituna. Haasta itsesi ottamaan päivittäin tunti ilman puhelinta.

1. 5 minuutin keskittyminen: Onko vaikeaa luoda meditaatiorutiinia? Helpota asiaa pitämällä päivittäin 5 minuutin tauko, jonka aikana ainoa tehtäväsi on nauttia ikkunasta avautuvasta näkymästä.`,
                sv: `
### Snabba återhämtningstips:

1. Sömn-alarm: För många är det svårt att få 7-9 timmar sömn – prova ställa ett alarm för läggdags, inte bara för väckning

1. Telefonfri zon: Din smartphone håller din hjärna på högvarv. Försök ge dig själv en telefonfri timme varje dag.

1. 5-minuters fokus: Har du svårt att etablera en meditationsrutin? Börja med att ta en 5-minuters paus varje dag, då du bara fokuserar på att njuta av utsikten från fönstret.`,
                no: `
### Rask restitusjonstips:

1. Gå-til-søvn-alarm: Mange sliter med å få 7-9 timers søvn – prøv å sette en alarm for leggetid, ikke bare vekketid

1. Ingen-telefon-sone: Smarttelefonen holder hjernen din aktivert. Utfordre deg selv til å ta en telefonfri time hver dag.

1. 5-minutters fokus: Sliter du med å danne en meditasjonsvane? Start enkelt med en daglig 5-minutters pause, der din eneste oppgave er å nyte utsikten fra vinduet.`,
                da: `
### Hurtige restitutionstips:

1. Gå-i-seng alarm: Mange kæmper for at få 7-9 timers søvn – prøv at sætte en alarm til sengetid, ikke kun til at vågne

1. Ingen-telefon-zone: Smartphonen holder din hjerne aktiveret. Udfordre dig selv til at tage en telefonfri time hver dag.

1. 5-minutters fokus: Kæmper du for at danne en meditationsvane? Begynd langsomt med en daglig 5-minutters pause, hvor din eneste opgave er at nyde udsigten fra vinduet.`,
                et: `
### Kiired taastumisnipid:

1. Magamamineku alarm: Paljudel on raskusi saada 7-9 tundi und – proovige seadistada alarm magamamineku, mitte ainult ärkamise jaoks

1. Telefonivaba ala: Nutitelefon hoiab teie aju aktiveeritud. Väljakutse endale võtta iga päev üks tund ilma telefonita.

1. 5-minutiline fookus: Kas sul on raske kujundada meditatsiooniharjumust? Lihtsusta seda igapäevase 5-minutilise pausiga, kus su ainus ülesanne on nautida vaadet aknast.`,
                de: `
### Schnelle Erholungstipps:

1. Schlafenszeit-Wecker: Viele kämpfen damit, 7-9 Stunden Schlaf zu bekommen – versuchen Sie, einen Wecker für die Schlafenszeit zu stellen, nicht nur für die Aufwachzeit

1. Telefonfreie Zone: Das Smartphone hält Ihr Gehirn aktiviert. Fordern Sie sich heraus, jeden Tag eine stunde ohne Telefon zu verbringen.

1. 5-Minuten-Fokus: Haben Sie Schwierigkeiten, eine Meditationsgewohnheit zu entwickeln? Machen Sie es sich leichter mit einer täglichen 5-minütigen Pause, bei der Ihre einzige Aufgabe darin besteht, die Aussicht aus dem Fenster zu genießen.`,
                pl: `
### Szybkie porady dotyczące regeneracji:

1. Alarm na sen: Wielu ludzi ma trudności z uzyskaniem 7-9 godzin snu – spróbuj ustawić alarm na czas snu, a nie tylko na pobudkę

1. Strefa bez telefonu: Smartfon utrzymuje twój mózg w stanie aktywności. Wyzwanie dla ciebie: codziennie godzina bez telefonu.

1. 5-minutowe skupienie: Masz trudności z wprowadzeniem nawyku medytacji? Ułatw sobie to codzienną 5-minutową przerwą, w której jedynym zadaniem jest podziwianie widoku z okna.`,
                zh: `
### 快速恢复提示：

1. 入睡闹钟：许多人难以获得7-9小时的睡眠——试着设置一个入睡闹钟，而不仅仅是起床闹钟

1. 无手机区：智能手机让你的大脑保持活跃。挑战自己每天进行一个小时无手机时光。

1. 5分钟专注：难以养成冥想习惯？通过每天5分钟的休息逐步适应，唯一的任务就是享受窗外的景色。`,
                pt: `
### Dicas rápidas de recuperação:

1. Alarme para dormir: Muitos lutam para conseguir 7-9 horas de sono – tente configurar um alarme para a hora de dormir, não apenas para acordar

1. Zona sem telefone: O smartphone mantém seu cérebro ativado. Desafie-se a passar uma hora por dia sem telefone.

1. Foco de 5 minutos: Dificuldade em formar um hábito de meditação? Facilite isso com uma pausa diária de 5 minutos, onde sua única tarefa é apreciar a vista da janela.`,
                es: `
### Consejos rápidos para la recuperación:

1. Alarma para dormir: Muchos luchan por conseguir 7-9 horas de sueño: intenta configurar una alarma para la hora de dormir, no solo para despertarte

1. Zona sin teléfono: El teléfono inteligente mantiene tu cerebro activado. Desafíate a tomar una hora libre de teléfono todos los días.

1. Enfoque de 5 minutos: ¿Te cuesta formar un hábito de meditación? Facilítalo con un descanso diario de 5 minutos, donde tu única tarea es disfrutar de la vista desde la ventana.`,
                fr: `
### Conseils de récupération rapide:

1. Alarme pour dormir: Beaucoup ont du mal à obtenir 7-9 heures de sommeil - essayez de régler une alarme pour l'heure du coucher, pas seulement pour l'heure du réveil

1. Zone sans téléphone: Le smartphone maintient votre cerveau activé. Défi vous-même à prendre une heure sans téléphone chaque jour.

1. Focus de 5 minutes: Vous avez du mal à prendre l'habitude de méditer ? Facilitez-vous la tâche avec une pause quotidienne de 5 minutes, où votre seule tâche est de profiter de la vue depuis la fenêtre.`
              },
              category_description__3: {
                en: ``
              },
              category_description__4: {
                en: ``
              },
              your_results: {
                en: "Your results",
                fi: "Tuloksesi",
                sv: "Dina resultat",
                no: "Dine resultater",
                da: "Dine resultater",
                et: "Teie tulemused",
                de: "Ihre Ergebnisse",
                pl: "Twoje wyniki",
                zh: "您的结果",
                pt: "Seus resultados",
                es: "Tus resultados",
                fr: "Vos résultats"
              },
              outcome_focused_questions_1: {
                en: "Wellbeing results",
                fi: "Hyvinvointitulokset",
                sv: "Hälsoresultat",
                no: "Resultater for velvære",
                da: "Velværsresultater",
                et: "Heaolu tulemused",
                de: "Wohlbefindens Ergebnisse",
                pl: "Wyniki dotyczące dobrostanu",
                zh: "健康结果",
                pt: "Resultados de bem-estar",
                es: "Resultados de bienestar",
                fr: "Résultats de bien-être"
              },
              outcome_focused_questions_2: {
                en: "slower to change",
                fi: "hitaammin muutettavissa",
                sv: "tar längre tid att förändra",
                no: "langsommere å endre",
                da: "langsommere at ændre",
                et: "aeglasem muutus",
                de: "langsamer zu ändern",
                pl: "wolniej zmieniać",
                zh: "变化较慢",
                pt: "mais lento para mudar",
                es: "más lento de cambiar",
                fr: "plus lent à changer"
              },
              behavior_focused_questions_1: {
                en: "Wellbeing behaviours",
                fi: "Hyvinvointitavat",
                sv: "Hälsobeteenden",
                no: "Atferd for velvære",
                da: "Velværsadfærd",
                et: "Heaolu käitumised",
                de: "Wohlbefindensverhalten",
                pl: "Zachowania dotyczące dobrostanu",
                zh: "健康行为",
                pt: "Comportamentos de bem-estar",
                es: "Comportamientos de bienestar",
                fr: "Comportements de bien-être"
              },
              behavior_focused_questions_2: {
                en: "quicker to change",
                fi: "nopeammin muutettavissa",
                sv: "snabbare att ändra",
                no: "raskere å endre",
                da: "hurtigere at ændre",
                et: "kiirem muutus",
                de: "schneller zu ändern",
                pl: "szybciej zmieniać",
                zh: "变化较快",
                pt: "mais rápido para mudar",
                es: "más rápido de cambiar",
                fr: "plus rapide à changer"
              }
            },
            ReportSectionMyPlan: {
              my_plan: {
                en: "My Plan",
                fi: "Minun suunnitelmani",
                sv: "Min plan",
                no: "Min plan",
                da: "Min plan",
                et: "Minu plaan",
                de: "Mein Plan",
                pl: "Mój plan",
                zh: "我的计划",
                pt: "Meu Plano",
                es: "Mi Plan",
                fr: "Mon Plan"
              },
              start_story_title: {
                en: "Start writing your new story",
                fi: "Aloita uuden tarinasi kirjoittaminen",
                sv: "Börja skriva din nya historia",
                no: "Begynn å skrive din nye historie",
                da: "Begynd at skrive din nye historie",
                et: "Alusta oma uue loo kirjutamist",
                de: "Beginnen Sie, Ihre neue Geschichte zu schreiben",
                pl: "Zacznij pisać swoją nową historię",
                zh: "开始写你的新故事",
                pt: "Comece a escrever sua nova história",
                es: "Empieza a escribir tu nueva historia",
                fr: "Commencez à écrire votre nouvelle histoire"
              },
              start_story_description: {
                en: "To change is to write a new life story. Below is how Hintsa’s coaches would start building your plan, called a Logical Performance Plan, adapted from the science-based WOOP model.",
                fi: "Muuttuminen on uuden elämäntarinan kirjoittamista. Alla on esimerkki siitä, miten Hintsan valmentajat aloittaisivat suunnitelmasi rakentamisen. Sitä kutsutaan Loogiseksi Suorituskykysuunnitelmaksi ja se on kehitetty tieteeseen perustuvan WOOP-mallin pohjalta.",
                sv: "Att göra förändringar innebär att forma en ny livshistoria. Här ser du hur Hintsas coacher skulle börja bygga din 'Logical Performance Plan', anpassad från den vetenskapligt baserade WOOP-modellen.",
                no: "Å endre seg er å skrive en ny livshistorie. Nedenfor vises hvordan Hintsas coacher ville begynne å bygge din plan, kalt en Logisk Ytelsesplan, tilpasset fra den vitenskapsbaserte WOOP-modellen.",
                da: "At ændre sig er at skrive en ny livshistorie. Nedenfor vises, hvordan Hintsas trænere ville begynde at opbygge din plan, kaldet en Logisk Ydeevneplan, tilpasset fra den videnskabsbaserede WOOP-model.",
                et: "Muutumine on uue eluloo kirjutamine. Allpool on näha, kuidas Hintsu treenerid alustaksid teie plaani koostamist, mida nimetatakse loogiliseks tulemuslikkuse plaaniks ja mis on kohandatud teaduspõhisest WOOP-mudelist.",
                de: "Sich zu verändern bedeutet, eine neue Lebensgeschichte zu schreiben. Im Folgenden wird gezeigt, wie die Trainer von Hintsa beginnen würden, Ihren Plan zu erstellen, der als Logischer Leistungsplan bezeichnet wird und aus dem wissenschaftlich fundierten WOOP-Modell abgeleitet ist.",
                pl: "Zmiana to pisanie nowej historii życia. Poniżej znajduje się sposób, w jaki trenerzy Hintsy rozpoczęliby budowanie twojego planu, zwanego Logicznym Planem Wydajności, dostosowanym z opartego na nauce modelu WOOP.",
                zh: "改变就是写一个新的生活故事。以下是Hintsa的教练如何开始制定你的计划，称为逻辑性能计划，改编自基于科学的WOOP模型。",
                pt: "Mudar é escrever uma nova história de vida. Abaixo está como os treinadores da Hintsa começariam a construir seu plano, chamado de Plano de Desempenho Lógico, adaptado do modelo baseado em ciência WOOP.",
                es: "Cambiar es escribir una nueva historia de vida. A continuación se muestra cómo los entrenadores de Hintsa comenzarían a construir tu plan, llamado Plan de Rendimiento Lógico, adaptado del modelo basado en ciencia WOOP.",
                fr: "Changer, c'est écrire une nouvelle histoire de vie. Ci-dessous, découvrez comment les coachs de Hintsa commenceraient à élaborer votre plan, appelé Plan de Performance Logique, adapté du modèle WOOP basé sur la science."
              },
              change_inside: {
                en: "Change starts from the inside"
              },
              change_inside_description: {
                en: "Even the best plans can fail in practice. Here are key factors of behavioural change readiness:"
              },
              higher_level_goal_title: {
                en: "Higher level goal",
                fi: "Korkeamman tason tavoite",
                sv: "Mitt högre mål",
                no: "Høyere mål",
                da: "Højere mål",
                et: "Kõrgema taseme eesmärk",
                de: "Höheres Ziel",
                pl: "Cel wyższego poziomu",
                zh: "更高层次的目标",
                pt: "Meta de nível superior",
                es: "Objetivo de nivel superior",
                fr: "Objectif de niveau supérieur"
              },
              higher_level_goal_description: {
                en: "This is your higher level goal (or outcome) in life or on your career",
                fi: "Tämä on korkeamman tason tavoite (tai lopputulos) elämässäsi tai urallasi",
                sv: "Detta är ditt högre mål (eller resultat) i livet eller på din karriär",
                no: "Dette er ditt høyere mål (eller resultat) i livet eller karrieren din",
                da: "Dette er dit højere mål (eller resultat) i livet eller på din karriere",
                et: "See on teie kõrgema taseme eesmärk (või tulemus) elus või karjääris",
                de: "Dies ist Ihr höheres Ziel (oder Ergebnis) im Leben oder in Ihrer Karriere",
                pl: "To jest twój cel wyższego poziomu (lub rezultat) w życiu lub karierze",
                zh: "这是你在人生或职业上的更高层次目标（或结果）。",
                pt: "Este é o seu objetivo de nível superior (ou resultado) na vida ou na sua carreira",
                es: "Este es tu objetivo de nivel superior (o resultado) en la vida o en tu carrera",
                fr: "C'est votre objectif de niveau supérieur (ou résultat) dans la vie ou dans votre carrière"
              },
              higher_level_goal_placeholder: {
                en: "E.g. “In the next decade of my life I want to have more energy at work, get a promotion, and spend more time with my family”",
                fi: "Esim. “Seuraavan vuosikymmenen aikana haluan olla energisempi töissä, saada ylennyksen ja viettää enemmän aikaa perheeni kanssa”",
                sv: "T.ex. “Under det kommande årtiondet vill jag ha mer energi på jobbet, avancera på min karriär och tillbringa mer betydelsefull tid med min familj”",
                no: "F.eks. “I det neste tiåret av mitt liv ønsker jeg å ha mer energi på jobb, få en forfremmelse og tilbringe mer tid med familien min”",
                da: "F.eks. “I det næste årti af mit liv vil jeg have mere energi på arbejde, få en forfremmelse og tilbringe mere tid med min familie”",
                et: "Nt. “Järgmise kümnendi jooksul tahan tööl rohkem energiat, saada edutamist ja veeta rohkem aega perega”.",
                de: "Z.B. “Im nächsten Jahrzehnt meines Lebens möchte ich mehr Energie bei der Arbeit haben, befördert werden und mehr Zeit mit meiner Familie verbringen”",
                pl: "Np. “W następnym dziesięcioleciu mojego życia chcę mieć więcej energii w pracy, awansować i spędzać więcej czasu z rodziną”",
                zh: "例如：“在我未来的十年里，我希望在工作中有更多的精力，获得晋升，并与家人共度更多时光”。",
                pt: "Por exemplo: “Na próxima década da minha vida, quero ter mais energia no trabalho, obter uma promoção e passar mais tempo com minha família”",
                es: "Ej. “En la próxima década de mi vida quiero tener más energía en el trabajo, obtener una promoción y pasar más tiempo con mi familia”",
                fr: "Par exemple : “Dans la prochaine décennie de ma vie, je veux avoir plus d'énergie au travail, obtenir une promotion et passer plus de temps avec ma famille”"
              },
              my_target_title: {
                en: "My goal",
                fi: "Minun tavoitteeni",
                sv: "Mitt mål",
                no: "Mitt mål",
                da: "Mit mål",
                et: "Minu eesmärk",
                de: "Mein Ziel",
                pl: "Mój cel",
                zh: "我的目标",
                pt: "Meu objetivo",
                es: "Mi objetivo",
                fr: "Mon objectif"
              },
              my_target_description: {
                en: "This is a concrete goal (or wish) you have for your coaching programme or your next 3 to 6 months",
                fi: "Tämä on konkreettinen tavoite (tai toive), joka sinulla on valmennusohjelmallesi tai seuraaville 3-6 kuukaudelle",
                sv: "Detta är ett konkret mål (eller önskan) du har för ditt coachingprogram eller dina följande 3 till 6 månader",
                no: "Dette er et konkret mål (eller ønske) du har for ditt coachingprogram eller de neste 3 til 6 månedene",
                da: "Dette er et konkret mål (eller ønske), du har for dit coachingprogram eller de næste 3 til 6 måneder",
                et: "See on konkreetne eesmärk (või soov), mis teil on oma treeningprogrammiks või järgmiseks 3 kuni 6 kuuks",
                de: "Dies ist ein konkretes Ziel (oder Wunsch), das Sie für Ihr Coaching-Programm oder die nächsten 3 bis 6 Monate haben",
                pl: "To jest konkretny cel (lub życzenie), który masz dla swojego programu coachingowego lub na następne 3 do 6 miesięcy",
                zh: "这是你对你的辅导计划或未来3到6个月的一个具体目标（或愿望）。",
                pt: "Este é um objetivo concreto (ou desejo) que você tem para seu programa de coaching ou seus próximos 3 a 6 meses",
                es: "Este es un objetivo concreto (o deseo) que tienes para tu programa de coaching o para los próximos 3 a 6 meses",
                fr: "C'est un objectif concret (ou un souhait) que vous avez pour votre programme de coaching ou pour les 3 à 6 prochains mois"
              },
              my_target_placeholder: {
                en: "E.g. “I want to detach better after the workday and improve my recovery”",
                fi: "Esim. “Haluan irtautua paremmin työpäivän jälkeen ja parantaa palautumistani”",
                sv: "T.ex. “Jag vill koppla av och återhämta mig bättre efter arbetsdagen”",
                no: "F.eks. “Jeg vil koble bedre av etter arbeidsdagen og forbedre min restitusjon”",
                da: "F.eks. “Jeg vil slappe bedre af efter arbejdsdagen og forbedre min restitution”",
                et: "Nt. “Tahan pärast tööpäeva paremini lõõgastuda ja oma taastumist parandada”",
                de: "Z.B. “Ich möchte mich nach dem Arbeitstag besser abschalten und meine Erholung verbessern”",
                pl: "Np. “Chcę lepiej odłączać się po dniu pracy i poprawić moją regenerację”",
                zh: "例如：“我希望在工作日结束后更好地放松，并改善我的恢复。”",
                pt: "Por exemplo: “Quero me desligar melhor após o dia de trabalho e melhorar minha recuperação”",
                es: "Ej. “Quiero desconectarme mejor después del día laboral y mejorar mi recuperación”",
                fr: "Par exemple : “Je veux mieux me détacher après la journée de travail et améliorer ma récupération”"
              },
              my_actions_title: {
                en: "My actions",
                fi: "Minun tehtäväni",
                sv: "Mina förändringar",
                no: "Mine handlinger",
                da: "Mine handlinger",
                et: "Minu tegevused",
                de: "Meine Handlungen",
                pl: "Moje działania",
                zh: "我的行动",
                pt: "Minhas ações",
                es: "Mis acciones",
                fr: "Mes actions"
              },
              my_actions_description: {
                en: "Concrete actions to support your goal",
                fi: "Konkreettiset toimet tavoitteen tukemiseksi",
                sv: "Konkreta handlingar för att uppnå ditt mål",
                no: "Konkrete handlinger for å støtte målet ditt",
                da: "Konkrete handlinger til at støtte dit mål",
                et: "Konkreetsete tegevustega toetate oma eesmärki",
                de: "Konkrete Maßnahmen zur Unterstützung Ihres Ziels",
                pl: "Konkretne działania wspierające twój cel",
                zh: "支持你目标的具体行动。",
                pt: "Ações concretas para apoiar seu objetivo.",
                es: "Acciones concretas para apoyar tu objetivo.",
                fr: "Actions concrètes pour soutenir votre objectif."
              },
              my_actions_placeholder_1: {
                en: "E.g. “I will limit my screen time after 9pm”",
                fi: "Esim. “Rajoitan ruutuaikaani klo 21 jälkeen”",
                sv: "T.ex. “Jag tänkter begränsa min skärmtid efter kl. 21”",
                no: "F.eks. “Jeg vil begrense skjermtiden min etter kl. 21”",
                da: "F.eks. “Jeg vil begrænse min skærmtid efter kl. 21”",
                et: "Nt. “Piiran oma ekraaniaega pärast kella 21”",
                de: "Z.B. “Ich werde meine Bildschirmzeit nach 21 Uhr begrenzen”",
                pl: "Np. “Będę ograniczać czas spędzony przed ekranem po godzinie 21”",
                zh: "例如：“我将在晚上9点后限制屏幕使用时间。”",
                pt: "Por exemplo: “Vou limitar o tempo de tela após as 21h”",
                es: "Ej. “Limitaré mi tiempo frente a la pantalla después de las 9pm”",
                fr: "Par exemple : “Je limiterai mon temps d'écran après 21h”"
              },
              my_actions_placeholder_2: {
                en: "E.g. “I will leave work earlier two days a week”",
                fi: "Esim. “Lähden töistä aikaisemmin kahtena päivänä viikossa”",
                sv: "T.ex. “Jag kommer gå tidigare från jobbet två dagar i veckan”",
                no: "F.eks. “Jeg vil gå fra jobb tidligere to dager i uken”",
                da: "F.eks. “Jeg vil forlade arbejdet tidligere to dage om ugen”",
                et: "Nt. “Lahkun töölt varem kahel päeval nädalas”",
                de: "Z.B. “Ich werde zwei Tage die Woche früher von der Arbeit gehen”",
                pl: "Np. “Będę wychodzić z pracy wcześniej dwa dni w tygodniu”",
                zh: "例如：“我每周有两天会早点下班。”",
                pt: "Por exemplo: “Vou sair do trabalho mais cedo dois dias por semana”",
                es: "Ej. “Saldré del trabajo más temprano dos días a la semana”",
                fr: "Par exemple : “Je quitterai le travail plus tôt deux jours par semaine”"
              },
              my_actions_placeholder_3: {
                en: "E.g. “I will block time for focused work every Wed and Thu”",
                fi: "Esim. “Varaan aikaa keskittymistä vaativalle työlle joka ke ja to”",
                sv: "T.ex. “Jag kommer att blockera tid för fokuserat arbete varje onsdag och torsdag”",
                no: "F.eks. “Jeg vil sette av tid til fokusert arbeid hver onsdag og torsdag”",
                da: "F.eks. “Jeg vil blokere tid til fokuseret arbejde hver onsdag og torsdag”",
                et: "Nt. “Ma blokeerin iga kolmapäeva ja neljapäeva fookustatud tööks”",
                de: "Z.B. “Ich werde jede Woche am Mittwoch und Donnerstag Zeit für konzentriertes Arbeiten blockieren”",
                pl: "Np. “Będę blokować czas na skupioną pracę w każdą środę i czwartek”",
                zh: "例如：“我将在每周三和周四安排专注工作的时间。”",
                pt: "Por exemplo: “Vou reservar tempo para trabalho focado todas as quartas e quintas-feiras”",
                es: "Ej. “Bloquearé tiempo para trabajo enfocado todos los miércoles y jueves”",
                fr: "Par exemple : “Je bloquerai du temps pour un travail concentré tous les mercredis et jeudis”"
              },
              obstacles_title: {
                en: "Obstacles",
                fi: "Esteet",
                sv: "Hinder",
                no: "Hindringer",
                da: "Forhindringer",
                et: "Takistused",
                de: "Hindernisse",
                pl: "Przeszkody",
                zh: "障碍",
                pt: "Obstáculos",
                es: "Obstáculos",
                fr: "Obstacles"
              },
              obstacles_description: {
                en: "What are potential obstacles that may keep you from doing your actions or reaching your goal? How can you mitigate them?",
                fi: "Mitkä ovat mahdolliset esteet, jotka voivat haitata tehtäviesi toteuttamista tai tavoitteesi saavuttamista? Kuinka voit vähentää niiden vaikutusta?",
                sv: "Vilka utmaningar kan hindra dig från att nå ditt mål? Hur kan du förebygga eller hantera dem?",
                no: "Hva er potensielle hindringer som kan hindre deg fra å utføre handlingene dine eller nå målet ditt? Hvordan kan du redusere dem?",
                da: "Hvad er potentielle forhindringer, der kan forhindre dig i at udføre dine handlinger eller nå dit mål? Hvordan kan du afbøde dem?",
                et: "Millised on potentsiaalsed takistused, mis võivad takistada teid oma tegevuste tegemisest või eesmärgi saavutamisest? Kuidas saate neid leevendada?",
                de: "Was sind potenzielle Hindernisse, die Sie daran hindern könnten, Ihre Handlungen auszuführen oder Ihr Ziel zu erreichen? Wie können Sie sie mindern?",
                pl: "Jakie potencjalne przeszkody mogą powstrzymać Cię przed podjęciem działań lub osiągnięciem celu? Jak możesz je złagodzić?",
                zh: "可能阻碍你采取行动或实现目标的潜在障碍是什么？你如何缓解它们？",
                pt: "Quais são os obstáculos potenciais que podem impedi-lo de realizar suas ações ou alcançar seu objetivo? Como você pode mitigá-los?",
                es: "¿Cuáles son los posibles obstáculos que pueden impedirte realizar tus acciones o alcanzar tu objetivo? ¿Cómo puedes mitigarlos?",
                fr: "Quels sont les obstacles potentiels qui peuvent vous empêcher de réaliser vos actions ou d'atteindre votre objectif ? Comment pouvez-vous les atténuer ?"
              },
              obstacles_placeholder: {
                en: "E.g. “I tend to get stuck on my phone in the evening. I will ask my spouse to point it out if I do, so I switch to a book instead.”",
                fi: "Esim. “Minulla on tapana juuttua puhelimelleni iltaisin. Pyydän puolisoani huomauttamaan siitä, jotta vaihdan kirjaan puhelimen sijaan.”",
                sv: "T.ex. “Jag har en vana att använda telefonen för mycket på kvällen. Jag ska be min partner att uppmärksamma mig på det, så jag kan byta ut den mot en bok.”",
                no: "F.eks. “Jeg har en tendens til å sitte fast på telefonen om kvelden. Jeg vil be min ektefelle påpeke det hvis jeg gjør det, så jeg bytter til en bok i stedet.”",
                da: "F.eks. “Jeg har en tendens til at sidde fast på min telefon om aftenen. Jeg vil bede min ægtefælle om at påpege det, hvis jeg gør det, så jeg skifter til en bog i stedet.”",
                et: "Nt. “Mul on kalduvus õhtuti telefoniga kinni jääda. Ma palun oma abikaasal sellele tähelepanu juhtida, et vahetaksin selle asemel raamatu vastu.”",
                de: "Z.B. “Ich neige dazu, abends an meinem Telefon hängen zu bleiben. Ich werde meinen Ehepartner bitten, mich darauf hinzuweisen, damit ich stattdessen zu einem Buch wechsle.”",
                pl: "Np. “Mam tendencję do utknąć z telefonem wieczorem. Poproszę współmałżonka, aby mi to przypomniał, abym zamiast tego sięgnął po książkę.”",
                zh: "例如：“我晚上容易沉迷于手机。我会请我的配偶提醒我，这样我就可以改看书了。”",
                pt: "Por exemplo: “Tenho tendência a ficar preso ao meu telefone à noite. Vou pedir ao meu cônjuge para me avisar se isso acontecer, para que eu mude para um livro.”",
                es: "Ej. “Tiendo a quedarme pegado a mi teléfono por la noche. Le pediré a mi cónyuge que me lo señale si lo hago, para que cambie a un libro en su lugar.”",
                fr: "Par exemple : “J'ai tendance à rester collé à mon téléphone le soir. Je demanderai à mon conjoint de me le signaler si je le fais, afin que je passe à un livre à la place.”"
              },
              need: {
                en: "Need"
              },
              need_description: {
                en: "Do you have a critical need or pain point to create a sense of urgency?"
              },
              motivation: {
                en: "Motivation"
              },
              motivation_description: {
                en: "How motivated are you? How can you keep going also on the ”bad days”?"
              },
              capability: {
                en: "Capability"
              },
              capability_description: {
                en: "What's a good starting level for you considering your skills and experience?"
              },
              support: {
                en: "Support"
              },
              support_description: {
                en: "Can you change your physical or social environment to be more supportive of change?"
              },
              need_to_change: {
                en: "Need to change"
              },
              motivation_to_change: {
                en: "Motivation to change"
              },
              capability_to_change: {
                en: "Capability to change"
              },
              support_to_change: {
                en: "Support to change"
              }
            },
            ReportSectionSurvivingPerformingThriving: {
              title: {
                en: "Are you Surviving, Performing, or Thriving?",
                fi: "Selviydytkö, suoriudutko vai kukoistatko?",
                sv: "Överlever du, presterar du eller blomstrar du?",
                no: "Overlever du, presterer du eller blomstrer du?",
                da: "Overlever du, yder du eller trives du?",
                et: "Kas sa ellu jääd, esined või õitsed?",
                de: "Überleben Sie, leisten Sie oder gedeihen Sie?",
                pl: "Przetrwasz, wykonujesz zadania czy rozkwitasz?",
                zh: "你是在生存、表现还是茁壮成长？",
                pt: "Você está Sobrevivendo, Desempenhando ou Prosperando?",
                es: "¿Estás sobreviviendo, rindiendo o prosperando?",
                fr: "Survivez-vous, performez-vous ou prospérez-vous?"
              },
              description: {
                en: `
We all fluctuate between these three ‘Performance Modes’ – even within the same day. **Which mode is the strongest for you?**

The questions in this section focus on wellbeing at work: e.g. exhaustion risk, meaning, engagement, learning, autonomy.`,
                fi: `
Me kaikki vaihtelemme näiden kolmen 'suorituskykytilan' välillä – jopa saman päivän aikana. **Mikä tila on sinulla vahvin?**

Tämän osion kysymykset keskittyvät hyvinvointiin työssä: esim. uupumisriski, merkitys, sitoutuminen, oppiminen, autonomia.`,
                sv: `
Vi växlar alla mellan dessa tre 'livslägen' – ibland flera gånger under en dag. **Vilket läge är mest påtagligt för dig just nu?**

Frågorna här handlar om ditt välmående på jobbet, inklusive utmattningsrisk, mening, engagemang, lärande och autonomi.`,
                no: `
Vi svinger alle mellom disse tre 'ytelsesmodusene' – til og med innenfor samme dag. **Hvilken modus er sterkest for deg?**

Spørsmålene i denne delen fokuserer på velvære på jobben: f.eks. utmattelsesrisiko, mening, engasjement, læring, autonomi.`,
                da: `
Vi svinger alle mellem disse tre 'præstationsmodi' – selv inden for samme dag. **Hvilken tilstand er stærkest for dig?**

Spørgsmålene i dette afsnit fokuserer på trivsel på arbejdet: f.eks. udmattelsesrisiko, mening, engagement, læring, autonomi.`,
                et: `
Me kõik kõigume nende kolme 'sooritusrežiimi' vahel – isegi sama päeva jooksul. **Milline režiim on teie jaoks kõige tugevam?**

Selle jaotise küsimused keskenduvad tööheaolule: nt kurnatusrisk, tähendus, kaasatus, õppimine, autonoomia.`,
                de: `
Wir alle schwanken zwischen diesen drei 'Leistungsmodi' – sogar innerhalb eines Tages. **Welcher Modus ist bei Ihnen am stärksten?**

Die Fragen in diesem Abschnitt konzentrieren sich auf das Wohlbefinden am Arbeitsplatz: z.B. Erschöpfungsrisiko, Sinn, Engagement, Lernen, Autonomie.`,
                pl: `
Wszyscy wahamy się między tymi trzema 'trybami wydajności' – nawet w ciągu jednego dnia. **Który tryb jest dla ciebie najsilniejszy?**

Pytania w tej sekcji koncentrują się na samopoczuciu w pracy: np. ryzyko wyczerpania, sens, zaangażowanie, nauka, autonomia.`,
                zh: `
我们在这三种“表现模式”之间波动 - 即使在同一天内也是如此。**哪种模式对你来说最强？**

本部分的问题侧重于工作中的幸福感：例如，疲劳风险、意义、参与、学习、自治。`,
                pt: `
Todos nós oscilamos entre esses três 'Modos de Desempenho' – até mesmo dentro do mesmo dia. **Qual modo é mais forte para você?**

As perguntas nesta seção focam no bem-estar no trabalho: por exemplo, risco de exaustão, significado, engajamento, aprendizado, autonomia.`,
                es: `
Todos fluctuamos entre estos tres 'Modos de Desempeño', incluso dentro del mismo día. **¿Cuál es el modo más fuerte para ti?**

Las preguntas en esta sección se centran en el bienestar en el trabajo: p. ej., riesgo de agotamiento, sentido, compromiso, aprendizaje, autonomía.`,
                fr: `
Nous fluctuons tous entre ces trois 'Modes de Performance' – même au cours d'une même journée. **Quel mode est le plus fort pour vous ?**

Les questions de cette section se concentrent sur le bien-être au travail : risque d'épuisement, sens, engagement, apprentissage, autonomie.`
              }
            },
            ReportSectionBigPicture: {
              title: {
                en: "Let's look at the big picture",
                fi: "Tarkastellaan kokonaiskuvaa",
                sv: "Din helhetsbild just nu",
                no: "La oss se på helheten",
                da: "Lad os se på det store billede",
                et: "Vaatame suurt pilti",
                de: "Lass uns das große Ganze betrachten",
                pl: "Spójrzmy na szerszą perspektywę",
                zh: "让我们看看大局",
                pt: "Vamos olhar para o quadro geral",
                es: "Veamos el panorama general",
                fr: "Regardons la vue d'ensemble"
              },
              subtitle: {
                en: "This is how you see your current life satisfaction and work performance.",
                fi: "Näin näet tämänhetkisen tyytyväisyytesi elämään ja työssä suoriutumisesi.",
                sv: "Så här ser du ditt nuvarande livsnöje och din arbetsprestation.",
                no: "Slik ser du på din nåværende livsglede og arbeidsprestasjon.",
                da: "Sådan ser du på din nuværende livstilfredshed og arbejdspræstation.",
                et: "Nii näed oma praegust eluga rahulolu ja töösooritust.",
                de: "So sehen Sie Ihre aktuelle Lebenszufriedenheit und Arbeitsleistung.",
                pl: "Tak widzisz swoje obecne zadowolenie z życia i wyniki w pracy.",
                zh: "这就是你对你当前的生活满意度和工作表现的看法。",
                pt: "É assim que você vê sua satisfação com a vida e desempenho no trabalho atuais.",
                es: "Así es como ves tu satisfacción con la vida y rendimiento laboral actuales.",
                fr: "Voici comment vous percevez votre satisfaction actuelle dans la vie et vos performances au travail."
              },
              description_standard: {
                en: `
Aiming for perfect scores is not the goal. Instead, reflect on:
* What would it feel like to be one rung higher?
* What would it take?

As you read your report, think about what actions and changes could help you get there.`,
                fi: `
Täydellisten tulosten saavuttaminen ei ole tavoitteena. Sen sijaan pohdi:
* Miltä tuntuisi olla yhden askelman korkeammalla?
* Mitä se vaatisi?

Kun luet raporttiasi, mieti, mitkä pienet teot ja muutokset voisivat auttaa sinua pääsemään sinne.`,
                sv: `
Du behöver inte eftersträva perfekta poäng. Reflektera istället över:
* Hur skulle det kännas att vara ett steg högre upp?
* Vad kräver det?

När du läser din rapport, tänk på vilka åtgärder och förändringar som kan hjälpa dig nå dit.`,
                no: `
Å sikte på perfekte resultater er ikke målet. Reflekter i stedet over:
* Hvordan ville det føles å være ett trinn høyere?
* Hva ville det kreve?

Når du leser rapporten din, tenk på hvilke handlinger og endringer som kan hjelpe deg med å komme dit.`,
                da: `
At sigte efter perfekte resultater er ikke målet. Reflekter i stedet over:
* Hvordan ville det føles at være et trin højere?
* Hvad ville det kræve?

Når du læser din rapport, så tænk over, hvilke handlinger og ændringer der kan hjælpe dig med at nå derhen.`,
                et: `
Täiuslike tulemuste saavutamine ei ole eesmärk. Selle asemel mõtle:
* Kuidas oleks tunda end üks aste kõrgemal?
* Mida see võtaks?

Kui loete oma aruannet, mõelge, millised tegevused ja muudatused võiksid aidata teil sinna jõuda.`,
                de: `
Perfekte Ergebnisse anzustreben ist nicht das Ziel. Reflektieren Sie stattdessen:
* Wie würde es sich anfühlen, eine Stufe höher zu sein?
* Was würde es erfordern?

Wenn Sie Ihren Bericht lesen, denken Sie darüber nach, welche Maßnahmen und Veränderungen Ihnen helfen könnten, dorthin zu gelangen.`,
                pl: `
Dążenie do perfekcyjnych wyników nie jest celem. Zamiast tego zastanów się:
* Jak by to było być jeden szczebel wyżej?
* Co by to wymagało?

Czytając swój raport, pomyśl o działaniach i zmianach, które mogłyby ci pomóc w osiągnięciu tego celu.`,
                zh: `
追求完美分数不是目标。相反，反思：
* 再高一级会是什么感觉？
* 这需要什么？

当你阅读报告时，想想哪些行动和改变可以帮助你达到那个目标。`,
                pt: `
Buscar pontuações perfeitas não é o objetivo. Em vez disso, reflita sobre:
* Como seria estar um degrau mais alto?
* O que seria necessário?

Ao ler seu relatório, pense em quais ações e mudanças poderiam ajudá-lo a chegar lá.`,
                es: `
Apuntar a puntuaciones perfectas no es el objetivo. En su lugar, reflexiona sobre:
* ¿Cómo se sentiría estar un peldaño más alto?
* ¿Qué se necesitaría?

Al leer tu informe, piensa en qué acciones y cambios podrían ayudarte a llegar allí.`,
                fr: `
L'objectif n'est pas de viser des scores parfaits. Réfléchissez plutôt à :
* Que ressentiriez-vous en étant un échelon plus haut ?
* Que faudrait-il pour y parvenir ?

En lisant votre rapport, pensez aux actions et aux changements qui pourraient vous aider à y parvenir.`
              },
              description_high_score: {
                en: `
Aiming for perfect scores is not the goal. Instead, reflect on:
* What enables you to sustain this level?
* What good habits do you already have?

As you read your report, think about what concrete actions help you stay on this level.`,
                fi: `
Täydellisten tulosten saavuttaminen ei ole tavoitteena. Sen sijaan pohdi:
* Mikä mahdollistaa tämän tason ylläpitämisen?
* Mitä hyviä tapoja sinulla jo on?

Kun luet raporttiasi, mieti, mitkä konkreettiset teot auttavat sinua pysymään tällä tasolla.`,
                sv: `
Att sträva efter perfekta resultat är inte målet. Reflektera istället över:
* Vad gör det möjligt för dig att upprätthålla denna nivå?
* Vilka goda vanor har du redan?

När du läser din rapport, tänk på vilka konkreta åtgärder som hjälper dig att stanna på denna nivå.`,
                no: `
Å sikte på perfekte resultater er ikke målet. Reflekter i stedet over:
* Hva gjør det mulig for deg å opprettholde dette nivået?
* Hvilke gode vaner har du allerede?

Når du leser rapporten din, tenk på hvilke konkrete handlinger som hjelper deg med å holde deg på dette nivået.`,
                da: `
At sigte efter perfekte resultater er ikke målet. Reflekter i stedet over:
* Hvad gør det muligt for dig at opretholde dette niveau?
* Hvilke gode vaner har du allerede?

Når du læser din rapport, så tænk over, hvilke konkrete handlinger der kan hjælpe dig med at blive på dette niveau.`,
                et: `
Täiuslike tulemuste saavutamine ei ole eesmärk. Selle asemel mõtle:
* Mis võimaldab teil seda taset säilitada?
* Millised head harjumused teil juba on?

Kui loete oma aruannet, mõelge, millised konkreetsed tegevused aitavad teil sellel tasemel püsida.`,
                de: `
Perfekte Ergebnisse anzustreben ist nicht das Ziel. Reflektieren Sie stattdessen:
* Was ermöglicht es Ihnen, dieses Niveau zu halten?
* Welche guten Gewohnheiten haben Sie bereits?

Wenn Sie Ihren Bericht lesen, denken Sie darüber nach, welche konkreten Maßnahmen Ihnen helfen, dieses Niveau zu halten.`,
                pl: `
Dążenie do perfekcyjnych wyników nie jest celem. Zamiast tego zastanów się:
* Co pozwala ci utrzymać ten poziom?
* Jakie dobre nawyki już masz?

Czytając swój raport, pomyśl o działaniach, które pomogą ci utrzymać ten poziom.`,
                zh: `
追求完美分数不是目标。相反，反思：
* 是什么让你能够保持这一水平？
* 你已经有哪些好习惯？

当你阅读报告时，想想哪些具体行动可以帮助你保持在这个水平上。`,
                pt: `
Buscar pontuações perfeitas não é o objetivo. Em vez disso, reflita sobre:
* O que lhe permite manter este nível?
* Quais bons hábitos você já tem?

Ao ler seu relatório, pense em quais ações concretas ajudam você a manter esse nível.`,
                es: `
Apuntar a puntuaciones perfectas no es el objetivo. En su lugar, reflexiona sobre:
* ¿Qué te permite mantener este nivel?
* ¿Qué buenos hábitos ya tienes?

Al leer tu informe, piensa en qué acciones concretas te ayudan a mantener este nivel.`,
                fr: `
L'objectif n'est pas de viser des scores parfaits. Réfléchissez plutôt à :
* Qu'est-ce qui vous permet de maintenir ce niveau ?
* Quelles bonnes habitudes avez-vous déjà ?

En lisant votre rapport, pensez aux actions concrètes qui vous aident à rester à ce niveau.`
              },
              life_satisfaction: {
                en: "Life satisfaction",
                fi: "Tyytyväisyys elämään",
                sv: "Livsnöje",
                no: "Livsglede",
                da: "Livstilfredshed",
                et: "Elurõõm",
                de: "Lebensfreude",
                pl: "Zadowolenie z życia",
                zh: "生活满意度",
                pt: "Satisfação com a vida",
                es: "Satisfacción con la vida",
                fr: "Satisfaction de la vie"
              },
              work_performance: {
                en: "Work performance",
                fi: "Työssä suoriutuminen",
                sv: "Arbetsprestation",
                no: "Arbeidsprestasjoner",
                da: "Arbejdspræstationer",
                et: "Töötulemused",
                de: "Arbeitsleistung",
                pl: "Wyniki w pracy",
                zh: "工作表现",
                pt: "Desempenho no trabalho",
                es: "Rendimiento laboral",
                fr: "Performance au travail"
              }
            },
            ReportSectionCongratulations: {
              congratulations_title: {
                en: "Your story begins here.",
                fi: "Sinun tarinasi alkaa tästä.",
                de: "Deine Geschichte beginnt hier.",
                es: "Tu historia comienza aquí.",
                fr: "Ton histoire commence ici.",
                sv: "Ditt nästa kapitel börjar här.",
                da: "Din historie begynder her.",
                et: "Sinu lugu algab siit.",
                no: "Din historie begynner her.",
                pl: "Twoja historia zaczyna się tutaj.",
                zh: "你的故事从这里开始。",
                pt: "A tua história começa aqui."
              },
              congratulations_body: {
                en: "You've taken the first step towards better life and better performance with this wellbeing snapshot. Pause, reflect, set a goal, and take action to build a life that looks like you.",
                fi: "Olet ottanut ensimmäisen askeleen kohti parempaa elämää ja suorituskykyä tällä hyvinvointikatsauksella. Pysähdy, pohdi, aseta tavoite ja ryhdy toimeen rakentaaksesi elämän, joka näyttää sinulta.",
                de: "Du hast mit diesem Wohlfühl-Snapshot den ersten Schritt zu einem besseren Leben und einer besseren Leistung gemacht. Halte inne, reflektiere, setze dir ein Ziel und ergreife Maßnahmen, um ein Leben zu gestalten, das aussieht wie du.",
                es: "Has dado el primer paso hacia una vida y un rendimiento mejores con esta instantánea de bienestar. Haz una pausa, reflexiona, establece una meta y toma medidas para construir una vida que se parezca a ti.",
                fr: "Tu as franchi la première étape vers une vie meilleure et des performances accrues grâce à ce portrait instantané de bien-être. Fais une pause, réfléchis, fixe-toi un objectif et passe à l'action pour construire une vie qui te ressemble.",
                sv: "Du har tagit det första steget mot ett bättre liv och bättre prestation med denna välmåenderapport. Ta dig tid att reflektera, sätta ett mål, och fundera hur du vill att ditt nästa kapitel inom välmående ser ut.",
                da: "Du har taget det første skridt mod et bedre liv og præstation med dette velværesnapshot. Hold pause, reflekter, sæt et mål og tag handling for at bygge et liv, der ligner dig.",
                et: "Oled astunud esimese sammu parema elu ja soorituse poole selle heaolu hetktõmmisega. Peatu, mõtiskle, sea eesmärk ja tegutse, et luua elu, mis näeb välja nagu sina.",
                no: "Du har tatt det første steget mot et bedre liv og prestasjon med dette velværebildet. Ta en pause, reflekter, sett deg et mål og ta grep for å bygge et liv som ser ut som deg.",
                pl: "Zrobiłeś pierwszy krok w kierunku lepszego życia i wydajności dzięki tej migawce dobrostanu. Zatrzymaj się, zastanów, wyznacz cel i podejmij działania, aby zbudować życie, które wygląda jak Ty.",
                zh: "通过这个幸福快照，你已经迈出了通往更美好生活和更高效表现的第一步。暂停一下，反思一下，设定一个目标，并采取行动，构建一个像你一样的生活。",
                pt: "Deste o primeiro passo em direção a uma vida e desempenho melhores com este instantâneo de bem-estar. Pára, reflete, define um objetivo e toma medidas para construir uma vida que se pareça contigo."
              },
              qr_code: {
                en: "See your report online",
                fi: "Katso raporttisi verkossa",
                de: "Sieh dir deinen Bericht online an",
                es: "Consulta tu informe en línea",
                fr: "Voir votre rapport en ligne",
                sv: "Se din rapport online",
                da: "Se din rapport online",
                et: "Vaata oma raportit veebis",
                no: "Se rapporten din på nett",
                pl: "Zobacz swój raport online",
                zh: "在线查看您的报告",
                pt: "Veja seu relatório online"
              }
            },
            ReportSectionCoblCircle: {
              overview: {
                en: "Overview",
                fi: "Yleiskatsaus",
                sv: "Översikt",
                no: "Oversikt",
                da: "Oversigt",
                et: "Ülevaade",
                de: "Übersicht",
                pl: "Przegląd",
                zh: "概览",
                pt: "Visão geral",
                es: "Resumen",
                fr: "Vue d'ensemble"
              },
              cobl_title: {
                en: "Your Circle of Better Life",
                fi: "Sinun Paremman Elämän Ympyräsi",
                sv: "Din 'Circle of Better Life'",
                no: "Din sirkel for bedre liv",
                da: "Din cirkel for et bedre liv",
                et: "Teie parema elu ring",
                de: "Ihr Kreislauf des besseren Lebens",
                pl: "Twój okrąg lepszego życia",
                zh: "你的更好生活圈",
                pt: "Seu círculo de melhor vida",
                es: "Tu círculo de mejor vida",
                fr: "Votre cercle de meilleure vie"
              },
              cobl_intro: {
                en: `Imagine a wheel with seven interconnected elements. For your wheel to spin smoothly, all parts must be in balance.

This is your wellbeing across the 7 elements of holistic wellbeing in Hintsa's Circle of Better Life. The centre of the circle, your Core, represents your deepest motivations and values - the foundation of meaningful, long-term change.`,
                fi: `Kuvittele pyörä, jossa on seitsemän toisiinsa kytkeytyvää elementtiä. Jotta pyörä pyörisi tasaisesti, kaikkien osien on oltava tasapainossa.

Tämä on hyvinvointisi Hintsan Paremman Elämän Ympyrä -mallin seitsemän kokonaisvaltaisen hyvinvoinnin osa-alueen mukaan. Kehän keskusta, Ytimesi, kuvaa syvimpiä motivaatioitasi ja arvojasi – jotka ovat merkityksellisen ja pitkäaikaisen muutoksen perusta.`,
                sv: `Föreställ dig ett hjul med sju sammanlänkade element. För att hjulet skulle snurra smidigt måste alla delar vara i balans.

Hintsas holistiska Circle of Better Life-modell, som har finslipats sedan 90-talet, beskriver ditt välbefinnande genom dessa sju element. I modellens mitt finns din 'kärna' – den del av dig som rymmer dina djupaste värderingar och din identitet, och som är avgörande för att skapa varaktig förändring.`,
                no: `Tenk deg et hjul med syv sammenkoblede elementer. For at hjulet ditt skal snurre jevnt, må alle deler være i balanse.

Dette er din velvære på tvers av de 7 elementene av helhetlig velvære i Hintsa's Circle of Better Life. I midten av sirkelen, kjernen din, representeres dine dypeste motivasjoner og verdier - grunnlaget for meningsfull, langsiktig endring.`,
                da: `Forestil dig et hjul med syv sammenkoblede elementer. For at dit hjul kan dreje glat, skal alle dele være i balance.

Dette er dit velvære på tværs af de 7 elementer af holistisk velvære i Hintsa's Circle of Better Life. Midt i cirklen, din kerne, repræsenteres dine dybeste motivationer og værdier - grundlaget for meningsfuld, langsigtet forandring.`,
                et: `Kujutage ette ratast, millel on seitse omavahel seotud elementi. Et teie ratas sujuks sujuvalt, peavad kõik osad olema tasakaalus.

See on teie heaolu Hintsa's Circle of Better Life seitsmes tervikliku heaolu elemendis. Ringi keskel, teie tuum, esindab teie sügavaimaid motivatsioone ja väärtusi - tähendusrikka, pikaajalise muutuse alust.`,
                de: `Stellen Sie sich ein Rad mit sieben miteinander verbundenen Elementen vor. Damit sich Ihr Rad reibungslos dreht, müssen alle Teile im Gleichgewicht sein.

Dies ist Ihr Wohlbefinden in den 7 Elementen des ganzheitlichen Wohlbefindens im Hintsa's Circle of Better Life. Im Zentrum des Kreises, Ihr Kern, stehen Ihre tiefsten Motivationen und Werte - die Grundlage für sinnvolle, langfristige Veränderungen.`,
                pl: `Wyobraź sobie koło z siedmioma połączonymi elementami. Aby twoje koło obracało się płynnie, wszystkie części muszą być w równowadze.

To jest twoje dobrostan w 7 elementach holistycznego dobrostanu w Hintsa's Circle of Better Life. W centrum kręgu, twoja rdzeń, znajdują się twoje najgłębsze motywacje i wartości - podstawa do znaczących, długoterminowych zmian.`,
                zh: `想象一个由七个相互关联的元素组成的轮子。为了让你的轮子平稳旋转，所有部分必须平衡。

这是你在 Hintsa's Circle of Better Life 中的整体健康的 7 个元素中的健康状况。圆圈的中心，你的核心，代表了你最深层的动机和价值观——有意义的长期变化的基础。`,
                pt: `Imagine uma roda com sete elementos interconectados. Para a sua roda girar suavemente, todas as partes devem estar em equilíbrio.

Este é o seu bem-estar nos 7 elementos do bem-estar holístico no Circle of Better Life de Hintsa. O centro do círculo, seu núcleo, representa suas motivações e valores mais profundos - a base para uma mudança significativa e de longo prazo.`,
                es: `Imagina una rueda con siete elementos interconectados. Para que tu rueda gire suavemente, todas las partes deben estar en equilibrio.

Este es tu bienestar a través de los 7 elementos del bienestar holístico en el Circle of Better Life de Hintsa. El centro del círculo, tu núcleo, representa tus motivaciones y valores más profundos: la base de un cambio significativo y a largo plazo.`,
                fr: `Imaginez une roue avec sept éléments interconnectés. Pour que votre roue tourne en douceur, toutes les parties doivent être en équilibre.

C'est votre bien-être à travers les 7 éléments du bien-être holistique dans le Circle of Better Life de Hintsa. Le centre du cercle, votre noyau, représente vos motivations et valeurs les plus profondes - la base d'un changement significatif et durable.`
              },
              interpreting_results: {
                en: "How to interpret the results",
                fi: "Miten tulkita tuloksia",
                sv: "Hur du tolkar resultaten",
                no: "Hvordan tolke resultatene",
                da: "Sådan tolkes resultaterne",
                et: "Kuidas tulemusi tõlgendada",
                de: "Wie man die Ergebnisse interpretiert",
                pl: "Jak interpretować wyniki",
                zh: "如何解读结果",
                pt: "Como interpretar os resultados",
                es: "Cómo interpretar los resultados",
                fr: "Comment interpréter les résultats"
              },
              results_excellent: {
                en: "Excellent: An area of strength",
                fi: "Erinomainen: Vahvuusalue",
                sv: "Utmärkt: En klar styrka",
                no: "Utmerket: Et styrkeområde",
                da: "Fremragende: Et styrkeområde",
                et: "Suurepärane: Tugevusala",
                de: "Ausgezeichnet: Ein Stärkebereich",
                pl: "Doskonały: Obszar siły",
                zh: "优秀：优势领域",
                pt: "Excelente: Uma área de força",
                es: "Excelente: Un área de fortaleza",
                fr: "Excellent : Un domaine de force"
              },
              results_well_done: {
                en: "Good: Maintain or build into a strength",
                fi: "Hyvä: Säilytä tai kehitä vahvuudeksi",
                sv: "Bra: Bevara eller utveckla till en styrka",
                no: "Bra: Oppretthold eller bygg videre på en styrke",
                da: "Godt: Oprethold eller byg videre på en styrke",
                et: "Hea: Säilitage või arendage tugevuseks",
                de: "Gut: Erhalten oder zu einer Stärke ausbauen",
                pl: "Dobry: Utrzymaj lub rozwijaj siłę",
                zh: "良好：保持或发展为优势",
                pt: "Bom: Mantenha ou desenvolva em uma área de força",
                es: "Bueno: Mantén o desarrolla en una fortaleza",
                fr: "Bon : Maintenir ou développer en force"
              },
              results_neutral: {
                en: "Monitor: Area for potential action",
                fi: "Seuraa: Mahdollinen kehitysalue",
                sv: "Övervaka: Kan kräva förändring",
                no: "Overvåk: Områder for potensielle tiltak",
                da: "Overvåg: Områder for potentielle handlinger",
                et: "Jälgige: Võimalikud tegevusalad",
                de: "Beobachten: Bereiche für potenzielle Maßnahmen",
                pl: "Monitor: Obszary potencjalnych działań",
                zh: "监控：潜在行动领域",
                pt: "Monitorar: Áreas para ação potencial",
                es: "Monitor: Áreas para posibles acciones",
                fr: "Surveiller : Domaines d'action potentiels"
              },
              results_need_attention: {
                en: "Focus: Area needing attention",
                fi: "Keskity: Huomiota vaativa alue",
                sv: "Fokusera: Kräver förändring",
                no: "Fokuser: Områder som trenger oppmerksomhet",
                da: "Fokus: Områder, der kræver opmærksomhed",
                et: "Keskenduge: Tähelepanu vajavad valdkonnad",
                de: "Fokus: Bereiche, die Aufmerksamkeit benötigen",
                pl: "Skup się: Obszary wymagające uwagi",
                zh: "重点关注：需要注意的领域",
                pt: "Foco: Áreas que precisam de atenção",
                es: "Enfocar: Áreas que necesitan atención",
                fr: "Concentrer : Domaines nécessitant une attention"
              },
              results_need_extra_focus: {
                en: "Improve: Area for extra focus and care",
                fi: "Paranna: Lisähuomiota vaativa alue",
                sv: "Förbättra: Kräver omedelbara åtgärder",
                no: "Forbedre: Områder for ekstra fokus og omsorg",
                da: "Forbedre: Områder, der kræver ekstra fokus og omsorg",
                et: "Parandage: Valdkonnad, mis vajavad lisafookust ja hoolt",
                de: "Verbessern: Bereiche, die besondere Aufmerksamkeit und Pflege erfordern",
                pl: "Popraw: Obszary wymagające dodatkowej uwagi i troski",
                zh: "改善：需要额外关注和照顾的领域",
                pt: "Melhorar: Áreas para foco e cuidado extra",
                es: "Mejorar: Áreas para mayor enfoque y cuidado",
                fr: "Améliorer : Domaines nécessitant une attention et des soins particuliers"
              },
              benchmark_explanation: {
                en: "Hintsa global benchmark, n=5000, prior to coaching",
                fi: "Hintsan maailmanlaajuinen vertailuarvo, n=5000, ennen valmennusta",
                sv: "Hintsas globala benchmark, n=5000, före coaching",
                no: "Hinsas globale referanse, n=5000, før coaching",
                da: "Hintsas globale benchmark, n=5000, før coaching",
                et: "Hintsa ülemaailmne võrdlusalus, n=5000, enne treenimist",
                de: "Hintsas globaler Benchmark, n=5000, vor dem Coaching",
                pl: "Globalny benchmark Hintsa, n=5000, przed coachingiem",
                zh: "Hintsa全球基准，n=5000，培训前",
                pt: "Referência global Hintsa, n=5000, antes do coaching",
                es: "Referencia global de Hintsa, n=5000, antes del coaching",
                fr: "Référence mondiale Hintsa, n=5000, avant le coaching"
              }
            },
            ReportSectionCoblCategories: {
              title: {
                en: "The seven elements together reflect the integrated nature of health, wellbeing, and performance.",
                fi: "Nämä seitsemän elementtiä yhdessä kuvastavat terveyden, hyvinvoinnin ja suorituskyvyn integroitua luonnetta.",
                sv: "De sju elementen tillsammans visar hur hälsa, välbefinnande och prestation är integrerade och beroende av varandra.",
                no: "De syv elementene sammen reflekterer den integrerte naturen av helse, velvære og prestasjon.",
                da: "De syv elementer sammen afspejler den integrerede natur af sundhed, velvære og præstation.",
                et: "Need seitse elementi koos peegeldavad tervise, heaolu ja soorituse integreeritud olemust.",
                de: "Die sieben Elemente zusammen spiegeln die integrierte Natur von Gesundheit, Wohlbefinden und Leistung wider.",
                pl: "Siedem elementów razem odzwierciedla zintegrowany charakter zdrowia, dobrostanu i wydajności.",
                zh: "七个元素共同反映了健康、幸福和绩效的综合性质。",
                pt: "Os sete elementos juntos refletem a natureza integrada da saúde, bem-estar e desempenho.",
                es: "Los siete elementos juntos reflejan la naturaleza integrada de la salud, el bienestar y el rendimiento.",
                fr: "Les sept éléments reflètent ensemble la nature intégrée de la santé, du bien-être et de la performance."
              }
            },
            ReportSectionContinueJourney: {
              continue_journey_title: {
                en: "Continue your story.",
                fi: "Jatka tarinaasi.",
                sv: "Fortsätt på ditt nya kapitel.",
                no: "Fortsett historien din",
                da: "Fortsæt din historie",
                et: "Jätka oma lugu",
                de: "Setzen Sie Ihre Geschichte fort",
                pl: "Kontynuuj swoją historię",
                zh: "继续你的故事",
                pt: "Continue sua história",
                es: "Continúa tu historia",
                fr: "Poursuivez votre histoire"
              },
              achieve_goal: {
                en: "Want to ensure you achieve your goal?",
                fi: "Haluatko varmistaa, että saavutat tavoitteesi?",
                sv: "Vill du göra allt för att nå ditt mål?",
                no: "Vil du sikre at du når målet ditt?",
                da: "Vil du sikre, at du når dit mål?",
                et: "Kas soovite veenduda, et saavutate oma eesmärgi?",
                de: "Möchten Sie sicherstellen, dass Sie Ihr Ziel erreichen?",
                pl: "Chcesz upewnić się, że osiągniesz swój cel?",
                zh: "想确保你实现目标吗？",
                pt: "Quer garantir que você atinja seu objetivo?",
                es: "¿Quieres asegurarte de que logras tu objetivo?",
                fr: "Vous voulez vous assurer d'atteindre votre objectif?"
              },
              learn_woop: {
                en: "Learn the science-based WOOP strategy for goal setting.",
                fi: "Opi tieteeseen perustuva WOOP-strategia tavoitteiden asettamiseen.",
                sv: "Lär dig WOOP-strategin för målsättning.",
                no: "Lær den vitenskapsbaserte WOOP-strategien for målsetting.",
                da: "Lær den videnskabsbaserede WOOP-strategi til målsætning.",
                et: "Õppige teaduspõhist WOOP-strateegiat eesmärkide seadmiseks.",
                de: "Lernen Sie die wissenschaftlich fundierte WOOP-Strategie zur Zielsetzung.",
                pl: "Naucz się opartej na nauce strategii WOOP do wyznaczania celów.",
                zh: "学习基于科学的WOOP目标设定策略。",
                pt: "Aprenda a estratégia WOOP baseada em ciência para definir metas.",
                es: "Aprende la estrategia WOOP basada en la ciencia para establecer objetivos.",
                fr: "Découvrez la stratégie WOOP basée sur la science pour définir des objectifs."
              },
              woop_content: {
                en: `
The WOOP method is the basis for Hintsa’s own behavioural change methodology. It covers four elements:
* Wish
* Outcome
* Obstacle
* Plan`,
                fi: `
WOOP-menetelmä on Hintsan oman käyttäytymisen muutoksen metodologian perusta. Se kattaa neljä elementtiä:
* Toive (Wish)
* Tulos (Outcome)
* Este (Obstacle)
* Suunnitelma (Plan)`,
                sv: `
WOOP-metoden är grunden för Hintsas egen beteendeförändringsmodell. Den omfattar fyra element:
* Önskan (Wish)
* Resultat (Outcome)
* Hinder (Obstacle)
* Plan (Plan)`,
                no: `
WOOP-metoden er grunnlaget for Hintsas egen metodikk for atferdsendring. Den dekker fire elementer:
* Ønske (Wish)
* Resultat (Outcome)
* Hindring (Obstacle)
* Plan (Plan)`,
                da: `
WOOP-metoden er grundlaget for Hintsas egen metodologi til adfærdsændring. Den dækker fire elementer:
* Ønske (Wish)
* Resultat (Outcome)
* Forhindring (Obstacle)
* Plan (Plan)`,
                et: `
WOOP-meetod on Hintsa enda käitumise muutmise metoodika aluseks. See hõlmab nelja elementi:
* Soov (Wish)
* Tulem (Outcome)
* Takistus (Obstacle)
* Plaan (Plan)`,
                de: `
Die WOOP-Methode ist die Grundlage für Hintsas eigene Methodik zur Verhaltensänderung. Sie umfasst vier Elemente:
* Wunsch (Wish)
* Ergebnis (Outcome)
* Hindernis (Obstacle)
* Plan (Plan)`,
                pl: `
Metoda WOOP jest podstawą własnej metodologii zmiany zachowania firmy Hintsa. Obejmuje cztery elementy:
* Życzenie (Wish)
* Wynik (Outcome)
* Przeszkoda (Obstacle)
* Plan (Plan)`,
                zh: `
WOOP方法是Hintsa自己的行为改变方法的基础。它涵盖四个要素：
* 愿望 (Wish)
* 结果 (Outcome)
* 障碍 (Obstacle)
* 计划 (Plan)`,
                pt: `
O método WOOP é a base da metodologia de mudança comportamental da Hintsa. Abrange quatro elementos:
* Desejo (Wish)
* Resultado (Outcome)
* Obstáculo (Obstacle)
* Plano (Plan)`,
                es: `
El método WOOP es la base de la propia metodología de cambio de comportamiento de Hintsa. Cubre cuatro elementos:
* Deseo (Wish)
* Resultado (Outcome)
* Obstáculo (Obstacle)
* Plan (Plan)`,
                fr: `
La méthode WOOP est la base de la méthodologie de changement de comportement de Hintsa. Elle couvre quatre éléments :
* Souhait (Wish)
* Résultat (Outcome)
* Obstacle (Obstacle)
* Plan (Plan)`
              },
              coach_corner: {
                en: "Coach Corner",
                fi: "Valmentajan Kulma",
                sv: "Coach Corner",
                no: "Coach Corner",
                da: "Coach Corner",
                et: "Treeneri nurk",
                de: "Trainer-Ecke",
                pl: "Kącik trenera",
                zh: "教练角",
                pt: "Canto do treinador",
                es: "Rincón del entrenador",
                fr: "Coin du coach"
              },
              coach_corner_details: {
                en: "Short strategies, tricks, and mythbusting from Hintsa coaches and specialists.",
                fi: "Lyhyitä strategioita, niksejä ja myyttien purkua Hintsa-valmentajilta ja asiantuntijoilta.",
                sv: "Korta strategier, tips och mytbrytning från Hintsas coachar och specialister.",
                no: "Korte strategier, triks og myteknusing fra Hintsas trenere og spesialister.",
                da: "Korte strategier, tricks og mytebusting fra Hintsas trænere og specialister.",
                et: "Lühikesed strateegiad, nipid ja müütide murdmine Hintsa treeneritelt ja spetsialistidelt.",
                de: "Kurze Strategien, Tricks und Mythenbusting von Hintsas Trainern und Spezialisten.",
                pl: "Krótkie strategie, triki i obalanie mitów od trenerów i specjalistów Hintsa.",
                zh: "来自Hintsa教练和专家的简短策略、技巧和破除迷信。",
                pt: "Estratégias curtas, truques e desmistificações de treinadores e especialistas da Hintsa.",
                es: "Estrategias cortas, trucos y desmitificación de entrenadores y especialistas de Hintsa.",
                fr: "Courtes stratégies, astuces et démystification des coachs et spécialistes de Hintsa."
              },
              coach_message_1: {
                en: "Top nutritional strategies for better sleep",
                fi: "Parhaat ravitsemusstrategiat parempaan uneen",
                sv: "Bästa näringsstrategierna för bättre sömn",
                no: "Topp ernæringsstrategier for bedre søvn",
                da: "Top ernæringsstrategier for bedre søvn",
                et: "Parimad toitumisstrateegiad parema une jaoks",
                de: "Top Ernährungsstrategien für besseren Schlaf",
                pl: "Najlepsze strategie żywieniowe na lepszy sen",
                zh: "改善睡眠的顶级营养策略",
                pt: "Principais estratégias nutricionais para melhor sono",
                es: "Las mejores estrategias nutricionales para dormir mejor",
                fr: "Meilleures stratégies nutritionnelles pour mieux dormir"
              },
              coach_message_2: {
                en: "Mythbusting: What is ‘good posture’?",
                fi: "Myyttien murtaminen: Millainen on 'hyvä asento'?",
                sv: "Mytbrytning: Vad är 'god hållning'?",
                no: "Myteknusing: Hva er ‘god holdning’?",
                da: "Mytebusting: Hvad er 'god kropsholdning'?",
                et: "Müütide purustamine: Mis on ‘hea rüht’?",
                de: "Mythenbusting: Was ist „gute Haltung“?",
                pl: "Obalanie mitów: Co to jest 'dobra postura'?",
                zh: "破除迷思：什么是“良好姿势”？",
                pt: "Desmistificando: O que é 'boa postura'?",
                es: "Desmitificación: ¿Qué es la ‘buena postura’?",
                fr: "Démystification : Qu'est-ce que la « bonne posture » ?"
              },
              coach_message_3: {
                en: "The cortisol curve and how to improve focus",
                fi: "Kortisolikäyrä ja kuinka parantaa keskittymistä",
                sv: "Kortisolkurvan och hur man förbättrar fokus",
                no: "Kortisolkurven og hvordan forbedre fokus",
                da: "Kortisolkurven og hvordan man forbedrer fokus",
                et: "Kortisoolikõver ja kuidas keskendumisvõimet parandada",
                de: "Die Cortisolkurve und wie man den Fokus verbessert",
                pl: "Krzywa kortyzolu i jak poprawić koncentrację",
                zh: "皮质醇曲线及如何提高注意力",
                pt: "A curva do cortisol e como melhorar o foco",
                es: "La curva del cortisol y cómo mejorar el enfoque",
                fr: "La courbe du cortisol et comment améliorer la concentration"
              },
              coach_message_4: {
                en: "Explore your Core and a life of meaning",
                fi: "Tutki Ydintäsi ja merkityksellistä elämää",
                sv: "Utforska din Kärna och ett meningsfullt liv",
                no: "Utforsk din kjerne og et meningsfylt liv",
                da: "Udforsk din kerne og et meningsfuldt liv",
                et: "Uurige oma tuuma ja tähendusrikast elu",
                de: "Erkunden Sie Ihren Kern und ein sinnvolles Leben",
                pl: "Odkryj swój Rdzeń i życie pełne sensu",
                zh: "探索你的核心和有意义的生活",
                pt: "Explore seu núcleo e uma vida de significado",
                es: "Explora tu núcleo y una vida con sentido",
                fr: "Explorez votre noyau et une vie pleine de sens"
              }
            },
            CustomAreaMasterClass: {
              heading1: {
                en: "Master the modes of work & life.",
                fi: "Master the modes of work & life.",
                sv: "Master the modes of work & life.",
                no: "Master the modes of work & life.",
                da: "Master the modes of work & life.",
                et: "Master the modes of work & life.",
                de: "Master the modes of work & life.",
                pl: "Master the modes of work & life.",
                zh: "Master the modes of work & life.",
                pt: "Master the modes of work & life.",
                es: "Master the modes of work & life.",
                fr: "Master the modes of work & life."
              },
              heading2: {
                en: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                fi: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                sv: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                no: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                da: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                et: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                de: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                pl: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                zh: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                pt: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                es: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn...",
                fr: "Beat exhaustion. Build balance. Transcend pressure. Methods proven in Formula 1 and Fortune 500 - distilled in an online masterclass. You’ll learn..."
              },
              content: {
                en: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                fi: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                sv: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                no: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                da: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                et: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                de: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                pl: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                zh: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                pt: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                es: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`,
                fr: `
* ...to identify 3 signature modes of work and life
* ...personal strategies for building your style of sustainable high performance
* ...managerial tools for leading energy and wellbeing in a team

**Get a special offer at checkout with the code ASSESSMENT.**`
              },
              sign_up: {
                en: "Learn more",
                fi: "Learn more",
                sv: "Learn more",
                no: "Learn more",
                da: "Learn more",
                et: "Learn more",
                de: "Learn more",
                pl: "Learn more",
                zh: "Learn more",
                pt: "Learn more",
                es: "Learn more",
                fr: "Learn more"
              }
            },
            ReportSectionFooter: {
              heading1: {
                en: "Better life.",
                fi: "Parempi elämä.",
                sv: "Bättre liv.",
                no: "Bedre liv.",
                da: "Bedre liv.",
                et: "Parem elu.",
                de: "Besseres Leben.",
                pl: "Lepsze życie.",
                zh: "更好的生活。",
                pt: "Vida melhor.",
                es: "Mejor vida.",
                fr: "Meilleure vie."
              },
              heading2: {
                en: "Better performance.",
                fi: "Parempi suorituskyky.",
                sv: "Bättre prestation.",
                no: "Bedre prestasjon.",
                da: "Bedre præstation.",
                et: "Parem sooritus.",
                de: "Bessere Leistung.",
                pl: "Lepsza wydajność.",
                zh: "更好的表现。",
                pt: "Melhor desempenho.",
                es: "Mejor rendimiento.",
                fr: "Meilleure performance."
              },
              content1: {
                en: "Hintsa is a world-leading wellbeing and coaching company that helps you achieve sustainable high performance — with methods proven in Formula 1 and Fortune 500.",
                fi: "Hintsa on maailman johtava hyvinvointi- ja valmennusyritys, joka auttaa sinua saavuttamaan kestävän korkean suorituskyvyn — Formula 1:ssä ja Fortune 500:ssa todennetuilla menetelmillä.",
                sv: "Hintsa är ett världsledande välbefinnande- och coachningsföretag som hjälper dig att uppnå hållbar hög prestation — med metoder beprövade i Formel 1 och Fortune 500.",
                no: "Hintsa er et verdensledende velvære- og coachingfirma som hjelper deg med å oppnå bærekraftig høy ytelse — med metoder bevist i Formel 1 og Fortune 500.",
                da: "Hintsa er en verdensførende velvære- og coachingvirksomhed, der hjælper dig med at opnå bæredygtig høj ydeevne — med metoder bevist i Formel 1 og Fortune 500.",
                et: "Hintsa on maailma juhtiv heaolu- ja treeningfirma, mis aitab teil saavutada jätkusuutlikku kõrget jõudlust – meetoditega, mis on tõestatud Formula 1 ja Fortune 500-s.",
                de: "Hintsa ist ein weltweit führendes Wohlfühl- und Coachingunternehmen, das Ihnen hilft, nachhaltige Höchstleistungen zu erzielen – mit Methoden, die in der Formel 1 und bei Fortune 500 bewiesen sind.",
                pl: "Hintsa to wiodąca na świecie firma zajmująca się dobrostanem i coachingiem, która pomaga osiągnąć zrównoważoną wysoką wydajność — metodami sprawdzonymi w Formule 1 i Fortune 500.",
                zh: "Hintsa 是一家全球领先的健康与教练公司，帮助您实现可持续的高绩效——采用在 F1 和财富 500 强中经过验证的方法。",
                pt: "A Hintsa é uma empresa líder mundial em bem-estar e coaching que ajuda você a alcançar um alto desempenho sustentável — com métodos comprovados na Fórmula 1 e Fortune 500.",
                es: "Hintsa es una empresa líder mundial en bienestar y coaching que te ayuda a lograr un alto rendimiento sostenible, con métodos probados en la Fórmula 1 y Fortune 500.",
                fr: "Hintsa est une entreprise de bien-être et de coaching de renommée mondiale qui vous aide à atteindre des performances élevées durables - avec des méthodes éprouvées en Formule 1 et chez Fortune 500."
              },
              content2: {
                en: "Re-write your story with us.",
                fi: "Kirjoita tarinasi uudelleen kanssamme.",
                sv: "Skriv om din berättelse med oss.",
                no: "Skriv om historien din med oss.",
                da: "Skriv din historie om med os.",
                et: "Kirjutage oma lugu meiega uuesti.",
                de: "Schreiben Sie Ihre Geschichte mit uns neu.",
                pl: "Napisz swoją historię na nowo z nami.",
                zh: "与我们一起重写你的故事。",
                pt: "Reescreva sua história conosco.",
                es: "Reescribe tu historia con nosotros.",
                fr: "Réécrivez votre histoire avec nous."
              },
              download_report: {
                en: "Download your report",
                fi: "Lataa raporttisi",
                sv: "Ladda ner din rapport",
                no: "Last ned rapporten din",
                da: "Download din rapport",
                et: "Laadige alla oma aruanne",
                de: "Laden Sie Ihren Bericht herunter",
                pl: "Pobierz swój raport",
                zh: "下载你的报告",
                pt: "Baixe seu relatório",
                es: "Descarga tu informe",
                fr: "Téléchargez votre rapport"
              }
            },
            ReportTopBanner: {
              download: {
                da: "Download",
                de: "Herunterladen",
                en: "Download",
                et: "Laadi alla",
                fi: "Lataa",
                no: "Last ned",
                sv: "Ladda ner",
                pl: "Pobierz",
                es: "Descargar",
                fr: "Télécharger",
                pt: "Descarregar",
                zh: "下载"
              }
            },
            ReportSelector: {
              previous_version: {
                da: "Forrige version",
                de: "Vorherige Version",
                en: "Previous version",
                et: "Eelmine versioon",
                fi: "Edellinen versio",
                no: "Forrige versjon",
                sv: "Föregående version",
                pl: "Poprzednia wersja",
                es: "Versión anterior",
                fr: "Version précédente",
                pt: "Versão anterior",
                zh: "上一个版本"
              },
              no_previous_version: {
                en: "No previous report",
                de: "Kein vorheriger Bericht",
                da: "Ingen tidligere rapport",
                et: "Eelmine aruanne puudub",
                fi: "Ei edellistä raporttia",
                no: "Ingen tidligere rapport",
                sv: "Ingen tidigare rapport",
                pl: "Brak poprzedniego raportu",
                es: "No hay informes anteriores",
                fr: "Aucun rapport précédent",
                pt: "Sem relatório anterior",
                zh: "没有以前的报告"
              },
              older: {
                en: "Older",
                fi: "Vanhempi",
                sv: "Äldre",
                no: "Eldre",
                da: "Ældre",
                et: "Vanem",
                de: "Älter",
                pl: "Starszy",
                zh: "更老",
                pt: "Mais velho",
                es: "Mayor",
                fr: "Plus âgé"
              },
              latest: {
                da: "Seneste",
                de: "Neueste",
                en: "Latest",
                et: "Viimane",
                fi: "Uusin",
                no: "Siste",
                sv: "Senaste",
                pl: "Najnowsza",
                es: "Última",
                fr: "Dernière",
                pt: "Mais recente",
                zh: "最新"
              }
            },
            ReportPageView: {
              download_pdf: {
                da: "Download PDF",
                de: "PDF herunterladen",
                en: "Download PDF",
                et: "Laadi alla PDF",
                fi: "Lataa PDF",
                no: "Last ned PDF",
                sv: "Ladda ner PDF",
                pl: "Pobierz plik PDF",
                es: "Descargar PDF",
                fr: "Télécharger le PDF",
                pt: "Descarregar PDF",
                zh: "下载 PDF"
              },
              loading: {
                en: "Loading...",
                fi: "Ladataan...",
                es: "Cargando...",
                da: "Indlæser...",
                de: "Wird geladen ...",
                et: "Laadimine...",
                no: "Laster ...",
                pl: "Ładowanie...",
                sv: "Laddar ...",
                fr: "Chargement…",
                pt: "A carregar...",
                zh: "正在加载……"
              },
              title: {
                da: "Velfærdsrapport",
                de: "Bericht über Ihr persönliches Wohlbefinden",
                en: "Wellbeing Report",
                et: "Heaoluaruanne",
                fi: "Hyvinvointiraportti",
                no: "Velværerapport",
                sv: "Hälsorapport",
                pl: "Raport dotyczący samopoczucia",
                es: "Informe sobre bienestar",
                fr: "Rapport de mieux-être",
                pt: "Relatório de bem-estar",
                zh: "健康报告"
              },
              follow_up_subtitle: {
                da: "Denne rapport sammenligner dine svar med din sidste velværeselvevaluering, som blev afsluttet den {0}.",
                de: "Dieser Bericht vergleicht Ihre Antworten mit der letzten Selbsteinschätzung Ihres Wohlbefindens, die Sie am {0} vorgenommen haben.",
                en: "This report compares your answers to your last wellbeing self-assessment, completed on {0}.",
                et: "Selles aruandes võrreldakse sinu vastuseid eelmise enesehinnangu vastustega, mille täitsid {0}",
                fi: "Tässä raportissa vastauksiasi verrataan edelliseen hyvinvoinnin itsearviointiin, jonka teit {0}.",
                no: "Denne rapporten sammenligner svarene dine med din siste egenvurdering av velvære, fullført den {0}.",
                sv: "I rapporten jämför vi dina svar med din senaste självutvärdering av ditt välbefinnande, som du gjorde {0}.",
                pl: "Raport zawiera porównanie Twoich aktualnych odpowiedzi z ostatnią samooceną samopoczucia z dnia {0}.",
                es: "Este informe compara sus respuestas con su última autoevaluación de bienestar, realizada el {0}.",
                fr: "Ce rapport compare vos réponses à celles fournies lors de votre plus récente auto-évaluation du bien-être, réalisée le {0}.",
                pt: "Este relatório compara as suas respostas à última autoavaliação de bem-estar, realizada a {0}.",
                zh: "本报告将对您的回答与您上次在 {0} 完成的健康自评进行比较。"
              },
              show_history_data: {
                en: "Show comparison to previous result",
                fi: "Näytä vertailu edelliseen tulokseen",
                es: "Mostrar comparación con el resultado anterior",
                da: "Vis sammenligning med tidligere resultat",
                de: "Vergleich zum vorherigen Ergebnis anzeigen",
                et: "Näita võrdlust eelmise tulemusega",
                no: "Vis sammenligning med tidligere resultater",
                pl: "Pokaż porównanie z poprzednim wynikiem",
                ru: "Сравнить с предыдущим результатом",
                sv: "Visa jämförelse med tidigare resultat",
                fr: "Afficher la comparaison par rapport au résultat précédent",
                pt: "Mostrar comparação com o resultado anterior",
                zh: "与先前的结果进行对比"
              }
            },
            SectionCoBLView: {
              title: {
                da: "Elementspecifikke resultater",
                de: "Bereichspezifische Ergebnisse ",
                en: "Element specific results",
                et: "Kategooriale eriomaste tulemuste üksikasjad",
                fi: "Yksityiskohtaiset tulokset osa-alueittain",
                no: "Elementspesifikke resultater",
                sv: "Elementspecifika resultat",
                pl: "Szczegółowe wyniki dotyczące poszczególnych elementów",
                ru: "Результаты по элементам в деталях",
                es: "Resultados detallados por elementos específicos",
                fr: "Résultats détaillés pour chaque élément",
                pt: "Resultados específicos de cada elemento em pormenor",
                zh: "专项结果详细说明"
              }
            },
            SectionGoalsToActionsView: {
              ingress: {
                da: "Nu hvor du har fastlagt relevante og realistiske mål, er det tid til at identificere de nødvendige trin for at nå dem. Hintsas holistiske model dækker flere områder af dit liv, men husk, at det kan blive svært at ændre noget ved at fokusere på for mange ting på samme tid. Stræb efter at finde de områder, der er nemmest at starte med og giver de største gevinster.",
                de: "Als Nächstes müssen Sie konkrete Schritte zu Ihrem Ziel festlegen. Das ganzheitliche Modell von Hintsa umfasst 7 Bereiche, aber wenn man an zu vielen gleichzeitig arbeitet, können Veränderungen nur schwer umzusetzen sein. Streben Sie nach Fortschritt, nicht nach Perfektion.",
                en: "Next, it’s time to identify concrete steps to your goal. Hintsa’s holistic model covers 7 elements, but working on too many at once can make change difficult. Aim for progress, not perfection.",
                et: "Nüüd, kui olete seadnud asjakohased ja realistlikud eesmärgid, on aeg tuvastada nende saavutamiseks vajalikud tegevused. Hintsa terviklik mudel hõlmab mitut teie eluaspekti, kuid pidage meeles, et korraga liiga paljudele asjadele keskendumine võib raskendada muudatuste tegemist. Proovige leida valdkonnad, millega on lihtsam alustada ja kus saab saavutada suurimaid võite.",
                fi: "Nyt kun olet asettanut asiaankuuluvat ja realistiset tavoitteet, on aika tunnistaa tarvittavat toimenpiteet niiden saavuttamiseksi. Hintsan kokonaisvaltainen malli kattaa useita elämäsi osa-alueita, mutta muista, että keskittyminen liian moniin asioihin samanaikaisesti voi vaikeuttaa muutosten tekemistä. Pyri löytämään ne alueet, joista on helpoin aloittaa ja saavuttaa parhaimmat tulokset.",
                no: "Nå som du har satt relevante og realistiske mål, er det på tide å identifisere stegene som må tas for å oppnå dem. Hintsas holistiske modell dekker flere aspekter av livet ditt, men husk at å fokusere på for mange ting samtidig kan gjøre endring vanskelig. Prøv å finne de områdene som er enklest å starte med, og til å produsere de største seirene.",
                sv: "Nu när du har satt upp relevanta och realistiska mål är det dags att identifiera de steg som krävs för att uppnå dem. Hintsas helhetsmodell täcker flera aspekter av ditt liv, men kom ihåg att fokus på för många saker samtidigt kan göra förändringar svåra. Sikta mot att hitta de områden som är lättast att börja med och ger de största vinsterna.",
                pl: "Wyznaczyłeś/wyznaczyłaś sobie istotne i realistyczne cele, a teraz nadszedł czas na określenie kroków potrzebnych do osiągnięcia tych celów. Holistyczny model Hintsa obejmuje kilka aspektów Twojego życia, ale pamiętaj o tym, że skupienie się jednocześnie na zbyt wielu rzeczach może utrudniać sprawy. Dąż do znalezienia takich obszarów, od których będzie Ci najłatwiej zacząć i uzyskać najlepsze rezultaty.",
                ru: "Теперь после определения релевантных и реалистичных целей самое время выявить шаги, необходимые для их достижения. Комплексная модель Hintsa охватывает несколько аспектов вашей жизни, но помните, что, уделяя одновременно внимание слишком многим аспектам, вы можете столкнуться с трудностями при осуществлении изменений. Стремитесь найти области, с которых легче всего начать, и которые приносят самую большую выгоду.",
                es: "Ahora es el momento de identificar pasos concretos hacia su objetivo. El modelo holístico de Hintsa abarca 7 elementos, pero trabajar en demasiados a la vez puede dificultar el cambio. Aspire al progreso, no a la perfección.",
                fr: "Ensuite, il est temps d’identifier des étapes concrètes pour atteindre votre objectif. Le modèle holistique de Hintsa couvre 7 éléments, mais il peut être contre-productif de travailler sur trop d’éléments à la fois. Visez la progression, et non la perfection.",
                pt: "De seguida, é altura de identificar medidas concretas para alcançar o seu objetivo. O modelo holístico Hintsa aborda 7 elementos, mas trabalhar em demasiados elementos ao mesmo tempo pode tornar a mudança mais difícil. O objetivo é o progresso, não a perfeição.",
                zh: "接下来，就该确定实现目标的具体步骤了。Hintsa 的整体训练模式包含 7 个维度，但同时进行过多维度的训练会使训练难以坚持、改变难以实现。请以逐步的改善为目标，而不是完美。"
              },
              strengths_description: {
                da: "For at nå dine mål kræves det ikke kun, at du behandler dine svagheder, men også at du bevarer og videreudvikler dine stærke sider. Nedenfor kan du finde et par eksempler på positive resultater i din vurdering. Overvej, om et af disse, eller måske noget andet, er en vigtig styrke, som du vil fortsætte med at fokusere på.",
                de: "Wir neigen dazu, uns auf unsere Schwächen zu konzentrieren. Aber um ein Ziel zu erreichen, sollten wir mehr auf unsere Stärken setzen. Betrachten Sie diese positiven Ergebnisse aus Ihrer Bewertung – können Sie darauf aufbauen?",
                en: "We tend to focus on our weaknesses. But achieving a goal often involves building on our strengths. Consider these positive results from your assessment - can you build on them?",
                et: "Eesmärkide saavutamine ei eelda mitte ainult oma nõrkustega tegelemist, vaid ka oma tugevuste säilitamist ja arendamist. Allpool on toodud mõni näide teie hindamise positiivsetest tulemustest. Mõelge, kas üks neist või ehk midagi muud on peamine tugevus, millele soovite ka edaspidi keskenduda.",
                fi: "Tavoitteiden saavuttaminen edellyttää paitsi heikkouksien käsittelemistä myös vahvuuksien ylläpitämistä ja kehittämistä. Alla on muutamia esimerkkejä positiivisista tuloksista arvioinnissasi. Mieti, onko jokin näistä, vai jokin muu, sellainen keskeinen vahvuus, johon keskittymistä haluat jatkaa.",
                no: "Å oppnå dine mål krever ikke bare å ta fatt i svakhetene dine, men også å opprettholde og bygge videre på dine sterke sider. Nedenfor finner du noen eksempler på positive resultater i vurderingen din. Tenk på om én av disse, eller kanskje noe annet, er en nøkkelstyrke som du ønsker å fortsette å fokusere på.",
                sv: "Att uppnå dina mål kräver inte bara att ta itu med dina svagheter, utan också att behålla och bygga vidare på dina styrkor. Nedan hittar du några exempel på positiva resultat i din bedömning. Fundera på om ett av dessa, eller kanske något annat, är en viktig styrka som du vill fortsätta att fokusera på.",
                pl: "Aby osiągnąć swoje cele, musisz nie tylko popracować nad swoimi słabościami, ale również musisz utrzymywać i wzmacniać swoje mocne strony. Poniżej znajdziesz kilka przykładów pozytywnych rezultatów z Twojej oceny. Zastanów się, czy chcesz się skupiać na jednym z nich, lub być może na innym, który jest dla Ciebie istotną mocną stroną.",
                ru: "Для достижения поставленных целей требуется понимать свои слабые стороны, а также поддерживать и использовать свои сильные стороны. Ниже приводится несколько примеров положительных результатов в вашей оценке. Подумайте, можете ли вы использовать одну из этих или, возможно, другую характеристику в качестве своей основной сильной стороны и уделять ей основное внимание.",
                es: "Tendemos a centrarnos en nuestras debilidades. Pero alcanzar un objetivo implica, a menudo, aprovechar nuestros nuestras fortalezas. Considere estos resultados positivos de su evaluación: ¿puede seguir avanzando a partir de ellos?",
                fr: "Nous avons tendance à nous concentrer sur nos faiblesses. Toutefois, pour atteindre un objectif, il faut souvent s’appuyer sur ses points forts. Analysez les résultats positifs de votre évaluation, pouvez-vous en tirer parti ?",
                pt: "Temos tendência para nos focarmos nas nossas fraquezas e dificuldades. Mas atingir um objetivo implica, muitas vezes, focarmo-nos nos nossos pontos fortes. Considere estes resultados positivos da sua avaliação - consegue usá-los como ponto de partida?",
                zh: "我们倾向于关注自己的弱项。但是，实现目标往往需要发挥我们的优势。评估后，想想所取得的各项积极成果——您能在此基础上再接再厉吗？"
              },
              strengths_title: {
                da: "Brug dine stærke sider",
                de: "Nutzen Sie Ihre Stärken",
                en: "Use your strengths",
                et: "Kasutage oma tugevusi",
                fi: "Käytä vahvuuksiasi",
                no: "Utnytt dine sterke sider",
                sv: "Använd dina styrkor",
                pl: "Wykorzystaj swoje mocne strony",
                ru: "Используйте свои сильные стороны.",
                es: "Utilice sus fortalezas",
                fr: "Utilisez vos points forts",
                pt: "Utilize os seus pontos fortes",
                zh: "发挥您的优势"
              },
              title: {
                da: "Fra mål til handling",
                de: "Von Zielen zum Handeln",
                en: "From Goals to Actions",
                et: "Eesmärkidest tegudeni",
                fi: "Tavoitteista tekoihin",
                no: "Fra mål til handling",
                sv: "Från mål till handling",
                pl: "Od celów do działania",
                ru: "От целей к действию",
                es: "De los objetivos a las acciones",
                fr: "Des objectifs aux actions",
                pt: "Dos objetivos às ações",
                zh: "基于目标，开始行动"
              },
              weaknesses_description: {
                da: "Store ting opnås ved at gøre små ting konsekvent godt. Find et eller to prioriterede områder, og find et passende næste skridt for dem. Overvej de forhindringer, som du tænkte på tidligere, og find noget, der passer til dine evner, muligheder og motivation. Baseret på dine resultater er her et par potentielle områder, som du kan fokusere på.",
                de: "Große Erfolge entstehen aus kleinen Dingen, die konsequent gut gemacht werden. Legen Sie ein bis zwei Schwerpunktbereiche und konkrete Maßnahmen fest, die Ihren Fähigkeiten, Möglichkeiten und Ihrer Motivation entsprechen. Basierend auf Ihren Ergebnissen finden Sie hier einige Bereiche, auf die Sie sich konzentrieren könnten:",
                en: "Big achievements grow from small things done consistently well. Identify 1-2 priority areas and concrete actions that match your capability, opportunity, and motivation. Based on your results, here are a few possible areas to focus on:",
                et: "Suurte asjade saavutamiseks tuleb teha väikseid asju järjekindlalt ja hästi. Määrake kindlaks üks või kaks prioriteetset valdkonda ja leidke neile sobivad järgmised tegevused. Pidage meeles varem kaalutud takistusi ning leidke midagi, mis vastab teie võimetele, võimalustele ja motivatsioonile. Toome teie vastuste põhjal välja paar valdkonda, millele keskenduda.",
                fi: "Isot asiat saavutetaan pienillä toimilla, jotka tehdään johdonmukaisesti hyvin. Tunnista yksi tai kaksi ensisijaista aluetta ja etsi niitä varten sopiva seuraava askel. Huomioi aiemmin pohtimasi esteet ja valitse jotain, joka vastaa kykyjäsi, mahdollisuuksiasi ja motivaatiotasi. Tässä on muutamia mahdollisia alueita, joihin voit tulosten perusteella keskittyä.",
                no: "Store ting oppnås ved å gjøre små ting bra på en konsekvent måte. Identifiser ett eller to prioritetsområder og finn passende fremgangsmåter for å nå dem. Husk at hindringene du tenkte på tidligere, og finn noe som passer til dine evner, muligheter og motivasjon. Basert på resultatene dine, er noen mulige områder å fokusere på.",
                sv: "Stora saker uppnås genom små saker som görs konsekvent bra. Identifiera ett eller två prioriterade områden och hitta lämpligt nästa steg för dem. Tänk på de hinder som du reflekterat över tidigare och hitta något som matchar din förmåga, möjlighet och motivation. Baserat på dina resultat, här är några möjliga områden att fokusera på.",
                pl: "Duże cele są osiągane poprzez systematyczne prawidłowe wykonywanie niewielkich kroków. Wskaż jeden lub dwa obszary priorytetowe i znajdź dla nich odpowiednie następne kroki. Pamiętaj o przeszkodach, o których wcześniej była mowa i znajdź coś, co odpowiada Twoim zdolnościom, możliwościom i Twojej motywacji. Oto kilka możliwych obszarów, na których powinieneś/powinnaś się skoncentrować (na podstawie Twoich wyników).",
                ru: "Больших результатов можно достичь, стабильно хорошо выполняя небольшие задачи. Выявите 1-2 приоритетных направления и определите соответствующий следующий шаг. Помните о тех обстоятельствах, о которых вы размышляли ранее, и найдите то, что соответствует вашим способностям, возможностям и мотивации. В зависимости от результатов можно сконцентрироваться на нескольких возможных областях.",
                es: "Los grandes logros surgen de las pequeñas cosas bien hechas de manera constante. Identifique 1-2 áreas prioritarias y acciones concretas que se ajusten a su capacidad, brechas y motivación. En función de sus resultados, éstas son algunas posibles áreas en las que centrarse:",
                fr: "Les grandes réalisations naissent de petites choses bien faites. Identifiez un à deux domaines prioritaires et des actions concrètes qui correspondent à vos capacités, à vos possibilités et à votre motivation. À partir de vos résultats, voici quelques domaines sur lesquels vous pouvez vous concentrer :",
                pt: "Para fazermos algo grande, temos de começar pelas pequenas coisas e fazê-las bem de forma consistente. Identifique 1 ou 2 áreas prioritárias e ações concretas que correspondam às suas capacidades, oportunidades e motivação. Com base nos seus resultados, eis algumas áreas possíveis em que pode concentrar-se:",
                zh: "从小事做起，不断精进，才能取得显著的成效。根据您的能力、时机和动力，确定 1-2 个优先领域和具体行动。根据您的目标，以下是几个可能需要重点关注的方面："
              },
              weaknesses_title: {
                da: "Begynd med små skridt og gå gradvist fremad",
                de: "Fangen Sie klein an und machen Sie langsam Fortschritte.",
                en: "Start small & progress gradually",
                et: "Alustage väikselt ja jätkake järk-järgult",
                fi: "Aloita pienestä, kehity asteittain",
                no: "Start forsiktig og beveg deg gradvis fremover",
                sv: "Börja smått och utveckla gradvis",
                pl: "Zacznij od małych kroków i rób stopniowe postępy",
                ru: "Начните с небольших шагов и постепенно увеличивайте масштаб.",
                es: "Empezar poco a poco y progresar gradualmente",
                fr: "Commencez doucement et avancez progressivement",
                pt: "Começar com pequenos passos e progredir gradualmente",
                zh: "从小处着手，循序渐进"
              }
            },
            SectionHealthAndWellbeingView: {
              change_capability: {
                da: "Evne til at ændre sig",
                de: "Fähigkeit zur Veränderung",
                en: "Capability to change",
                et: "Suutlikkus muutuda",
                fi: "Kyky muutokseen",
                no: "Evne til endring",
                sv: "Möjlighet att förändras",
                es: "Capacidad de cambio",
                pl: "Zdolność do zmiany",
                ru: "Способность к изменениям",
                fr: "Capacité de changement",
                pt: "Capacidade de mudança",
                zh: "改变自己之能力"
              },
              change_motivation: {
                da: "Motivation til ændring",
                de: "Motivation zur Veränderung",
                en: "Motivation to change",
                et: "Motivatsioon muutuda",
                fi: "Motivaatio muutokseen",
                no: "Motivasjon for endring",
                sv: "Motivation till förändring",
                es: "Motivación para el cambio",
                pl: "Motywacja do zmiany",
                ru: "Мотивация к изменениям",
                fr: "Motivation au changement",
                pt: "Motivação para mudar",
                zh: "改变自己之动力"
              },
              change_need: {
                da: "Behov for ændring",
                de: "Notwendigkeit und Änderung",
                en: "Need to change",
                et: "Muutumisvajadus",
                fi: "Tarve muutokseen",
                no: "Behov for endring",
                sv: "Behöver förändras",
                es: "Necesidad de cambio",
                pl: "Potrzeba zmiany",
                ru: "Необходимость изменений",
                fr: "Nécessité de changement",
                pt: "Necessidade de mudança",
                zh: "改变自己之需要"
              },
              change_support: {
                da: "Støtte til ændring",
                de: "Unterstützung bei Veränderung",
                en: "Support to change",
                et: "Tugi muutumisel",
                fi: "Tuki muutokseen",
                no: "Støtte for endring",
                sv: "Stöd för förändring",
                es: "Apoyo al cambio",
                pl: "Wsparcie w kierunku zmiany",
                ru: "Поддержка изменений",
                fr: "Accompagnement au changement",
                pt: "Apoio à mudança",
                zh: "改变自己之支柱"
              },
              cobl_title: {
                da: "Din cirkel for et bedre liv",
                de: "Ihr Kreislauf des besseren Lebens (Circle of Better Life)",
                en: "Your Circle of Better Life",
                et: "Teie parema elu ring",
                fi: "Sinun tuloksesi",
                no: "Din sirkel for bedre liv",
                sv: "Din cirkel för bättre liv",
                pl: "Twój okrąg lepszego życia",
                ru: "Ваш круг лучшей жизни",
                es: "Su Circulo de bienestar ",
                fr: "Votre Cercle de mieux-être",
                pt: "O seu círculo de uma vida melhor",
                zh: "良性循环型生活模式"
              },
              follow_up_ingress: {
                da: "Varig, høje præstationer bygger på et fundament af sundhed og velvære. Når du overvejer forandringer i velværeelementerne, så reflekter over:",
                de: "Nachhaltige Höchstleistungen basieren auf einem Fundament aus Gesundheit und Wohlbefinden:",
                en: "Sustainable high performance is built on a foundation of health and wellbeing. As you consider changes in the wellbeing elements, reflect on:",
                et: "Püsivalt kõrge suutlikkus toetub tervisele ja heaolule. Sel ajal, kui mõtiskled muutustest, mis sinu heaolu tagavates elementides on toimunud, pööra tähelepanu järgnevale:",
                fi: "Jatkuvan korkean suorituskyvyn perustana on terveys ja hyvinvointi. Kun mietit hyvinvointielementtien muutoksia, pohdi seuraavia asioita:",
                no: "Bærekraftig høy ytelse er bygget på et grunnlag av helse og velvære. Når du vurderer endringer i velværeelementene, kan du reflektere over:",
                sv: "Hållbar hög prestanda bygger på en grund av hälsa och välbefinnande. När du överväger förändringar i välbefinnandeelementen, reflektera över:",
                pl: "Stały poziom wysokiej wydajności zależy od stanu zdrowia i dobrego samopoczucia. Przeanalizuj zmiany w stanie samopoczucia i zastanów się nad poniższym:",
                ru: "Устойчивая высокая личная эффективность базируется на здоровье и благополучии. Оценивая изменения, происходящие в отдельных элементах, из которых складывается общая картина Вашего благополучия, постарайтесь ответить на следующие вопросы:",
                es: "El alto rendimiento sostenible se fundamenta en la salud y el bienestar. Cuando considere los cambios en los elementos de bienestar, reflexione sobre:",
                fr: "De hautes performances durables reposent sur la santé et le mieux-être. Lorsque vous envisagez de modifier les éléments relatifs au bien-être, réfléchissez aux points suivants :",
                pt: "O bom desempenho consistente assenta numa base de saúde e bem-estar. Considere mudanças nos elementos de bem-estar e reflita sobre:",
                zh: "身心健康和幸福感是实现可持续卓越绩效的基础。当您细想不同健康维度的改变时，请思考："
              },
              ingress: {
                da: "Bæredygtig høj præstation er baseret på et grundlag af sundhed og velvære. Ikke omvendt. Derfor er det næste trin at identificere specifikke mål og resultatmål i forbindelse med din personlige sundhed og velvære ved hjælp af vores Circle of Better Life-model.",
                de: "Ein nachhaltig hohes Leistungsniveau im Leben generell und bei der Arbeit bedürfen einem soliden Fundament aus Gesundheit und Wohlbefinden. Ihr nächster Schritt besteht darin, ein konkretes Ziel für Ihr Wohlbefinden, Erfolgskriterien und Hindernisse zu bestimmen.",
                en: "Sustainable high performance is built on a foundation of health and wellbeing. Your next step is to identify a concrete wellbeing goal, success measures, and obstacles.",
                et: "Järjepidev hea tulemuslikkus põhineb heal tervisel ja heaolul, mitte vastupidi. Seega tuleb järgmisena selgitada välja konkreetsed eesmärgid ja edumeetmed, mis on seotud teie isikliku tervise ja heaoluga, kasutades meie parema elu ringmudelit.",
                fi: "Kestävä suorituskyky perustuu hyvään terveyteen ja yleiseen hyvinvointiin. Ei päinvastoin. Seuraava askel on tunnistaa henkilökohtaiseen terveyteesi ja hyvinvointiisi liittyvät konkreettiset tavoitteet ja niihin tähtäävät toimet Hintsan ympyrämallin kautta.",
                no: "Et bærekraftig høy prestasjonsnivå er bygget på en grunnmur av god helse og velvære. Ikke omvendt. Derfor er det neste trinnet å identifisere konkrete mål og suksesstiltak relatert til din egen helse og velvære gjennom vår Bedre livssirkel-modell.",
                sv: "Hållbar hög prestanda byggs på en grund av god hälsa och välbefinnande. Inte tvärtom. Därför är nästa steg att identifiera konkreta mål och framgångsåtgärder relaterade till din personliga hälsa och ditt välbefinnande genom vår modell Cirkel för bättre liv.",
                pl: "Trwałą wysoką wydajność można wybudować tylko na fundamencie dobrego zdrowia i samopoczucia. Nie na odwrót. Dlatego następnym etapem jest wyznaczenie konkretnych celów i miar sukcesu dotyczących Twojego zdrowia i samopoczucia, do czego posłuży nasz model Circle of Better Life.",
                ru: "Стабильная высокая производительность базируется на хорошем состоянии здоровья и хорошем самочувствии. А не наоборот. Поэтому следующий этап — определение конкретных целей и показателей успеха в связи с вашим состоянием здоровья и самочувствия в рамках нашей модели Circle of Better Life (Круг лучшей жизни).",
                es: "El alto rendimiento sostenible se fundamenta en la salud y el bienestar. El siguiente paso es identificar un objetivo concreto de bienestar, medidas de éxito y obstáculos.",
                fr: "De hautes performances durables reposent sur la santé et le mieux-être. L’étape suivante consiste à identifier un objectif concret lié au bien-être, des mesures de la réussite et les obstacles qui pourraient survenir.",
                pt: "O bom desempenho consistente assenta numa base de saúde e bem-estar. O próximo passo é identificar um objetivo concreto de bem-estar, bem como as respetivas medidas de sucesso e obstáculos.",
                zh: "身心健康和幸福感是实现可持续卓越绩效的基础。下一步是确定具体的健康目标、目标达成标准和阻碍。"
              },
              follow_up_first_reflect: {
                da: "Hvorfor har denne forandring fundet sted? Hvad har du gjort anderledes, eller hvordan har dit liv eller din kontekst ændret sig?",
                de: "Was hat diese Veränderung möglich gemacht? Was haben Sie anders gemacht, oder wie hat sich Ihr Leben oder Ihr Umfeld verändert?",
                en: "Why has this change happened? What have you done differently, or how has your life or context changed?",
                et: "Mis selle muutuse tingis? Mida oled hakanud tegema teisiti, või kuidas on vahepeal muutunud sinu elu laiem kontekst?",
                fi: "Miksi tämä muutos tapahtui? Mitä olet tehnyt toisin, tai miten elämäsi tai kontekstisi on muuttunut?",
                no: "Hvorfor har denne endringen skjedd? Hva har du gjort annerledes, eller hvordan har livet ditt eller konteksten endret seg?",
                sv: "Varför har den här förändringen skett? Vad har du gjort annorlunda, eller hur har ditt liv eller din kontext förändrats?",
                pl: "co przyczyniło się do wystąpienia tej zmiany? Co zrobiłeś(-aś) inaczej lub jak zmieniło się Twoje życie lub okoliczności?",
                ru: "Почему произошло то или иное изменение? Что Вы сделали по-другому или как изменилась Ваша жизнь или Ваше отношение к ней?",
                es: "¿Por qué se ha producido este cambio? ¿Qué ha hecho de forma diferente o cómo ha cambiado su vida o su contexto?",
                fr: "Pourquoi ce changement est-il survenu ? Qu’avez-vous fait différemment, ou comment votre vie ou votre environnement ont-ils changé ?",
                pt: "Porque é que esta mudança aconteceu? O que é que fez de forma diferente, ou como é que a sua vida ou situação mudou?",
                zh: "这种改变为什么会发生？您做了哪些与以往不同的事情，或者您的生活或环境发生了哪些变化？"
              },
              follow_up_second_reflect: {
                da: "Hvis der ikke er sket nogen forandring, selvom du håbede på det, hvilken lille ting kan du så gøre, som virkelig kan gøre en forskel?",
                de: "Wenn keine Veränderung eingetreten ist, obwohl Sie es sich erhofft haben – was könnten Sie tun, um wirklich etwas zu verändern?",
                en: "If no change has happened although you hoped for it - what's one small thing you can do that might really make a difference?",
                et: "Kui muudatusi pole võimalik täheldada, kuigi sa neid ilmselt lootsid, siis mis on see üks väike asi, mida võiksid olukorra muutmiseks teha?",
                fi: "Jos mitään muutosta ei toiveistasi huolimatta ole tapahtunut, millä pienellä toimellasi voisi olla suuri vaikutus?",
                no: "Hvis ingen endring har skjedd selv om du håpet på det – hva er en liten ting du kan gjøre som virkelig kan utgjøre en forskjell?",
                sv: "Om ingen förändring har skett trots att du hoppats på det: vilken liten sak du kan göra som verkligen kan göra skillnad?",
                pl: "jeśli nie nastąpiła oczekiwana zmiana, to jakie niewielkie działanie możesz podjąć, aby naprawdę coś zmienić?",
                ru: "Если несмотря на Ваши надежды, никаких изменений так и не произошло, что Вы можете сделать, чтобы все-таки переломить ситуацию?",
                es: "Si no se ha producido ningún cambio, aunque usted lo esperaba, ¿hay alguna pequeña acción que pueda realizar y que realmente marque la diferencia?",
                fr: "Si aucun changement ne s’est produit alors que vous l’espériez, quel petit pas pourriez-vous franchir pour amorcer le changement ?",
                pt: "Se não houve qualquer mudança, apesar de querer que ela acontecesse, qual é a pequena coisa que pode fazer que pode realmente fazer a diferença?",
                zh: "如果您希望改变，但却没有改变——您能做哪件有望真地可以改变现状的小事？"
              },
              follow_up_third_reflect: {
                da: "Hvilken vane eller rutine kan du styrke for at opretholde positive forandringer?",
                de: "Welche Angewohnheit oder Routine könnten Sie ausbauen, um positive Veränderungen beizubehalten?",
                en: "To maintain positive changes, what's a habit or routine you could strengthen?",
                et: "Positiivsete muudatuste jätkumist silmas pidades, millist harjumust või rutiini võiksid süvendada?",
                fi: "Jotta voit ylläpitää myönteisiä muutoksia, mitä tapaa tai rutiinia voisit lujittaa?",
                no: "For å opprettholde positive endringer, hva er en vane eller rutine du kan styrke?",
                sv: "Vilken vana eller rutin kan du stärka för att upprätthålla positiva förändringar?",
                pl: "który nawyk lub rutynę możesz umocnić, aby utrzymać pozytywne zmiany?",
                ru: "Какую привычку Вам следует закрепить в своем ежедневном распорядке дня для поддержания позитивных изменений?",
                es: "Para mantener los cambios positivos, ¿qué hábito o rutina podría reforzar?",
                fr: "Pour maintenir les changements positifs, quelle est l’habitude ou la routine que vous pourriez renforcer ?",
                pt: "Para manter as mudanças positivas, qual é o hábito ou rotina que poderia reforçar?",
                zh: "为了保持积极的变化，您可以强化哪些习惯或做法？"
              },
              obstacle_1: {
                da: "Hvad er et passende startniveau for dig, baseret på dine færdigheder, evner og viden?",
                de: "Fähigkeit: Was ist ein geeignetes Ausgangsniveau? Bsp.: 10 Minuten oder 1 Stunde Bewegung",
                en: "Capability: What is a suitable starting level considering your skills and experience?",
                et: "Milline on teie oskusi, võimeid ja teadmisi arvestades sobiv lähtetase?",
                fi: "Mikä on sinulle sopiva lähtötaso taitojesi, kykyjesi ja tietosi perusteella?",
                no: "Hva er et passende startnivå for deg, basert på dine ferdigheter, evner og kunnskap?",
                sv: "Vad är en lämplig startnivå för dig, baserat på dina färdigheter, förmågor och kunskaper?",
                pl: "Jaki jest odpowiedni dla Ciebie poziom początkowy (na podstawie Twoich umiejętności, możliwości i wiedzy)?",
                ru: "Какой начальный уровень вам подходит исходя из ваших навыков, способностей и знаний?",
                es: "Capacidad: ¿Cuál es el nivel inicial adecuado teniendo en cuenta sus competencias y su experiencia?",
                fr: "Capacité : Quel serait le meilleur point de départ compte tenu de vos compétences et de votre expérience ?",
                pt: "Capacidade: Qual seria o nível inicial adequado tendo em conta as suas competências e experiência?",
                zh: "能力：根据您的技能与经验，从什么水平开始比较适合您？"
              },
              obstacle_2: {
                da: "Hvordan påvirker dine sociale og fysiske omgivelser dine muligheder for at ændre dine vaner? Kan du finde en ændring, der passer til din situation i livet?",
                de: "Möglichkeit: Wie hindert oder hilft Ihnen Ihr Umfeld oder Ihre Lebenssituation? Kleine Kinder können zum Beispiel perfekten Schlaf unmöglich machen, Sie können es aber nach der Arbeit einfacher machen, sich zu entspannen.",
                en: "Opportunity: How do your environment or life situation hinder or boost your opportunities for change?",
                et: "Kuidas mõjutab teie sotsiaalne ja füüsiline keskkond võimalusi muuta oma harjumusi? Kas leiate muudatuse, mis teie eluolukorraga sobib?",
                fi: "Miten sosiaalinen ja fyysinen ympäristö vaikuttaa mahdollisuuksiisi muuttaa tapojasi? Löydätkö muutostavoitteen joka sopii elämäntilanteeseesi?",
                no: "Hvordan påvirker ditt sosiale og fysiske miljø dine muligheter for å endre vanene dine? Kan du finne en endring som passer inn i din livssituasjon?",
                sv: "Hur påverkar din sociala och fysiska miljö dina möjligheter att förändra dina vanor? Kan du hitta en förändring som passar din livssituation?",
                pl: "Jak Twoje otoczenie społeczne i fizyczne wpływa na Twoje możliwości zmiany nawyków? Czy jesteś w stanie znaleźć możliwości odpowiednie dla Twoje sytuacji życiowej?",
                ru: "Каким образом ваши социальные и физические условия влияют на ваши возможности изменять свои привычки? Можете ли вы найти возможность, соответствующую вашей жизненной ситуации?",
                es: "Oportunidad: ¿De qué manera su entorno o su contexto/circunstancias de vida obstaculizan o potencian sus oportunidades de cambio?",
                fr: "Opportunité : Comment votre environnement ou votre situation de vie entravent-ils ou favorisent-ils vos possibilités de changement ?",
                pt: "Oportunidade: Como é que o seu ambiente ou situação de vida impede, potencia ou reforça as suas oportunidades de mudança?",
                zh: "时机：您所处的环境或生活处境对您实现改变的可能性会起到多大的促进或阻碍效果？"
              },
              obstacle_3: {
                da: "Hvor motiveret er du til at ændre dig og nå dine mål? Hvad er kilden til denne motivation? Kan du finde et engagementsniveau, der passer til din motivation?",
                de: "Motivation: Wie motiviert sind Sie - wirklich? Was ist die Quelle Ihrer Motivation? Zum Beispiel: Abnehmen, um besser auszusehen oder um für die nächste Generation da zu sein",
                en: "Motivation: How motivated are you - really? What is the source of that motivation?",
                et: "Kui suur on teie motivatsioon oma eesmärke muuta ja saavutada? Mis on selle motivatsiooni allikas? Kas leiate oma motivatsiooniga sobiva pühendumuse taseme?",
                fi: "Kuinka motivoitunut olet muuttumaan ja saavuttamaan tavoitteesi? Mikä on tämän motivaation lähde? Löydätkö motivaatiotasi vastaavan sitoutumistason?",
                no: "Hvor motivert er du for å endre deg og oppnå målene dine? Hva er kilden til denne motivasjonen? Kan du finne et engasjementsnivå som tilsvarer motivasjonen din?",
                sv: "Hur motiverad är du till förändring och att nå dina mål? Vad är källan till den motivationen? Kan du hitta ett nivå av engagemang som matchar din motivation?",
                pl: "Jaką masz motywację do tego, aby się zmienić i osiągnąć cele? Jakie jest źródło tej motywacji? Czy jesteś w stanie określić poziom zaangażowania, który odpowiada Twojej motywacji?",
                ru: "Насколько вы мотивированы измениться и достичь поставленных целей? Что является источником такой мотивации? Можете ли вы найти уровень готовности, который соответствует вашему уровню мотивации?",
                es: "Motivación: ¿Cuál es su grado real de motivación? ¿Cuál es la fuente de esa motivación?",
                fr: "Motivation : Êtes-vous réellement motivé(e) ? Quelle est la source de cette motivation ?",
                pt: "Motivação: Qual é o seu nível REAL de motivação? Qual é a fonte dessa motivação?",
                zh: "动力：您的积极性到底有多高？这种动力的来源是什么？"
              },
              obstacles_description: {
                da: "Lad os derefter overveje mulige hindringer, der kan påvirke dine mål og dine planer for at opnå dem. Tænk på dine mål og tiltag. Er de realistiske? Hvad har du gjort før for at nå dem? Hvad har virket, og hvad har ikke virket?\n\nAdfærdsændringer er tæt forbundet med tre afgørende faktorer: evne, mulighed og motivation. Før du går videre til din konkrete handlingsplan, bør du overveje følgende:",
                de: "Lassen Sie uns als Nächstes mögliche Hindernisse für das Erreichen Ihres Ziels betrachten. Wenn Sie in der Vergangenheit erfolgreiche Veränderungen vorgenommen haben, was hat funktioniert und was nicht? In welchen Situationen könnten Sie Rückschläge erleiden? Beachten Sie die drei Schlüsselfaktoren der Verhaltensänderung:",
                en: "Next, let’s consider possible obstacles to achieving your goal. If you've made successful changes in the past, what worked and what didn't? In what situations might you face setbacks? Consider the three key factors of behaviour change:",
                et: "Järgmisena mõtleme võimalike takistuste peale, mis võivad mõjutada teie eesmärke ja nende saavutamise plaane. Mõelge oma eesmärkidele ja meetmetele. Kas need on realistlikud? Mida olete varem teinud, et neid saavutada? Mis on toiminud ja mis mitte?\n\nKäitumise muutumine on tihedalt seotud kolme põhiteguriga: suutlikkus, võimalus ja motivatsioon. Enne konkreetse tegevuskava juurde asumist kaaluge järgmist.",
                fi: "Seuraavaksi pohdi mahdollisia esteitä, jotka voivat vaikuttaa tavoitteidesi saavuttamiseen. Mieti tavoitteitasi ja mittareitasi. Ovatko ne realistisia? Mitä olet aikaisemmin tehnyt niiden saavuttamiseksi? Mikä on toiminut, mikä ei ole toiminut?\n\nKäyttäytymisen muutos liittyy läheisesti kolmeen keskeiseen tekijään: kykyihin, mahdollisuuksiin ja motivaatioon. Ennen kuin siirryt konkreettiseen toimintasuunnitelmaan, huomioi seuraavat asiat:",
                no: "La oss så se på mulige hindringer, som kan påvirke målene dine og planene du har for å nå dem. Tenk på målene og tiltakene dine. Er de realistiske? Hva har du gjort tidligere for å oppnå dem? Hva har fungert og hva har ikke fungert?\n\nAdferdsendring er nært beslektet med tre nøkkelfaktorer: evne, mulighet og motivasjon. Tenk på følgende før du går videre til den konkrete handlingsplanen:",
                sv: "Låt oss nu överväga möjliga hinder som kan påverka dina mål och dina planer för att uppnå dem. Tänk på dina mål och åtgärder. Är de realistiska? Vad har du gjort tidigare för att uppnå dem? Vad har fungerat och vad har inte fungerat?\n\nBeteendeförändring är nära kopplad till tre nyckelfaktorer: förmåga, möjlighet och motivation. Tänk på följande innan du går vidare till din konkreta handlingsplan:",
                pl: "Teraz zastanowimy się nad ewentualnymi przeszkodami, które mogą wpłynąć na Twoje cele i plany ich osiągnięcia. Pomyśl o swoich celach i miarach. Czy są realistyczne? Co zrobiłeś/zrobiłaś w przeszłości, aby je osiągnąć? Co działało, a co nie?",
                ru: "Далее давайте рассмотрим возможные препятствия, которые могут влиять на ваши цели и планы по их достижению. Подумайте о своих целях и показателях. Они реалистичны? Что вы сделали в прошлом для их достижения? Какие действия оказались эффективными, а какие нет?",
                es: "A continuación, consideremos los posibles obstáculos para alcanzar su objetivo. Si ha realizado cambios con éxito en el pasado, ¿qué ha funcionado y qué no? ¿En qué situaciones podría enfrentarse a un retroceso? Considere los tres factores clave del cambio de comportamiento:",
                fr: "Maintenant, examinons les obstacles possibles à la réalisation de votre objectif. Si vous avez procédé à des changements réussis dans le passé, qu’est-ce qui a fonctionné et qu’est-ce qui n’a pas fonctionné ? Dans quelles situations pourriez-vous être confronté(e) à des obstacles ? Examinez les trois facteurs clés du changement de comportement :",
                pt: "Vamos agora considerar os possíveis obstáculos à concretização do seu objetivo. Se já conseguiu fazer mudanças bem-sucedidas no passado, o que é que funcionou e o que é que não funcionou? Em que situações é que se pode deparar com contratempos? Considere os três fatores-chave da mudança de comportamento:",
                zh: "接下来，让我们考虑一下实现目标途中可能遇到的障碍。如果您过去曾成功地实现过改变，那么其中哪些是有效的，哪些是无效的？在什么情况下您可能会遇到阻碍？考虑行为改变的三个关键因素："
              },
              obstacles_title: {
                da: "Hindringer for adfærdsændringer ",
                de: "Hindernisse für Verhaltensänderungen ",
                en: "Obstacles to behaviour change",
                et: "Takistused käitumise muutmisel ",
                fi: "Esteet toimintamallien muuttamiselle ",
                no: "Hindringer for adferdsendring ",
                sv: "Hinder för beteendeförändring ",
                pl: "Przeszkody utrudniające zmianę zachowania",
                ru: "Препятствия на пути к изменению поведения",
                es: "Obstáculos al cambio de comportamiento",
                fr: "Obstacles au changement de comportement",
                pt: "Obstáculos à mudança de comportamento",
                zh: "行为改变的障碍"
              },
              personal_details: {
                da: "Personlige oplysninger",
                de: "Persönliche Angaben",
                en: "Personal details",
                et: "Isikuandmed",
                fi: "Henkilötiedot",
                no: "Personopplysninger",
                sv: "Personlig information",
                es: "Datos personales",
                pl: "Dane osobowe",
                ru: "Личные данные",
                fr: "Données personnelles",
                pt: "Questões pessoais",
                zh: "个人具体情况"
              },
              priorities: {
                da: "Prioriteter for elementerne",
                de: "Prioritäten in den einzelnen Bereichen",
                en: "Element priorities",
                et: "Kategooria prioriteedid",
                fi: "Osa-alueiden prioriteetit",
                no: "Elementprioriteringer",
                sv: "Elementprioriteringar",
                pl: "Priorytety dotyczące elementów",
                ru: "Приоритеты по элементам",
                es: "Prioridades de los elementos",
                fr: "Priorités des éléments",
                pt: "Prioridade dos elementos",
                zh: "优先考虑的健康维度"
              },
              scale: {
                da: "Skala: 1-5",
                de: "Skala: 1-5",
                en: "Scale: 1-5",
                et: "Skaala: 1-5",
                fi: "Asteikko: 1-5",
                no: "Skala: 1-5",
                sv: "Skala: 1-5",
                pl: "Skala: 1-5",
                ru: "Шкала: 1-5",
                es: "Escala: 1-5",
                fr: "Échelle : 1 à 5",
                pt: "Escala: 1-5",
                zh: "评分范围：1-5"
              },
              success_measures_description: {
                da: "Mens du udfyldte vurderingen, skrev du ovenstående mål ned som noget, du gerne vil opnå for at forbedre din sundhed og velbefindende. Gennemgå det igen og overvej, om det stadig har brug for yderligere redigering. Når dit mål er klart, tænk på forskellige tiltag, som du kan følge for at spore dine fremskridt mod dette mål. Du kan vælge forskellige typer fysiske eller mentale målinger eller endda bestemte områder i denne vurdering. Her er et par eksempler, baseret på dine svar.",
                de: "Beim Ausfüllen der Selbsteinschätzung haben Sie das obige Ziel formuliert. Überprüfen Sie es noch einmal. Muss es weiter angepasst werden? Wenn Ihr Ziel klar ist, überlegen Sie, wie Sie den Fortschritt messen können. Wählen Sie körperliche oder mentale Messwerte aus, entweder numerisch (z. B. Zeiten pro Woche) oder qualitativ (subjektives Empfinden). Beispiele aus Ihren Antworten:",
                en: "While completing the assessment, you wrote down the goal above. Review it again. Does it need further editing? Once your goal is clear, think about how to track progress. Pick physical or mental measurements, either numeric (e.g. times per week) or qualitative (subjective feeling). Examples from your answers:",
                et: "Küsimustiku täitmise käigus panite ülaltoodud eesmärgi kirja kui millegi, mida sooviksite oma tervise ja heaolu parandamiseks saavutada. Vaadake see uuesti läbi ja kaaluge, kas seda on vaja veel muuta. Kui eesmärk on selge, mõelge meetmetele, mida võiksite järgida, et jälgida oma edusamme selle eesmärgi poole püüeldes. Võite valida eri tüüpi füüsilisi või vaimseid mõõdikuid või selle hindamisküsimustiku teatavaid valdkondi. Toome teie vastuste põhjal paar näidet.",
                fi: "Arvioinnissasi ilmoitit yllä olevan tavoitteen sellaiseksi, jonka haluat saavuttaa terveytesi ja hyvinvointisi parantamiseksi. Tarkastele sitä uudelleen ja mieti, tarvitseeko se muokkausta. Kun tavoitteesi on selvä, mieti erilaisia mittareita, joilla voit seurata edistymistäsi tämän tavoitteen saavuttamisessa. Voit valita erityyppisiä fyysisiä tai henkisiä mittareita tai jopa tiettyjä tämän kyselyn osa-alueita. Tässä on muutama vastauksiisi perustuva esimerkki.",
                no: "Da du fylte inn vurderingen, skrev du ned målet ovenfor som noe du gjerne vil oppnå for å forbedre din egen helse og velvære. Gå gjennom det igjen og vurder om det trenger litt mer redigering. Når målet ditt er klart, skal du tenke på forskjellige tiltak du kan ta for å være i stand til å registrere fremgangen du har med hensyn til å nå dette målet. Du kan velge forskjellige typer målinger av fysisk eller mental helse, eller spesifikke områder i denne vurderingen. Her er noen eksempler, basert på svarene dine.",
                sv: "När du slutförde utvärderingen skrev du ner målet ovan som något du skulle vilja uppnå för att förbättra din hälsa och ditt välbefinnande. Granska det igen och fundera på om det fortfarande behöver ytterligare redigering. När ditt mål är klart, tänk på olika åtgärder du kan göra för att kunna följa dina framsteg mot detta mål. Du kan välja olika typer av fysiska eller mentala mätningar, eller till och med specifika områden i denna utvärdering. Här är några exempel, baserade på dina svar.",
                pl: "Podczas tej oceny zapisałeś/zapisałaś powyższy cel jako coś, co chcesz osiągnąć w celu poprawy swojego zdrowia i samopoczucia. Przyjrzyj się mu ponownie i zastanów się, czy chcesz go zmienić. Jeśli masz wyraźnie określony cel, zastanów się nad tym, w jaki sposób możesz śledzić swoje postępy na drodze do tego celu. Możesz wybrać różne typy miar fizycznych i mentalnych, a nawet konkretne obszary w tej ocenie. Oto kilka przykładów opartych na Twoich odpowiedziach.",
                ru: "В процессе выполнения оценки выше вы записали цель — то, чего вы хотите достичь для улучшения вашего состояния здоровья и самочувствия. Рассмотрите ее снова и подумайте, требует ли она доработки. Поставив четкую цель, подумайте, какие различные меры можно предпринять для отслеживания своего прогресса на пути к достижению поставленной цели. Вы можете выбрать разные типы физических или психических показателей или даже конкретные области данной оценки. Вот несколько примеров исходя из ваших ответов.",
                es: "Mientras completaba la evaluación, escribió el objetivo mencionado más arriba. Vuelva a revisarlo. ¿Necesita modificaciones adicionales? Una vez que tenga claro su objetivo, piense en cómo hacer un seguimiento de los progresos. Elija medidas físicas o mentales, ya sean numéricas (por ejemplo, veces por semana) o cualitativas (sensación subjetiva). Ejemplos de sus respuestas:",
                fr: "Lors de l’évaluation, vous avez noté l’objectif ci-dessus. Réfléchissez-y à nouveau. Doit-il être retravaillé ? Une fois que votre objectif est clair, réfléchissez à la manière de suivre les progrès réalisés. Choisissez des mesures physiques ou mentales, soit numériques (par exemple, le nombre de fois par semaine), soit qualitatives (sentiment subjectif). Exemples tirés de vos réponses :",
                pt: "Quando efetuou a avaliação, escreveu o objetivo indicado acima. Leia-o novamente. Necessita de alguma modificação? Quando tiver o objetivo claramente definido, pense em como pode acompanhar o progresso. Escolha medidas físicas ou mentais, quer sejam numéricas (p. ex., vezes por semana) ou qualitativas (sensação subjetiva). Exemplos retirados das suas respostas:",
                zh: "在完成评估时，您写下了上述目标。再看一遍。是否需要进一步修改？一旦目标明确，就要考虑如何记录进展。选择您要用来测评身体或心理状态的指标，可以是数字（如每周次数），也可以是定性评估（主观感受）。您可以参考下述示例进行回答："
              },
              success_measures_title: {
                da: "Resultatmål",
                de: "Erfolgskriterien",
                en: "Success measures",
                et: "Edumeetmed",
                fi: "Onnistumisen mittarit",
                no: "Suksesstiltak",
                sv: "Framgångsmått",
                pl: "Miary sukcesu",
                ru: "Показатели успеха",
                es: "Medidas de éxito",
                fr: "Mesures de la réussite",
                pt: "Medidas de sucesso",
                zh: "目标达成标准"
              },
              title: {
                da: "Sundhed og velvære",
                de: "Gesundheit und Wohlbefinden",
                en: "Health & Wellbeing",
                et: "Tervis ja heaolu",
                fi: "Terveys ja hyvinvointi",
                no: "Helse og velvære",
                sv: "Hälsa och välbefinnande",
                pl: "Zdrowie i samopoczucie",
                ru: "Здоровье и самочувствие",
                es: "Salud y bienestar",
                fr: "Santé et mieux-être",
                pt: "Saúde e bem-estar",
                zh: "健康安乐"
              },
              your_goal: {
                da: "Dit mål",
                de: "Ihr Ziel",
                en: "Your goal",
                et: "Teie eesmärk",
                fi: "Tavoitteesi",
                no: "Ditt mål",
                sv: "Ditt mål",
                pl: "Twój cel",
                ru: "Ваша цель",
                es: "Su objetivo",
                fr: "Votre objectif",
                pt: "O seu objetivo",
                zh: "您的目标"
              }
            },
            SectionLifePerformanceView: {
              energy_12_5: {
                da: "Du har få eller ingen tegn på udmattelse og er generelt energisk. Godt klaret!",
                de: "Sie haben wenig bis gar keine Anzeichen von Erschöpfung und sind generell voller Energie. Gut gemacht!",
                en: "You have little to no sign of exhaustion and are generally energised. Well done!",
                et: "Teil on vähe või üldse mitte kurnatuse märke ja olete üldiselt pingestatud. Hästi tehtud!",
                fi: "Et juurikaan kärsi väsymyksestä ja olet yleisesti energinen. Hienoa!",
                no: "Du har lite eller ingen tegn til utmattelse og er generelt energisk. Bra gjort!",
                sv: "Du har mycket små eller inga tecken på trötthet och verkar ha bra med energi just nu. Fint!",
                pl: "Masz niewiele oznak wyczerpania lub nie masz ich wcale i ogólnie jesteś naładowany energią. Bardzo dobrze!",
                ru: "У вас практически нет признаков истощения и, как правило, вы полны энергии. Отличная работа!",
                es: "Apenas tiene signos de agotamiento y, en general, está lleno/a de energía. ¡Bien hecho!",
                fr: "Vous ne présentez que peu ou pas de signes d’épuisement et vous êtes généralement énergique. Bravo !",
                pt: "Apresenta poucos ou nenhuns sinais de exaustão e, de um modo geral, está cheio(a) de energia. Muito bem!",
                zh: "您没有或几乎没有疲惫的迹象，总体上精力充沛。完成得很出色！"
              },
              energy_9_11: {
                da: "Du har nogle lette tegn på fysisk, kognitiv eller følelsesmæssig udmattelse. Vær mere opmærksom på dit energiniveau og husk at restituere.",
                de: "Sie haben einige leichte Anzeichen von körperlicher, kognitiver oder emotionaler Erschöpfung. Achten Sie mehr auf Ihr Energieniveau und denken Sie daran, sich zu erholen.",
                en: "You have some slight signs of physical, cognitive, or emotional exhaustion. Pay more attention to your energy levels and remember to recover.",
                et: "Teil on kergeid füüsilise, kognitiivse või emotsionaalse kurnatuse tunnuseid. Pöörake rohkem tähelepanu oma energiatasemele ja ärge unustage taastumist.",
                fi: "Sinulla on lieviä fyysisen, kognitiivisen tai emotionaalisen väsymyksen oireita. Kiinnitä enemmän huomiota energiatasoihisi ja muista palautuminen.",
                no: "Du har noen små tegn på fysisk, kognitiv eller følelsesmessig utmattelse. Vær mer oppmerksom på energinivåene dine og husk å restituere.",
                sv: "Du har vissa tecken på fysisk, kognitiv eller emotionell trötthet. Uppmärksamma din dagliga energinivå och försök prioritera återhämtning och välmående.",
                pl: "Masz niewielkie oznaki wyczerpania fizycznego, poznawczego lub emocjonalnego. Zwracaj większą uwagę na poziom energii i pamiętaj o regeneracji.",
                ru: "У вас есть легкие признаки физического, когнитивного или эмоционального истощения. Уделите больше внимания своему уровню энергии и не забывайте восстанавливаться.",
                es: "Presenta ligeros signos de agotamiento físico, cognitivo o emocional. Preste más atención a sus niveles de energía y acuérdese de recuperarse.",
                fr: "Vous présentez de légers signes d’épuisement physique, cognitif ou émotionnel. Accordez plus d’attention à votre niveau d’énergie et n’oubliez pas de récupérer.",
                pt: "Apresenta alguns sinais ligeiros de cansaço físico, cognitivo ou emocional. Preste mais atenção aos seus níveis de energia e não se esqueça de reservar tempo para recuperar.",
                zh: "您在身体、认知或情感方面表现出轻微的疲惫迹象。多关注自己的精力充沛程度，记得恢复精力。"
              },
              energy_3_8: {
                da: "Du har nogle tegn på fysisk, kognitiv eller følelsesmæssig udmattelse. Sørg for genopretning af høj kvalitet, pas på dig selv, og sørg for at række ud efter støtte.",
                de: "Sie haben Anzeichen von körperlicher, kognitiver oder emotionaler Erschöpfung. Sorgen Sie für eine gute und wirksame Erholung, passen Sie auf sich auf und holen Sie sich Unterstützung.",
                en: "You have some signs of physical, cognitive, or emotional exhaustion. Ensure high-quality recovery, take care of yourself, and make sure you reach out for support.",
                et: "Teil on füüsilise, kognitiivse või emotsionaalse kurnatuse tunnused. Tagage kvaliteetne taastumine, hoolitsege enda eest ja otsige kindlasti abi.",
                fi: "Sinulla on joitain fyysisen, kognitiivisen tai emotionaalisen väsymyksen oireita. Pidä huolta itsestäsi ja palautumisestasi. Ota yhteyttä asiantuntijoihin saadaksesi tukea.",
                no: "Du har noen tegn på fysisk, kognitiv eller følelsesmessig utmattelse. Sørg for restitusjon av høy kvalitet, ta vare på deg selv og sørg for at du søker støtte.",
                sv: "Du visar tecken på fysisk, kognitiv eller emotionell trötthet. Se till att få in tillräckligt tid för återhämtning varje dag och sök stöd ifall du känner för det.",
                pl: "Masz oznaki fizycznego, poznawczego lub emocjonalnego wyczerpania. Zapewnij sobie wysokiej jakości regenerację, zadbaj o siebie i upewnij się, że sięgasz po wsparcie.",
                ru: "У вас есть некоторые признаки физического, когнитивного или эмоционального истощения. Обеспечьте качественное восстановление, позаботьтесь о себе и обязательно обратитесь за поддержкой.",
                es: "Presenta algunos signos de agotamiento físico, cognitivo o emocional. Asegúrese una recuperación de alta calidad, cuídese y busque apoyo.",
                fr: "Vous présentez des signes d’épuisement physique, cognitif ou émotionnel. Veillez à récupérer correctement, prenez soin de vous et assurez-vous de demander de l’aide.",
                pt: "Apresenta alguns sinais de cansaço físico, cognitivo ou emocional. Não se esqueça de reservar tempo para recuperar, cuidar de si e procure ajuda, se precisar.",
                zh: "您在身体、认知或情感方面表现出一些疲惫迹象。确保高质量的精力恢复，保养精神，并确保寻求支持。"
              },
              energy_score: {
                da: "Energipoint*",
                de: "Energiewert*",
                en: "Energy score*",
                et: "Energiaallikas*",
                fi: "Energiatasosi*",
                no: "Energiscore*",
                sv: "Din energinivå*",
                pl: "Wynik stanu pokładów energii*",
                ru: "Оценка уровня энергии*",
                es: "Puntuación energética*",
                fr: "Score d’énergie*",
                pt: "Pontuação de energia*",
                zh: "精力充沛程度评分*"
              },
              energy_score_base: {
                da: "*Resultatet er baseret på tre spørgsmål fra Shirom-Melamed Burnout Index. Selv om resultatet er vejledende, er det ikke det samme som det bekræftede indeks og bør derfor ikke anvendes som diagnoseteknik. Kontakt en professionel for at få en godkendt test.",
                de: "*Dieser Wert basiert auf drei Fragen des Shirom-Melamed Burnout Index, der die körperliche, kognitive und emotionale Erschöpfung misst. Er ist ein Richtwert und sollte nicht zur Diagnose verwendet werden. Wenden Sie sich an eine medizinische Fachkraft, um einen validierten Test durchzuführen, wenn Sie sich persönlich betroffen fühlen.",
                en: "*This score is based on three questions from the Shirom-Melamed Burnout Index, measuring physical, cognitive, and emotional exhaustion. It is indicative and should not be used as a diagnostic tool. Reach out to a health professional for a validated test if you feel personally concerned.",
                et: "*Tulemus põhineb kolmel küsimusel Shirom-Melamedi läbipõlemisindeksist. Kuigi see annab hinnangulise tulemuse, ei ole see samaväärne kinnitatud indeksiga, mistõttu ei tohiks seda kasutada diagnoosimeetodina. Kinnitatud testi jaoks pöörduge spetsialisti poole.",
                fi: "*Tämä tulos perustuu Shirom-Melamed Burnout indeksin kolmeen kysymykseen, jotka mittaavat fyysistä, kognitiivista ja emotionaalista uupumusta. Se on suuntaa-antava eikä sitä pidä käyttää diagnosoinnin työkaluna. Jos olet huolissasi omasta jaksamisestasi, ota yhteyttä terveydenhuollon asiantuntijoihin, jotka voivat tutkia asiaa tarkemmin validoiduin testein.",
                no: "*Denne scoren er basert på tre spørsmål fra undersøkelsen Shirom-Melamed Burnout Index. Selv om den er indikativ, er det ikke det samme som den validerte indeksen, og den bør derfor ikke brukes som en diagnoseteknikk. Snakk med en fagperson om å ta en validert test.",
                sv: "*Dessa poäng baseras på tre frågor från Shirom-Melamed Burnout Index som mäter fysisk, kognitiv och emotionell utbrändhet. Dessa poäng är vägledande, inte ett validerat index, och bör ej användas för diagnostisering. Ta kontakt med en hälsovårdsspecialist ifall du känner dig orolig för hur du orkar.",
                pl: "*Ta punktacja jest oparta na trzech pytaniach ze wskaźnika wypalenia zawodowego Shirom-Melamed. Punktacja ma charakter wskaźnika, ale nie jest równoważna zatwierdzonemu wskaźnikowi i dlatego nie powinna być stosowana jako technika diagnostyczna. W celu przeprowadzania zatwierdzonego testu skontaktuj się z profesjonalistą.",
                ru: "*Этот балл рассчитывается на основании ответа на три вопроса, исходя из показателя выгорания Широма-Меламед. Он ориентировочный и не соответствует валидированному показателю, в связи с чем не должен использоваться в качестве техники диагностики. Обратитесь к специалисту для проведения валидированного теста.",
                es: "*Esta puntuación se basa en tres preguntas del Índice de Burnout de Shirom-Melamed, que miden el agotamiento físico, cognitivo y emocional. Es indicativa y no debe utilizarse como herramienta de diagnóstico. Acuda a un profesional de salud para someterse a una prueba validada si se siente afectado/a personalmente.",
                fr: "*Ce score est basé sur trois questions de l’indice d’épuisement Shirom-Melamed, qui mesure l’épuisement physique, cognitif et émotionnel. Il est indicatif et ne doit pas être utilisé comme un outil de diagnostic. Si ce score vous inquiète, adressez-vous à un professionnel de la santé qui pourra vous faire passer un test valide.",
                pt: "*Esta pontuação baseia-se em três perguntas do Índice de “Burnout” de Shirom-Melamed, que avalia o cansaço físico, cognitivo e emocional. Tem um caráter indicativo e não deve ser utilizada como instrumento de diagnóstico. Procure um profissional de saúde para efetuar um exame comprovado se tiver alguma preocupação.",
                zh: "*这个评分以 Shirom-Melamed 职业倦怠量表中的三个问题为基础，用来衡量身体、认知和情感上的疲惫程度。评分只是参考性的，不应被用作诊断工具。如果您个人感到担忧，请联系健康专家进行有效测试。"
              },
              core_total: {
                da: "Kerneelementets samlede resultat",
                de: "Gesamtpunktzahl für den Bereich Core",
                en: "Core total score",
                et: "Tuumosa lõpptulemus",
                fi: "Ydinkysymykset - kokonaispisteet",
                no: "Totalscore på kjerneelementer",
                sv: "Total poäng för kärnelement",
                pl: "Łączny wynik dotyczący wartości zasadniczych",
                ru: "Общий балл за элемент «Основа»",
                es: "Puntuación total de los Valores esenciales",
                fr: "Score total du noyau",
                pt: "Pontuação total Essência ",
                zh: "核心总分"
              },
              follow_up_description: {
                da: "Hvad har haft en effekt på disse resultater? Er der, siden du sidst svarede, sket nogen større hændelser i livet generelt eller i arbejdslivet? Har en lille velværerutine gjort en stor forskel? Det at reflektere over dette kan hjælpe dig med at identificere, hvad der har en effekt på forandring, og hvordan du gør forandringer holdbare.",
                de: "Was hat sich auf diese Ergebnisse ausgewirkt? Gab es seit Ihrer letzten Einschätzung wichtige Ereignisse in Ihrem Leben oder bei der Arbeit? Hat eine kleine Wohlfühl-Routine einen großen Unterschied gemacht? Wenn Sie darüber nachdenken, können Sie herausfinden, was Veränderungen beeinflusst und wie Sie diese nachhaltig gestalten können.",
                en: "What has impacted these results? Since you last answered, have any major life or work events happened? Has a small wellbeing routine made a large difference? Reflecting on this can help you identify what impacts change and how to make changes sustainable.",
                et: "Mis võis selliseid tulemusi tingida? Kas pärast eelmise enesehinnangu täitmist toimus sinu igapäevases era- või tööelus mõni suurem muutus? Kas väike heaoluharjumus tekitas suure muudatuse? Sellele mõtlemine aitab sul näha, millised tegurid on muutunud ja kuidas teha püsivaid muutusi.",
                fi: "Mikä näihin tuloksiin on vaikuttanut? Onko elämässäsi tai työssäsi tapahtunut jotain merkittävää edellisen itsearviointikerran jälkeen? Onko jollain pienellä hyvinvointirutiinilla ollut suuri merkitys? Tämän pohtiminen auttaa sinua tunnistamaan, mitkä asiat vaikuttavat muutoksiin ja miten voit tehdä muutoksista pysyviä.",
                no: "Hva har påvirket disse resultatene? Har det skjedd noen større livs- eller arbeidshendelser siden du svarte sist? Har en liten velværerutine gjort en stor forskjell? Reflektering over dette kan hjelpe deg med å identifisere hva som påvirker endring og hvordan du kan gjøre endringer bærekraftige.",
                sv: "Vad har påverkat dessa resultat? Har du upplevt några större händelser i ditt liv eller på jobbet sedan du senast svarade? Har en liten välbefinnanderutin gjort stor skillnad? Att reflektera över det här kan hjälpa dig identifiera vad som påverkar förändringar och hur du åstadkommer hållbara förändringar.",
                pl: "Co wpłynęło na te wyniki? Czy w Twoim życiu osobistym lub zawodowym zdarzyło się coś ważnego od czasu ostatniego wypełnienia kwestionariusza? Czy drobna rutyna mająca poprawić Twoje samopoczucie doprowadziła do dużych zmian? Odpowiadając na powyższe pytania, możesz określić, co zainicjowało zmiany, i jak je utrwalić.",
                ru: "Что могло повлиять на полученный результат? С момента Ваших последних ответов не происходило ли существенных событий в Вашей личной жизни и на работе? Не привела ли тенденция к рутинному повышению благополучия в малом к большим переменам? Размышляя на эту тему, постарайтесь выявить факторы, которые оказали влияние на эти изменения, чтобы закрепить достигнутый результат.",
                es: "¿Qué ha influido en estos resultados? Desde la última vez que contestó, ¿se ha producido algún acontecimiento importante en su vida o en su trabajo? ¿Una pequeña rutina de bienestar ha marcado una gran diferencia? Reflexionar sobre ello puede ayudarle a identificar qué influye en el cambio y cómo hacer que los cambios sean sostenibles.",
                fr: "Qu’est-ce qui a influencé ces résultats ? Depuis votre dernière réponse, des événements majeurs sont-ils survenus dans votre vie ou au travail ? Une petite routine de mieux-être a-t-elle un une grande incidence ? Cette réflexion peut vous aider à identifier les éléments qui influencent le changement et à trouver des moyens de rendre ce changement durable.",
                pt: "O que é que influenciou estes resultados? Desde a última vez que indicou as suas respostas, ocorreu algum acontecimento importante na sua vida ou no seu trabalho? A sua pequena rotina de bem-estar fez uma grande diferença? Refletir sobre isto pode ajudá-lo(a) a identificar os aspetos que influenciam a mudança e como torná-la sustentável.",
                zh: "上述结果都受到了哪些方面的影响？自您上次回答调查问题以来，您的生活或工作中是否发生过任何重大事件？是否有某件小小的健康习惯带来了巨大的变化？对此进行反思可以帮助您确定是什么给您带来了改变，以及如何保持这些改变。"
              },
              description: {
                da: "Når du overvejer, hvordan du vil forbedre din livsglæde, så tænk tilbage på de kernespørgsmål i denne vurdering, der fokuserer på de grundlæggende dele af et godt liv, f.eks. positive følelser, engagement, forhold, mening og resultater. Du kan finde spørgsmålene igen længere nede i denne rapport. Tænk nu på dine egne værdier, prioriteter og styrker, og tænk på, hvem du gerne vil være.",
                de: "Was könnte Ihre Lebensfreude verbessern? Denken Sie an die Fragen zum Bereich Core (weiter unten in diesem Bericht) und die grundlegenden Bestandteile eines guten Lebens: positive Gefühle, Energie, Beziehungen und Sinn. Betrachten Sie nun Ihre Werte, Prioritäten und Stärken: Wer wollen Sie sein?",
                en: "What could improve your life enjoyment? Think back to the Core questions (further down in this report) and the fundamental elements of a good life: positive emotions, energy, relationships, meaning. Now, considering your values, priorities, and strengths: who do you want to be?",
                et: "Mõeldes sellele, kuidas soovite oma elurõõmu suurendada, mõelge uuesti küsimustiku tuumosa küsimustele, mis keskendusid hea elu põhiosadele, positiivsetele emotsioonidele, kaasatusele, suhetele, tähendusrikkusele ja saavutustele. Aruande lõpupoole on küsimused uuesti välja toodud. Nüüd mõelge oma väärtustele, prioriteetidele ja tugevustele ning selle üle, kes te soovite olla.",
                fi: "Pohtiessasi miten voisit tehdä elämästäsi nautinnollisempaa, muistele omaan sisimpääsi liittyneitä kysymyksiä, jotka keskittyivät hyvän elämän osa-alueisiin kuten myönteisiin tunteisiin, sitoutumiseen, ihmissuhteisiin, merkityksellisyyteen ja onnistumiseen. Löydät kysymykset tästä raportista vielä tuonnempana. Ajattele nyt omia arvojasi, prioriteettejasi ja vahvuuksiasi ja mieti, mitä haluaisit olla.",
                no: "Når du reflekterer på hvordan du ønsker å få mer livsglede, skal du tenke tilbake på kjernespørsmålene i denne vurderingen som fokuserte på fundamentale elementer av et godt liv, som for eksempel positive følelser, engasjement, forhold, mening og fullbyrdelse. Du finner igjen spørsmålene lenger ned i denne rapporten. Nå skal du vurdere dine egne verdier, prioriteringer og sterke sider og tenke på hvem du ønsker å være.",
                sv: "När du reflekterar över hur du skulle vilja förbättra dina livsnöjen, tänk tillbaka på kärnfrågorna i denna bedömning som fokuserade på de grundläggande elementen i ett bra liv, såsom positiva känslor, engagemang, relationer, mening och prestation. Frågorna hittar du längre ner i rapporten. Tänk nu på dina egna värderingar, prioriteringar och styrkor och tänk på vem du vill vara.",
                pl: "Zastanawiając się nad tym, jak możesz odczuwać więcej radości z życia, pomyśl o pytaniach, które w tej ocenie dotyczyły wartości zasadniczych. Zastanów się nad fundamentami dobrego życia, takimi jak pozytywne emocje, zaangażowanie, relacje, znaczenie i poczucie spełnienia. Pytania są dostępne w dalszej części niniejszego raportu. Teraz rozważ swoje wartości, priorytety i mocne strony, i pomyśl o tym, kim chcesz być.",
                ru: "Размышляя над тем, как бы вы хотели повысить свою удовлетворенность качеством жизни, вспомните свои ответы на вопросы в разделе этой оценки «Основа», в которых основное внимание уделялось таким основополагающим элементам хорошей жизни, как положительные эмоции, вовлеченность, отношения, смысл и реализация. Вопросы еще раз приводятся в этом отчете. А теперь рассмотрите собственные ценности, приоритеты и сильные стороны и подумайте, кем вы хотите стать.",
                es: "¿Qué podría mejorar su disfrute de la vida? Piense en las Cuestiones centrales (más adelante en este informe) y en los elementos esenciales de una buena vida: emociones positivas, energía, relaciones, sentido. Ahora, teniendo en cuenta sus valores, prioridades y puntos fuertes: ¿quién quiere ser?",
                fr: "Qu’est-ce qui pourrait améliorer votre joie de vivre ? Repensez aux questions du noyau (plus loin dans ce rapport) et aux éléments fondamentaux d’une vie heureuse : des émotions positives, de l’énergie, des relations sociales, du sens. Maintenant, compte tenu de vos valeurs, de vos priorités et de vos points forts, qui voulez-vous être ?",
                pt: "O que poderia melhorar o seu prazer de viver? Pense nas questões Essência (mais abaixo neste relatório) e nos elementos fundamentais de uma vida boa: emoções positivas, energia, relações, propósito. Agora, tendo em conta os seus valores, prioridades e pontos fortes: que tipo de pessoa quer ser?",
                zh: "哪些事物能提高您的生活乐趣？回想一下核心问题（见本报告下文）以及美好生活的基本要素：积极情绪、精力、关系、意义。现在，仔细考虑一下您的价值观、优先事项和优势：您想成为什么样的人？"
              },
              follow_up_ingress: {
                da: "Lad os starte med din overordnede livsglæde og arbejdsindsats og ændringer i disse.",
                de: "Beginnen wir mit Ihrer allgemeinen Lebenszufriedenheit und Ihrer Arbeitsleistung und den damit verbundenen Veränderungen.",
                en: "Let's start with your overall life enjoyment and work performance and changes in these.",
                et: "Alustame sinu üleüldisest elust tuntavast naudingust ja töisest jõudlusest ning neis valdkondades toimunud muutustest.",
                fi: "Aloitetaan yleisestä elämän mielekkyydestä ja työssä suoriutumisesta sekä näitä koskevista muutoksista.",
                no: "La oss starte med din generelle livsglede, arbeidsytelse og endringer i disse.",
                sv: "Låt oss börja med din övergripande livsnöjdhet och arbetsprestation samt förändringar i dessa.",
                pl: "Zacznijmy od ogólnej radości z życia i wydajności w pracy oraz zmian, jakie nastąpiły w tych obszarach.",
                ru: "Давайте начнем с общей удовлетворенности уровнем жизни и личной эффективности на работе и изменений в них.",
                es: "Empecemos por el disfrute de la vida en general y el rendimiento laboral y los cambios en estos aspectos.",
                fr: "Commençons par votre joie de vivre et vos performances professionnelles en général, ainsi que les changements qui en découlent.",
                pt: "Comecemos pelo seu prazer geral na vida e pelo seu desempenho profissional e as mudanças que ocorreram nestes contextos.",
                zh: "让我们从您的生活乐趣和工作表现的总体情况及其变化开始。"
              },
              ingress: {
                da: "Denne rapport følger strukturen i en logisk præstationsplan, anvendt som hjørnesten i Hintsas coachingprogrammer. Lad os starte med at fokusere på din livsglæde og præstation. Tag et kig på dine resultater nedenfor og se, om du kan komme med langsigtede mål i forbindelse med disse områder.",
                de: "Dieser Bericht folgt der Struktur eines zielführenden Leistungsplans, dem Hauptbestandteil eines Coaching-Programms von Hintsa.",
                en: "This report follows the structure of a Logical Performance Plan, the backbone of a Hintsa coaching programme. Let’s start with your overall rating of life enjoyment and work performance.",
                et: "Selles aruandes järgitakse loogilise soorituskava struktuuri, mis on Hintsa nõustamisprogrammide alus. Esmalt keskendume teie elurõõmule ja sooritusele. Vaadake alltoodud tulemusi ja mõelge, kas suudate leida nende valdkondadega seotud pikaajalisi eesmärke.",
                fi: "Tämä raportin rakenne on Hintsan valmennusohjelmien perustana käytettävän Logical Performance Planin mukainen. Aloitetaan tarkastelemalla elämästä nauttimiseen ja suoriutumiskykyyn liittyviä asioita. Tarkastele tuloksiasi ja mieti, olisiko näihin alueisiin liitettävissä pitkän aikavälin tavoitteita.",
                no: "Denne rapporten følger strukturen til en logisk prestasjonsplan, som brukes som ryggrad i Hintsas coaching-programmer. La oss starte med å fokusere på din livsglede og prestasjoner. Ta en kikk på resultatene nedenfor og se om du kan komme opp med noen langsiktige mål relatert til disse områdene.",
                sv: "Denna rapport följer strukturen hos en logisk prestationsplan som används som en stomme i Hintsas coachningsprogram. Låt oss börja med att fokusera på dina livsnöjen och din prestanda. Ta en titt på dina resultat nedan och se om du kan komma med några långsiktiga mål som är relaterade till dessa områden.",
                pl: "Ten raport jest zgodny ze strukturą logicznego planu na wydajność (ang. Logical Performance Plan), który jest używany jako podstawa programów coachingowych Hintsa. Zacznijmy od Twojej radości z życia i rezultatów, jakie osiągasz. Przyjrzyj się poniższym wynikom i zastanów się nad tym, czy możesz jakiekolwiek cele długoterminowe dotyczące tych obszarów.",
                ru: "Этот отчет построен по принципу логического плана выполнения, составляющего основу программ наставничества от Hintsa. Давайте начнем с удовлетворенности качеством жизни и эффективности. Посмотрите свои результаты ниже и оцените возможность постановки долгосрочных целей в этих областях.",
                es: "Este informe sigue la estructura de un Plan Lógico de Rendimiento, la columna vertebral de un programa de coaching Hintsa. Empecemos por su valoración general del disfrute de la vida y el rendimiento laboral.",
                fr: "Ce rapport suit la structure d’un plan de performance logique, l’épine dorsale d’un programme de coaching Hintsa. Commençons par votre évaluation globale de votre joie de vivre et de vos performances professionnelles.",
                pt: "Este relatório segue a estrutura de um Plano Lógico de Desempenho, que é a base dos programas de coaching Hintsa. Comecemos pela sua avaliação global do prazer de viver e do desempenho profissional.",
                zh: "本报告采用逻辑推理式绩效训练计划（Hintsa 训练计划的框架基础）的结构。让我们从您的生活乐趣和工作表现的总体情况开始。"
              },
              title: {
                da: "Liv og præstationer",
                de: "Leben & Leistung",
                en: "Life & Performance",
                et: "Elu ja tulemused",
                fi: "Elämä ja suoriutumiskyky",
                no: "Liv og prestasjoner",
                sv: "Liv och prestation",
                pl: "Życie i rezultaty",
                ru: "Качество жизни и эффективность",
                es: "Vida y rendimiento",
                fr: "Vie et performances",
                pt: "Vida e desempenho profissional",
                zh: "生活和工作"
              },
              follow_up_work_performance: {
                da: "Hvad har du gjort anderledes på arbejdet eller uden for arbejdet? Skyldes dine resultater eksterne begivenheder, som du ikke har kontrol over, eller dine egne handlinger eller eget mindset? Det at reflektere over dette kan hjælpe dig med at identificere, hvad der har en effekt på forandring, og hvordan du gør forandringer holdbare.",
                de: "Was haben Sie bei der Arbeit oder im Privatleben verändert? Sind Ihre Ergebnisse auf externe Ereignisse zurückzuführen, die sich Ihrer Kontrolle entziehen, oder auf Ihre eigenen Handlungen oder Einstellungen? Wenn Sie darüber nachdenken, können Sie herausfinden, was Veränderungen beeinflusst und wie Sie diese nachhaltig gestalten können.",
                en: "What have you done differently at work or outside it? Are your results due to external events beyond your control, or your own actions or mindsets? Reflecting on this can help you identify what impacts change and how to make changes sustainable.",
                et: "Mida sa oled hakanud tööl või mujal teisiti tegema? Kas sinu tulemusi mõjutasid kontrollile mitte alluvad välised tegurid, või hoopis muutused sinu mõtlemises või tegutsemises? Sellele mõtlemine aitab sul näha, millised tegurid on muutunud ja kuidas teha püsivaid muutusi.",
                fi: "Mitä olet tehnyt eri tavalla töissä tai vapaa-ajalla? Johtuvatko tuloksesi ulkoisista tapahtumista, jotka eivät ole sinun hallinnassasi, vai omista teoistasi tai ajattelutavoistasi? Tämän pohtiminen auttaa sinua tunnistamaan, mitkä asiat vaikuttavat muutoksiin ja miten voit tehdä muutoksista pysyviä.",
                no: "Hva har du gjort annerledes på eller utenfor jobben? Er resultatene dine på grunn av eksterne hendelser utenfor din kontroll, eller dine egne handlinger eller tankesett? Reflektering over dette kan hjelpe deg med å identifisere hva som påvirker endring og hvordan du kan gjøre endringer bærekraftige.",
                sv: "Vad har du gjort annorlunda på jobbet eller utanför det? Beror dina resultat på externa händelser du inte kan kontrollera, eller på dina egna handlingar eller tankesätt? Att reflektera över det här kan hjälpa dig identifiera vad som påverkar förändringar och hur du åstadkommer hållbara förändringar.",
                pl: "Co zrobiłeś(-aś) inaczej w pracy lub poza nią? Co miało wpływ na uzyskane wyniki — wydarzenia zewnętrzne będące poza Twoją kontrolą czy własne działania i nastawienie? Odpowiadając na powyższe pytania, możesz określić, co zainicjowało zmiany, i jak je utrwalić.",
                ru: "Что Вы делали по-другому на работе и в нерабочее время? Полученные Вами результаты были вызваны событиями вне Вашего контроля или Вашими собственными усилиями и установками? Размышляя на эту тему, постарайтесь выявить факторы, которые оказали влияние на эти изменения, чтобы закрепить достигнутый результат.",
                es: "¿Qué ha hecho de forma diferente en el trabajo o fuera de él? ¿Sus resultados se deben a acontecimientos externos que escapan a su control, o a sus propias acciones o forma de pensar? Reflexionar sobre ello puede ayudarle a identificar qué influye en el cambio y cómo hacer que los cambios sean sostenibles.",
                fr: "Qu’avez-vous fait différemment au travail ou en dehors ? Vos résultats sont-ils dus à des événements extérieurs sur lesquels vous n’avez aucun contrôle, ou à vos propres actions ou schèmes de pensée ? Cette réflexion peut vous aider à identifier les éléments qui influencent le changement et à trouver des moyens de rendre ce changement durable.",
                pt: "O que é que fez de diferente no trabalho ou fora dele? Os seus resultados devem-se a acontecimentos externos que estão fora do seu controlo, ou às suas próprias ações ou maneira de pensar? Refletir sobre isto pode ajudá-lo(a) a identificar os aspetos que influenciam a mudança e como torná-la sustentável.",
                zh: "在工作上或工作之外，您做了哪些与以往不同的事情？您取得的成绩是得益于您无法控制的外部事件，还是得益于您自己的行为或心态？对此进行反思可以帮助您确定是什么给您带来了改变，以及如何保持这些改变。"
              },
              work_performance: {
                da: "Arbejdspræstationer og trivsel på arbejdspladsen påvirkes af flere faktorer, der både er knyttet til den virksomhed, du arbejder på, og til dig som person. Engagerede medarbejdere, der finder mening i deres arbejde og kan varetage deres helbred og velvære, vil sandsynligvis have gode resultater. Længere nede i denne rapport kan du finde dine detaljerede svar på spørgsmålene om trivsel på arbejdspladsen. Tænk over, hvordan de kan hjælpe dig med at sætte mål for at forbedre din præstation og din velvære.",
                de: "Was könnte Ihre Leistung bei der Arbeit verbessern? Scrollen Sie nach unten zu den berufsbezogenen Fragen: Berufliche Ziele, Erfüllung, Lernen, Energie, Ressourcen. Was könnte Ihnen helfen, besser zurechtzukommen, Ihre Zufriedenheit zu steigern oder  bessere Leistung zu erbringen?",
                en: "What could improve your performance at work? Scroll down to the work questions: professional purpose, accomplishment, learning, energy, resources. What could help you cope better, reach higher, or experience greater performance?",
                et: "Töötulemusi ja tööheaolu mõjutab mitu tegurit, mis on seotud nii teie töökoha kui ka teie kui isikuga. Suure tõenäosusega on töötulemused paremad kaasatud töötajatel, kes tunnevad, et nende töö on tähendusrikas, ning suudavad oma tervist ja heaolu juhtida. Aruande lõpupoole leiate oma täpsed vastused tööheaoluga seotud küsimustele. Mõelge, kuidas need saaksid aidata teil seada eesmärgiks oma tulemuste ja heaolu parandamise.",
                fi: "Työssä suoriutumiseen ja työhyvinvointiin vaikuttavat useat tekijät, jotka liittyvät sekä organisaatioon, jossa työskentelet että sinuun itseesi yksilönä. Terveyttään ja hyvinvointiaan hallitsemaan kykenevien sitoutuneiden, työnsä merkitykselliseksi kokevien työntekijöiden suoriutumiskyky on todennäköisesti hyvä. Löydät tuonnempana tästä raportista yksityiskohtaiset vastauksesi työhyvinvointia koskeviin kysymyksiin. Mieti, miten ne voisivat ohjata sinua asettamaan tavoitteita suoriutumiskykysi ja hyvinvointisi parantamiseksi.",
                no: "Arbeidsprestasjoner og velvære på arbeidet påvirkes av mange faktorer relatert til både organisasjonen du arbeider for og deg selv som person. Engasjerte arbeidere som finner mening i arbeidet og som kan forvalte sin helse og velvære, klarer seg sannsynligvis bra. Lenger ned i denne rapporten kan du finne detaljerte svar på spørsmål om velvære på arbeidet. Tenk på hvordan de kan veilede deg til å sette mål for å forbedre dine prestasjoner og ditt velvære.",
                sv: "Arbetsprestation och välbefinnande på jobbet påverkas av flera faktorer relaterade till både den organisation du arbetar i och dig som individ. Engagerade anställda som finner mening i sitt arbete och kan hantera sin hälsa och välbefinnande kommer sannolikt att prestera bra. Längre ner i denna rapport kan du hitta dina detaljerade svar på frågor om välbefinnande på jobbet. Reflektera över hur de kan vägleda dig till att sätta mål för att förbättra dina prestationer och ditt välbefinnande.",
                pl: "Na Twoje wyniki i samopoczucie w pracy wpływa wiele czynników dotyczących organizacji, w której pracujesz, jak i Ciebie. Zaangażowani pracownicy, którzy odnajdują sens swojej pracy i potrafią zarządzać swoim zdrowiem oraz samopoczuciem, prawdopodobnie osiągają lepsze wyniki w pracy. W dalszej części niniejszego raportu znajdziesz swoje szczegółowe odpowiedzi na pytania dotyczące samopoczucia w pracy. Zastanów się nad tym, jak te odpowiedzi pozwolą Ci wyznaczyć sobie cel dotyczący poprawy wyników i samopoczucia.",
                ru: "На производительность и самочувствие на рабочем месте влияют самые разные факторы, связанные как с организацией, в которой вы работаете, так и с вами лично. Те, кто работает с энтузиазмом, находят смысл в своей работе и могут управлять своим здоровьем и самочувствием, с большей вероятностью показывают высокие результаты. Ниже в данном отчете вы увидите свои детальные ответы на вопросы о самочувствии на работе. Поразмыслите над тем, как они могут направить вас к постановке цели для улучшения производительности и самочувствия.",
                es: "¿Qué podría mejorar su rendimiento en el trabajo? Desplácese hacia abajo hasta las preguntas de trabajo: objetivo profesional, realización, aprendizaje, energía, recursos. ¿Qué podría ayudarle a sobrellevar mejor la situación, llegar más alto o experimentar un mayor rendimiento?",
                fr: "Qu’est-ce qui pourrait améliorer vos performances au travail ? Faites défiler la page jusqu’aux questions relatives au travail : objectif professionnel, accomplissement, apprentissage, énergie, ressources. Qu’est-ce qui pourrait vous aider à mieux vous adapter, à vous élever ou à être plus performant ?",
                pt: "O que poderia melhorar o seu desempenho no trabalho? Navegue para baixo na página até às questões relacionadas com o trabalho: propósito profissional, realização, aprendizagem, energia, recursos. O que é que o(a) poderia ajudar a lidar melhor com as situações, a progredir mais ou a ter um melhor desempenho?",
                zh: "什么可以提高您的工作表现/绩效？向下滚动到与工作相关的问题：职业目标、成就、学习、精力、资源。什么能帮助您更好地应对工作、实现更高的绩效或拥有更出色的表现？"
              },
              work_total: {
                da: "Samlet resultat for trivsel på arbejdspladsen",
                de: "Punktzahl für Wohlbefinden am Arbeitsplatz",
                en: "Wellbeing at work score",
                et: "Tööheaolu lõpptulemus",
                fi: "Hyvinvointi työssä - kokonaispisteet",
                no: "Total-score for velvære på arbeidet",
                sv: "Total poäng för välbefinnande på jobbet",
                pl: "Samopoczucie w pracy — wynik łączny",
                ru: "Общий балл за самочувствие на работе",
                es: "Puntuación de bienestar en el trabajo",
                fr: "Score de mieux-être au travail",
                pt: "Pontuação do bem-estar no trabalho",
                zh: "工作状态健康度评分"
              }
            },
            SectionWellbeingGoalAndChangesView: {
              title: {
                da: "Mål og forandring inden for velvære",
                de: "Wohlbefinden – Ziel und Veränderungen",
                en: "Wellbeing goal and changes",
                et: "Heaolu-alane eesmärk ja muutused",
                fi: "Hyvinvoinnin tavoite ja muutokset",
                no: "Må for velvære og endring",
                sv: "Hälsa och välbefinnande",
                pl: "Samopoczucie — cel i zmiana",
                ru: "Благополучие: цель и изменение",
                es: "Objetivo de bienestar y cambios",
                fr: "Objectif et changements en matière de mieux-être",
                pt: "Objetivo e mudanças do bem-estar",
                zh: "健康目标和改变"
              },
              reached_goal_title: {
                da: "Dit oprindeligt erklærede mål:",
                de: "Ihr ursprünglich angegebenes Ziel:",
                en: "Your initially stated goal:",
                et: "Sinu algselt paika pandud eesmärk:",
                fi: "Alkuperäinen tavoitteesi:",
                no: "Ditt opprinnelig uttalte mål:",
                sv: "Ditt mål i början:",
                pl: "Twój początkowy cel:",
                ru: "Изначально заявленная Вами цель:",
                es: "Su objetivo inicial:",
                fr: "Votre objectif initial :",
                pt: "O seu objetivo inicial:",
                zh: "您最初提出的目标："
              },
              goal_open_answer: {
                da: "#",
                de: "#",
                en: "No goal provided:",
                et: "#",
                fi: "#",
                no: "#",
                sv: "#",
                pl: "#",
                ru: "#",
                es: "No se ha marcado ningún objetivo:",
                fr: "Aucun objectif défini :",
                pt: "Não foi indicado um objetivo:",
                zh: "未设定目标："
              },
              how_well_reached_goal_title: {
                da: "Din vurdering af, hvor godt du nåede dit mål:",
                de: "Ihre Einschätzung, wie gut Sie Ihr Ziel erreicht haben:",
                en: "Your assessment of how well you reached your goal:",
                et: "Sinu hinnang liikumisele selle eesmärgi suunas:",
                fi: "Arviosi siitä, kuinka hyvin saavutit tavoitteesi:",
                no: "Din vurdering av hvor godt du nådde målet ditt:",
                sv: "Din bedömning av hur väl du nått ditt mål:",
                pl: "Twoja ocena stopnia osiągnięcia celu:",
                ru: "Ваша оценка того, насколько достигнут желаемый результат:",
                es: "Su evaluación de hasta qué punto ha alcanzado su objetivo:",
                fr: "Votre évaluation par rapport à l’atteinte de votre objectif :",
                pt: "A sua avaliação do nível de concretização do seu objetivo:",
                zh: "您对目标实现情况的评估："
              },
              positive_life_style_title: {
                da: "Din vurdering af, hvor godt du skabte en positiv livsstilsforandring i løbet af programmet:",
                de: "Ihre Einschätzung, wie gut Sie während des Programms Ihren Lebensstil verändern konnten:",
                en: "Your assessment of how well you created positive lifestyle change during the programme:",
                et: "Sinu hinnang sellele, kui hästi oled suutnud teha programmi käigus positiivseid muutusi oma elustiilis:",
                fi: "Arviosi siitä, kuinka hyvin onnistuit positiivisen elämäntapamuutoksen tekemisessä ohjelman aikana:",
                no: "Din vurdering av hvor godt du skapte en positiv livsstilsendring i løpet av programmet:",
                sv: "Din bedömning av hur väl du nådde en positiv livsstilsförändring under programmet:",
                pl: "Twoja ocena skuteczności wprowadzenia przez siebie pozytywnej zmiany w stylu życia podczas trwania programu:",
                ru: "Ваша оценка того, насколько Вам удались позитивные изменения в Вашем образе жизни за время прохождения программы:",
                es: "Su evaluación de cómo ha logrado un cambio positivo en su estilo de vida durante el programa:",
                fr: "Votre évaluation de la manière dont vous avez réussi à modifier positivement votre mode de vie au cours du programme :",
                pt: "A sua avaliação da forma como gerou uma mudança positiva no seu estilo de vida durante o programa:",
                zh: "您对自己在训练计划期间生活方式改变情况的评估："
              },
              changed_or_achieved_in_programme_title: {
                da: "En ting, du har ændret eller opnået i dette program:",
                de: "Eine Sache, die Sie in diesem Programm verändert oder erreicht haben:",
                en: "One thing you've changed or achieved in this programme:",
                et: "Üks asi, mida sa selle programmi käigus oled muutnud või saavutanud:",
                fi: "Yksi asia, jonka muutit tai saavutit tässä ohjelmassa:",
                no: "En ting du har endret eller oppnådd i dette programmet:",
                sv: "En sak du har förändrat eller uppnått i det här programmet:",
                pl: "Twoja zmiana lub osiągnięcie zaistniałe podczas programu:",
                ru: "Один момент, который Вам удалось изменить, или одно достижение за время прохождения программы:",
                es: "Algo que haya conseguido cambiar o lograr en este programa:",
                fr: "Une chose que vous avez changée ou accomplie durant ce programme :",
                pt: "Uma coisa que mudou ou alcançou com este programa:",
                zh: "您已经改进或实现的计划事项："
              },
              goal_footer_text: {
                da: "Bemærk, at det mål, du anførte i starten, måske ikke er det, du og din træner arbejdede på under dit program.",
                de: "Beachten Sie, dass das Ziel, das Sie ursprünglich angegeben haben, möglicherweise nicht das ist, woran Sie und Ihr Coach während des Programms gearbeitet haben.",
                en: "Note that the goal you stated initially might not be what you and your coach worked on during your programme.",
                et: "Pane tähele, et sinu esialgu välja öeldud eesmärk ei pruugi olla see, millega sina ja su juhendaja programmi käigus tööd tegite.",
                fi: "Huomaa, että aluksi ilmoittamasi alkuperäinen tavoite ei välttämättä ole se, mitä sinä ja valmentajasi päädyitte ohjelmassa työstämään.",
                no: "Merk at målet du oppga i utgangspunktet kanskje ikke var det du og coachen din jobbet med under programmet.",
                sv: "Observera att målet du satte ut i början kanske inte var det du och din coach arbetade med under ditt program.",
                pl: "Pamiętaj, że początkowo określony cel może nie pokrywać się z pracą realizowaną z trenerem w ramach programu.",
                ru: "Обратите внимание, что цель, которую Вы ставили себе изначально, может не совпадать с тем, над чем Вы с коучем работали во время прохождения программы.",
                es: "Tenga en cuenta que el objetivo que estableció inicialmente podría no ser lo que usted y su coach trabajaron durante el programa.",
                fr: "Notez que votre objectif initial peut ne pas être celui sur lequel vous et votre coach avez travaillé au cours de votre programme.",
                pt: "Tenha em atenção que o objetivo que indicou inicialmente pode não ser aquele em que trabalhou durante o programa.",
                zh: "请注意，您最初提出的目标可能不是您和您辅导者在计划中努力实现的目标。"
              },
              reflect_changes_youve_made_title: {
                da: "Reflekter over de forandringer, du har gennemført:",
                de: "Denken Sie über die Änderungen nach, die Sie vorgenommen haben:",
                en: "Reflect on the changes you've made:",
                et: "Mõtle tagasi enda tehtud muutustele.",
                fi: "Pohdi tekemiäsi muutoksia:",
                no: "Reflekter over endringene du har gjort:",
                sv: "Reflektera över de ändringar du har gjort:",
                pl: "Zastanów się nad wprowadzonymi zmianami:",
                ru: "Подумайте о произошедших с Вами изменениях:",
                es: "Reflexione sobre los cambios que ha realizado:",
                fr: "Réfléchissez aux changements que vous avez mis en œuvre :",
                pt: "Reflita sobre as mudanças que fez:",
                zh: "思考自己做出的改变："
              },
              reflect_changes_1: {
                da: "Hvis du stod over for forhindringer, hvad ville du så gøre anderledes nu?",
                de: "Wenn Sie auf Hindernisse stoßen würden – was würden Sie jetzt anders machen?",
                en: "If you faced obstacles - what would you now do differently?",
                et: "Kui kohtaksid takistusi, siis mida sa teeksid praegu teisiti?",
                fi: "Jos kohtasit vaikeuksia, mitä tekisit nyt toisin?",
                no: "Hvis du møtte hindringer – hva ville du gjort annerledes nå?",
                sv: "vad skulle du nu göra annorlunda?",
                pl: "Jeżeli wystąpiły przeszkody, to co teraz zrobił(a)byś inaczej?",
                ru: "Если бы Вам сейчас пришлось решать какую-то проблему, что бы Вы сделали по-другому?",
                es: "Si se enfrentara a obstáculos, ¿qué haría ahora de forma diferente?",
                fr: "Si vous étiez face à des obstacles actuellement, que feriez-vous différemment ?",
                pt: "Em relação aos obstáculos com que se deparou - o que faria de forma diferente agora?",
                zh: "如果您在开始训练后遇到了障碍——您现在会做什么不同的事情？"
              },
              reflect_changes_2: {
                da: "Hvis du nåede eller overgik dit mål eller dine forandringer, hvordan kan du så opretholde dem nu?",
                de: "Wenn Sie Ihr Ziel oder Ihre Veränderungen erreicht oder übertroffen haben – wie können Sie diese jetzt beibehalten?",
                en: "If you reached or exceeded your goal or changes - how can you now sustain them?",
                et: "Kui saavutasid või ületasid seatud eesmärgi, siis kuidas nüüd saavutatut hoida?",
                fi: "Jos saavutit tai ylitit tavoitteesi tai sait aikaan muutoksia, miten voit nyt ylläpitää niitä?",
                no: "Hvis du nådde eller overskred målet eller endringene – hvordan kan du nå opprettholde dem?",
                sv: "Om du nådde eller överträffade ditt mål eller dina förändringar: hur kan du nu upprätthålla dem?",
                pl: "Jeśli udało Ci się wprowadzić zmiany lub osiągnąć cel, albo nawet przekroczyć go, to jak możesz teraz utrzymać ten stan?",
                ru: "Если Вы достигли своих целей и желаемых изменений или даже превзошли их, как Вы можете закрепить результат?",
                es: "Si ha alcanzado o superado su objetivo o sus cambios, ¿cómo puede mantenerlos ahora?",
                fr: "Si vous avez atteint ou dépassé votre objectif ou si vous avez accompli les changements souhaités, comment pouvez-vous maintenant les maintenir ?",
                pt: "Em relação aos objetivos, ou mudanças, que alcançou, ou ultrapassou - como pode agora mantê-los?",
                zh: "如果您实现了目标、超越了目标或取得了进步——您现在如何保持现有的状态或取得的进步？"
              },
              biggest_individual_changes_title: {
                da: "Største individuelle forandringer",
                de: "Größte Veränderungen",
                en: "Biggest individual changes",
                et: "Suurimad isiklikud väljakutsed",
                fi: "Suurimmat yksittäiset muutokset",
                no: "Største individuelle endringer",
                sv: "Största individuella förändringar",
                pl: "Największe zmiany indywidualne",
                ru: "Наиболее существенные индивидуальные изменения",
                es: "Mayores cambios individuales",
                fr: "Principaux changements individuels",
                pt: "Maiores mudanças individuais",
                zh: "最大的个人变化"
              },
              continued_strengths_title: {
                da: "Fortsatte styrker",
                de: "Unveränderte Stärken",
                en: "Continued strengths",
                et: "Jätkuvalt sinu tugevad küljed",
                fi: "Jatkuvat vahvuudet",
                no: "Fortsatte styrker",
                sv: "Fortsatta styrkor",
                pl: "Trwałe mocne strony",
                ru: "Закрепление успеха",
                es: "Fortalezas sostenidas",
                fr: "Forces encore présentes",
                pt: "Pontos fortes contínuos",
                zh: "仍然拥有的优势"
              },
              focus_on_title: {
                da: "Fokus på en af disse som det næste?",
                de: "Konzentrieren Sie sich als Nächstes auf eines dieser Themen?",
                en: "Focus on one of these next?",
                et: "Kas keskendud järgmisena ühele neist?",
                fi: "Keskitytkö seuraavaksi johonkin näistä?",
                no: "Fokusere på en av disse som neste?",
                sv: "Fokusera på en av dessa härnäst?",
                pl: "Obszary warte uwagi",
                ru: "Сосредоточиться на какой-либо стороне успеха?",
                es: "¿Centrarse en uno de ellos a continuación?",
                fr: "Concentrez-vous ensuite sur l’une d’elles ?",
                pt: "Concentrar-se num destes a seguir?",
                zh: "接下来专注于其中之一？"
              }
            }
          }
        }
      },
      survey: {
        banners: {
          PrivacyBanner: {
            privacy_note: {
              da: "Dette er et sikkert sted, hvor du kan fokusere på dit velbefindende. Dine svar er [fortrolige](https://www.hintsa.com/privacy/).",
              de: "Dies ist ein sicherer Raum, in dem Sie sich auf Ihr Wohlbefinden konzentrieren können. Ihre Antworten sind [vertraulich](https://www.hintsa.com/privacy/).",
              en: "This is a safe space to focus on your wellbeing. Your answers are [confidential](https://www.hintsa.com/privacy/).",
              et: "See on turvaline keskkond, kus saab keskenduda oma heaolule. Teie vastused on [konfidentsiaalsed](https://www.hintsa.com/privacy/).",
              fi: "Täällä voit turvallisesti keskittyä hyvinvointiisi. Vastauksesi ovat [luottamuksellisia](https://www.hintsa.com/privacy/).",
              no: "Dette er et trygt sted å fokusere på ditt velvære. Svarene dine er [konfidensielle](https://www.hintsa.com/privacy/).",
              sv: "Detta är en tryggt plats där du kan fokusera på ditt välbefinnande. Dina svar är [konfidentiella](https://www.hintsa.com/privacy/).",
              pl: "Oferujemy bezpieczną przestrzeń, w której możesz skupić się na swoim samopoczuciu. Odpowiedzi są [poufne](https://www.hintsa.com/privacy/).",
              ru: "Это безопасное пространство для работы над вашим благополучием. Ваши ответы являются [конфиденциальными](https://www.hintsa.com/privacy/).",
              es: "Este es un espacio seguro para centrarse en su bienestar. Sus respuestas son [confidenciales](https://www.hintsa.com/privacy/).",
              fr: "Il s’agit d’un espace sécurisé pour se concentrer sur votre bien-être. Vos réponses sont [confidentielles](https://www.hintsa.com/privacy/).",
              pt: "Este é um espaço seguro para se concentrar no seu bem-estar. As suas respostas são [confidenciais](https://www.hintsa.com/privacy/).",
              zh: "这是一个专注于您健康的安全之地。您的回答是[保密的](https://www.hintsa.com/privacy/)。"
            }
          }
        },
        ui: {
          questions: {
            CheckboxQuestionView: {
              noneOfTheAbove: {
                fi: "Ei mikään ylläolevista",
                en: "None of the above",
                ru: "Ни один из вышеперечисленных",
                pl: "Żadne z powyższych",
                et: "Mitte ükski ülaltoodust",
                sv: "Inget av ovanstående",
                lv: "Nė vienas iš aukščiau paminėtų",
                lt: "Neviens no iepriekš minētajiem",
                de: "Nichts des oben Genannten",
                es: "Ninguna de las anteriores",
                da: "Ingen af ovenstående",
                no: "Ingen av de ovennevnte",
                fr: "Aucune de ces réponses",
                pt: "Nenhuma das opções anteriores",
                zh: "以上都不是"
              }
            },
            FieldQuestionView: {
              open_question_desc: {
                fi: "Vastaukset näytetään raportissa siinä muodossa kuin ne on kirjoitettu. Vältä kirjoittamasta vastaukseen tietoja, joista sinut tai muita henkiöitä voidaan tunnistaa. Kyselyssä tulee olla vähintään viisi vastaajaa, jotta vastaukset näkyvät raportilla. Avoimet vastaukset jaetaan tiimin esimiehelle anonyymeinä",
                en: "Your answers will be included in the report exactly as written by you. Please avoid including information from which you, or anyone else, can be identified. The answers will be included in the report, if the survey has a minimum of five respondents. Open responses are shared anonymously with team leaders.",
                ru: "Ваши ответы будут включены в отчет именно в той форме, в которой вы их написали. Пожалуйста, не указывайте информацию, по которой можно было бы установить вашу личность или личность каких-либо других людей. Ответы будут включены в отчет, если на опрос ответят не менее пяти человек.",
                pl: "Twoje odpowiedzi znajdą się w raporcie w oryginalnym brzmieniu. Unikaj podawania informacji pozwalających na identyfikację Ciebie lub innych osób. Odpowiedzi znajdą się w raporcie, jeśli w ankiecie weźmie udział co najmniej pięć osób.",
                et: "Teie vastused lisatakse kokkuvõttesse täpselt teie poolt kasutatud sõnastuses. Ärge palun lisage ennast või teisi tuvastada võimaldavat infot. Vastused lisatakse kokkuvõttesse, kui vastajaid on vähemalt viis.",
                sv: "Dina svar kommer att inkluderas i rapporten exakt så som du skrivit dem. Undvik att ta med information som gör att du, eller någon annan, kan identifieras. Svaren kommer att inkluderas i rapporten, om undersökningen har minst fem deltagare.",
                lv: "Jūsu atbildes tiks iekļautas ziņojumā tieši tā, kā jūs tās uzrakstīsiet. Lūdzu, izvairieties iekļaut informāciju, kas ļautu identificēt jūs vai kādu citu personu. Atbildes tiks iekļautas ziņojumā, ja aptaujā ir vismaz pieci respondenti.",
                lt: "Ataskaitoje bus pateikti tiksliai tokie pat atsakymai, kuriuos nurodysite raštu. Nenurodykite informacijos, iš kurios būtų galima nustatyti jūsų ar kitos asmens tapatybę. Atsakymai bus įtraukti į ataskaitą, jei apklausoje dalyvaus mažiausiai penki respondentai.",
                de: "Ihre Antworten werden unverändert im Bericht aufgeführt. Bitte machen Sie keine Angaben, die Sie oder eine andere Person identifizierbar machen. Die Antworten werden im Bericht aufgeführt, wenn mindestens fünf Personen an der Befragung teilnehmen. Die offenen Antworten werden in anonymisieter Form an die Vorgesetzten der Teams verteilt.",
                es: "Sus respuestas se incluirán en el informe exactamente como usted las haya escrito. Le rogamos que evite incluir información que permita identificarle a usted o a cualquier otra persona. Las respuestas se incluirán en el informe, si la encuesta tiene un mínimo de cinco encuestados. Las respuestas abiertas se comparten de forma anónima con los jefes de equipo.",
                da: "Dine svar vil blive inkluderet i rapporten, præcis som du har skrevet dem. Undlad at medtage information, som kan bruges til at identificere dig eller andre. Svarene vil blive inkluderet i rapporten, hvis undersøgelsen har mindst fem respondenter. Åbne svar deles anonymt med teamledere.",
                no: "Svarene dine vil bli inkludert i rapporten nøyaktig slik du har skrevet dem. Unngå å inkludere informasjon som du, eller noen andre, kan identifiseres fra. Svarene vil inkluderes i rapporten, hvis undersøkelsen har minst fem respondenter. Åpne svar deles anonymt med teamledere.",
                fr: "Vos réponses seront incluses dans le rapport telles que vous les avez rédigées. Veuillez éviter d’inclure des informations qui permettraient de vous identifier ou d’identifier quelqu’un d’autre. Les réponses seront incluses dans le rapport si l’enquête compte un minimum de cinq répondants. Les réponses ouvertes sont communiquées de manière anonyme aux chefs d’équipe.",
                pt: "As suas respostas serão incluídas no relatório exatamente como foram escritas por si. Evite escrever informações que permitam a sua identificação ou a de qualquer outra pessoa. As respostas serão incluídas no relatório, se o inquérito tiver, no mínimo, cinco inquiridos. As respostas abertas são partilhadas anonimamente com os líderes de equipa.",
                zh: "您的回答将原封不动地写入报告。请避免提供可确定您或其他任何人身份的信息。如果回答调查问题的参与者不少于五名，参与者的回答将被纳入报告。非匿名的回答以匿名方式分享给团队领导。"
              },
              open_question_placeholder: {
                fi: "Kirjoita vastauksesi tähän...",
                en: "Please write your answer here...",
                ru: "Введите свой ответ здесь...",
                pl: "Wpisz tutaj odpowiedź...",
                et: "Palun kirjutage oma vastus siia...",
                sv: "Skriv ditt svar här...",
                lv: "Lūdzu, rakstiet savu atbildi šeit...",
                lt: "Savo atsakymą pateikite čia...",
                de: "Bitte Antwort hier eingeben...",
                no: "Skriv svaret ditt her ...",
                es: "Por favor, escriba aquí su respuesta...",
                da: "Skriv dit svar her ...",
                "": {},
                fr: "Veuillez écrire votre réponse ici…",
                pt: "Escreva a sua resposta aqui...",
                zh: "请在此写下您的回答……"
              }
            },
            RadioQuestionView: {
              open_question_desc: {
                fi: "Vastaukset näytetään raportissa siinä muodossa kuin ne on kirjoitettu. Vältä kirjoittamasta vastaukseen tietoja, joista sinut tai muita henkiöitä voidaan tunnistaa. Kyselyssä tulee olla vähintään viisi vastaajaa, jotta vastaukset näkyvät raportilla. Avoimet vastaukset jaetaan tiimin esimiehelle anonyymeinä",
                en: "Your answers will be included in the report exactly as written by you. Please avoid including information from which you, or anyone else, can be identified. The answers will be included in the report, if the survey has a minimum of five respondents. Open responses are shared anonymously with team leaders.",
                ru: "Ваши ответы будут включены в отчет именно в той форме, в которой вы их написали. Пожалуйста, не указывайте информацию, по которой можно было бы установить вашу личность или личность каких-либо других людей. Ответы будут включены в отчет, если на опрос ответят не менее пяти человек.",
                pl: "Twoje odpowiedzi znajdą się w raporcie w oryginalnym brzmieniu. Unikaj podawania informacji pozwalających na identyfikację Ciebie lub innych osób. Odpowiedzi znajdą się w raporcie, jeśli w ankiecie weźmie udział co najmniej pięć osób.",
                et: "Teie vastused lisatakse kokkuvõttesse täpselt teie poolt kasutatud sõnastuses. Ärge palun lisage ennast või teisi tuvastada võimaldavat infot. Vastused lisatakse kokkuvõttesse, kui vastajaid on vähemalt viis.",
                sv: "Dina svar kommer att inkluderas i rapporten exakt så som du skrivit dem. Undvik att ta med information som gör att du, eller någon annan, kan identifieras. Svaren kommer att inkluderas i rapporten, om undersökningen har minst fem deltagare.",
                lv: "Jūsu atbildes tiks iekļautas ziņojumā tieši tā, kā jūs tās uzrakstīsiet. Lūdzu, izvairieties iekļaut informāciju, kas ļautu identificēt jūs vai kādu citu personu. Atbildes tiks iekļautas ziņojumā, ja aptaujā ir vismaz pieci respondenti.",
                lt: "Ataskaitoje bus pateikti tiksliai tokie pat atsakymai, kuriuos nurodysite raštu. Nenurodykite informacijos, iš kurios būtų galima nustatyti jūsų ar kitos asmens tapatybę. Atsakymai bus įtraukti į ataskaitą, jei apklausoje dalyvaus mažiausiai penki respondentai.",
                de: "Ihre Antworten werden unverändert im Bericht aufgeführt. Bitte machen Sie keine Angaben, die Sie oder eine andere Person identifizierbar machen. Die Antworten werden im Bericht aufgeführt, wenn mindestens fünf Personen an der Befragung teilnehmen. Die offenen Antworten werden in anonymisieter Form an die Vorgesetzten der Teams verteilt.",
                es: "Sus respuestas se incluirán en el informe exactamente como usted las haya escrito. Le rogamos que evite incluir información que permita identificarle a usted o a cualquier otra persona. Las respuestas se incluirán en el informe, si la encuesta tiene un mínimo de cinco encuestados. Las respuestas abiertas se comparten de forma anónima con los jefes de equipo.",
                da: "Dine svar vil blive inkluderet i rapporten, præcis som du har skrevet dem. Undlad at medtage information, som kan bruges til at identificere dig eller andre. Svarene vil blive inkluderet i rapporten, hvis undersøgelsen har mindst fem respondenter. Åbne svar deles anonymt med teamledere.",
                no: "Svarene dine vil bli inkludert i rapporten nøyaktig slik du har skrevet dem. Unngå å inkludere informasjon som du, eller noen andre, kan identifiseres fra. Svarene vil inkluderes i rapporten, hvis undersøkelsen har minst fem respondenter. Åpne svar deles anonymt med teamledere.",
                fr: "Vos réponses seront incluses dans le rapport telles que vous les avez rédigées. Veuillez éviter d’inclure des informations qui permettraient de vous identifier ou d’identifier quelqu’un d’autre. Les réponses seront incluses dans le rapport si l’enquête compte un minimum de cinq répondants. Les réponses ouvertes sont communiquées de manière anonyme aux chefs d’équipe.",
                pt: "As suas respostas serão incluídas no relatório exatamente como foram escritas por si. Evite escrever informações que permitam a sua identificação ou a de qualquer outra pessoa. As respostas serão incluídas no relatório, se o inquérito tiver, no mínimo, cinco inquiridos. As respostas abertas são partilhadas anonimamente com os líderes de equipa.",
                zh: "您的回答将原封不动地写入报告。请避免提供可确定您或其他任何人身份的信息。如果回答调查问题的参与者不少于五名，参与者的回答将被纳入报告。非匿名的回答以匿名方式分享给团队领导。"
              },
              open_question_placeholder: {
                fi: "Kirjoita vastauksesi tähän...",
                en: "Please write your answer here...",
                ru: "Введите свой ответ здесь...",
                pl: "Wpisz tutaj odpowiedź...",
                et: "Palun kirjutage oma vastus siia...",
                sv: "Skriv ditt svar här...",
                lv: "Lūdzu, rakstiet savu atbildi šeit...",
                lt: "Savo atsakymą pateikite čia...",
                de: "Bitte Antwort hier eingeben...",
                no: "Vennligst skriv svaret ditt her...",
                da: "Skriv venligst dit svar her… ",
                es: "Por favor, escriba aquí su respuesta...",
                fr: "Veuillez écrire votre réponse ici…",
                pt: "Escreva a sua resposta aqui...",
                zh: "请在此写下您的回答……"
              }
            }
          }
        },
        SurveyApp: {
          title: {
            en: "Questionnaire",
            fi: "Kysely",
            ru: "Опрос",
            pl: "Ankieta",
            et: "Küsitlus",
            sv: "Enkät",
            lv: "Aptauja",
            lt: "Apklausa",
            de: "Fragebogen",
            es: "Cuestionario",
            da: "Spørgeskema",
            no: "Spørreskjema",
            fr: "Questionnaire",
            pt: "Questionário",
            zh: "调查问卷"
          },
          login_error: {
            en: "An error occurred when logging in. Please reload the page or try again later.",
            fi: "Kirjautuminen epäonnistui teknisen ongelman takia. Ole hyvä ja päivitä sivu tai kokeile myöhemmin uudelleen.",
            ru: "Не удалось войти из-за технической неполадки. Пожалуйста, обновите страницу или повторите попытку позднее.",
            pl: "Logowanie nie powiodło się z powodu problemu technicznego. Odśwież stronę i spróbuj ponownie.",
            et: "Sisselogimine ebaõnnestus tehnilise probleemi tõttu. Uuenda lehte või proovi hiljem uuesti.",
            sv: "Inloggningen misslyckades på grund av ett tekniskt problem. Uppdatera sidan eller försök igen senare.",
            lv: "Tehniskas problēmas dēļ pierakstīšanās bija neveiksmīga. Lūdzu atjauniniet lapu vai mēģiniet no jauna vēlāk.",
            lt: "Nepavyko prisijungti dėl techninės klaidos. Prašome perkrauti puslapį arba pabandyti prisijungti vėliau.",
            de: "Fehler beim Anmelden. Bitte Seite erneut laden oder später erneut versuchen.",
            es: "Se ha producido un error al iniciar sesión. Por favor, vuelva a cargar la página o inténtelo más tarde.",
            da: "Der opstod en fejl ved log ind. Genindlæs siden, eller prøv igen senere.",
            no: "Det oppstod en feil under innlogging. Last inn siden på nytt eller prøv igjen senere.",
            fr: "Une erreur s’est produite lors de la connexion. Veuillez recharger la page ou réessayer plus tard.",
            pt: "Ocorreu um erro ao iniciar sessão. Recarregue a página ou tente novamente mais tarde.",
            zh: "登录时发生了错误。请重新加载页面或稍后再试。"
          },
          invalid_grant: {
            en: "Personal id or email not valid",
            fi: "Pahoittelut, käyttäjätunnusta ei löydy.",
            ru: "К сожалению, имя пользователя не найдено.",
            pl: "Przepraszamy, nie znaleziono użytkownika o podanym loginie.",
            et: "Vabandust, kasutajatunnust ei leitud.",
            sv: "Användarnamnet kunde tyvärr inte hittas.",
            lv: "Atvainojamies, šāds lietotājvārds nav atrasts.",
            lt: "Napavyko identifikuoti jūsų darbuotojo ID.",
            de: "Persönliche ID nicht gefunden.",
            es: "No hemos podido encontrar su identificación personal.",
            da: "Personligt id eller e-mail ikke gyldig",
            no: "Personlig ID eller e-post er ikke gyldig",
            fr: "L’identifiant personnel ou l’adresse e-mail n’est pas valide",
            pt: "ID pessoal ou e-mail inválido",
            zh: "个人 ID 或电子邮箱无效"
          },
          email_detected: {
            en: "Seems like you are trying to log in with your email address. Please use your own ID instead.",
            fi: "Näyttää siltä, että yrität kirjautua sähköpostiosoitteella. Käytä omaa tunnustasi sen sijaan.",
            ru: "По всей видимости, вы пытаетесь выполнить вход с помощью своего адреса электронной почты. Используйте вместо него своё имя пользователя.",
            pl: "Wygląda na to, że próbujesz zalogować się przy użyciu swojego adresu e-mail. Zamiast tego użyj swojego identyfikatora.",
            et: "Näib, et proovid sisse logida oma e-posti aadressiga. Kasuta selle asemel oma ID-d.",
            sv: "Det verkar som att du försöker logga in med din e-postadress. Vänligen använd ditt eget ID istället.",
            lv: "Šķiet, ka jūs mēģināt pierakstīties, izmantojot savu e-pasta adresi. Lūdzu, tās vietā izmantojiet savu ID numuru.",
            lt: "Atrodo, kad bandote prisijungti savo el. pašto adresu. Prašome naudoti savo ID kodą.",
            de: "Sie möchten sich anscheinend mit Ihrer E-Mail-Adresse anmelden. Bitte verwenden Sie stattdessen Ihre persönliche ID.",
            es: "Parece que está intentando iniciar sesión con su dirección de correo electrónico. Por favor, utilice su propia identificación.",
            da: "Det ser ud til, at du forsøger at logge ind med din e-mailadresse. Brug dit eget id i stedet.",
            no: "Det ser ut til at du prøver å logge på med e-postadressen din. Bruk din egen ID i stedet.",
            fr: "Il semblerait que vous essayez de vous connecter avec votre adresse e-mail. Veuillez utiliser votre identifiant personnel.",
            pt: "Parece que está a tentar iniciar sessão com o seu endereço de e-mail. Utilize antes o seu ID pessoal.",
            zh: "似乎您在试图用电子邮件地址进行登录。请使用您的 ID 登录。"
          },
          email_validation_error: {
            en: "There was an error trying to validate your email address.",
            fi: "Sähköpostiosoitteesi vahvistamisessa tapahtui virhe.",
            ru: "Произошла ошибка при попытке подтвердить ваш адрес электронной почты.",
            pl: "Wystąpił błąd podczas próby zweryfikowania Twojego adresu e-mail.",
            et: "Teie e-posti aadressi kinnitamisel ilmnes viga.",
            sv: "Det uppstod ett fel när du försökte validera din e-postadress.",
            lv: "Mēģinot apstiprināt jūsu e-pasta adresi, radās kļūda.",
            lt: "Nepavyko patvirtinti jūsų el. Pašto",
            de: "Beim Versuch, Ihre E-Mail-Adresse zu validieren, ist ein Fehler aufgetreten.",
            es: "Se ha producido un error al intentar validar su dirección de correo electrónico.",
            da: "Der opstod en fejl, da vi forsøgte at bekræfte din e-mailadresse.",
            no: "Det oppstod en feil under forsøk på å validere e-postadressen din.",
            fr: "Une erreur s’est produite lors de la validation de votre adresse e-mail.",
            pt: "Ocorreu um erro ao tentar validar o seu endereço de e-mail.",
            zh: "验证您的电子邮件地址时出现错误。"
          },
          email_token_invalid: {
            en: "The token provided did not match our records.",
            fi: "Annettu tunnus ei täsmää.",
            ru: "Предоставленный токен не соответствует.",
            pl: "Dostarczony token nie pasuje.",
            et: "Esitatud märk ei ühtinud.",
            sv: "Det angivna tokenet matchade inte.",
            lv: "Norādītais marķieris neatbilst.",
            lt: "Pateiktas prieigos raktas neatitiko.",
            de: "Das bereitgestellte Token stimmte nicht überein.",
            es: "El token proporcionado no coincide con nuestros registros.",
            da: "Det angivne token stemte ikke overens med vores registreringer.",
            no: "Tokenet som ble oppgitt, samsvarte ikke med postene våre.",
            fr: "Le jeton fourni ne correspond pas à nos informations.",
            pt: "O token indicado não corresponde aos nossos registos.",
            zh: "所提供的凭证与我们的记录不符。"
          },
          email_check_token: {
            en: "Please check the token value and try again.",
            fi: "Tarkista tunnisteen arvo ja yritä uudelleen.",
            ru: "Проверьте значение токена и повторите попытку.",
            pl: "Sprawdź wartość tokena i spróbuj ponownie.",
            et: "Kontrollige märgi väärtust ja proovige uuesti.",
            sv: "Kontrollera tokenvärdet och försök igen.",
            lv: "Lūdzu, pārbaudiet marķiera vērtību un mēģiniet vēlreiz.",
            lt: "Patikrinkite prieigos rakto vertę ir bandykite dar kartą.",
            de: "Bitte überprüfen Sie den Tokenwert und versuchen Sie es erneut.",
            es: "Por favor, compruebe el valor del token e inténtelo de nuevo.",
            da: "Tjek token-værdien, og prøv igen.",
            no: "Kontroller tokenverdien og prøv igjen.",
            fr: "Veuillez vérifier la valeur du jeton et réessayer.",
            pt: "Verifique o valor do token e tente novamente.",
            zh: "请检查凭证号并重试。"
          }
        },
        pages: {
          personal_details: {
            PersonalDetailsPage: {
              email_domain_error: {
                en: "Email must end with {0}",
                fi: "Sähköpostin on päättyttävä {0}",
                de: "E-Mail muss mit {0} enden",
                es: "El correo electrónico debe terminar con {0}",
                da: "Email skal ende med {0}",
                et: "E-post peab lõppema {0}-ga",
                no: "E-post må ende med {0}",
                pl: "Email musi kończyć się na {0}",
                ru: "Адрес электронной почты должен оканчиваться на {0}",
                sv: "E-post måste sluta med {0}",
                fr: "L’adresse e-mail doit se terminer par {0}",
                pt: "O e-mail tem de terminar com {0}",
                zh: "电子邮件地址必须以 {0} 为结尾"
              }
            },
            PersonalDetailsPageView: {
              firstName: {
                en: "First name",
                fi: "Etunimi",
                de: "Vorname",
                es: "Nombre",
                da: "Fornavn",
                et: "Eesnimi",
                no: "Fornavn",
                pl: "Imię",
                ru: "Имя",
                sv: "Förnamn",
                fr: "Prénom",
                pt: "Nome",
                zh: "名"
              },
              lastName: {
                en: "Last name",
                fi: "Sukunimi",
                de: "Nachname",
                es: "Apellidos",
                da: "Efternavn",
                et: "Perekonnanimi",
                no: "Etternavn",
                pl: "Nazwisko",
                ru: "Фамилия",
                sv: "Efternamn",
                fr: "Nom",
                pt: "Apelido",
                zh: "姓"
              },
              birth_date: {
                en: "Birth date",
                fi: "Syntymäaika",
                de: "Geburtsdatum",
                es: "Fecha de nacimiento",
                da: "Fødselsdato",
                et: "Sünnikuupäev",
                no: "Fødselsdato",
                pl: "Data urodzenia",
                ru: "Дата рождения",
                sv: "Födelsedatum",
                fr: "Date de naissance",
                pt: "Data de nascimento",
                zh: "出生日期"
              },
              email: {
                en: "Email",
                fi: "Sähköposti",
                de: "E-Mail-Adresse",
                es: "Correo electrónico",
                da: "E-mailadresse",
                et: "E-post",
                no: "E-post",
                pl: "E-mail",
                ru: "Адрес электронной почты",
                sv: "E-post",
                fr: "Adresse e-mail",
                pt: "E-mail",
                zh: "电子邮件地址"
              },
              country: {
                en: "Country",
                fi: "Maa",
                de: "Land",
                es: "País",
                da: "Land",
                et: "Riik",
                no: "Land",
                pl: "Kraj",
                ru: "Страна",
                sv: "Land",
                fr: "Pays",
                pt: "País",
                zh: "国家/地区"
              },
              height: {
                en: "Height",
                fi: "Pituus",
                de: "Größe",
                es: "Altura",
                da: "Højde",
                et: "Pikkus",
                no: "Høyde",
                pl: "Wzrost",
                ru: "Рост",
                sv: "Längd",
                fr: "Taille",
                pt: "Altura",
                zh: "身高"
              },
              weight: {
                en: "Weight",
                fi: "Paino",
                de: "Gewicht",
                es: "Peso",
                da: "Vægt",
                et: "Kaal",
                no: "Vekt",
                pl: "Waga",
                ru: "Вес",
                sv: "Vikt",
                fr: "Poids",
                pt: "Peso",
                zh: "体重"
              },
              birthYear: {
                en: "Birth year",
                fi: "Syntyävuosi",
                de: "Geburtsjahr",
                es: "Año de nacimiento",
                da: "Fødselsår",
                et: "Sünniaasta",
                no: "Fødselsår",
                pl: "Rok urodzenia",
                ru: "Год рождения",
                sv: "Födelseår",
                fr: "Année de naissance",
                pt: "Ano de nascimento",
                zh: "出生年份"
              },
              kg: {
                en: "Kg",
                fi: "Kg",
                de: "kg",
                es: "Kg",
                da: "Kg",
                et: "kg",
                no: "Kg",
                pl: "kg",
                ru: "кг",
                sv: "Kg",
                fr: "Kg",
                pt: "kg",
                zh: "公斤"
              },
              cm: {
                en: "cm",
                fi: "cm",
                de: "cm",
                es: "cm",
                da: "cm",
                et: "cm",
                no: "cm",
                pl: "cm",
                ru: "см",
                sv: "cm",
                fr: "cm",
                pt: "cm",
                zh: "厘米"
              },
              choose: {
                en: "Choose...",
                fi: "Valitse...",
                de: "Auswahl...",
                es: "Elija...",
                da: "Vælg...",
                et: "Vali...",
                no: "Velg ...",
                pl: "Wybierz...",
                ru: "Выбрать...",
                sv: "Välj ...",
                fr: "Choisissez…",
                pt: "Escolher...",
                zh: "请选择……"
              },
              selectTeam: {
                en: "Team",
                fi: "Tiimi",
                de: "Team",
                ru: "Команда",
                es: "Equipo",
                da: "Team",
                et: "Meeskond",
                no: "Team",
                pl: "Zespół",
                sv: "Team",
                fr: "L’équipe",
                pt: "Equipa",
                zh: "团队"
              },
              personal_details: {
                da: "Personlige oplysninger",
                de: "Persönliche Angaben",
                en: "Personal details",
                et: "Isikuandmed",
                fi: "Henkilötiedot",
                no: "Personopplysninger",
                sv: "Personlig information",
                es: "Datos personales",
                pl: "Dane osobowe",
                ru: "Личные данные",
                fr: "Données personnelles",
                pt: "Questões pessoais",
                zh: "个人具体情况"
              },
              background: {
                da: "Baggrund",
                de: "Hintergrund",
                en: "Background",
                et: "Taustteave",
                fi: "Taustatiedot",
                no: "Bakgrunn",
                sv: "Bakgrund",
                ru: "исходная",
                es: "Antecedentes",
                pl: "Informacje ogólne",
                fr: "Contexte",
                pt: "Historial",
                zh: "背景介绍"
              }
            }
          },
          validate_email: {
            ValidateEmailPageView: {
              email_token: {
                en: "Email token",
                fi: "Sähköposti-varmenne",
                ru: "Введите свой токен",
                pl: "Wpisz swój token",
                et: "Sisesta oma kood",
                sv: "Ange din token",
                lv: "Levadiet savu pilnvaru",
                lt: "Įveskite savo žetoną",
                de: "Geben sie ihren Token ein",
                es: "Enviar token por correo electrónico",
                da: "E-mail-token",
                no: "E-posttoken",
                fr: "Jeton d’adresse e-mail",
                pt: "Token de e-mail",
                zh: "电子邮件地址凭证"
              },
              email_validate: {
                en: "Validate email",
                fi: "Vahvista sähköpostiosoitteesi",
                ru: "Подтвердите Ваш электронный адрес",
                pl: "Potwierdź swój email",
                et: "Kinnitage oma e-posti aadress",
                sv: "Verifiera din e-post",
                lv: "Apstipriniet savu e-pastu",
                lt: "Patvirtinkite savo el",
                de: "Bestätige deine e-Mail",
                es: "Validar correo electrónico",
                da: "Bekræft e-mail",
                no: "Valider e-post",
                fr: "Valider l’adresse e-mail",
                pt: "Validar e-mail",
                zh: "验证电子邮件地址"
              },
              email_token_sent: {
                en: "A confirmation token has been sent to your email",
                fi: "Varmennetunnistetta on lähetetty sähköpostiisi",
                ru: "Код подтверждения был отправлен на ваш адрес электронной почты",
                pl: "Kod potwierdzający został wysłany na Twój adres e-mail",
                et: "Teie e-posti aadressile on saadetud kinnituskood",
                sv: "En verifieringskod har skickats till din e-postadress",
                lv: "Uz jūsu e-pasta adresi ir nosūtīts verifikācijas kods",
                lt: "Patvirtinimo kodas išsiųstas jūsų el. pašto adresu",
                de: "Ein bestätigungscode wurde an Ihre e-mail-adresse gesendet",
                es: "Se ha enviado un token de confirmación a su correo electrónico",
                da: "Et bekræftelses-token er blevet sendt til din e-mail.",
                no: "Et bekreftelsestoken er sendt til e-posten din",
                fr: "Un jeton de confirmation a été envoyé à votre adresse e-mail",
                pt: "Foi enviado um token de confirmação para o seu e-mail",
                zh: "确认信息已发送到您的电子邮箱"
              },
              email_token_please_enter: {
                en: "Please enter the short token value here to confirm your email address.",
                fi: "Syötä koodi vahvistaaksesi sähköpostiosoitteesi.",
                ru: "Пожалуйста, введите код, чтобы подтвердить свой адрес электронной почты.",
                pl: "Wprowadź kod, aby potwierdzić swój adres e-mail.",
                et: "Sisestage oma e-posti aadressi kinnitamiseks kood.",
                sv: "Ange koden för att bekräfta din e-postadress.",
                lv: "Lūdzu, ievadiet kodu, lai apstiprinātu savu e-pasta adresi.",
                lt: "Įveskite kodą, kad patvirtintumėte savo el. pašto adresą.",
                de: "Bitte geben Sie den Code ein, um Ihre E-Mail-Adresse zu bestätigen.",
                es: "Por favor, introduzca aquí el valor corto del token para confirmar su dirección de correo electrónico.",
                da: "Indtast den korte token-værdi her for at bekræfte din e-mailadresse.",
                no: "Skriv inn den korte tokenverdien her for å bekrefte e-postadressen din.",
                fr: "Veuillez saisir la valeur du jeton ici pour confirmer votre adresse e-mail.",
                pt: "Introduza aqui o valor do token abreviado para confirmar o seu endereço de e-mail.",
                zh: "请在此处输入凭证号，以确认您的电子邮件地址。"
              },
              email_check_spam: {
                en: "If you do not see the email, please check your spam/junk folders.",
                fi: "Jos et näe sähköpostia, tarkista roskapostikansiosi.",
                ru: "Если вы не видите письмо, проверьте папку со спамом.",
                pl: "Jeśli nie widzisz wiadomości e-mail, sprawdź folder ze spamem.",
                et: "Kui te meili ei näe, kontrollige oma rämpsposti kausta.",
                sv: "Om du inte ser e-postmeddelandet, kontrollera din skräppostmapp.",
                lv: "Ja neredzat e-pastu, lūdzu, pārbaudiet savu surogātpasta mapi.",
                lt: "Jei nematote el. laiško, patikrinkite savo šlamšto aplanką.",
                de: "Wenn Sie die E-Mail nicht sehen, überprüfen Sie bitte Ihren Spam-Ordner.",
                es: "Si no ve el correo electrónico, compruebe sus carpetas de correo no deseado.",
                da: "Hvis du ikke kan se e-mailen, skal du tjekke din spam-/junkmappe.",
                no: "Hvis du ikke ser e-posten, kan du sjekke søppelpostmappene.",
                fr: "Si vous ne voyez pas l’e-mail, veuillez vérifier votre dossier de courrier indésirable/spam.",
                pt: "Se não encontrar o e-mail, consulte as pastas de spam/lixo.",
                zh: "如果您没有看到该电子邮件，请检查您的垃圾邮件文件夹。"
              },
              email_to_resend: {
                en: "To resend the confirmation email",
                fi: "Lähetä vahvistusviesti uudelleen",
                ru: "Чтобы повторно отправить электронное письмо",
                pl: "Aby ponownie wysłać e-mail",
                et: "Meili uuesti saatmiseks",
                sv: "För att skicka e-postmeddelandet igen",
                lv: "Lai atkārtoti nosūtītu e-pastu",
                lt: "Norėdami iš naujo išsiųsti el",
                de: "Um die E-Mail erneut zu senden",
                es: "Para reenviar el correo electrónico de confirmación",
                da: "Send bekræftelsesmailen igen ved at",
                no: "For å sende bekreftelses-e-posten på nytt",
                fr: "Pour renvoyer l’e-mail de confirmation,",
                pt: "Para reenviar o e-mail de confirmação",
                zh: "欲重新发送确认电子邮件"
              },
              click_this_link: {
                en: "click this link",
                fi: "napsauta tästä",
                ru: "нажмите на эту ссылку",
                pl: "kliknij ten link",
                et: "klõpsake sellel lingil",
                sv: "klicka på denna länk",
                lv: "noklikšķiniet uz šīs saites",
                lt: "spustelėkite šią nuorodą",
                de: "klicken sie auf diesen link",
                es: "haga clic en este enlace",
                da: "klikke på dette link",
                no: "klikk på denne koblingen",
                fr: "cliquez sur ce lien",
                pt: "clique neste link",
                zh: "点击此链接"
              }
            }
          },
          select_survey: {
            SelectSurveyPageView: {
              select_survey: {
                en: "Please select the questionnaire you wish to take first",
                fi: "Valitse kysely, johon haluat vastata ensin",
                ru: "Выберите опрос, в котором вы хотите принять участие в первую очередь.",
                pl: "Wybierz ankietę, w której chcesz wziąć udział.",
                et: "Vali küsitlus, millele soovid esimesena vastata",
                sv: "Välj den enkät som du vill besvara först",
                lv: "Izvēlieties aptauju, uz kuras jautājumiem vēlaties atbildēt vispirms",
                lt: "Prašome pasirinkti apklausą, kurią norite užpildyti pirmiausiai.",
                de: "Bitte wählen Sie Ihren ersten Fragebogen.",
                es: "Seleccione el primer cuestionario que desea realizar",
                da: "Vælg det spørgeskema, du ønsker at besvare først",
                no: "Velg spørreskjemaet du ønsker å ta først",
                fr: "Veuillez sélectionner le questionnaire auquel vous souhaitez répondre en premier",
                pt: "Selecione primeiro o questionário que pretende realizar",
                zh: "请先选择您要参与的调查问卷"
              },
              available_surveys: {
                en: "The following questionnaires are available:",
                fi: "Sinulla on seuraavat kyselyt odottamassa:",
                ru: "Ожидается ваше участие в следующих опросах:",
                pl: "Dostępne ankiety do wypełnienia:",
                et: "Sul on ootel järgmised küsitlused:",
                sv: "Du har följande enkäter som väntar:",
                lv: "Jūs gaida tālāk norādītās aptaujas:",
                lt: "Jūsų dar laukia šios apklausos:",
                de: "Folgende Fragebögen stehen zur Wahl:",
                es: "Están disponibles los siguientes cuestionarios:",
                da: "Følgende spørgeskemaer er tilgængelige:",
                no: "Følgende spørreskjemaer er tilgjengelige:",
                fr: "Les questionnaires suivants sont disponibles :",
                pt: "Estão disponíveis os seguintes questionários:",
                zh: "可参与以下调查问卷："
              },
              no_surveys_available: {
                en: "You don't have any open surveys at the moment.",
                fi: "Sinulla ei ole avoimia kyselyitä tällä hetkellä.",
                ru: "На данный момент незавершенных опросов нет.",
                pl: "W tej chwili nie masz żadnych otwartych ankiet.",
                et: "Sul ei ole praegu pooleli küsitlusi.",
                sv: "Du har inga öppna enkäter just nu.",
                lv: "Jums patlaban nav atvērtu aptauju.",
                lt: "Šiuo metu neturite jokių neatsakytų apklausų.",
                de: "Sie haben derzeit keine offenen Befragungen.",
                no: "Du behøver ikke åpne noen undersøkelser for øyeblikket.",
                da: "Du har ingen åbne undersøgelser i øjeblikket.",
                es: "No tiene ninguna encuesta abierta en este momento.",
                fr: "Vous n’avez pas d’enquêtes en cours pour le moment.",
                pt: "De momento, não tem inquéritos abertos.",
                zh: "您目前未参与任何进行中的调查。"
              },
              last_completed: {
                en: "you last completed this survey on {date}",
                fi: "viimeksi vastasit tähän kyselyyn {date}",
                ru: "вы последний раз принимали участие в этом опросе {date}",
                pl: "ostatnio wypełniłeś tę ankietę {date}",
                et: "viimati vastasid sellele küsitlusele {date}",
                sv: "du besvarade senast denna enkät {date}",
                lv: "jūs pēdējo reizi aizpildījāt šo aptauju {date}",
                lt: "paskutinį kartą užpildėte šią apklausą {date}",
                de: "sie haben diesen Fragebogen zuletzt am {date}",
                no: "du fullførte denne undersøkelsen sist {date}",
                da: "du udfyldte sidst denne undersøgelse {date}",
                es: "la última vez que completó esta encuesta fue el {date}",
                fr: "vous avez répondu à cette enquête pour la dernière fois le {date}",
                pt: "respondeu a este inquérito pela última vez a {date}",
                zh: "您于 {date} 最后完成此调查"
              },
              last_started: {
                en: "you started to answer this survey on {date}",
                fi: "aloitit vastaamaan tähän kyselyyn {date}",
                ru: "вы начали отвечать на этот опрос {date}",
                pl: "zacząłeś wypełniać tę ankietę {date}",
                et: "alustasid sellele küsitlusele vastamist {date}",
                sv: "du började besvara denna enkät {date}",
                lv: "jūs sākāt atbildēt uz šo aptauju {date}",
                lt: "pradėjote atsakyti į šią apklausą {date}",
                de: "sie haben diesen Fragebogen begonnen am {date}",
                no: "du begynte å svare på denne undersøkelsen {date}",
                da: "du begyndte at besvare denne undersøgelse {date}",
                es: "empezó a responder a esta encuesta el {date}",
                fr: "vous avez commencé à répondre à cette enquête le {date}",
                pt: "começou a responder a este inquérito a {date}",
                zh: "您于 {date} 开始回答此调查"
              }
            }
          },
          survey_code: {
            SurveyCodePageView: {
              welcome: {
                en: "Welcome to the questionnaire!",
                fi: "Tervetuloa vastaamaan kyselyyn!",
                ru: "Добро пожаловать в опрос!",
                pl: "Zapraszamy do wypełnienia ankiety",
                et: "Tere tulemast vastama küsitlusele",
                sv: "Välkommen att besvara enkäten",
                lv: "Aizinām atbildēt uz aptauju",
                lt: "Maloniai prašome atsakyti į klausimus",
                de: "Willkommen beim Fragebogen!",
                es: "Le invitamos a responder al cuestionario.",
                da: "Velkommen til spørgeskemaet!",
                no: "Velkommen til spørreskjemaet!",
                fr: "Bienvenue dans le questionnaire !",
                pt: "Bem-vindo ao questionário!",
                zh: "欢迎参与调查！"
              },
              login: {
                en: "Log in",
                fi: "Kirjaudu sisään",
                ru: "Вход",
                pl: "Zaloguj się",
                et: "Logi sisse",
                sv: "Logga in",
                lv: "Pierakstīties",
                lt: "Prisijungti",
                de: "Anmelden",
                es: "Conectarse",
                da: "Log ind",
                no: "Logg på",
                fr: "Se connecter",
                pt: "Iniciar sessão",
                zh: "登录"
              },
              enter_your_id: {
                en: "Please enter your personal id or email",
                fi: "Anna koodi tai sähköpostiosoite",
                ru: "Пожалуйста, введите код или адрес электронной почты",
                pl: "Wprowadź kod lub e-mail",
                et: "Sisestage kood või e-posti aadress",
                sv: "Vänligen ange kod eller e-postadress",
                lv: "Ievadiet personīgo lietotājvārdu",
                lt: "Lūdzu, ievadiet kodu vai e-pasta adresi",
                de: "Bitte Code oder E-Mail-Adresse eingeben",
                no: "Vennligst skriv inn kode eller e-postadresse",
                da: "Indtast venligst kode eller e-mailadresse",
                es: "Por favor, introduzca su identificación personal o correo electrónico",
                fr: "Veuillez saisir votre identifiant personnel ou votre adresse e-mail",
                pt: "Introduza o seu ID pessoal ou e-mail",
                zh: "请输入您的个人 ID 或电子邮件地址"
              },
              login_placeholder: {
                en: "",
                fi: "",
                ru: "",
                pl: "",
                et: "",
                sv: "",
                lv: "",
                lt: "",
                de: ""
              }
            }
          },
          start: {
            StartPageView: {
              estimatedTime: {
                en: "**{estimatedTime}** min",
                fi: "**{estimatedTime}** min",
                ru: "**{estimatedTime}** мин",
                pl: "**{estimatedTime}** min",
                et: "**{estimatedTime}** min",
                sv: "**{estimatedTime}** min",
                lv: "**{estimatedTime}** min",
                lt: "**{estimatedTime}** min",
                de: "**{estimatedTime}** min",
                es: "**{estimatedTime}** min",
                da: "**{estimatedTime}** min",
                no: "**{estimatedTime}** min",
                fr: "**{estimatedTime}** min",
                pt: "**{estimatedTime}** min",
                zh: "**{estimatedTime}**分钟"
              },
              reportCustomTextWellbeing: {
                en: "**Your** personal report upon completion",
                fi: "**Sinun** henkilökohtainen raporttisi kyselyn päätteeksi",
                ru: "**Ваш** личный отчет после завершения",
                pl: "**Twój** osobisty raport po zakończeniu",
                et: "**Teie** isiklik aruanne pärast lõpetamist",
                sv: "**Din** personliga rapport vid avslutning",
                lv: "**Jūsu** personīgais ziņojums pēc pabeigšanas",
                lt: "**Jūsų** asmeninė ataskaita baigus",
                de: "**Ihr** persönlicher Bericht nach Abschluss",
                es: "**Tu** informe personal al finalizar",
                da: "**Din** personlige rapport ved afslutning",
                no: "**Din** personlige rapport ved ferdigstillelse",
                fr: "**Votre** rapport personnel à la fin",
                pt: "**Seu** relatório pessoal após conclusão",
                zh: "完成后**您的**个人报告"
              },
              numberOfQuestions: {
                en: "**{numberOfQuestions}** questions",
                fi: "**{numberOfQuestions}** kysymystä",
                ru: "**{numberOfQuestions}** вопросов",
                pl: "**{numberOfQuestions}** pytań",
                et: "**{numberOfQuestions}** küsimust",
                sv: "**{numberOfQuestions}** frågor",
                lv: "**{numberOfQuestions}** jautājumi",
                lt: "**{numberOfQuestions}** klausimai",
                de: "**{numberOfQuestions}** Fragen",
                es: "**{numberOfQuestions}** preguntas",
                da: "**{numberOfQuestions}** spørgsmål",
                no: "**{numberOfQuestions}** spørsmål",
                fr: "**{numberOfQuestions}** questions",
                pt: "**{numberOfQuestions}** perguntas",
                zh: "**{numberOfQuestions}**个问题"
              },
              purpose: {
                en: "Give feedback on your work, your wellbeing, and your working environment. Your feedback will be used for developing your work environment, as well as for research that aims to improve the quality of working life.",
                fi: "Anna palautetta työstäsi ja hyvinvoinnistasi sekä työyhteisösi toiminnasta. Palautettasi käytetään työyhteisösi toiminnan kehittämiseen sekä työelämän parantamiseen tähtäävään tutkimukseen.",
                ru: "Поделитесь мнением о своей работе и своем благополучии, а также о деятельности своего трудового коллектива. Ваш отзыв будет использован для развития деятельности Вашего трудового коллектива и для исследования, нацеленного на улучшение трудовой жизни.",
                pl: "Podziel się opinią na temat swojej pracy, atmosfery i samopoczucia oraz funkcjonowania zespołu. Wyniki ankiety i opinie będą wykorzystane do opracowania działań wspierających rozwój działalności i badań koncentrujących się na poprawie jakości życia zawodowego.",
                et: "Andke tagasisidet enda töö ja heaolu ning töökollektiivi tegevuse kohta. Esitatud tagasisidet kasutatakse töökollektiivi tegevuse arendamiseks ning tööelu parandamiseks tehtavateks uuringuteks.",
                sv: "Ge respons på ditt arbete och välbefinnande samt verksamheten bland de anställda på din arbetsplats. Din respons används för att utveckla verksamheten på din arbetsplats samt för forskning som syftar till att förbättra arbetslivet.",
                lv: "Sniedziet atsauksmes par savu darbu un savu labklājību, kā arī darba kolektīva darbību. Jūsu sniegtās atsauksmes tiks izmantotas darbības pilnveidošanai, kā arī uz darba dzīves uzlabošanu vērstā pētījumā.",
                lt: "Atsakykite į klausimus apie savo darbą, gerovę ir darbinę aplinką. Jūsų atsakymai bus panaudoti siekiant pagerinti jūsų darbinę aplinką, taip pat jie bus panaudoti tyrime apie darbinio gyvenimo kokybę.",
                de: "Geben Sie Feedback über Ihre Arbeit, Ihr Wohlbefinden und Ihre Arbeitsumgebung. Ihr Feedback wird zur Weiterentwicklung Ihrer Arbeitsumgebung sowie zu Forschungszwecken mit dem Ziel verwendet, die Qualität Ihres Arbeitslebens zu verbessern.",
                es: "Dé su opinión sobre su trabajo, su bienestar y su entorno laboral. Sus comentarios se utilizarán para desarrollar su entorno de trabajo, así como para investigaciones destinadas a mejorar la calidad de la vida laboral.",
                da: "Giv feedback på dit arbejde, dit velbefindende og dit arbejdsmiljø. Din feedback vil blive brugt til at udvikle dit arbejdsmiljø samt til forskning, der har til formål at forbedre kvaliteten af arbejdslivet.",
                no: "Gi tilbakemelding om arbeidet, trivselen og arbeidsmiljøet ditt. Tilbakemeldingene dine vil bli brukt til å utvikle arbeidsmiljøet ditt, samt til forskning som tar sikte på å forbedre arbeidslivskvaliteten.",
                fr: "Donnez votre avis sur votre travail, votre mieux-être et votre environnement de travail. Vos commentaires seront utilisés pour améliorer votre environnement de travail, ainsi que dans le cadre de recherches visant à améliorer la qualité de la vie professionnelle.",
                pt: "Faça comentários sobre o seu trabalho, o seu bem-estar e o seu ambiente de trabalho. Os seus comentários serão utilizados para desenvolver o seu ambiente de trabalho, bem como para investigações que visam melhorar a qualidade da vida profissional.",
                zh: "提供关于您的工作、健康和工作环境的反馈。您的反馈将用于改善您的工作环境，以及用于以提高工作生活质量为目的的研究。"
              },
              free_form_question_caution: {
                en: "Answers to potential free form questions are shared anonymously with your team leader and employer. Please keep this in mind when replying.",
                fi: "Vastaukset mahdollisiin avoimiin kysymyksiin jaetaan anonyymisti esimiehille sekä työnantajalle. Otathan tämän  huomioon vastatessasi.",
                ru: "Просим учитывать, что ответы на возможные открытые вопросы будут переданы руководителям и работодателю в анонимной форме. Ответы в свободной форме будут переданы руководителям в анонимном виде",
                pl: "Odpowiedzi na możliwe pytania otwarte, przekazywane będą anonimowo przełożonym oraz pracodawcy. Prosimy mieć to na uwadze podczas udzielania odpowiedzi. Swobodne odpowiedzi uczestników zostaną przekazane anonimowo przełożonym zespołów",
                et: "Võimalike avatud küsimuste vastuseid jagatakse anonüümselt nii otsese juhi kui ka tööandjaga. Võtke seda vastamisel arvesse. Avatud küsimuste vastuseid jagatakse anonüümselt meeskonna otsese juhiga.",
                sv: "Svaren på eventuella öppna frågor delas anonymt med cheferna och arbetsgivaren. Vänligen ta hänsyn till detta när du svarar. De öppna svaren delas anonymt med teamens chefer",
                lv: "Atbildes uz atvērtajiem jautājumiem, ja tādi aptaujā ir, tiks anonīmi nodotas vadītājiem un darba devējam. Atbildot ņemiet to vērā. Brīvi rakstītās atbildes tiks anonīmi nodotas komandu vadītājiem.",
                lt: "Atsakymai į galimus atvirus klausimus bus anonimiškai parodyti vadovams ir darbdaviui. Įvertinkite tai pateikdami savo atsakymus. Atsakymai į atvirus klausimus bus anonimiškai parodyti komandų vadovams.",
                de: "Die auf eventuelle offene Fragen gegebenen Antworten werden in anonymisierter Form an die Vorgesetzten und Arbeitgeber verteilt. Bitte berücksichtigen Sie dies in Ihren Antworten.",
                es: "Las respuestas a las posibles preguntas del formulario libre se comparten de forma anónima con su jefe de equipo y su empleador. Por favor, téngalo en cuenta cuando responda.",
                da: "Svarene på eventuelle åbne spørgsmål deles anonymt med din teamleder og arbejdsgiver. Husk på det, når du svarer.",
                no: "Svar på potensielle spørsmål i fri form deles anonymt med teamlederen og arbeidsgiveren. Vær oppmerksom på dette når du svarer.",
                fr: "Les réponses aux éventuelles questions libres sont communiquées de manière anonyme à votre chef d’équipe et à votre employeur. Veuillez en tenir compte lorsque vous répondez.",
                pt: "As respostas às perguntas de formulário livre são partilhadas anonimamente com o seu chefe de equipa e entidade empregadora. Pedimos que tenha isto em conta quando responder.",
                zh: "对开放式问题的回答将匿名分享给您的团队领导和工作单位。回答时请谨记这一点。"
              },
              start: {
                en: "Start",
                fi: "Aloita",
                ru: "Начать",
                pl: "Rozpocznij",
                et: "Alustage",
                sv: "Börja",
                lv: "Sākt",
                lt: "Pradėti",
                de: "Selbsteinschätzung starten",
                no: "Start",
                da: "Start",
                es: "Comenzar",
                fr: "Démarrer",
                pt: "Começar",
                zh: "开始"
              },
              tos: {
                en: "I agree to the [privacy policy](https://www.hintsa.com/privacy) and [terms of service](https://www.hintsa.com/terms)",
                fi: "Hyväksyn [tietosuojakäytännön](https://www.hintsa.com/privacy) ja [käyttöehdot](https://www.hintsa.com/terms)",
                ru: "Принимаю [политику конфиденциальности](https://www.hintsa.com/privacy) и [условия обслуживания](https://www.hintsa.com/terms)",
                pl: "Wyrażam zgodę na [zasady ochrony prywatności](https://www.hintsa.com/privacy) i [warunki użytkowania usługi](https://www.hintsa.com/terms)",
                et: "Nõustun [privaatsuspõhimõtete](https://www.hintsa.com/privacy) ja [kasutustingimustega](https://www.hintsa.com/terms)",
                sv: "Jag godkänner [sekretesspolicyn](https://www.hintsa.com/privacy) och [användarvillkoren](https://www.hintsa.com/terms)",
                lv: "",
                lt: "",
                de: "Ich stimme [den Datenschutzrichtlinien](https://www.hintsa.com/privacy) und [Nutzungsbedingungen](https://www.hintsa.com/terms) zu",
                no: "Jeg godtar [personvernerklæringen](https://www.hintsa.com/privacy) og [tjenestevilkårene](https://www.hintsa.com/terms)",
                da: "Jeg er [indforstået med [privatlivspolitikken](https://www.hintsa.com/privacy) og [servicevilkårene](https://www.hintsa.com/terms)",
                es: "Acepto la [política de privacidad](https://www.hintsa.com/privacy) y las [condiciones de servicio](https://www.hintsa.com/terms)",
                fr: "J’accepte la [privacy policy](https://www.hintsa.com/privacy) et les [terms of service](https://www.hintsa.com/terms)",
                pt: "Aceito a [privacy policy] (https://www.hintsa.com/privacy) e os [terms of service] (https://www.hintsa.com/terms)",
                zh: "我同意[隐私政策] (https://www.hintsa.com/privacy) 和[服务条款] (https://www.hintsa.com/terms)"
              },
              tos_start: {
                en: "I agree to the",
                fi: "Hyväksyn palvelun",
                ru: "Подтверждаю свое согласие с",
                pl: "Akceptuję",
                et: "Nõustun",
                sv: "Jag accepterar",
                lv: "Akceptēju pakalpojuma",
                lt: "Sutinku su",
                de: "Ich stimme den",
                no: "Jeg godtar",
                da: "Jeg er indforstået med",
                es: "Acepto las",
                fr: "J’accepte les",
                pt: "Concordo com",
                zh: "我同意"
              },
              tos_link: {
                en: "https://www.hintsa.com/terms",
                fi: "https://www.hintsa.com/terms",
                ru: "https://www.hintsa.com/terms",
                pl: "https://www.hintsa.com/terms",
                et: "https://www.hintsa.com/terms",
                sv: "https://www.hintsa.com/terms",
                lv: "https://www.hintsa.com/terms",
                lt: "https://www.hintsa.com/terms",
                de: "https://www.hintsa.com/terms",
                no: "https://www.hintsa.com/terms",
                da: "https://www.hintsa.com/terms",
                es: "https://www.hintsa.com/terms",
                fr: {
                  text: "https://www.hintsa.com/terms",
                  hyperlink: "https://www.hintsa.com/terms"
                },
                pt: "https://www.hintsa.com/terms",
                zh: "https://www.hintsa.com/terms"
              },
              tos_link_text: {
                en: "Terms of Service",
                fi: "Käyttöehdot",
                ru: "Условиями пользования",
                pl: "Warunki korzystania z usługi",
                et: "teenuse kasutustingimustega",
                sv: "tjänstens Användarvillkor",
                lv: "lietošanas nosacījumus",
                lt: "naudojimo sąlygomis",
                de: "Nutzungsbedingungen zu.",
                no: "tjenestevilkårene",
                da: "servicevilkårene",
                es: "Condiciones de servicio",
                fr: "Conditions de service",
                pt: "Termos de serviço",
                zh: "服务条款"
              },
              tos_end: {
                en: "",
                fi: "",
                ru: "",
                pl: "",
                et: "",
                sv: "",
                lv: "",
                lt: "",
                de: ""
              }
            },
            StartPage: {
              survey_subheading_personal_wellbeing: {
                en: "Explore your current state of wellbeing",
                fi: "Tutustu henkiklökohtaisen hyvinvointisi tilaan",
                ru: "Изучите ваше текущее состояние благополучия",
                pl: "Zbadaj swój obecny stan dobrego samopoczucia",
                et: "Uurige oma praegust heaolu seisundit",
                sv: "Utforska din nuvarande välbefinnande",
                lv: "Izpētiet savu pašreizējo labklājības stāvokli",
                lt: "Ištirkite savo dabartinę gerovės būklę",
                de: "Erkunden Sie Ihren aktuellen Wohlfühlszustand",
                es: "Explora tu estado actual de bienestar personal",
                da: "Udforsk din nuværende tilstand af velvære",
                no: "Utforsk din nåværende tilstand av velvære",
                fr: "Explorez votre état actuel de bien-être",
                pt: "Explore o seu estado atual de bem-estar",
                zh: "探索您目前的幸福状态"
              },
              notFound_title: {
                en: "Questionnaire not found",
                fi: "Kyselyä ei löytynyt",
                ru: "Опрос не найден",
                pl: "Nie znaleziono ankiety.",
                et: "Küsitlust ei leitud",
                sv: "Enkäten hittades inte",
                lv: "Aptauja nav atrasta",
                lt: "Klausimyno nerasta",
                de: "Fragebogen nicht gefunden",
                es: "Cuestionario no encontrado",
                da: "Spørgeskema ikke fundet",
                no: "Finner ikke spørreskjemaet",
                fr: "Questionnaire non trouvé",
                pt: "Questionário não encontrado",
                zh: "未找到调查问卷"
              },
              notFound_description: {
                en: "Either you have already answered or the questionnaire was removed.",
                fi: "Joko olet jo vastannut kyselyyn tai se on poistettu.",
                ru: "Вы либо уже приняли участие в опросе, либо он удален.",
                pl: "Wypełniono już ankietę albo została ona usunięta.",
                et: "Sa oled küsitlusele juba vastanud või on see eemaldatud.",
                sv: "Du har redan besvarat enkäten eller så har den tagits bort.",
                lv: "Vai nu jau esat atbildējis uz pieprasījumu, vai tas ir dzēsts.",
                lt: "Jūs arba jau atsakėte į klausimus, arba klausimynas buvo ištrintas.",
                de: "Sie haben den Fragebogen entweder schon beantwortet oder er wurde entfernt.",
                no: "Enten har du allerede svart, eller så er spørsmålsskjemaet blitt fjernet.",
                da: "Du har enten allerede svaret, eller også er spørgeskemaet blevet fjernet.",
                es: "Ya ha contestado al cuestionario o éste ha sido eliminado.",
                fr: "Soit vous y avez déjà répondu, soit le questionnaire a été supprimé.",
                pt: "Já respondeu a este questionário ou o questionário foi removido.",
                zh: "可能是因为您已经完成回答，也可能是因为此调查问卷已被删除。"
              },
              error_title: {
                en: "Error",
                fi: "Virhe",
                ru: "Ошибка",
                pl: "Błąd",
                et: "Viga",
                sv: "Fel",
                lv: "Kļūda",
                lt: "Klaida",
                de: "Fehler",
                es: "Error",
                da: "Fejl",
                no: "Feil",
                fr: "Erreur",
                pt: "Erro",
                zh: "错误"
              },
              error_description: {
                en: "An error occurred when loading questionnaire",
                fi: "Virhe ladattaessa kyselyä",
                ru: "Ошибка при загрузке опроса",
                pl: "Błąd ładowania ankiety",
                et: "Viga küsitluse laadimisel",
                sv: "Ett fel uppstod när enkäten skulle laddas",
                lv: "Kļūda aptaujas ielādē",
                lt: "Klaida atsisiunčiant anketą",
                de: "Fehler beim Laden des Fragebogens.",
                es: "Se ha producido un error al cargar el cuestionario",
                da: "Der opstod en fejl under indlæsning af spørgeskemaet",
                no: "Det oppstod en feil under lasting av spørreskjema",
                fr: "Une erreur s’est produite lors du chargement du questionnaire",
                pt: "Ocorreu um erro ao carregar o questionário",
                zh: "加载调查问卷时出现错误"
              },
              alreadyCompleted_title: {
                en: "Questionnaire complete",
                fi: "Kyselylomake on täytetty",
                ru: "Анкета заполнена",
                pl: "Ankieta została wypełniona",
                et: "Ankeet on täidetud",
                sv: "Enkäten är ifylld",
                lv: "Anketa ir aizpildīta",
                lt: "Anketa užpildyta",
                de: "Der Fragebogen ist ausgefüllt",
                es: "Cuestionario completado",
                da: "Spørgeskema udfyldt",
                no: "Spørreskjema fullført",
                fr: "Questionnaire rempli",
                pt: "Questionário preenchido",
                zh: "调查问卷回答完毕"
              },
              alreadyCompleted_description: {
                en: "You have already completed this questionnaire.",
                fi: "Olet jo vastannut tähän kyselyyn.",
                ru: "Вы уже заполнили эту анкету",
                pl: "Wypełniłeś już ten kwestionariusz",
                et: "Olete selle küsimustiku juba täitnud",
                sv: "Du har redan fyllt i detta frågeformulär",
                lv: "Jūs jau esat aizpildījis šo anketu",
                lt: "Jūs jau užpildėte šią anketą",
                de: "Sie haben diesen Fragebogen bereits ausgefüllt",
                es: "Ya ha completado este cuestionario.",
                da: "Du har allerede udfyldt dette spørgeskema.",
                no: "Du har allerede fullført dette spørreskjemaet.",
                fr: "Vous avez déjà rempli ce questionnaire.",
                pt: "Já preencheu este questionário.",
                zh: "您已经完成此调查问卷。"
              },
              surveyClosed_title: {
                en: "Questionnaire closed",
                fi: "Kysely on suljettu",
                ru: "Опрос закрыт",
                pl: "Ankieta została zamknięta",
                et: "Küsitlus on suletud",
                sv: "Enkäten är stängd",
                lv: "Aptauja ir slēgta",
                lt: "Anketa uždaryta",
                de: "Der Fragebogen ist geschlossen",
                es: "Cuestionario cerrado",
                da: "Spørgeskema lukket",
                no: "Spørreskjema lukket",
                fr: "Questionnaire fermé",
                pt: "Questionário encerrado",
                zh: "调查问卷已关闭"
              },
              surveyClosed_description: {
                en: "This questionnaire is no longer available.",
                fi: "Tämä kysely ei ole enää saatavilla.",
                ru: "Этот опрос больше недоступен",
                pl: "Ten kwestionariusz nie jest już dostępny",
                et: "See küsimustik pole enam saadaval",
                sv: "Detta frågeformulär är inte längre tillgängligt",
                lv: "Šī aptauja vairs nav pieejama",
                lt: "Ši apklausa nebeprieinama",
                de: "Dieser Fragebogen ist nicht mehr verfügbar",
                es: "Este cuestionario ya no está disponible.",
                da: "Dette spørgeskema er ikke længere tilgængeligt.",
                no: "Dette spørreskjemaet er ikke lenger tilgjengelig.",
                fr: "Ce questionnaire n’est plus disponible.",
                pt: "Este questionário já não está disponível.",
                zh: "此调查问卷不再开放给更多的人参与。"
              }
            }
          },
          thank_you: {
            ThankYouPage: {
              wellbeing_survey_thank_you: {
                da: "Resultaterne af din selvvurdering er nu færdige. Du kan få adgang til din personlige velfærdsrapport ved at klikke på nedenstående link.",
                de: "Die Ergebnisse der Selbsteinschätzung Ihres Wohlbefindens liegen nun vor. Sie können den Vergleichsbericht Ihres persönlichen Wohlbefindens über den unten stehenden Link aufrufen.",
                en: "The results of your wellbeing self-assessment are now ready. Access your personal wellbeing report through the link below.",
                et: "Teie enesehindamise tulemused on nüüd valmis. Isikliku heaoluaruandega tutvumiseks klõpsake alltoodud lingil.",
                fi: "Itsearviointisi tulokset ovat nyt valmiit. Pääset henkilökohtaiseen hyvinvointiraporttiin napsauttamalla alla olevaa linkkiä.",
                no: "Resultatene av egenvurderingen din er nå klare. Du får tilgang til din personlige velværerapport ved klikke på lenken nedenfor.",
                sv: "Resultaten för din självutvärdering är nu klara. Du kan komma åt din personliga hälsorapport genom klicka på länken nedan.",
                pl: "Wyniki Twojej samooceny są już gotowe. Klikając poniższe łącze, możesz uzyskać dostęp do raportu dotyczącego Twojego samopoczucia.",
                ru: "Результаты Вашей самооценки уже готовы. Вы можете получить доступ к собственному отчету о самочувствии по приведенной ниже ссылке.",
                es: "Ya están listos los resultados de su autoevaluación del bienestar. Acceda a su informe de bienestar personal a través del siguiente enlace.",
                fr: "Les résultats de votre auto-évaluation du bien-être sont maintenant prêts. Accédez à votre rapport personnel de bien-être en cliquant sur le lien ci-dessous.",
                pt: "Os resultados da sua autoavaliação de bem-estar estão agora prontos. Aceda ao seu relatório pessoal de bem-estar através do link indicado abaixo.",
                zh: "您的健康自评已得出结果。通过以下链接获取您的个人健康报告。"
              }
            },
            ThankYouPageView: {
              title: {
                da: "Mange tak!",
                de: "Vielen Dank!",
                en: "Thank you!",
                et: "Suur tänu!",
                fi: "Kiitos!",
                lt: "Ačiū",
                lv: "Paldies",
                no: "Takk!",
                pl: "Dziękujemy",
                ru: "Спасибо",
                sv: "Tack!",
                es: "Gracias.",
                fr: "Merci !",
                pt: "Obrigado!",
                zh: "谢谢！"
              },
              show_results: {
                en: "View report",
                fi: "Katso raportti",
                ru: "Посмотреть отчет",
                pl: "Wyświetlanie raportu",
                et: "Vaata aruannet",
                sv: "Visa rapport",
                lv: "Parādīt rezultātus",
                lt: "Rodyti rezultatus",
                de: "Bericht anzeigen",
                no: "Vis rapport",
                da: "Se rapport",
                es: "Ver el informe",
                fr: "Voir le rapport",
                pt: "Ver relatório",
                zh: "查看报告"
              },
              surveys_remaining: {
                en: "The following surveys are available for you to complete. Please select the next questionnaire.",
                fi: "Voit täyttää seuraavat kyselyt. Aloita vastaaminen valitsemalla kysely.",
                ru: "Вам доступны следующие опросы. Для того, чтобы приступить к ответам на вопросы, выберите опрос.",
                pl: "Dostępne są do wypełnienia następujące ankiety. Zacznij od wybrania ankiety.",
                et: "Täitmiseks on saadaval järgmised küsitlused. Alusta vastamist, valides küsitluse.",
                sv: "Följande undersökningar är tillgängliga att fylla i. Börja svara genom att välja en enkät.",
                lv: "Aizpildīšanai ir pieejamas šādas aptaujas. Sāciet atbildēšanu, izvēloties aptauju.",
                lt: "Galima užpildyti šias apklausas. Prašome pasirinkti apklausą, kurią toliau norite pildyti.",
                de: "Die folgenden Umfragen können ausgefüllt werden. Bitte wählen Sie den nächsten Fragebogen.",
                es: "Las siguientes encuestas están disponibles para completar. Seleccione el siguiente cuestionario.",
                da: "Du kan udfylde følgende undersøgelser. Vælg det næste spørgeskema.",
                no: "Følgende undersøkelser er tilgjengelige for deg å fullføre. Velg neste spørreskjema.",
                fr: "Les enquêtes suivantes sont à votre disposition. Veuillez sélectionner le questionnaire suivant.",
                pt: "Os seguintes inquéritos estão disponíveis para serem preenchidos. Selecione o questionário seguinte.",
                zh: "您可以参与以下调查。请选择下一份问卷。"
              },
              last_completed: {
                en: "you last completed this survey on {date}",
                fi: "viimeksi vastasit tähän kyselyyn {date}",
                ru: "вы последний раз принимали участие в этом опросе {date}",
                pl: "ostatnio wypełniłeś tę ankietę {date}",
                et: "viimati vastasid sellele küsitlusele {date}",
                sv: "du besvarade senast denna enkät {date}",
                lv: "jūs pēdējo reizi aizpildījāt šo aptauju {date}",
                lt: "paskutinį kartą užpildėte šią apklausą {date}",
                de: "sie haben diesen Fragebogen zuletzt am {date}",
                no: "du fullførte denne undersøkelsen sist {date}",
                da: "du udfyldte sidst denne undersøgelse {date}",
                es: "la última vez que completó esta encuesta fue el {date}",
                fr: "vous avez répondu à cette enquête pour la dernière fois le {date}",
                pt: "respondeu a este inquérito pela última vez a {date}",
                zh: "您于 {date} 最后完成此调查"
              },
              last_started: {
                en: "you started to answer this survey on {date}",
                fi: "aloitit vastaamaan tähän kyselyyn {date}",
                ru: "вы начали отвечать на этот опрос {date}",
                pl: "zacząłeś wypełniać tę ankietę {date}",
                et: "alustasid sellele küsitlusele vastamist {date}",
                sv: "du började besvara denna enkät {date}",
                lv: "jūs sākāt atbildēt uz šo aptauju {date}",
                lt: "pradėjote atsakyti į šią apklausą {date}",
                de: "sie haben diesen Fragebogen begonnen am {date}",
                no: "du begynte å svare på denne undersøkelsen {date}",
                da: "du begyndte at besvare denne undersøgelse {date}",
                es: "empezó a responder a esta encuesta el {date}",
                fr: "vous avez commencé à répondre à cette enquête le {date}",
                pt: "começou a responder a este inquérito a {date}",
                zh: "您于 {date} 开始回答此调查"
              }
            }
          },
          questions: {
            QuestionsPageView: {
              not_likely: {
                fi: "Ei lainkaan todennäköistä",
                en: "Not at all likely",
                ru: "Крайне маловероятно",
                pl: "Znikome",
                et: "Väga ebatõenäoline",
                sv: "Inte alls troligt",
                lv: "Nav iespējams",
                lt: "Visiškai netikėtina",
                de: "Sehr unwahrscheinlich",
                es: "Nada probable",
                da: "Slet ikke sandsynligt",
                no: "Ikke sannsynlig i det hele tatt",
                fr: "Peu probable",
                pt: "Nada provável",
                zh: "完全不可能"
              },
              very_likely: {
                fi: "Erittäin todennäköistä",
                en: "Extremely likely",
                ru: "С большой долей вероятности",
                pl: "Bardzo duże",
                et: "Väga tõenäoline",
                sv: "Väldigt troligt",
                lv: "Ļoti iespējams",
                lt: "Labai tikėtina",
                de: "Sehr wahrscheinlich",
                es: "Muy probable",
                da: "Ekstremt sandsynligt",
                no: "Svært sannsynlig",
                fr: "Extrêmement probable",
                pt: "Extremamente provável",
                zh: "极有可能"
              },
              next: {
                en: "Next",
                fi: "Seuraava",
                ru: "Далее",
                pl: "Dalej",
                et: "Järgmine",
                sv: "Nästa",
                lv: "Nākošais",
                lt: "Kitas",
                de: "Nächste",
                no: "Neste",
                da: "Næste",
                es: "Siguiente",
                fr: "Suivant",
                pt: "Seguinte",
                zh: "下一步"
              },
              previous: {
                en: "Previous",
                fi: "Edellinen",
                ru: "Назад",
                pl: "Wstecz",
                et: "Eelmine",
                sv: "Föregående",
                lv: "Iepriekšējais",
                lt: "Ankstesnis",
                de: "Vorherige",
                no: "Forrige",
                da: "Forrige",
                es: "Anterior",
                fr: "Précédent",
                pt: "Anterior",
                zh: "上一步"
              },
              done: {
                en: "Finish",
                fi: "Valmis",
                ru: "Готово",
                pl: "Koniec",
                et: "Valmis",
                sv: "Färdig",
                lv: "Gatavs",
                lt: "Pabaiga",
                de: "Beenden",
                no: "Ferdig",
                da: "Færdig",
                es: "Terminar",
                fr: "Terminer",
                pt: "Terminar",
                zh: "完成"
              }
            }
          }
        }
      },
      ui: {
        report: {
          common: {
            // Possibly remove these
            category_bm: {
              en: "Body mechanics"
            },
            category_co: {
              en: "The core"
            },
            category_fe: {
              en: "Feedback"
            },
            category_gh: {
              en: "General health"
            },
            category_gm: {
              en: "Golas & motivation"
            },
            category_ls: {
              en: "Life satisfaction"
            },
            category_me: {
              en: "Mental energy"
            },
            category_nu: {
              en: "Nutrition"
            },
            category_pa: {
              en: "Physical activity"
            },
            category_sr: {
              en: "Sleep & recovery"
            },
            category_ww: {
              en: ""
            },
            category_wp: {
              en: ""
            },
            category_wc: {
              en: ""
            },
            score1: {
              da: "Forbedring",
              de: "Verbessern",
              en: "Improve",
              et: "Parandamine",
              fi: "Paranna",
              no: "Forbedring",
              sv: "Förbättra",
              pl: "Poprawa",
              ru: "Улучшить",
              es: "Mejorar",
              fr: "Améliorer",
              pt: "Melhorar",
              zh: "改善"
            },
            score2: {
              da: "Fokus",
              de: "Schwerpunkt",
              en: "Focus",
              et: "Fookus",
              fi: "Keskity",
              no: "Fokus",
              sv: "Fokus",
              pl: "Koncentracja",
              ru: "Сконцентрироваться",
              es: "Enfoque",
              fr: "Concentration",
              pt: "Foco",
              zh: "专注"
            },
            score3: {
              da: "Neutral",
              de: "Neutral",
              en: "Neutral",
              et: "Neutraalne",
              fi: "Neutraali",
              no: "Nøytral",
              sv: "Neutralt",
              pl: "Obojętnie",
              ru: "Нормально",
              es: "Neutro",
              fr: "Neutre",
              pt: "Neutro",
              zh: "中等"
            },
            score4: {
              da: "God",
              de: "Gut",
              en: "Good",
              et: "Hea",
              fi: "Hyvä",
              no: "God",
              sv: "God",
              pl: "Dobrze",
              ru: "Хорошо",
              es: "Bien",
              fr: "Bon",
              pt: "Bom",
              zh: "良好"
            },
            score5: {
              da: "Fremragende",
              de: "Ausgezeichnet",
              en: "Excellent",
              et: "Suurepärane",
              fi: "Erinomainen",
              no: "Utmerket",
              sv: "Utmärkt",
              pl: "Doskonale",
              ru: "Отлично",
              es: "Excelente",
              fr: "Excellent",
              pt: "Excelente",
              zh: "优异"
            }
          },
          CategoryAnswersView: {
            element_score: {
              da: "Resultat for elementet",
              de: "Punktzahl für den Bereich",
              en: "Element score",
              et: "Kategooria tulemus",
              fi: "Tekijäkohtaiset pisteet",
              no: "Element-score",
              sv: "Elementpoäng",
              pl: "Punktacja za elementy",
              ru: "Балл за элемент",
              es: "Puntuación del elemento",
              fr: "Score de l’élément",
              pt: "Pontuação do elemento",
              zh: "单个维度得分"
            }
          },
          ScoreView: {
            title: {
              en: "Change",
              es: "Cambiar",
              da: "ændring",
              de: "Änderung",
              et: "muutus",
              fi: "muutos",
              no: "endring",
              sv: "förändring",
              pl: "zmiana",
              ru: "изменение",
              fr: "Changement",
              pt: "Alterar",
              zh: "改变"
            }
          },
          EnergyScoreView: {
            delta_title: {
              en: "Change",
              es: "Cambiar",
              da: "ændring",
              de: "Änderung",
              et: "muutus",
              fi: "muutos",
              no: "endring",
              sv: "förändring",
              pl: "zmiana",
              ru: "изменение",
              fr: "Changement",
              pt: "Alterar",
              zh: "改变"
            }
          }
        }
      }
    }
  }
}
