import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { Answer } from "app/surveys/modules/state/model/Model"
import DeltaScoreView from "app/surveys_app/components/ui/report/DeltaScoreView"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import AnswerView from "lib/ui/components/reports/AnswerView"
import Typography from "lib/ui/components/typography/Typography"

import { COBL_CATEGORIES, CategoryPrefix, RATING_LIMITS } from "lib/utils/report/WellbeingCommonReportUtils"
import {
  ReportType,
  TypeOfReport,
  answersByCategory,
  answersByReportType,
  answersWithPoints,
  getAnswersAveragePoints,
  getMaxScoreForAnswer,
  pointsSorter
} from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"
import CategoryRatingsView from "../../../ui/report/CategoryRatingsView"
import ScoreCardView from "../../../ui/report/ScoreCardView"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
  reportType?: TypeOfReport
  reportKey: string
}

class SectionHealthAndWellbeingView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["report", "pages", "report", "SectionHealthAndWellbeingView"]
  }

  get coblSrc() {
    return `${this.appConfig.imagesRoot}/${this.getCoblImage()}`
  }

  get goal() {
    return this.props.answers?.find(a => a.question_key === "gm_goal")?.options?.[0]?.note
  }

  get impactAnswers() {
    const actionAnswers = answersByReportType(this.props.answers, ReportType.IMPACT)
    const previousActionAnswers =
      this.props.reportType === TypeOfReport.INITIAL || !this.props.previousAnswers
        ? []
        : answersByReportType(this.props.previousAnswers, ReportType.IMPACT)
    const answers = answersWithPoints(actionAnswers).sort(pointsSorter).slice(0, 2)
    const answer_ids = answers.map(answer => answer.question_id)
    const previous_answers = answersWithPoints(previousActionAnswers)
      .filter(answer => answer_ids.includes(answer.question_id))
      .sort((a, b) => {
        return answer_ids.indexOf(a.question_id) - answer_ids.indexOf(b.question_id)
      })
    return {
      answers,
      previous_answers
    }
  }

  render() {
    const { classes, reportType, reportKey } = this.props

    let answerViews = []
    for (let index = 0; index < this.impactAnswers.answers.length; index++) {
      const answer = this.impactAnswers.answers[index]
      const previousAnswer = this.impactAnswers.previous_answers[index]

      answerViews.push(
        <Card key={answer.question_id} className={this.props.classes.answer} variant="outlined">
          <CardContent>
            <AnswerView
              answer={answer}
              maxScore={getMaxScoreForAnswer(answer)}
              previousAnswer={previousAnswer}
              showCategory
            />
          </CardContent>
        </Card>
      )
    }
    return (
      <li>
        <Typography className={classes.header} variant="smallHeading">
          {this.txt("title")}
        </Typography>

        {this.renderSubtitle(reportType)}

        <Divider className={classes.spacing} />

        {this.renderCobl()}

        {reportType === TypeOfReport.INITIAL && (
          <>
            <div className="same-page">
              {this.renderGoal()}
              {reportKey !== "wellbeing_pulse" && (
                <Grid className={classes.spacing} container spacing={4}>
                  <Grid item xs>
                    <Divider className={classes.spacing} />
                    <Typography variant="largeBold">{this.txt("priorities")}</Typography>

                    <CategoryRatingsView answers={this.props.answers} previousAnswers={this.props.previousAnswers} />
                  </Grid>

                  <Grid item xs>
                    {/* Placeholder for personal details until we decide whether to include them */}
                  </Grid>
                </Grid>
              )}
            </div>

            <div className="same-page">
              <Typography variant="largeBold">{this.txt("success_measures_title")}</Typography>

              <Typography variant="largeBody">{this.txt("success_measures_description")}</Typography>
            </div>

            <div className="same-page">{answerViews}</div>

            <div className="hard-page-break"></div>
            <div>
              <Typography variant="largeBold">{this.txt("obstacles_title")}</Typography>
            </div>

            <div className="same-page">
              <Typography className={classes.spacing} component="p">
                {this.txt("obstacles_description")}

                <ol className={classes.obstacles}>
                  <li>{this.txt("obstacle_1")}</li>
                  <li>{this.txt("obstacle_2")}</li>
                  <li>{this.txt("obstacle_3")}</li>
                </ol>
              </Typography>
            </div>

            <div className="same-page">{this.renderChanges()}</div>
          </>
        )}
      </li>
    )
  }

  renderSubtitle(type: TypeOfReport) {
    if (type === TypeOfReport.INITIAL) {
      return <Typography variant="largeBody">{this.txt("ingress")}</Typography>
    } else if (type === TypeOfReport.FOLLOW_UP) {
      return (
        <div className="same-page">
          <Typography variant="largeBody">{this.txt("follow_up_ingress")}</Typography>
          <ol className={this.props.classes.reflects}>
            <li>{this.txt("follow_up_first_reflect")}</li>
            <li>{this.txt("follow_up_second_reflect")}</li>
            <li>{this.txt("follow_up_third_reflect")}</li>
          </ol>
        </div>
      )
    }
  }

  renderChanges() {
    const { answers, classes } = this.props

    // Question keys and titles for score card
    const titles = {
      [`${CategoryPrefix.GOAL_MOTIVATION}_change_need`]: this.txt("change_need"),
      [`${CategoryPrefix.GOAL_MOTIVATION}_change_motivation`]: this.txt("change_motivation"),
      [`${CategoryPrefix.GOAL_MOTIVATION}_change_capability`]: this.txt("change_capability"),
      [`${CategoryPrefix.GOAL_MOTIVATION}_change_support`]: this.txt("change_support")
    }

    // Answers for those keys
    const changes = Object.keys(titles)
      .map(key => {
        return {
          answer: answers.find(a => a.question_key === key),
          previousAnswer: this.props.previousAnswers?.find(p => p.question_key === key)
        }
      })
      .filter(change => change && change.answer)

    return (
      <Grid container spacing={4}>
        {changes.map(change => (
          <Grid key={change.answer.question_key} item xs sm={6}>
            <Divider className={classes.spacing} />
            <ScoreCardView
              max={10}
              previousScore={change.previousAnswer?.options[0].points}
              score={change.answer.options[0].points}
              title={titles[change.answer.question_key]}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  renderCobl() {
    const { classes, language } = this.props

    return (
      <figure className={classes.figure}>
        <Typography variant="largeBold">{this.txt("cobl_title")}</Typography>

        <Grid container>
          <Grid item xs={12} sm={3} md>
            {this.renderCoblCaption()}
          </Grid>

          <Grid item xs={12} sm={9} md={6}>
            <div className={classes.cobl}>
              <img alt="CoBL" src={this.coblSrc} />
              {this.renderCoblResults()}
            </div>
          </Grid>

          <Grid item md />
        </Grid>
      </figure>
    )
  }

  renderCoblCaption() {
    const scoreRoot = [this.appName, "components", "ui", "report", "common"]
    const scoreClasses = ["score1", "score2", "score3", "score4", "score5"]

    return (
      <figcaption className={this.props.classes.caption}>
        <p>{this.txt("scale")}</p>
        {scoreClasses.map(scoreClass => (
          <Box key={scoreClass} component="p" display={{ xs: "none", sm: "block" }}>
            <i className={`score ${scoreClass}`} />
            {this.txt(...scoreRoot, scoreClass)}
          </Box>
        ))}
      </figcaption>
    )
  }

  renderCoblResults() {
    const { answers, classes, language, previousAnswers, reportType } = this.props

    // This is the hack to choose different classNames for EN and ES language COBL
    // and to position categories in correct places. Currently even EN and ES are a
    // bit different.
    const languagesWithNewCobl = ["en", "es"]

    const classNamePrefix = languagesWithNewCobl.includes(language) ? language : ""
    return COBL_CATEGORIES.map(category => {
      const categoryAnswers = answersByCategory(answers, category)
      const average = getAnswersAveragePoints(categoryAnswers)

      const previousCategoryAnswers =
        reportType === TypeOfReport.INITIAL || !previousAnswers ? [] : answersByCategory(previousAnswers, category)
      const previousAverage = getAnswersAveragePoints(previousCategoryAnswers)
      const averageDelta = average - previousAverage

      const ratingIndex = RATING_LIMITS.findIndex(rating => average <= rating)
      const scoreClass = `score${ratingIndex + 1}`
      const icon = `${this.appConfig.imagesRoot}/icon-${category}.png`

      if (!average) return undefined

      return (
        <div key={category} className={`${classes.result} ${classNamePrefix + category}`}>
          <span className={`icon ${scoreClass}`}>
            <img src={icon} />
          </span>
          <span className="score">{average.toPrecision(2)}</span>
          {previousAverage > 0 && (
            <div style={{ position: "absolute", bottom: 4, left: `calc(50% - 15px)` }}>
              <DeltaScoreView deltaScore={averageDelta} scorePrecision={1} />
            </div>
          )}
        </div>
      )
    }).filter(Boolean)
  }

  renderGoal() {
    if (!this.goal) return null

    const { classes } = this.props

    return (
      <>
        <Divider className={classes.spacing} />

        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <Typography variant="largeBold">{this.txt("your_goal")}</Typography>
          </Grid>
          <Grid item xs={12} sm={9} className={classes.goalContainer}>
            <Typography className={classes.goal}>&ldquo;{this.goal}&rdquo;</Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  private getCoblImage() {
    const { language } = this.props

    switch (language) {
      case "en":
      case "fi":
      case "da":
      case "de":
      case "et":
      case "no":
      case "sv":
      case "pl":
      case "ru":
        return `cobl_${language}.png`
      case "fr":
      case "pt":
      case "zh":
      case "es":
        return `cobl_${language}.svg`
      default:
        return "cobl_en.png"
    }
  }
}

const scoreBallSize = 62

const scoreColors = () => ({
  "& .score1": {
    backgroundColor: ViewComponent.theme.colors.report.report_need_extra_focus.toString() + " !important"
  },
  "& .score2": {
    backgroundColor: ViewComponent.theme.colors.report.report_need_attention.toString() + " !important"
  },
  "& .score3": { backgroundColor: ViewComponent.theme.colors.report.neutral.toString() + " !important" },
  "& .score4": { backgroundColor: ViewComponent.theme.colors.report.report_well_done.toString() + " !important" },
  "& .score5": { backgroundColor: ViewComponent.theme.colors.report.report_excellent.toString() + " !important" }
})

const styles = ({ spacing }: Theme) =>
  createStyles({
    answer: { marginTop: spacing(2) },

    caption: {
      color: ViewComponent.theme.colors.deEmphasizedColor.toString(),

      "& .score": {
        borderRadius: "50%",
        display: "inline-block",
        height: "12px",
        marginRight: "10px",
        width: "12px"
      },
      ...scoreColors()
    },

    cobl: {
      position: "relative",
      margin: "0 auto",
      maxWidth: "480px",
      width: "100%",

      "& > img": {
        height: "100%",
        width: "100%"
      }
    },

    figure: {
      marginBottom: spacing(3),
      position: "relative"
    },

    goal: {
      color: ViewComponent.theme.colors.secondary.toString(),
      fontStyle: "italic",
      display: "inline",
      alignItems: "center"
    },
    goalContainer: {
      display: "flex",
      alignItems: "center"
    },
    obstacles: {
      marginTop: spacing(2),
      listStylePosition: "outside",

      "& li": { marginBottom: "10px" },
      "& li::marker": { color: ViewComponent.theme.colors.secondary.toString() }
    },

    reflects: {
      marginTop: spacing(2),
      listStylePosition: "outside",
      listStyleType: "disc",
      "& li": { marginBottom: "10px" },
      "& li::marker": { color: ViewComponent.theme.colors.secondary.toString() }
    },

    preWrap: { whiteSpace: "pre-wrap" },

    result: {
      backgroundColor: ViewComponent.theme.colors.backgroundColor.toString() + " !important",
      borderRadius: "50%",
      height: scoreBallSize,
      marginLeft: -scoreBallSize / 2,
      marginTop: -scoreBallSize / 2,
      position: "absolute",
      textAlign: "center",
      width: scoreBallSize,

      ["&." + CategoryPrefix.BIOMECHANICS]: { left: "37%", top: "73%" },
      ["&." + CategoryPrefix.CORE]: { left: "50%", top: "46%" },
      ["&." + CategoryPrefix.GENERAL_HEALTH]: { left: "37%", top: "27%" },
      ["&." + CategoryPrefix.MENTAL_ENERGY]: { left: "24%", top: "50%" },
      ["&." + CategoryPrefix.NUTRITION]: { left: "76%", top: "50%" },
      ["&." + CategoryPrefix.PHYSICAL_ACTIVITY]: { left: "63%", top: "27%" },
      ["&." + CategoryPrefix.SLEEP_AND_RECOVERY]: { left: "63%", top: "73%" },

      ["&.en" + CategoryPrefix.SLEEP_AND_RECOVERY]: { left: "37%", top: "73%" },
      ["&.en" + CategoryPrefix.CORE]: { left: "50%", top: "46%" },
      ["&.en" + CategoryPrefix.GENERAL_HEALTH]: { left: "37%", top: "27%" },
      ["&.en" + CategoryPrefix.PHYSICAL_ACTIVITY]: { left: "24%", top: "50%" },
      ["&.en" + CategoryPrefix.MENTAL_ENERGY]: { left: "76%", top: "50%" },
      ["&.en" + CategoryPrefix.NUTRITION]: { left: "63%", top: "27%" },
      ["&.en" + CategoryPrefix.BIOMECHANICS]: { left: "63%", top: "73%" },

      ["&.es" + CategoryPrefix.SLEEP_AND_RECOVERY]: { left: "37%", top: "71%" },
      ["&.es" + CategoryPrefix.CORE]: { left: "50%", top: "44%" },
      ["&.es" + CategoryPrefix.GENERAL_HEALTH]: { left: "37%", top: "25%" },
      ["&.es" + CategoryPrefix.PHYSICAL_ACTIVITY]: { left: "24%", top: "46%" },
      ["&.es" + CategoryPrefix.MENTAL_ENERGY]: { left: "76%", top: "46%" },
      ["&.es" + CategoryPrefix.NUTRITION]: { left: "63%", top: "25%" },
      ["&.es" + CategoryPrefix.BIOMECHANICS]: { left: "63%", top: "71%" },

      "& .icon": {
        borderRadius: "50%",
        height: "20px",
        position: "absolute",
        right: 0,
        top: 0,
        width: "20px"
      },
      "& img": {
        filter: "brightness(0) invert(1)", // Color the icons white
        height: "20px",
        width: "20px"
      },
      "& .score": {
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: -0.22,
        lineHeight: scoreBallSize + "px"
      },
      ...scoreColors()
    },
    header: { display: "inline" },
    spacing: {
      marginBottom: spacing(2),

      "@media print": { minWidth: "8cm" }
    }
  })

export default withStyles<any, any, Props>(styles)(SectionHealthAndWellbeingView)
