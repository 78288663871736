import Box from "@material-ui/core/Box"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import ScoreBarView from "lib/ui/components/reports/ScoreBarView"
import Typography from "lib/ui/components/typography/Typography"

import { ViewComponent } from "../../base/ViewComponent"
import ScoreView from "./ScoreView"

interface ScoreCardProps extends ViewComponentProps {
  max?: number
  previousScore?: number
  score?: number
  title?: string
}

export default class ScoreCardView extends ViewComponent<ScoreCardProps> {
  render() {
    super.render()

    const { max, previousScore, score, title } = this.props

    return (
      <div>
        <Box component="header" display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="largeBold">{title}</Typography>
          <ScoreView max={max} previousScore={previousScore} score={score} />
        </Box>
        <ScoreBarView max={max} score={score} />
      </div>
    )
  }
}
