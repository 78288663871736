import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { Answer } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"

import { RATING_LIMITS } from "lib/utils/report/WellbeingCommonReportUtils"
import { answersByCategoryName, getAnswersAveragePoints } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../base/ViewComponent"
import DeltaScoreView from "./DeltaScoreView"

interface Props extends ViewComponentProps {
  answers?: Answer[]
  previousAnswers?: Answer[]
}

class CategoryRatingsView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get topCategories() {
    const { answers, previousAnswers } = this.props

    // Parse the chosen categories, have to do it the hard way..
    const categories = answers?.find(a => a.question_key === "gm_change_desire")?.options?.map(o => o.title) ?? []

    return categories.map(category => {
      const categoryAnswers = answersByCategoryName(answers, category.en)
      const score = getAnswersAveragePoints(categoryAnswers)
      const previousCategoryAnswers = answersByCategoryName(previousAnswers, category.en)
      const previousScore = getAnswersAveragePoints(previousCategoryAnswers)
      const delta = score - previousScore
      const ratingIndex = RATING_LIMITS.findIndex(rating => score <= rating)
      const scoreClass = `score score${ratingIndex + 1}`

      return { category, delta, score, scoreClass }
    })
  }

  render() {
    if (this.topCategories.length === 0) return false

    return (
      <div className={this.props.classes.root}>
        {this.topCategories.map((c, index) => (
          <div className="li" key={index}>
            <span className="marker">{index + 1}</span>
            <span>{this.txt(c.category)}</span>
            <span className={c.scoreClass}>
              <i /> {c.score.toPrecision(2)}
            </span>
            {this.props.previousAnswers && <DeltaScoreView deltaScore={c.delta} scorePrecision={1} />}
          </div>
        ))}
      </div>
    )
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      paddingLeft: 0,

      "& .li": { paddingTop: spacing(1), display: "flex" },
      "& .li > .marker": { color: ViewComponent.theme.colors.secondary.toString(), marginRight: "0.5rem" },
      "& .score": { width: "60px" },
      "& .score1 i": {
        backgroundColor: ViewComponent.theme.colors.report.report_need_extra_focus.toString() + " !important"
      },
      "& .score2 i": {
        backgroundColor: ViewComponent.theme.colors.report.report_need_attention.toString() + " !important"
      },
      "& .score3 i": { backgroundColor: ViewComponent.theme.colors.report.neutral.toString() + " !important" },
      "& .score4 i": { backgroundColor: ViewComponent.theme.colors.report.report_well_done.toString() + " !important" },
      "& .score5 i": { backgroundColor: ViewComponent.theme.colors.report.report_excellent.toString() + " !important" },
      "& i": {
        borderRadius: "50%",
        display: "inline-block",
        height: "12px",
        width: "12px",
        marginLeft: "0.5rem"
      }
    }
  })

export default withStyles<any, any, Props>(styles)(CategoryRatingsView)
