import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import Button from "lib/ui/components/form/Button"
import LanguageSelector from "lib/ui/components/settings/LanguageSelector"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../base/ViewComponent"
import { ReportVariant } from "../../report/pages/report/WellbeingReport"

interface Props extends ViewComponentProps {
  logo: string
  language: string | undefined
  reportVariant: ReportVariant
  onDownload: () => void
  onSetLanguage: (language: string) => void
}

const backgroundColor = "#282828"

export default class ReportTopBanner extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportTopBanner"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.reportVariant)
  }

  render() {
    super.render()

    const { logo } = this.props

    return (
      <>
        <div className={styles.topGradient}></div>
        <div className={styles.banner}>
          <div className={styles.leftSideContainer}>
            <img className={styles.logo} src={logo} />
          </div>
          <div className={styles.rightSideContainer}>{this.renderButtons()}</div>
        </div>
      </>
    )
  }

  private renderButtons() {
    const { reportVariant, onDownload } = this.props

    const downloadButton = (
      <Button onClick={onDownload} className={styles.button} variant="secondary">
        {this.txt("download")}
      </Button>
    )

    const languageSelector = (
      <LanguageSelector
        variant="light"
        id="language-selector"
        language={this.props.language}
        setLanguage={this.props.onSetLanguage}
      />
    )

    return reportVariant === "mobile" ? [languageSelector, downloadButton] : [downloadButton, languageSelector]
  }
}

let styles

const setupStyles = (reportVariant: ReportVariant) => {
  styles = stylesheet({
    banner: {
      display: "flex",
      width: "100%",
      top: "0.25rem",
      height: "5rem",
      backgroundColor: backgroundColor,
      position: "fixed",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: reportVariant === "mobile" ? "1rem" : "4rem",
      paddingRight: reportVariant === "mobile" ? "1rem" : "4rem",
      zIndex: 100
    },
    topGradient: {
      display: "flex",
      width: "100%",
      top: "0rem",
      height: "0.25rem",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      background:
        "linear-gradient(to right, #EA9F88 0%, #FFE0D4 20%, #F8F6E3 40%, #B8CBC6 60%, #7996A1 80%, #537D81 100%)",
      zIndex: 100
    },
    logo: {
      width: "10rem"
    },

    button: {
      color: csx.important(ReportTopBanner.theme.colors.fontLightColor.toString()),
      background: csx.important("transparent"),
      borderColor: csx.important(ReportTopBanner.theme.colors.fontLightColor.toString()),
      borderWidth: "3px",
      height: "2.5rem"
    },

    leftSideContainer: {
      display: "flex",
      width: "17rem"
    },

    rightSideContainer: {
      display: "flex",
      width: "17rem",
      justifyContent: "space-around"
    }
  })
}
