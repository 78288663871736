import { style } from "typestyle"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { TriangleDirection } from "lib/ui/components/symbols"
import Triangle from "../../../../../lib/ui/components/symbols/Triangle"
import { ViewComponent } from "../../base/ViewComponent"

interface Props extends ViewComponentProps {
  deltaScore: number
  scorePrecision?: number
}

export default class DeltaScoreView extends ViewComponent<Props> {
  get componentName() {
    return ["ui", "report", "DeltaScoreView"]
  }

  private deltaColor = this.theme.colors.report.neutral

  render() {
    super.render()

    const { deltaScore, scorePrecision } = this.props
    const deltaTriangleSize = 0.6
    const triangleDirection: TriangleDirection = deltaScore > 0 ? "up" : "down"

    if (deltaScore > 0) this.deltaColor = this.theme.colors.report.positive
    if (deltaScore < 0) this.deltaColor = this.theme.colors.report.negative

    return (
      <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
        <Triangle
          width={deltaTriangleSize}
          height={deltaTriangleSize}
          direction={triangleDirection}
          color={this.deltaColor}
        />
        <small className={this.deltaClass()}>{deltaScore.toPrecision(scorePrecision || 1)}</small>
      </div>
    )
  }

  private deltaClass() {
    return style({
      color: this.deltaColor.toString(),
      marginLeft: "0.2rem"
    })
  }

  private deltaContainerClass = style({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  })
}
