import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { RATING_LIMITS } from "lib/utils/report/WellbeingCommonReportUtils"
import { ViewComponent } from "../../base/ViewComponent"
import ScoreView from "./ScoreView"

interface Props extends ViewComponentProps {
  previousScore?: number
  score: number
  subtitle?: string
  title?: string
}

class ScoreRatingView extends ViewComponent<Props & WithStyles<typeof styles>> {
  get componentName() {
    return ["ui", "report", "ScoreRatingView"]
  }

  private deltaColor = this.theme.colors.report.neutral

  render() {
    const { classes, previousScore, score } = this.props
    const ratingIndex = RATING_LIMITS.findIndex(rating => score <= rating)
    const scoreClass = `score${ratingIndex + 1}`
    const scoreTxt = [this.appName, "components", "ui", "report", "common", scoreClass]

    return (
      <div className={classes.root}>
        <div>
          <Typography variant="h6">{this.props.title}</Typography>
          <Typography>{this.props.subtitle}</Typography>
        </div>
        <div className={classes.scoreContainer}>
          <Chip className={scoreClass} color="primary" label={this.txt(...scoreTxt)} size="small" />
          <ScoreView max={5} previousScore={previousScore} previousScorePrecision={2} score={score.toPrecision(2)} />
        </div>
      </div>
    )
  }
}

const styles = () =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },

    scoreContainer: {
      display: "flex",
      alignItems: "center",

      "& div": {
        marginRight: "10px",

        "& span": { color: "white !important" }
      },
      "& .score1": {
        backgroundColor: ViewComponent.theme.colors.report.report_need_extra_focus.toString() + " !important"
      },
      "& .score2": {
        backgroundColor: ViewComponent.theme.colors.report.report_need_attention.toString() + " !important"
      },
      "& .score3": { backgroundColor: ViewComponent.theme.colors.report.neutral.toString() + " !important" },
      "& .score4": { backgroundColor: ViewComponent.theme.colors.report.report_well_done.toString() + " !important" },
      "& .score5": { backgroundColor: ViewComponent.theme.colors.report.report_excellent.toString() + " !important" }
    }
  })

export default withStyles<any, any, Props>(styles)(ScoreRatingView)
