import { CircularProgress } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Divider from "@material-ui/core/Divider"
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import moment from "moment"
import { createRef } from "react"

import { Survey, SurveyAnswerInstance, SurveyResult, User } from "app/surveys/modules/state/model/Model"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import BlockDiv from "lib/ui/components/layout/BlockDiv"
import Markdown from "lib/ui/components/markdown/Markdown"
import MarkdownConverter from "lib/ui/components/markdown/MarkdownConverter"
import LanguageSelector from "lib/ui/components/settings/LanguageSelector"
import Typography from "lib/ui/components/typography/Typography"
import { slugifyFileName } from "lib/utils/TextUtils"

import { TypeOfReport, getReportType } from "lib/utils/report/WellbeingPersonalReportUtils"
import { ViewComponent } from "../../../base/ViewComponent"
import SectionCoBLView from "./SectionCoBLView_old"
import SectionGoalsToActionsView from "./SectionGoalsToActionsView_old"
import SectionHealthAndWellbeingView from "./SectionHealthAndWellbeingView_old"
import SectionLifePerformanceView from "./SectionLifePerformanceView_old"
import SectionWellbeingAtWorkView from "./SectionWellbeingAtWorkView_old"
import SectionWellbeingGoalAndChangesView from "./SectionWellbeingGoalAndChanges_old"

interface PageProps extends ViewComponentProps {
  createPdf: () => Promise<Blob>
  isLoading?: boolean
  isLoadingPdf?: boolean
  surveyResult?: SurveyResult
  user?: User
  onSetLanguage: (language: string) => void
}

class ReportPageViewOld extends ViewComponent<PageProps & WithStyles<typeof styles>> {
  private downloadLink = createRef<HTMLAnchorElement>()

  get componentName() {
    return ["report", "pages", "report", "ReportPageView"]
  }

  constructor(props) {
    super(props)

    this.state = {
      showHistoryData: false
    }
  }

  onDownload = async () => {
    const pdf = await this.props.createPdf()

    // @TODO: Show some error message
    if (!pdf) return

    const a = this.downloadLink.current
    a.download = slugifyFileName(this.txt("title")) + ".pdf"
    a.href = window.URL.createObjectURL(pdf)
    a.click()
    window.URL.revokeObjectURL(a.href)
    a.href = ""
  }

  render() {
    super.render()

    return (
      <div className={this.props.classes.container}>
        {this.renderOverlayItems()}
        {this.isMobile ? this.renderMobile() : this.renderDesktop()}
      </div>
    )
  }

  private renderDownload() {
    const { classes, isLoadingPdf } = this.props

    const icon = isLoadingPdf ? (
      <CircularProgress color="secondary" size={20} />
    ) : (
      <img className={classes.icon} src={`${this.appConfig.imagesRoot}/icon-download.svg`} />
    )

    return (
      <Box displayPrint="none">
        <Button
          className={classes.download}
          color="secondary"
          disabled={isLoadingPdf}
          onClick={this.onDownload}
          size="small"
          startIcon={icon}
        >
          {this.txt("download_pdf")}
        </Button>
      </Box>
    )
  }

  private renderMobile() {
    return this.renderMain()
  }

  private renderDesktop() {
    return <Container maxWidth="md">{this.renderMain()}</Container>
  }

  private renderMain() {
    const { classes, surveyResult, isLoading, language, onSetLanguage, user } = this.props

    const reportMessage = this.getReportCustomMessage()
    const reportType = getReportType(surveyResult)

    const currentInstance: SurveyAnswerInstance = surveyResult?.instances[0]
    const previousInstance: SurveyAnswerInstance = surveyResult?.instances[1]

    if (isLoading) return false

    return (
      <Box alignItems="flex-start" display="flex" flexDirection="column" mt={this.isMobile ? 0 : 5}>
        <div className="same-page">
          <header className={classes.headerContainer}>
            <Box display="flex" justifyContent="space-between">
              <img
                alt="Hintsa"
                className={this.isMobile ? classes.logoMobile : classes.logo}
                src={this.theme.images.header}
              />
              <Box displayPrint="none">
                <LanguageSelector id="language-selector" language={language} setLanguage={onSetLanguage} />
              </Box>
            </Box>

            <Typography gutterBottom variant="largeHeading">
              {this.txt("title")}
            </Typography>
            {this.renderSubtitle(reportType, previousInstance)}

            <Box alignItems="center" display="flex" justifyContent="space-between" pb={1}>
              {user && (
                <span className={classes.name}>
                  <strong>
                    {user.first_name} {user.last_name}
                  </strong>
                  &nbsp;&bull;&nbsp;
                  {currentInstance.date?.format(this.localization.dateFormat)}
                </span>
              )}

              {surveyResult && this.renderDownload()}
            </Box>

            <Divider />
          </header>

          {this.getAnswers().length ? (
            <ol className={classes.sectionContainer}>
              <SectionLifePerformanceView
                answers={this.getAnswers()}
                reportType={reportType}
                previousAnswers={this.getPreviousAnswers()}
                language={language}
              />
              <SectionHealthAndWellbeingView
                answers={this.getAnswers()}
                reportType={reportType}
                previousAnswers={this.getPreviousAnswers()}
                language={language}
                reportKey={surveyResult?.report_key}
              />
              {reportType === TypeOfReport.INITIAL && (
                <SectionGoalsToActionsView answers={this.getAnswers()} language={language} />
              )}
              {reportType === TypeOfReport.FOLLOW_UP && (
                <SectionWellbeingGoalAndChangesView
                  answers={this.getAnswers()}
                  previousAnswers={this.getPreviousAnswers()}
                  language={language}
                />
              )}
              <SectionWellbeingAtWorkView
                answers={currentInstance.answers}
                previousAnswers={this.getPreviousAnswers()}
                language={language}
              />
              <SectionCoBLView
                answers={currentInstance.answers}
                previousAnswers={this.getPreviousAnswers()}
                language={language}
              />
            </ol>
          ) : null}
          {!!reportMessage && (
            <MarkdownConverter imagesToFullWidth={true}>
              <Markdown content={reportMessage} />
            </MarkdownConverter>
          )}
        </div>
      </Box>
    )
  }

  private renderSubtitle(reportType: TypeOfReport, previousInstance?: SurveyAnswerInstance) {
    {
      if (reportType == TypeOfReport.FOLLOW_UP && previousInstance) {
        return (
          <Typography variant="body">
            {this.txt("follow_up_subtitle").replace(
              "{0}",
              moment(previousInstance?.date?.toString()).format(this.localization.dateFormat)
            )}
          </Typography>
        )
      }
    }
  }

  private getAnswers() {
    const { surveyResult } = this.props

    return surveyResult?.instances[0]?.answers ?? []
  }

  private getPreviousAnswers() {
    const { surveyResult } = this.props

    return surveyResult?.instances[1]?.answers
  }

  private renderOverlayItems() {
    const { isLoading } = this.props

    const spinnerDelay = 500

    return isLoading ? <BlockDiv showProgress={true} progressTimeout={spinnerDelay} /> : undefined
  }

  private getReportCustomMessage() {
    const { surveyResult, language } = this.props

    const reportMessage = (surveyResult?.survey as Survey)?.report_custom_message

    return reportMessage?.[language] ?? reportMessage?.["en"] ?? null
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    container: {
      backgroundColor: ViewComponent.theme.colors.windowColor.toString(),
      minHeight: "100%",
      padding: spacing(1)
    },
    icon: {
      filter: "saturate(0)"
    },
    download: {
      textTransform: "none"
    },

    headerContainer: {
      width: "100%"
    },

    logo: { height: "40px" },
    logoMobile: { height: "25px" },

    name: { color: ViewComponent.theme.colors.deEmphasizedColor.toString() },

    sectionContainer: {
      listStylePosition: "inside",
      paddingLeft: 0,
      width: "100%",

      "& > li": {
        breakAfter: "page",
        marginBottom: spacing(4)
      },

      // H3
      "& > li::marker": {
        fontSize: "28px",
        fontWeight: 700
      },
      "& h2": {
        display: "inline-block",
        marginBottom: spacing(4),
        marginTop: spacing(4)
      },
      "& h3": {
        marginTop: spacing(3),
        marginBottom: spacing(2)
      }
    }
  })

export default withStyles<any, any, PageProps>(styles)(ReportPageViewOld)
