import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import { Circle } from "lib/ui/components/symbols"
import Typography from "lib/ui/components/typography/Typography"
import { getValueColor } from "lib/utils/report/WellbeingCommonReportUtils"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { reportBodyClass } from "../../WellbeingReport"

interface Props extends ViewComponentProps {
  name: string
  value: number | undefined
  steps: number
}

export default class ReportBigPictureLadder extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportBigPictureLadder"]
  }

  constructor(props) {
    super(props)

    setupStyles()
  }

  render() {
    const { name, value } = this.props

    return (
      <div className={styles.container}>
        <Typography useContrastColor className={styles.name}>
          {name}
        </Typography>
        {this.renderLadder()}
      </div>
    )
  }

  private renderLadder() {
    return (
      <div className={styles.ladder}>
        {this.renderSteps()}
        {this.renderSelection()}
      </div>
    )
  }

  private renderSteps() {
    const { steps } = this.props
    const renderedSteps: React.JSX.Element[] = []

    for (let i = steps; i >= 1; i--) {
      renderedSteps.push(this.renderStep(i))
    }

    return renderedSteps
  }

  private renderStep(index: number) {
    return (
      <div className={classNames(styles.ladderStep, index === 1 && styles.ladderBottomStep)} key={index}>
        <Typography useContrastColor className={classNames(reportBodyClass, styles.value)}>
          {index}
        </Typography>
      </div>
    )
  }
  private renderSelection() {
    const { value } = this.props

    if (value === undefined) return

    return (
      <div style={{ bottom: `${3 * value + 0.25}rem` }} className={styles.selectionContainer}>
        <Circle
          className={styles.circle}
          shadow
          diameter="3.5rem"
          color={getValueColor(value / 2, this.theme).toString()}
        >
          <Typography className={classNames(reportBodyClass, styles.valueClassSelected)}>{value}</Typography>
        </Circle>
      </div>
    )
  }
}

let styles

const setupStyles = () => {
  styles = stylesheet({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      height: "100%",
      width: "100%"
    },
    name: {
      fontSize: "28px",
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: 0,
      // Give layout some stability when resizing
      minHeight: "5.5rem"
    },
    ladder: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "15rem",
      padding: "0 2rem",
      marginTop: "2rem"
    },
    ladderStep: {
      display: "flex",
      height: "3rem",
      width: "100%",
      borderStyle: "solid",
      borderWidth: "0 2px 2px 2px",
      borderColor: ReportBigPictureLadder.theme.colors.fontLightColor.toString(),
      alignItems: "center",
      justifyContent: "center"
    },
    ladderBottomStep: {
      borderBottomWidth: 0
    },
    selectionContainer: {
      display: "flex",
      position: "relative"
    },
    value: {
      fontWeight: "bold"
    },
    valueClassSelected: {
      fontWeight: "bold",
      fontSize: "24px"
    }
  })
}
