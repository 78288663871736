import { SurveyResult, User } from "app/surveys/modules/state/model/Model"
import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"
import { LocalizationState } from "core/modules/state/model/Model"

import { SurveyActions } from "../../../../../surveys/modules/actions/SurveyActions"
import { LogicComponent } from "../../../base/LogicComponent"
import ReportPageViewOld from "./ReportPageView_old"

interface ReportPageProps extends LogicComponentProps {
  reportId?: string
}

interface ReportPageState extends LogicComponentState {
  language?: string
  loading: boolean
  loadingPdf: boolean
  resultId?: string
}

export default class ReportPageOld extends LogicComponent<ReportPageProps, ReportPageState> {
  declare surveyActions: SurveyActions

  constructor(props) {
    super(props)

    this.state = { loading: false, loadingPdf: false, resultId: undefined }
  }

  get dependencies(): string[] {
    return ["SurveyActions"]
  }

  componentDidMount() {
    const { reportId } = this.props
    const language = this.localization.currentLanguage
    this.setState({ language })

    reportId && this.loadReport(reportId)
  }

  createPdf = async (): Promise<Blob> => {
    if (!this.surveyResult) return

    try {
      this.setState({ loadingPdf: true })
      const margins = { top: "1.2cm", right: "1.0cm", bottom: "0.5cm", left: "1.0cm" }
      return await this.surveyActions.printPdf(this.surveyResult.uuid, this.state.language, false, margins)
    } catch (e) {
      this.logger.error("Could not create PDF report", e)
    } finally {
      this.setState({ loadingPdf: false })
    }
  }

  async loadReport(id: string) {
    try {
      this.setState({ loading: true })

      const surveyResult = await this.surveyActions.getDocument<SurveyResult>(id, "SurveyResult")

      this.setState({ resultId: surveyResult?.id })
    } catch {
      // @TODO: Add error message
    } finally {
      this.setState({ loading: false })
    }
  }

  private setLanguage = (language: string) => {
    this.updateDefault<LocalizationState>("LocalizationState", state => (state.language = language))
    this.setState({ language })
  }

  get surveyResult() {
    const surveyResult = Object.assign({}, this.doc<SurveyResult>(this.state.resultId, "SurveyResult"))

    if (!surveyResult) return undefined

    surveyResult.instances = surveyResult.instances?.filter(instance => instance.completed) ?? []

    return surveyResult
  }

  get user() {
    const userId = this.surveyResult?.user?.id

    return userId && this.doc<User>(userId, "User")
  }

  render() {
    super.render()

    return (
      <ReportPageViewOld
        createPdf={this.createPdf}
        language={this.state.language}
        isLoading={this.state.loading}
        isLoadingPdf={this.state.loadingPdf}
        surveyResult={this.surveyResult}
        user={this.user}
        onSetLanguage={this.setLanguage}
      />
    )
  }
}
