import { SurveyAnswerInstance } from "app/surveys/modules/state/model/Model"
import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import Grid from "lib/ui/components/layout/grid/Grid"
import Markdown from "lib/ui/components/markdown/Markdown"
import Typography from "lib/ui/components/typography/Typography"
import { stylesheet } from "typestyle"
import { ViewComponent } from "../../../../../base/ViewComponent"
import { ReportVariant, reportBodyClass, reportLargeBodyClass, reportMediumHeadingClass } from "../../WellbeingReport"
import ReportBigPictureLadder from "./ReportBigPictureLadder"

interface Props extends ViewComponentProps {
  answer: SurveyAnswerInstance
  comparison: SurveyAnswerInstance | undefined
  reportVariant: ReportVariant
}

export default class ReportSectionBigPicture extends ViewComponent<Props> {
  get componentName() {
    return ["report", "pages", "report", "ReportSectionBigPicture"]
  }

  constructor(props) {
    super(props)

    setupStyles(props.variant)
  }

  render() {
    const { answer, reportVariant } = this.props

    const lsLadderPoints = answer.answers?.find(answer => answer.question_key === "ls_ladder")?.options?.[0]?.points
    const wpLadderPoints = answer.answers?.find(answer => answer.question_key === "wp_ladder")?.options?.[0]?.points

    return (
      <>
        {reportVariant === "pdf" && <div className="hard-page-break" />}
        <Grid container>
          <Grid spacer spacerSize={6} />
          <Grid sm={12} lg={reportVariant === "pdf" ? 12 : 6}>
            {this.renderDescription(lsLadderPoints! >= 9 && wpLadderPoints! >= 9)}
          </Grid>
          {reportVariant === "pdf" && <Grid spacer spacerSize={12} />}
          <Grid sm={12} lg={reportVariant === "pdf" ? 12 : 6} className={styles.ladderGrid}>
            {this.renderLadders(lsLadderPoints, wpLadderPoints)}
          </Grid>
        </Grid>
      </>
    )
  }

  private renderDescription(isHighScore: boolean) {
    return (
      <div className={styles.descriptionContainer}>
        <Typography useContrastColor className={classNames(reportMediumHeadingClass, styles.heading)}>
          {this.txt("title")}
        </Typography>
        <Typography useContrastColor className={classNames(reportLargeBodyClass, styles.descriptionLargeBody)}>
          {this.txt("subtitle")}
        </Typography>
        <Typography component="div" useContrastColor className={classNames(reportBodyClass, styles.descriptionBody)}>
          <Markdown content={isHighScore ? this.txt("description_high_score") : this.txt("description_standard")} />
        </Typography>
      </div>
    )
  }

  private renderLadders(lsLadderPoints: number | undefined, wpLadderPoints: number | undefined) {
    const { answer } = this.props

    const numberOfSteps = 10

    return (
      <div className={styles.ladderContainer}>
        <ReportBigPictureLadder name={this.txt("life_satisfaction")} value={lsLadderPoints} steps={numberOfSteps} />
        <ReportBigPictureLadder name={this.txt("work_performance")} value={wpLadderPoints} steps={numberOfSteps} />
      </div>
    )
  }
}

let styles

const setupStyles = (variant: ReportVariant) => {
  styles = stylesheet({
    descriptionContainer: {
      display: "flex",
      flexDirection: "column"
    },
    ladderGrid: {
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    ladderContainer: {
      marginTop: variant === "mobile" ? "1rem" : "4rem",
      display: "flex",
      scale: variant === "mobile" ? "80%" : undefined
    },
    heading: {
      marginBottom: 0
    },
    descriptionLargeBody: {
      marginTop: "2rem",
      marginBottom: "0.5rem"
    },
    descriptionBody: {
      margin: 0
    }
  })
}
