import { SurveyResult, User } from "app/surveys/modules/state/model/Model"
import { LogicComponent } from "app/surveys_app/components/base/LogicComponent"
import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"
import { LocalizationState } from "core/modules/state/model/Model"

import { SurveyActions } from "app/surveys/modules/actions/SurveyActions"
import { isMobile } from "lib/ui/compatibility/Browsers"
import { slugifyFileName } from "lib/utils/TextUtils"
import { createRef } from "react"
import ReportPageView from "./ReportPageView"

interface ReportPageProps extends LogicComponentProps {
  reportId?: string
}

interface ReportPageState extends LogicComponentState {
  language?: string
  loading: boolean
  loadingPdf: boolean
  resultId?: string
  selectedReportIndex: number
}

export default class ReportPage extends LogicComponent<ReportPageProps, ReportPageState> {
  declare surveyActions: SurveyActions

  private downloadLink = createRef<HTMLAnchorElement>()

  constructor(props) {
    super(props)
    // Set loading initially true to make blockdiv animation befave nicer
    this.state = { loading: true, loadingPdf: false, resultId: undefined, selectedReportIndex: 0 }
  }

  get dependencies(): string[] {
    return ["SurveyActions"]
  }

  render() {
    super.render()

    const { selectedReportIndex } = this.state

    return (
      <>
        <a ref={this.downloadLink} />
        <ReportPageView
          reportVariant={this.getReportVariant()}
          language={this.state.language}
          isLoading={this.state.loading}
          isLoadingPdf={this.state.loadingPdf}
          surveyResult={this.surveyResult}
          user={this.user}
          onDownload={this.onDownload}
          onSetLanguage={this.setLanguage}
          selectedReportIndex={selectedReportIndex}
          onSelectReport={this.onSelectReport}
        />
      </>
    )
  }

  componentDidMount() {
    const { reportId } = this.props
    const language = this.localization.currentLanguage
    this.setState({ language })

    reportId && this.loadReport(reportId)
  }
  onDownload = async () => {
    const pdf = await this.createPdf()

    // @TODO: Show some error message
    if (!pdf) return

    const a = this.downloadLink.current!
    a.download = slugifyFileName(this.txt("title")) + ".pdf"
    a.href = window.URL.createObjectURL(pdf)
    a.click()
    window.URL.revokeObjectURL(a.href)
    a.href = ""
  }

  private createPdf = async (): Promise<Blob | undefined> => {
    if (!this.surveyResult) return

    try {
      this.setState({ loadingPdf: true })
      const margins = { top: "0", right: "0", bottom: "0", left: "0" }
      return await this.surveyActions.printPdf(this.surveyResult.uuid!, this.state.language, true, margins)
    } catch (e) {
      this.logger.error("Could not create PDF report", e)
    } finally {
      this.setState({ loadingPdf: false })
    }
  }

  async loadReport(id: string) {
    try {
      this.setState({ loading: true })

      const surveyResult = await this.surveyActions.getDocument<SurveyResult>(id, "SurveyResult")

      this.setState({ resultId: surveyResult?.id })
    } catch {
      // @TODO: Add error message
    } finally {
      this.setState({ loading: false })
    }
  }

  private setLanguage = (language: string) => {
    this.updateDefault<LocalizationState>("LocalizationState", state => (state.language = language))
    this.setState({ language })
  }

  private onSelectReport = (index: number) => {
    this.setState({ selectedReportIndex: index })
  }

  get surveyResult() {
    const surveyResult = Object.assign({}, this.doc<SurveyResult>(this.state.resultId, "SurveyResult"))

    if (!surveyResult) return undefined

    surveyResult.instances = surveyResult.instances?.filter(instance => instance.completed) ?? []

    return surveyResult
  }

  get user() {
    const userId = this.surveyResult?.user?.id

    return userId ? this.doc<User>(userId, "User") : undefined
  }

  private getReportVariant() {
    if (this.isPrintMode()) return "pdf"

    if (isMobile()) return "mobile"

    return "desktop"
  }

  private isPrintMode() {
    try {
      const params = new URLSearchParams(location.search)

      const mediaQueryList = window.matchMedia("print")
      return mediaQueryList.matches || params.get("printmode") === "1"
    } catch (e) {
      return false
    }
  }
}
