import { ViewComponentProps } from "core/components/base/ViewComponent"

import ThemeProvider from "core/components/base/ThemeProvider"
import Survey from "../../components/survey/base/Survey"
import { ViewComponent } from "../base/ViewComponent"
import ReportPage from "../report/pages/report/ReportPage"
import ReportPageOld from "../report/pages/report_old/ReportPage_old"

interface RootComponentViewProps extends ViewComponentProps {}

export default class RootComponentView extends ViewComponent<RootComponentViewProps> {
  get componentName(): string[] {
    return ["survey_app", "components", "RootComponentView"]
  }

  render() {
    return <ThemeProvider theme={this.theme}>{this.renderPage()}</ThemeProvider>
  }

  private renderPage() {
    const path = location.pathname.split("/").filter(Boolean)
    const page = path[0] ?? ""
    const id = path[1]

    switch (page) {
      case "report":
        return <ReportPageOld reportId={id} />
      case "report_new":
        return <ReportPage reportId={id} />
      case "":
        return <Survey />
      default:
        throw `Invalid page: "${page}"`
    }
  }
}
